import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarService } from '../../services/sidebar.service';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { FilterPillButtonComponent } from '../filter-pill-button/filter-pill-button.component';
import { ButtonComponent } from '../button/button.component';
import { TimeInputComponent } from '../inputs/time-input/time-input.component';
import { ModalComponent } from '../modal/modal.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { HttpService } from '../../services/http.service';
import { ToastrService } from 'ngx-toastr';
import { CheckInService } from '../../services/check-in.service';
import { CheckOutService } from '../../services/check-out.service';
import { AttendanceService } from '../../services/attendance.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass, RouterLink, RouterLinkActive, FormsModule, NgFor, NgIf, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, TimeInputComponent, ModalComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './sidebar.component.html',
  providers: [DatePipe]
})
export class SidebarComponent {
  @Output() link_clicked = new EventEmitter<any>();
  location_permission_modal: boolean = false
  active_link_id: number | null = null;
  current_link: any;
  drawer_open = false;
  mobile_sub_sidebar = false;
  main_link: string = '';
  @ViewChild('view_attandance_modal') view_attandance_modal: any;
  @ViewChild('view_attandance_modal2') view_attandance_modal2: any;
  @ViewChild('check_out_redirect') check_out_redirect: any;
  showModal: boolean = false;
  latitude?: number;
  longitude?: number;
  countdownTimer: any;
  isCheckIn: boolean = true;
  isCheckout: boolean = true;
  checkInButtonText: string = 'Check In';
  disabled_btn: any;
  remark: string = '';
  form: any = this.fb.group({
    check_in_time: [''],
    remark: ' ',
    check_out_time: [''],
  })
  onAddReportClick() {
    const url = '/daily-log/form?remark=' + encodeURIComponent(this.remark);
    this.router.navigateByUrl(url);
    this.check_out_redirect.close()
  }
  constructor(public ss: SidebarService, public fb: FormBuilder, public gs: GlobalService, public router: Router, public hs: HttpService, public toastr: ToastrService, public cis: CheckInService, public cos: CheckOutService, public atts: AttendanceService, public datePipe: DatePipe,) { }
  getCurrentTime(is_checkin: boolean): string {
    const now = new Date();
    const formattedTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    console.log(formattedTime, typeof formattedTime, "FFFFFFFFFFFF");
    if (is_checkin) {
      this.form?.get('check_in_time')?.setValue(formattedTime, { emitEvent: false });
    } else {
      this.form?.get('check_out_time')?.setValue(formattedTime, { emitEvent: false });
    }
    return formattedTime;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }


  ngOnInit() {
    this.check_currentUrl()
  }

  check_currentUrl() {
    const currentUrl = this.router.url;
    const items = this.ss.sidebar_data[this.gs.user.role];
    for (const item of items) {
      if (item.router_link === currentUrl) {
        this.main_link = item.label;
      } else if (item.sublinks) {
        for (const sublink of item.sublinks) {
          if (sublink.router_link === currentUrl) {
            this.main_link = item.label;
          }
        }
      }
    }
  }

  activateLink(link: any) {
    this.ss.sidebar_data[this.gs.user.role].forEach((item: any) => {
      if (item.id === link.id) {
        item.is_open = !item.is_open;
      } else {
        item.is_open = false;
      }
    });
    if (link.router_link) {
      console.log(link.query_params, ";;;;;;;;;;;;", this.gs.selected_school);
      let school_data = localStorage.getItem('selected_school')
      if (school_data && link.query_params) {
        let school = JSON.parse(school_data)
        this.router.navigate([link.router_link], { queryParams: {id: school?.school_id} })
      }else{
        this.router.navigate([link.router_link])
      }
      this.link_clicked.emit(false)
      this.drawer_open = false;
      this.active_link_id = null;
      this.current_link = link
      return
    }
    if (this.active_link_id == link.id) {
      this.drawer_open = false;
      this.active_link_id = null;
      this.link_clicked.emit(false)
    } else {
      this.drawer_open = true;
      this.active_link_id = link.id;
      this.current_link = link
      this.link_clicked.emit(true)
    }
  }

  mouseOver(link: any) {
    if (link.router_link) {
      return
    }
    if (!this.active_link_id) {
      this.current_link = link
      this.drawer_open = true;
    }
  }

  mouseLeave() {
    this.check_currentUrl()
    if (this.active_link_id) {
      this.drawer_open = true;
    } else {
      this.drawer_open = false;
    }
  }

  toggle_sub_sidebar(link: any) {
    if (link.router_link) {
      // this.router.navigate([link.router_link])
      this.router.navigate([link.router_link], { queryParams: link.query_params })

      this.drawer_open = false
      return
    }
    this.current_link = link
    this.mobile_sub_sidebar = !this.mobile_sub_sidebar
  }

  mobile_sublink_clicked(route: string) {
    this.router.navigate([route])
    this.mobile_sub_sidebar = false;
    this.drawer_open = false
  }

  web_sublink_clicked() {
    this.check_currentUrl()
  }

  async onCheckout() {
    if (this.gs.attendnace?.check_in_time != null) {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const now = new Date();
          const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
          const data: any = {
            date: formattedDate,
            check_out_time: this.form.value.check_out_time,
            check_out_lat: this.latitude.toString(),
            check_out_long: this.longitude.toString(),
            remark: this.form.value.remark || "",
            school_id: this.gs.selected_school.school_id ? this.gs.selected_school.school_id : 0
          };
          console.log(data, "kkkkjk");
          try {
            let response: any = await this.cos.add(data);
            let filter_object = {
              date: formattedDate
            }
            this.form.reset();
            this.atts.get_current_day_attendance(filter_object)
            this.toastr.success(response.message || 'Check Out Successfully!')
          } catch (error: any) {
            console.error(error?.error?.message, '');
            this.toastr.error(error?.error?.message);
          }
          this.view_attandance_modal.close();
          this.check_out_redirect.open()
          this.isCheckIn = !this.isCheckIn;
          this.location_permission_modal = false
        }, (error) => {
          console.error('Error getting location:', error);
          this.location_permission_modal = true
          alert('Error, Getting your location. Please enable if off!')
        },
          options);
      } else {
        console.error('Geolocation is not supported by this browser.');
        this.location_permission_modal = true
        alert("Location Permission Required")
      }
    } else {
      this.performCheckOut();
      this.view_attandance_modal.close();
      this.isCheckIn = !this.isCheckIn;
      this.check_out_redirect.open()
    }
  }

  async onCheckIn() {
    if (this.gs.attendnace?.check_in_time == null) {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const now = new Date();
          const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
          const add: any = {
            date: formattedDate,
            check_in_time: this.form.value.check_in_time,
            check_in_lat: this.latitude.toString(),
            check_in_long: this.longitude.toString(),
            school_id: this.gs.selected_school.school_id ? this.gs.selected_school.school_id : 0
          }
          this.location_permission_modal = false
          console.log(add, "kkkkjk");
          try {
            let response: any = await this.cis.add(add);
            let filter_object = {
              date: formattedDate
            }
            this.form.reset();
            this.atts.get_current_day_attendance(filter_object)
            this.toastr.success(response.message || 'Check In Successfully!')
          } catch (error: any) {
            console.error(error?.error?.message, '');
            this.toastr.error(error?.error?.message);
          }
        }, (error) => {
          console.error('Error getting location:', error);
          this.location_permission_modal = true
          alert('Error, Getting your location. Please enable if off!')
        }, options);

      } else {
        console.error('Geolocation is not supported by this browser.');
        this.location_permission_modal = true
        alert("Location Permission Required")
      }
    } else {
      this.performCheckOut();
    }
    this.view_attandance_modal2.close();
    this.isCheckIn = !this.isCheckIn;
  }
  performCheckOut() {
    clearInterval(this.countdownTimer);
  }

  async sign_out() {
    try {
      let response = await this.hs.get('/auth/sign-out', {});
      if (response) {
        localStorage.removeItem('user')
        localStorage.removeItem('selected_school')
        this.gs.user = {}
        this.gs.selected_school = {}
        this.gs.attendnace = {}
        this.toastr.success('Sign Out Successfully!')
        this.router.navigate(['/sign-in']);
      }
    } catch (error: any) {
      console.log(error);
      this.toastr.error(error || 'Something Went Wrong!')
    }
  }

}
