import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '../../components/modal/modal.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { UploderComponent } from '../../uploder/uploder.component';
import { GlobalService } from '../../services/global.service';
import { CourseTreeComponent } from '../../components/course-tree/course-tree.component';
import { GetLevelNameControlPipe } from '../../pipes/get-level-name-control.pipe';
import { GetStudyMaterialControlPipe } from '../../pipes/get-study-material-control.pipe';
import { MaterialTypeService } from '../../services/material-type.service';
import { TitleByValuePipe } from '../../pipes/title-by-value.pipe';
import { GradeService } from '../../services/grade.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from '../../services/course.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';
import { FileService } from '../../services/file.service';
import { SplitStringPipe } from '../../pipes/split-string.pipe';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { FileTypePipe } from '../../pipes/file-type.pipe';
import { SubjectService } from '../../services/subject.service';

@Component({
  selector: 'app-course-form-page',
  standalone: true,
  imports: [HeaderComponent, SafeUrlPipe, FileTypePipe, ZoroFileInputComponent, ButtonComponent, NgxUiLoaderModule, TextInputComponent, TitleByValuePipe, NgClass, SelectInputComponent, NgFor, NgIf, FormsModule, RouterLink, ModalComponent, ConfirmationPopupComponent, IconCtaComponent, UploderComponent, ReactiveFormsModule, CourseTreeComponent, GetLevelNameControlPipe, JsonPipe, GetStudyMaterialControlPipe, SplitStringPipe],
  templateUrl: './course-form-page.component.html',
})
export class CourseFormPageComponent {
  @ViewChild('add_course_button') add_course_button: any
  @ViewChild('update_course_button') update_course_button: any
  @ViewChild('open_media') open_media: any
  selected_material: any = '';
  selected_level_array: any = []
  // change_name(level_array: any[], name: string) {
  //   let position_reference: any = [...this.treeData]
  //   level_array.forEach((item: any) => {
  //     position_reference = position_reference[item]
  //   })
  //   position_reference.name = name
  //   this.treeData = { ...this.treeData }
  //   console.log(this.treeData);
  // }

  dropdown_list: any = {}
  study_materail_field: any = []
  params: any;
  view: any = false
  courseForm: any
  current_data: any
  previewImages: any
  option: any
  imageUrl: any
  disable: any = false
  file_url: string = ''

  images: any = [
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'video', title: 'Computer Network' },
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'image', title: 'Computer system' },
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'video', title: 'Python Programming' },
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'file', title: 'Web Designing' },
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'image', title: 'Java Programming' },
    { image: 'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type: 'video', title: 'C Programming' },
  ]
  videos: any = []

  constructor(public fs: FileService, public cs: CourseService, public router: Router, public toastr: ToastrService, public ui_spinner: NgxUiLoaderService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, public gs: GlobalService, public ms: MaterialTypeService, public grade_service: GradeService, public subject_service: SubjectService) {
  }

  add_study_material_2(level_array: any[]) {
    let control_to_push = this.fb.group({ front_end_unique_id: uuidv4(), id: '', name: ['', Validators.required], material_type_id: ['', Validators.required], value: ['', Validators.required], language: ['', Validators.required], visibility: ['', Validators.required] })
    if (level_array.length == 0) {
      this.courseForm.controls.materials.push(control_to_push)
    }
    if (level_array.length == 1) {
      // console.log(this.courseForm.controls.levels, level_array)
      this.courseForm.controls.levels.controls[level_array[0]].controls.materials.push(control_to_push)
    }
    if (level_array.length == 2) {
      this.courseForm.controls.levels.controls[level_array[0]].controls.levels.controls[level_array[1]].controls.materials.push(control_to_push)
    }
    this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
  }
  remove_study_material_2(level_array: any[], position: number) {
    let study_material: any
    if (level_array.length == 0) {
      study_material = this.courseForm.value.materials[position]
      this.courseForm.controls.materials.removeAt(position)
    }
    if (level_array.length == 1) {
      study_material = this.courseForm.value.levels[level_array[0]].materials[position]
      this.courseForm.controls.levels.controls[level_array[0]].controls.materials.removeAt(position)
    }
    if (level_array.length == 2) {
      study_material = this.courseForm.value.levels[level_array[0]].levels[level_array[1]].materials[position]
      this.courseForm.controls.levels.controls[level_array[0]].controls.levels.controls[level_array[1]].controls.materials.removeAt(position)
    }
    if (study_material.id) {
      this.final_changes['deleted_study_materials'] = this.final_changes['deleted_study_materials'] || {}
      this.final_changes['deleted_study_materials'][study_material.id] = true
    } else {
      this.final_changes['newly_added_deleted_study_materials'] = this.final_changes['newly_added_deleted_study_materials'] || {}
      this.final_changes['newly_added_deleted_study_materials'][study_material.front_end_unique_id] = true
    }
    this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
  }

  add_level_2(level_array: any[]) {
    let control_to_push = this.fb.group({ front_end_unique_id: uuidv4(), id: '', grade_id: this.courseForm.value.grade_id, name: ['', Validators.required], materials: this.fb.array([]), levels: this.fb.array([]) })
    if (level_array.length == 0) {
      this.courseForm.controls.levels.push(control_to_push)
    }
    if (level_array.length == 1) {
      this.courseForm.controls.levels.controls[level_array[0]].controls.levels.push(control_to_push)
    }
    this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
    // console.log(this.courseForm, "aaaaaaaaaaaaaaaaaaaaaaaa");
  }

  remove_level_2(level_array: any[]) {
    let course;
    if (level_array.length == 1) {
      course = this.courseForm.value.levels[level_array[0]]
      this.courseForm.controls.levels.removeAt(level_array[0])
    }
    if (level_array.length == 2) {
      course = this.courseForm.value.levels[level_array[0]].levels[level_array[1]]
      this.courseForm.controls.levels.controls[level_array[0]].controls.levels.removeAt(level_array[1])
    }
    if (course.id) {
      this.final_changes['deleted_courses'] = this.final_changes['deleted_courses'] || {}
      this.final_changes['deleted_courses'][course.id] = true
    } else {
      this.final_changes['newly_added_deleted_courses'] = this.final_changes['newly_added_deleted_courses'] || {}
      this.final_changes['newly_added_deleted_courses'][course.front_end_unique_id] = true
    }
    this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
  }

  changed_controls: any[] = [];
  final_changes: any = {}
  form_change_subscriptions: any = [];


  trackFormChanges(formGroup: FormGroup | FormArray, path: string, parent_id: any, parent_type: string): void {
    // parent_id = formGroup.value.id
    // // console.log({...this.courseForm.value})
    // // return;
    if (path == '') {
      this.form_change_subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe())
    }
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      const controlPath = path !== '' ? `${path}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        // console.log(controlPath)
        let parent_id = control.value.id || control.value.front_end_unique_id
        let parent_type = control.value.hasOwnProperty('visibility') ? 'Study_Material' : 'Course';
        this.trackFormChanges(control as FormGroup, controlPath, parent_id, parent_type); // Recursively handle FormGroup or FormArray
      } else if (control instanceof FormControl) {
        let subscription = control.valueChanges.subscribe(() => {
          // Store the key and path of the control that changed
          if (!this.changed_controls.find(item => item.path == controlPath)) {
            this.changed_controls.push({ path: controlPath, key: key, parent_id, parent_type, value: control.value });
            if (isNaN(parent_id)) {
              this.final_changes['new_' + parent_type.toLowerCase() + 's'] = this.final_changes['new_' + parent_type.toLowerCase() + 's'] || {}
              this.final_changes['new_' + parent_type.toLowerCase() + 's'][parent_id] = true
            } else {
              this.final_changes['updated_' + parent_type.toLowerCase() + 's'] = this.final_changes['updated_' + parent_type.toLowerCase() + 's'] || {}
              this.final_changes['updated_' + parent_type.toLowerCase() + 's'][parent_id] = true
            }
            // console.log('Changes in Form', this.final_changes, this.changed_controls)
          }
        });
        this.form_change_subscriptions.push(subscription);
      }
    });
  }

  async ngOnInit() {

    this.courseForm = this.fb.group({
      id: [''],
      front_end_unique_id: uuidv4(),
      language: ['', Validators.required],
      course_code: ['', Validators.required],
      name: ['', Validators.required],
      duration: ['',[Validators.required,Validators.pattern('^-?\\d*(\\.\\d+)?$')]],
      grade_id: ['', Validators.required],
      subject: ['', Validators.required],
      number_of_levels: [0, Validators.required],
      level1_name: 'Topic',
      level2_name: 'Sub Topic',
      description: ['', Validators.required],
      materials: this.fb.array([]),
      levels: this.fb.array([])
    });

    this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
    await this.get_dropdown_list()

    this.courseForm.get('grade_id').valueChanges.subscribe((value: any) => {
      console.log(value);
      this.update_grade_ids(value);
    });

    this.activatedRoute.queryParams.subscribe(async params => {
      this.params = { ...params }
      if (params['id']) {
        // this.view = true
        let course = await this.get_course(params['id'])
        // console.log(course);
        this.add_levels_and_study_materials_in_form(course)
        // this.rectify_study_material(course)
        this.courseForm.patchValue(course, { emitEvent: false });
        this.trackFormChanges(this.courseForm, '', this.courseForm.value.id || this.courseForm.value.front_end_unique_id, 'Course');
        this.changed_controls = []
        this.final_changes = {}
      }
    });
  }

  rectify_study_material(course: any) {
    course.materials.forEach((material: any) => {
      material.material_type_id = material.material_type.id
    })
    course.levels.forEach((level: any) => {
      this.rectify_study_material(level)
    })
  }



  async get_dropdown_list() {
    try {
      const material_type_response = await this.ms.get_list({})
      const grade_response = await this.grade_service.get_list({})
      const subject_response = await this.subject_service.get_list({})
      let material_type_options = await material_type_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: parseInt(item.id) || "none",
          material_category: item.material_category,
          is_multiple_allow: item.is_multiple_allow,
          supportted_extensions: item.supportted_extensions,
        };
      });
      let grade_options = await grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: parseInt(item.id) || "none",
        };
      });
      let subject_options = await subject_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: parseInt(item.id) || "none",
        };
      });
      this.dropdown_list.material_type_list = material_type_options;
      this.dropdown_list.grade_list = grade_options;
      this.dropdown_list.subject_list = subject_options;
      console.log(this.dropdown_list, "Lsit");

    } catch (error: any) {
      console.error("Error fetching Material Type list:", error);
    }
  }

  async add() {
    if (this.courseForm.valid) {
      this.ui_spinner.startLoader('add-country-loader');
      this.add_course_button.disabled = true
      console.log(this.courseForm);
      try {
        let response: any = await this.cs.add(this.courseForm.value)
        this.courseForm.reset();
        this.ui_spinner.stopLoader('add-country-loader');
        this.toastr.success(response.message || 'Course Added Successfully!')
        this.ui_spinner.stopLoader('update-udise-loader');
        this.add_course_button.disabled = false
        this.router.navigate(['/master/course']);
      } catch (error: any) {
        this.toastr.error(error?.error?.message)
        this.ui_spinner.stopLoader('add-country-loader');
        this.add_course_button.disabled = false
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.courseForm.markAllAsTouched();
    }
  }

  // update() {
  //   let body = {
  //     data: this.courseForm.value,
  //     changes: this.final_changes
  //   }
  //   console.log(body);
  // }

  async update(id: number) {
    if (this.courseForm.valid) {
    let body = {
      data: this.courseForm.value,
      changes: this.final_changes
    }
    this.update_course_button.disabled = true
    this.ui_spinner.startLoader('update-country-loader');
    try {
      let response = await this.cs.update(id, body);
      this.toastr.success(' Updated Successfully!')
      this.courseForm.reset();
      this.update_course_button.disabled = false
      this.ui_spinner.stopLoader('update-country-loader');
      this.router.navigate(['/master/course']);
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.update_course_button.disabled = false
      this.ui_spinner.stopLoader('update-country-loader');
      this.toastr.error(error?.error?.message)
    }
    } else {
      this.courseForm.markAllAsTouched();
    }
  }

  async get_course(id: any) {
    console.log(id);
    try {
      let data = await this.cs.get(id)
      this.toastr.success(data.message)
      return data.data
    } catch (error: any) {
      console.error("Error fetching course:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async add_levels_and_study_materials_in_form(course: any) {
    try {
      course.materials?.forEach((material: any) => {
        this.add_study_material_2([])
      })
      course.levels?.forEach((level: any, index1: any) => {
        this.add_level_2([]);
        level.materials?.forEach((material: any) => {
          this.add_study_material_2([index1])
        })
        level.levels?.forEach((level2: any, index2: any) => {
          this.add_level_2([index1]);
          level2.materials?.forEach((material: any) => {
            this.add_study_material_2([index1, index2])
          })
        })
      })
    } catch (error) {
      console.log(error)
    }
  }


  add_study_material() {
    this.study_materail_field.push({})
  }

  level_selected(position: any) {
    this.selected_level_array = position
  }
  add_level(position: any) {
    console.log(position);
    this.add_level_2(position)
  }

  get_title_by_value(value: any): string | undefined {
    const material = this.dropdown_list?.material_type_list.find((item: any) => item.value == value);
    return material ? material.title : undefined;
  }

  update_grade_ids(grade_id: string): void {
    // Update all grade_id fields in nested objects
    this.update_grade_id_in_levels(this.courseForm.get('levels') as FormArray, grade_id);
  }

  update_grade_id_in_levels(levels: FormArray, grade_id: any): void {
    for (let i = 0; i < levels.length; i++) {
      const level = levels.at(i) as FormGroup | any;
      level.get('grade_id').setValue(grade_id, { emitEvent: false });
      const subLevels = level.get('levels') as FormArray;
      if (subLevels.length > 0) {
        this.update_grade_id_in_levels(subLevels, grade_id);
      }
    }
  }
  open_attachment(attachment: string) {
    console.log(attachment);

    if (!attachment.includes('.xlsx')) {
      this.open_media.open()
      this.file_url = this.fs.base_gcs_url + attachment
    }
  }

  goToLink(url: string) {
    window.open(this.fs.base_gcs_url + url, "_blank");
  }

  download_template(file_url: string): void {
    const a = document.createElement('a');
    a.href = file_url;
    a.target = '_blank';
    a.download = file_url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
