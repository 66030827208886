import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ButtonComponent } from '../../components/button/button.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { SchoolService } from '../../services/school.service';
import { DistrictsService } from '../../services/districts.service';
import { StateService } from '../../services/state.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service';
import { CountryService } from '../../services/country.service';
import { BlockService } from '../../services/block.service';
import { UdiseService } from '../../services/udise.service';

@Component({
  selector: 'app-school-form-page',
  standalone: true,
  imports: [HeaderComponent, NgxUiLoaderModule, TextInputComponent, ButtonComponent, RouterLink, SelectInputComponent, FormsModule, ReactiveFormsModule, NgFor, NgIf],
  templateUrl: './school-form-page.component.html',
})
export class SchoolFormPageComponent {
  @ViewChild('update_school_button') update_school_button: any
  @ViewChild('add_school_bitton') add_school_bitton: any

  selectedUDISECode: string = '';
  isTabledisabled: boolean = true;
  newRowIndex: number = -1;
  params: any = {};
  selected_school: any = {};
  dropdown_list: any = {}


  list: any = [
    { asset_name: 'Laptop', Initial_Quantity: "10", Current_Quantity: "8" },
    { asset_name: 'White Board', Initial_Quantity: "20", Current_Quantity: "9" },
    { asset_name: 'Keyboard', Initial_Quantity: "18", Current_Quantity: "10" },
  ]

  constructor(public ui_spinner: NgxUiLoaderService, public udise_ser: UdiseService, public cs: CountryService, public bs: BlockService, public toastr: ToastrService, public fb: FormBuilder, public ss: SchoolService, public sts: StateService, public ds: DistrictsService, public ar: ActivatedRoute, public router: Router, public gs: GlobalService,) { }

  school_form: any = this.fb.group({
    udise_code: ['', Validators.required],
    name: ['', Validators.required],
    district_id: ['', Validators.required],
    country_id: ['', Validators.required],
    block_id: ['', Validators.required],
    // udise_block: ['', Validators.required],
    location_type: ['', Validators.required],
    village_name: ['', Validators.required],
    gram_panchayat_name: ['', Validators.required],
    ward_name: ['', Validators.required],
    urban_local_bodies: ['', Validators.required],
    address: ['', Validators.required],
    cluster_resourse_centre: ['', Validators.required],
    assembly_constituency: ['', Validators.required],
    parliamentary_constituency: ['', Validators.required],
    lat: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$')]],
    long: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$')]],
    school_contact_details: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
    std_code: ['', Validators.required],
    landline_number: ['', Validators.required],
    contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
    school_website: ['', Validators.required],
    email: ['', Validators.required],
    head_of_school: ['', Validators.required],
    hos_in_charge_name: ['', Validators.required],
    hos_contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
    hos_email: ['', Validators.required],
    respondent_type: ['', Validators.required],
    respondent_name: ['', Validators.required],
    respondent_contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
    special_school_for_cwsn: ['', Validators.required],
    state_id: ['', Validators.required],
    city: ['', Validators.required],
    pincode: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(6), Validators.maxLength(6)]],

    // -----x-----
    primary_only_with_grades_1_to_5: ['',[Validators.required]],
    upper_primary_with_grades_1_to_8: ['',[Validators.required]],
    higher_secondary_with_grades_1_to_12: ['',[Validators.required]],
    upper_primary_only_with_grades_6_to_8: ['',[Validators.required]],
    higher_secondary_with_grades_6_to_12: ['',[Validators.required]],
    secondary_with_grades_6_to_10: ['',[Validators.required]],
    secondary_only_with_grades_9_to_10: ['',[Validators.required]],
    higher_secondary_with_grades_9_to_12: ['',[Validators.required]],
    hr_sec_jr_college_only_with_grades_11_12: ['',[Validators.required]],
    lowest_and_highest_class_in_school: ['',[Validators.required]],
    type_of_the_school: ['',[Validators.required]],
    affiliation_board_of_the_school_for_secondary_sections: ['',[Validators.required]],
    affiliation_board_of_the_school_for_higher_secondary_sections: ['',[Validators.required]],
    respondent_email: ['',[Validators.required]],
    academic_session_start_date: ['',[Validators.required]],
    academic_session_end_date: ['',[Validators.required]],
    medium_of_instruction_1: ['',[Validators.required]],
    medium_of_instruction_2: ['',[Validators.required]],
    medium_of_instruction_3: ['',[Validators.required]],
    medium_of_instruction_4: ['',[Validators.required]],
    is_special_school_for_cwsn: ['',[Validators.required]],
    type_of_special_school: ['',[Validators.required]],
    is_majority_of_pupils_are_of_primary_level: ['',[Validators.required]],
    is_teacher_attendance_captured: ['',[Validators.required]],
    is_inventory_register_on_ict_items: ['',[Validators.required]],
    number_of_laboratory_rooms_including_computer_labs: ['',[Validators.required]],
    audio_visual_public_address_system: ['',[Validators.required]],
    desktop_pcs: ['',[Validators.required]],
    laptop_notebook: ['',[Validators.required]],
    tablets: ['',[Validators.required]],
    pcs_with_integrated_teaching_learning_devices: ['',[Validators.required]],
    digital_boards_with_content_management_systems: ['',[Validators.required]],
    projector: ['',[Validators.required]],
    printer: ['',[Validators.required]],
    scanner: ['',[Validators.required]],
    server: ['',[Validators.required]],
    web_camera: ['',[Validators.required]],
    smart_tv: ['',[Validators.required]],
    smart_class_rooms_used_for_teaching_with: ['',[Validators.required]],
    mobile_phone_used_for_teaching: ['',[Validators.required]],
    generator_invertors_power_backup_big_ups: ['',[Validators.required]],
    is_school_have_internet_facility: ['',[Validators.required]],
    type_of_internet_facility_available_in_the_school: [''],
    is_access_to_internet_facility_used_for_pedagogical_purpose: [''],
    is_computer_ict_lab_available_in_school: ['',[Validators.required]],
    number_of_ict_labs_available: [''],
    is_only_for_govt_and_govt_aided_schools: ['',[Validators.required]],
    is_the_ict_lab_available_in_the_school_under_samagra_siksha: [''],
    year_of_implementation: [''],
    whether_the_ict_lab_is_functional_or_not: [''],
    which_model_is_implemented_in_the_school: [''],
    type_of_the_ict_instructor_in_the_school: [''],
    whether_ict_based_tools_are_being_used_for_teaching: ['',[Validators.required]],
    access_to_e_contents_digital_contents_resources_at_school: ['',[Validators.required]],
    assistive_tech_based_solutions_for_cwsn: ['',[Validators.required]],
    access_to_dth_tv_channels: ['',[Validators.required]],
    does_the_school_have_digital_library: ['',[Validators.required]],
    number_of_e_books_e_contents_available: [''],
    is_trained_for_teaching_cwsn: ['',[Validators.required]],
    is_trained_in_computer_for_use_in_teaching: ['',[Validators.required]],
    is_languages_in_which_the_teacher_is_having_working_knowledge: ['',[Validators.required]],
    is_whether_trained_in_safety_and_security: ['',[Validators.required]],
    is_whether_received_training_on_cyber_safety: ['',[Validators.required]],
    is_whether_received_training_in_early_identification_support: ['',[Validators.required]],
    is_whether_having_ict_training_knowledge_to_conduct_classes: ['',[Validators.required]],
    is_whether_capable_of_handling_digital_devices: ['',[Validators.required]],
    // is_only_for_govt_and_govt_aided_schools: [''],
    physical_facilities: this.fb.array([this.createFacility()]),
    // --new added---
  });
  get physical_facilities(): FormArray {
    return this.school_form.get('physical_facilities') as FormArray;
  }
  removeFacility(index: number) {
    this.physical_facilities.removeAt(index);
  }
  createFacility(): FormGroup {
    return this.fb.group({
      laboratory_room: ['', [Validators.required]],
      computer_lab: ['', [Validators.required]],
      type_of_internet_facility: ['', [Validators.required]],
      number_of_ict_lab: ['', [Validators.required]],
      ict_instructor_type: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }
  add_study_material() {
    this.physical_facilities.push(this.createFacility())
  }


  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.patchFormData(params['id'])
      }
    })
  }

  async get_dropdown_list() {
    try {
      const state_response = await this.sts.get_list({})
      const district_response = await this.ds.get_list({})
      const country_response = await this.cs.get_list({})
      const block_response = await this.bs.get_list({})
      let state_options = state_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let district_options = district_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let country_options = country_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let block_options = block_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.state_list = state_options
      this.dropdown_list.district_list = district_options
      this.dropdown_list.country_list = country_options
      this.dropdown_list.block_list = block_options

    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  async add() {
    if (this.school_form.valid) {
    this.ui_spinner.startLoader('add-School-loader');
    this.add_school_bitton.disabled = true
    try {
      const formData = { ...this.school_form.value, };
      console.log(this.school_form.value, " jjj");
      let response: any = await this.ss.add(formData)
      console.log(response, "lolololo");
      this.school_form.reset();
      this.ui_spinner.stopLoader('add-School-loader');
      this.add_school_bitton.disabled = false
      this.toastr.success(response.message || 'School Added Successfully!')
      console.log(response);
      this.router.navigate(['/master/school'])
    } catch (error: any) {
      this.ui_spinner.stopLoader('add-School-loader');
      this.add_school_bitton.disabled = false
      console.error(error?.error?.message, '')
    }
    } else {
      this.school_form.markAllAsTouched();
    }
  }



  // async get_school(id: any) {
  //   try {
  //     let data = await this.ss.get(id);
  //     this.selected_school = data.data;
  //     this.school_form.patchValue({
  //       udise_code: data.data.udise_code,
  //       name: data.data.name,
  //       district_id: data.data.district_id,
  //       udise_block: data.data.udise_block,
  //       location_type: data.data.location_type,
  //       village_name: data.data.village_name,
  //       gram_panchayat_name: data.data.gram_panchayat_name,
  //       ward_name: data.data.ward_name,
  //       urban_local_bodies: data.data.urban_local_bodies,
  //       address: data.data.address,
  //       cluster_resourse_centre: data.data.cluster_resourse_centre,
  //       assembly_constituency: data.data.assembly_constituency,
  //       parliamentary_constituency: data.data.parliamentary_constituency,
  //       lat: data.data.lat,
  //       long: data.data.long,
  //       school_contact_details: data.data.school_contact_details,
  //       std_code: data.data.std_code,
  //       landline_number: data.data.landline_number,
  //       contact_number: data.data.contact_number,
  //       school_website: data.data.school_website,
  //       email: data.data.email,
  //       head_of_school: data.data.head_of_school,
  //       hos_in_charge_name: data.data.hos_in_charge_name,
  //       hos_contact_number: data.data.hos_contact_number,
  //       hos_email: data.data.hos_email,
  //       respondent_type: data.data.respondent_type,
  //       respondent_name: data.data.respondent_name,
  //       respondent_contact_number: data.data.respondent_contact_number,
  //       special_school_for_cwsn: data.data.special_school_for_cwsn,
  //       state_id: data.data.state_id,
  //       city: data.data.city,
  //       pincode: data.data.pincode,
  //     });
  //     const facilities = data.data.physical_facilities || [];
  //     const facilitiesFormArray = this.school_form.get('physical_facilities') as FormArray;
  //     while (facilitiesFormArray.length !== 0) {
  //       facilitiesFormArray.removeAt(0);
  //     }
  //    await facilities.forEach((facility:any) => {
  //       facilitiesFormArray.push(this.fb.group({
  //         laboratory_room: facility.laboratory_room || '',
  //         computer_lab: facility.computer_lab || '',
  //         type_of_internet_facility: facility.type_of_internet_facility || '',
  //         number_of_ict_lab: facility.number_of_ict_lab || '',
  //         ict_instructor_type: facility.ict_instructor_type || '',
  //         description: facility.description || '',
  //       }));
  //     });
  //     console.log('School data patched successfully:', data);
  //   } catch (error: any) {
  //     console.error("Error fetching school:", error);
  //   }
  // }


  async patchFormData(id: any) {
    let data = await this.ss.get(id);

    if (data && data.data) {
      this.school_form.patchValue({
        udise_code: data.data.udise_code,
        name: data.data.name,
        city: data.data.city,
        country_id: data.data.country_id,
        block_id: data.data.block_id,
        district_id: data.data.district_id,
        state_id: data.data.state_id,
        // udise_block: data.data.udise_block,
        location_type: data.data.location_type,
        village_name: data.data.village_name,
        gram_panchayat_name: data.data.gram_panchayat_name,
        urban_local_bodies: data.data.urban_local_bodies,
        ward_name: data.data.ward_name,
        address: data.data.address,
        pincode: data.data.pincode,
        cluster_resourse_centre: data.data.cluster_resourse_centre,
        assembly_constituency: data.data.assembly_constituency,
        parliamentary_constituency: data.data.parliamentary_constituency,
        lat: data.data.lat,
        long: data.data.long,
        school_contact_details: data.data.school_contact_details,
        std_code: data.data.std_code,
        landline_number: data.data.landline_number,
        contact_number: data.data.contact_number,
        school_website: data.data.school_website,
        email: data.data.email,
        head_of_school: data.data.head_of_school,
        hos_in_charge_name: data.data.hos_in_charge_name,
        hos_contact_number: data.data.hos_contact_number,
        hos_email: data.data.hos_email,
        respondent_type: data.data.respondent_type,
        respondent_name: data.data.respondent_name,
        respondent_contact_number: data.data.respondent_contact_number,
        special_school_for_cwsn: data.data.special_school_for_cwsn,
        primary_only_with_grades_1_to_5: data.data.primary_only_with_grades_1_to_5,
    upper_primary_with_grades_1_to_8:data.data.upper_primary_with_grades_1_to_8,
    higher_secondary_with_grades_1_to_12:data.data.higher_secondary_with_grades_1_to_12,
    upper_primary_only_with_grades_6_to_8:data.data.upper_primary_only_with_grades_6_to_8,
    higher_secondary_with_grades_6_to_12:data.data.higher_secondary_with_grades_6_to_12,
    secondary_with_grades_6_to_10:data.data.secondary_with_grades_6_to_10,
    secondary_only_with_grades_9_to_10:data.data.secondary_only_with_grades_9_to_10,
    higher_secondary_with_grades_9_to_12:data.data.higher_secondary_with_grades_9_to_12,
    hr_sec_jr_college_only_with_grades_11_12:data.data.hr_sec_jr_college_only_with_grades_11_12,
    lowest_and_highest_class_in_school:data.data.lowest_and_highest_class_in_school,
    type_of_the_school:data.data.type_of_the_school,
    affiliation_board_of_the_school_for_secondary_sections:data.data.affiliation_board_of_the_school_for_secondary_sections,
    affiliation_board_of_the_school_for_higher_secondary_sections:data.data.affiliation_board_of_the_school_for_higher_secondary_sections,
    respondent_email:data.data.respondent_email,
    academic_session_start_date:data.data.academic_session_start_date,
    academic_session_end_date:data.data.academic_session_end_date,
    medium_of_instruction_1:data.data.medium_of_instruction_1,
    medium_of_instruction_2:data.data.medium_of_instruction_2,
    medium_of_instruction_3:data.data.medium_of_instruction_3,
    medium_of_instruction_4:data.data.medium_of_instruction_4,
    is_special_school_for_cwsn:data.data.is_special_school_for_cwsn,
    type_of_special_school:data.data.type_of_special_school,
    is_majority_of_pupils_are_of_primary_level:data.data.is_majority_of_pupils_are_of_primary_level,
    is_teacher_attendance_captured:data.data.is_teacher_attendance_captured,
    is_inventory_register_on_ict_items:data.data.is_inventory_register_on_ict_items,
    number_of_laboratory_rooms_including_computer_labs:data.data.number_of_laboratory_rooms_including_computer_labs,
    audio_visual_public_address_system:data.data.audio_visual_public_address_system,
    desktop_pcs:data.data.desktop_pcs,
    laptop_notebook:data.data.laptop_notebook,
    tablets:data.data.tablets,
    pcs_with_integrated_teaching_learning_devices:data.data.pcs_with_integrated_teaching_learning_devices,
    digital_boards_with_content_management_systems:data.data.digital_boards_with_content_management_systems,
    projector:data.data.projector,
    printer:data.data.printer,
    scanner:data.data.scanner,
    server:data.data.server,
    web_camera:data.data.web_camera,
    smart_tv:data.data.smart_tv,
    smart_class_rooms_used_for_teaching_with:data.data.smart_class_rooms_used_for_teaching_with,
    mobile_phone_used_for_teaching:data.data.mobile_phone_used_for_teaching,
    generator_invertors_power_backup_big_ups:data.data.generator_invertors_power_backup_big_ups,
    is_school_have_internet_facility:data.data.is_school_have_internet_facility,
    type_of_internet_facility_available_in_the_school:data.data.type_of_internet_facility_available_in_the_school,
    is_access_to_internet_facility_used_for_pedagogical_purpose:data.data.is_access_to_internet_facility_used_for_pedagogical_purpose,
    is_computer_ict_lab_available_in_school:data.data.is_computer_ict_lab_available_in_school,
    number_of_ict_labs_available:data.data.number_of_ict_labs_available,
    is_the_ict_lab_available_in_the_school_under_samagra_siksha:data.data.is_the_ict_lab_available_in_the_school_under_samagra_siksha,
    year_of_implementation:data.data.year_of_implementation,
    whether_the_ict_lab_is_functional_or_not:data.data.whether_the_ict_lab_is_functional_or_not,
    which_model_is_implemented_in_the_school:data.data.which_model_is_implemented_in_the_school,
    type_of_the_ict_instructor_in_the_school:data.data.type_of_the_ict_instructor_in_the_school,
    whether_ict_based_tools_are_being_used_for_teaching:data.data.whether_ict_based_tools_are_being_used_for_teaching,
    access_to_e_contents_digital_contents_resources_at_school:data.data.access_to_e_contents_digital_contents_resources_at_school,
    assistive_tech_based_solutions_for_cwsn:data.data.assistive_tech_based_solutions_for_cwsn,
    access_to_dth_tv_channels:data.data.access_to_dth_tv_channels,
    does_the_school_have_digital_library:data.data.does_the_school_have_digital_library,
    number_of_e_books_e_contents_available:data.data.number_of_e_books_e_contents_available,
    is_trained_for_teaching_cwsn:data.data.is_trained_for_teaching_cwsn,
    is_trained_in_computer_for_use_in_teaching:data.data.is_trained_in_computer_for_use_in_teaching,
    is_languages_in_which_the_teacher_is_having_working_knowledge:data.data.is_languages_in_which_the_teacher_is_having_working_knowledge,
    is_whether_trained_in_safety_and_security:data.data.is_whether_trained_in_safety_and_security,
    is_whether_received_training_on_cyber_safety:data.data.is_whether_received_training_on_cyber_safety,
    is_whether_received_training_in_early_identification_support:data.data.is_whether_received_training_in_early_identification_support,
    is_whether_having_ict_training_knowledge_to_conduct_classes:data.data.is_whether_having_ict_training_knowledge_to_conduct_classes,
    is_whether_capable_of_handling_digital_devices:data.data.is_whether_capable_of_handling_digital_devices,
    is_only_for_govt_and_govt_aided_schools:data.data.is_only_for_govt_and_govt_aided_schools,

      });

      const facilitiesArray = this.school_form.get('physical_facilities') as FormArray;
      while (facilitiesArray.length) {
        facilitiesArray.removeAt(0);
      }
      data.data['physical-facilities'].forEach((facility: any) => {
        facilitiesArray.push(this.fb.group({
          laboratory_room: facility.laboratory_room,
          computer_lab: facility.computer_lab,
          number_of_ict_lab: facility.number_of_ict_lab,
          type_of_internet_facility: facility.type_of_internet_facility,
          ict_instructor_type: facility.ict_instructor_type,
          description: facility.description
        }));
      })
    }
  }


  async get_udise(code: any) {
    try {
      let data = await this.udise_ser.get_by_udise_code(code)
      if (this.school_form.get('udise_code').value != '' && data.data) {
        this.school_form.patchValue({
          name: data.data.school_name,
          city: data.data.city,
          district_id: data.data.district_id,
          state_id: data.data.state_id,
          pincode: data.data.pincode,
          lat: data.data.lat,
          long: data.data.long,
        });
      } else {
        this.toastr.error('Please, Enter a valid UDISE!')
      }
    } catch (error: any) {
      this.toastr.error('Error fetching UDISE!')
      console.error("Error fetching UDISE:", error);
      console.log(error);

    }
  }



  async edit(id: number) {
    if (this.school_form.valid) {
    this.ui_spinner.startLoader('update-School-loader');
    this.update_school_button.disabled = true
    try {
      let response: any = await this.ss.update(id, this.school_form.value);
      this.school_form.reset();
      this.toastr.success(' Updated Successfully!');
      this.ui_spinner.stopLoader('update-School-loader');
      this.update_school_button.disabled = false
      if (this.gs.user.role == 'Instructor' || this.gs.user.role == 'Block Resource Coordinator') {
        this.router.navigate(['/master/school']);
      } else {
        this.router.navigate(['/master/school']);
      }
    } catch (error: any) {
      this.ui_spinner.stopLoader('update-School-loader');
      this.update_school_button.disabled = false
      console.error("Error updating school:", error);
    }
    } else {
      this.school_form.markAllAsTouched();
    }
  }
}
