import { Component, HostListener, } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { GlobalService } from '../../services/global.service';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { AttendanceService } from '../../services/attendance.service';
import { SidebarService } from '../../services/sidebar.service';
import { BrcAssignmentService } from '../../services/brc-assignment.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [NavbarComponent, SidebarComponent, RouterOutlet, NgIf, NgClass, NgFor],
  templateUrl: './dashboard-layout-page.component.html',
  providers: [DatePipe]
})
export class DashboardLayoutPageComponent {
  is_sidebar_open: boolean = true
  link_clicked: boolean = false
  isLargeWindow: any;
  window: any;
  is_school_modal: boolean = false
  selectedSchool: string = '';
  list: any = {}
  params: any = {}
  path = window.location.pathname

  constructor(public ss: SidebarService, public hs: HttpService, public datePipe: DatePipe, public toastr: ToastrService, public gs: GlobalService, public router: Router, public ar: ActivatedRoute, public brc_ass: BrcAssignmentService, public instr_ass: InstructorAssignmentService, public attendance_service: AttendanceService) { }

  async ngOnInit() {
    // this.ar.queryParams.subscribe(async (params: any) => {
    //   this.params = { ...params }
    await this.get_school_list({})
    // })

  }

  async get_school_list(filter: any) {
    try {
      if ((this.gs.user.role == 'Instructor' || this.gs.user.role == 'Block Resource Coordinator') && this.path.includes('/dashboard')) {
        let response = await this.instr_ass.get_inst_school_assignment_list({})
        this.list = response
        // if (this.gs.selected_school.name || this.gs.selected_school.school_name) {
        //   this.is_school_modal = false
        // } else {
        //   this.is_school_modal = true
        // }
        if (this.gs.user.role == 'Block Resource Coordinator' && response.data.length > 0) {
          this.is_school_modal = true
          console.log(response, "if");
        } else {
          this.is_school_modal = false
          console.log(response, "else");
        }

        if (this.gs.user.role == 'Instructor') {
          this.is_school_modal = true
          console.log(response, "if");
        }
      }
      await this.get_today_attendance()
    } catch (error: any) {
      console.error("Error fetching Country list:", error);
    }
  }
  async get_today_attendance() {
    try {
      const now = new Date();
      const date = this.datePipe.transform(now, 'yyyy-MM-dd') || '';
      let filter_object = {
        date: date
      };
      console.log(filter_object); // Verify the formatted date here
      await this.attendance_service.get_current_day_attendance(filter_object);
    } catch (error: any) {
      console.error("Error fetching Country list:", error);
    }
  }

  async onSchoolChange(school: any) {
    localStorage.setItem('selected_school', JSON.stringify(school))
    console.log(school, "++++++++++++++++++++++++");
    this.selectedSchool = school?.school_name
    this.gs.selected_school = school
    await this.get_today_attendance()
  }

  proceed() {
    console.log("rrrrrrrrrrrrrrrr", this.selectedSchool == '');
    if (this.selectedSchool != '') {
      console.log("INSIDE SIGN");

      this.is_school_modal = false
      this.router.navigate(['/dashboard'])
    }
  }

  toggle_sidebar() {
    this.is_sidebar_open = !this.is_sidebar_open
  }

  handle_sidebar_width(event: any) {
    if (event) {
      this.link_clicked = true
    } else {
      this.link_clicked = false
    }
  }

  sign_in() {
    this.router.navigate(['/dashboard'])
    this.is_school_modal = false
  }

  async sign_out() {
    try {
      let response = await this.hs.get('/auth/sign-out', {});
      if (response) {
        localStorage.removeItem('user')
        localStorage.removeItem('selected_school')
        this.gs.user = {}
        this.gs.selected_school = {}
        this.gs.attendnace = {}
        this.toastr.success('Sign Out Successfully!')
        this.router.navigate(['/sign-in']);
      }
    } catch (error: any) {
      console.log(error);
      this.toastr.error(error || 'Something Went Wrong!')
    }
  }
}
