<app-header header_title="Courses">
    <app-button *ngIf="gs.user.role == 'Super Admin'" tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Course" routerLink="/master/course/form"></app-button>
</app-header>
<div class="flex flex-col sm:flex-row justify-between mb-5 gap-3 px-8">
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-select-input class="w-full" (change)="school_show_filter($event)" label="Language" [options]="[{title: 'English'},{title: 'Marathi'},{title: 'Hindi'}]"></app-select-input>
        <!-- <app-select-input class="w-full" label="Grade" [options]="dropdown_list.grade_list"></app-select-input> -->
    </div>
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-serch-text-input #searchInput placeholder="Search Course Name" (valueChange)="onSearch($event)"></app-serch-text-input>
        <!-- <app-filter-pill-button label="Filter" icon="bx bx-filter-alt" (click)="toggle_filter()"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
        <table class=" table-auto w-full border-collapse ">
            <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-nowrap text-center">Sr No</th>
                    <th class="px-4 h-12 text-nowrap text-left">Course Name</th>
                    <th class="px-4 h-12 text-nowrap text-left" *ngIf="gs.user.role == 'Block Resource Coordinator'">
                        Instructor</th>
                    <th class="px-4 h-12 text-nowrap text-left" *ngIf="gs.user.role == 'Block Resource Coordinator'">
                        Instructor</th>
                    <th class="px-4 h-12 text-nowrap text-left">Language</th>
                    <th class="px-4 h-12 text-nowrap text-left" *ngIf="gs.user.role != 'Instructor'">Course Duration
                    </th>
                    <!-- <th class="px-4 h-12 text-nowrap text-left">Grade</th> -->
                    <th *ngIf="gs.user.role == 'Instructor'" class="px-4 h-12 text-nowrap text-left">Progress</th>
                    <th class="px-4 h-12 text-nowrap text-center">Action</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px] ">
                <tr *ngFor="let item of list.data ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{i+1}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.course_details?.name}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left" *ngIf="gs.user.role == 'Block Resource Coordinator'">{{item?.instructor_user_name}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.course_details?.language}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left" *ngIf="gs.user.role != 'Instructor'">
                        {{item.course_details?.duration}}</td>
                    <!-- <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.grade_name}}</td> -->
                    <td class="px-4 h-12 text-center">
                        <div class="w-full bg-gray-200 rounded-full">
                            <div class="bg-primary-100 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" [style]="'width:' + calculateProgress(item)">
                            </div>
                        </div>
                        {{calculateProgress(item)}}%
                    </td>
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta routerLink="/master/course/form" [queryParams]="{id: item.course_id, view: 'true'}" icon="bx bx-show" tooltip="view"></app-icon-cta>
                            <app-icon-cta (click)="open_progress(item)" icon="bx bx-calendar-edit" tooltip="Update Progress"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>


<!-- <app-modal #open_progress_modal title="Update Course Progress">
    <div class="w-full">
        <div class="flex flex-col overflow-hidden p-4 rounded">
            <div class="flex w-full items-center justify-start gap-5">
                <div class="h-[250px] w-full">

                    <circle-progress class="h-full w-full" [space]="-12" outerStrokeLinecap="square" [percent]="progressPercent" [radius]="40" [outerStrokeWidth]="12" [innerStrokeWidth]="12" [responsive]="false" [outerStrokeColor]="'#3F41D1'" [innerStrokeColor]="'#e6e6e6'" [animation]="true" [showSubtitle]="false" [animationDuration]="300" [showTitle]="true" [showUnits]="true" [titleFormat]="formatPercentTitle">
                    </circle-progress>

                </div>
                <div class="w-full flex flex-col gap-2">
                    <h1 class="leading-7 md:text-xl print-section sm:leading-10 text-lg tracking-tight font-medium text-[#212121]">
                        {{selected_course_for_progress.course_details?.name}}</h1>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Total Topics</p>
                        <p class="text-text-color-600 text-md w-full">{{
                            selected_course_for_progress.course_details?.sub_course_count}}</p>
                    </div>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Completed Topics</p>
                        <p class="text-text-color-600 text-md w-full">{{selected_course_for_progress.progress}}</p>
                    </div>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Total Completed Topics</p>
                        <div class="flex items-center gap-2">
                            <app-icon-cta icon="bx bx-minus" [disabled]="counter==0" (click)="decrement()" tooltip="Decrement"></app-icon-cta>
                            <h1 class="text-text-color-600 text-md">{{ counter }}</h1>
                            <app-icon-cta icon="bx bx-plus" [disabled]="counter ==selected_course_for_progress.course_details?.sub_course_count" (click)="increment()" tooltip="Increment"></app-icon-cta>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="update_course_progress(selected_course_for_progress?.id)" class="self-center" color="bg-[#3F41D1] text-white" label="Update Progress"></app-button>
    </div>
</app-modal> -->


<!-- new -->

<app-modal #open_progress_modal title="Update Course Progress">
    <div class="w-full">
        <div class="flex flex-col overflow-hidden p-4 rounded">
            <div class="flex w-full items-center justify-start gap-5">
                <div class="h-[250px] w-full">
                    <circle-progress
                        class="h-full w-full"
                        [space]="-12"
                        outerStrokeLinecap="square"
                        [percent]="progress()"
                        [radius]="40"
                        [outerStrokeWidth]="12"
                        [innerStrokeWidth]="12"
                        [responsive]="false"
                        [outerStrokeColor]="'#3F41D1'"
                        [innerStrokeColor]="'#e6e6e6'"
                        [animation]="true"
                        [showSubtitle]="false"
                        [animationDuration]="300"
                        [showTitle]="true"
                        [showUnits]="true"
                        [titleFormat]="formatPercentTitle"
                    ></circle-progress>
                </div>
                <div class="w-full flex flex-col gap-2">
                    <h1 class="leading-7 md:text-xl print-section sm:leading-10 text-lg tracking-tight font-medium text-[#212121]">
                        {{selected_course_for_progress.course_details?.name}}
                    </h1>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Total Topics</p>
                        <p class="text-text-color-600 text-md w-full">
                            {{ selected_course_for_progress.course_details?.sub_course_count }}
                        </p>
                    </div>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Completed Topics</p>
                        <p class="text-text-color-600 text-md w-full">{{ selected_course_for_progress.progress }}</p>
                    </div>
                    <div class="w-full">
                        <p class="block mb-2 text-sm font-medium text-gray-900">Total Completed Topics</p>
                        <div class="flex items-center gap-2">
                            <app-icon-cta icon="bx bx-minus" [disabled]="counter==0" (click)="decrement()" tooltip="Decrement"></app-icon-cta>
                            <h1 class="text-text-color-600 text-md">{{ counter }}</h1>
                            <app-icon-cta icon="bx bx-plus" [disabled]="counter ==selected_course_for_progress.course_details?.sub_course_count" (click)="increment()" tooltip="Increment"></app-icon-cta>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button
            (click)="update_course_progress(selected_course_for_progress?.id)"
            class="self-center"
            color="bg-[#3F41D1] text-white"
            label="Update Progress"
        ></app-button>
    </div>
</app-modal>

<!-- Progress Bar in <td> element -->

