<app-header header_title="Ground Activity">
    <app-button color="bg-[#E697AB] text-[#310000]" routerLink="/ground-activity/form" label="Add Ground Activity"
        *ngIf="gs.user.role != 'Sub Admin' && !isReportUrl"></app-button>
</app-header>
<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-start items-center gap-3 md:w-[500px]">
        <div class="relative inline-block">
            <input type="month" (change)="lable_month_show($event)"
                class="rounded-lg block px-4 h-12 w-full text-sm text-gray-900 bg-white border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
            <label [for]=""
                class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Month
            </label>
        </div>
    </div>
    <div class="w-full flex justify-end items-center gap-3 md:w-[800px]">
        <app-select-input
            *ngIf="path.includes('reports') && (gs.user.role == 'Super Admin' || gs.user.role == 'Block Resource Coordinator'  || gs.user.role == 'Sub Admin')"
            class="w-full" label="Grade" [options]="dropdown_list.grade_list"
            (change)="grade_show_filter($event)"></app-select-input>
        <app-select-input
            *ngIf="path.includes('reports') && (gs.user.role == 'Super Admin' || gs.user.role == 'Block Resource Coordinator'  || gs.user.role == 'Sub Admin')"
            class="w-full" label="Instructor" [options]="dropdown_list.instructor_list"
            (change)="instructor_show_filter($event)"></app-select-input>
        <app-select-input
            *ngIf="path.includes('reports') &&( gs.user.role == 'Block Resource Coordinator' || gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin')"
            class="w-full" label="School" [options]="dropdown_list.school_list"
            (change)="school_show_filter($event)"></app-select-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="overflow-auto bg-white border border-primary rounded-lg">
        <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
            <div class="w-full h-96 p-10 flex justify-center items-center">
                <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
            </div>
        </div>
        <table *ngIf="list.data?.length > 0" class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">Grade</th>
                    <!-- <th class="px-4 h-12 text-left" *ngIf="gs.user.role != 'Instructor'">Course</th> -->
                    <th class="px-4 h-12 text-left">Date</th>
                    <th class="px-4 h-12 text-left">Activity Type</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role != 'Instructor'">Instructor</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'|| gs.user.role == 'Block Resource Coordinator'">School</th>
                    <th class="px-4 h-12 text-left">Description</th>
                    <!-- <th class="px-4 h-12 text-left">Proofs</th> -->
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list.data ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left truncate">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.grade_name}}</td>
                    <!-- <td class="px-4 h-12 text-left truncate" *ngIf="gs.user.role != 'Instructor'">{{item.Course}}</td> -->
                    <!-- <td class="px-4 h-12 text-left truncate">{{item.date | date:'dd-MM-yyyy'}}</td> -->
                    <td class="px-4 h-12 text-left truncate">{{item.date}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.activity_type_name}}</td>
                    <td class="px-4 h-12 text-left truncate" *ngIf="gs.user.role != 'Instructor'">{{item.user_name}}
                    </td>
                    <td class="px-4 h-12 text-left  max-w-36 truncate" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin' || gs.user.role == 'Block Resource Coordinator'">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left  max-w-36 truncate">{{item.description}}</td>
                    <!-- <td class="px-4 h-12 text-left max-w-36 truncate">{{item.image}}</td> -->
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta *ngIf="!path.includes('reports')" icon="bx bx-show" tooltip="view"
                                routerLink="/ground-activity-view"
                                [queryParams]="{id: item.id, view:true}"></app-icon-cta>
                            <app-icon-cta *ngIf="path.includes('reports')" icon="bx bx-show" tooltip="view"
                                routerLink="/reports/ground-activity-report"
                                [queryParams]="{id: item.id, view:true}"></app-icon-cta>
                            <app-icon-cta *ngIf="!path.includes('reports')" icon="bx bx-edit-alt" tooltip="edit"
                                routerLink="/ground-activity/form" [queryParams]="{id: item.id}"></app-icon-cta>
                            <app-icon-cta *ngIf="!path.includes('reports')" icon="bx bx-trash" tooltip="delete"
                                (click)="ground_level_delete.open(); selected_ground_activity = item"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems"
        [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-confirmation-popup #ground_level_delete
    confirmation_message="Are you sure you want to Delete this Ground Activity?" popup_title="Delete Ground Activity">
    <app-button label="Delete" class="relative" (click)="delete_confirm(selected_ground_activity.id)"
        color="bg-[#3F41D1] text-white">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="delete-state-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>