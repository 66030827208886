import { Component,Input, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { IconCtaComponent } from '../icon-cta/icon-cta.component';
import { ModalComponent } from '../modal/modal.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-confirmation-popup',
  standalone: true,
  imports: [NgIf,ButtonComponent,IconCtaComponent,ModalComponent,ButtonComponent,RouterLink],
  templateUrl: './confirmation-popup.component.html',
})
export class ConfirmationPopupComponent {

  @Input() button_title = ''
  @Input() color = ''
  @Input() confirmation_message = ''
  @Input() cancel_title:any;
  @Input() Cancel_option:any = false
  @Input() popup_title = ''
  @Input() router_link = ''
  @Input() redirect = '' || '/dashboard';
  @ViewChild('confirmation') confirmation:any;
  is_popup_visible:any;


  constructor(public router:Router,){}

  open(){
    this.is_popup_visible = true
  }
  close(){
    this.is_popup_visible = false
  }
}
