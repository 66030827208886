<app-header header_title="Daily Log">
    <app-button color="bg-[#E697AB] text-[#310000]" routerLink="/daily-log/form" label="Add Daily Log" *ngIf="!path.includes('reports')"></app-button>
</app-header>
<div class="w-full flex flex-col-reverse sm:flex-row justify-end mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-end md:w-[500px] gap-3">
        <app-select-input *ngIf="path.includes('reports') && ( gs.user.role == 'Super Admin' ||  gs.user.role == 'Block Resource Coordinator' || gs.user.role == 'Sub Admin')" class="w-full" label="School" [options]="dropdown_list.school_list" (change)="school_show_filter($event)"></app-select-input>
        <app-select-input *ngIf="path.includes('reports') && (gs.user.role == 'Super Admin' ||  gs.user.role == 'Block Resource Coordinator' ||  gs.user.role == 'Sub Admin')" class="w-full" label="User" [options]="dropdown_list.instructor_list" (change)="instructor_show_filter($event)"></app-select-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="daily_report_list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="daily_report_list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">Date</th>
                    <th *ngIf="gs.user.role != 'Instructor' || gs.user.role == 'Block Resource Coordinator'" class="px-4 h-12 text-left">School</th>
                    <th class="px-4 h-12 text-left">Description</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Block Resource Coordinator' || gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">Instructor
                    </th>
                    <!-- <th class="px-4 h-12 text-left">Proofs</th> -->
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of daily_report_list.data ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.date | date:'dd-MM-yyyy'}}</td>
                    <td *ngIf="gs.user.role != 'Instructor'" class="px-4 h-12 text-left truncate">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left  max-w-36 truncate">{{item.description}}</td>
                    <td class="px-4 h-12 text-left truncate" *ngIf="gs.user.role == 'Block Resource Coordinator' || gs.user.role == 'Super Admin'  || gs.user.role == 'Sub Admin'">
                        {{item.user_name}}
                    </td>
                    <!-- <td class="px-4 h-12 text-left">{{item.image}}</td> -->
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-show" *ngIf="!path.includes('reports')" tooltip="view" routerLink="/daily-log-view" [queryParams]="{id: item.id,view:true}"></app-icon-cta>
                            <app-icon-cta icon="bx bx-show" *ngIf="path.includes('reports')" tooltip="view" routerLink="/reports/daily-log-report" [queryParams]="{id: item.id,view:true}"></app-icon-cta>
                            <app-icon-cta *ngIf="!path.includes('reports')" icon="bx bx-edit-alt" routerLink="/daily-log/form" [queryParams]="{id: item.id}" tooltip="edit"></app-icon-cta>
                            <app-icon-cta *ngIf="!path.includes('reports')" (click)="daily_log_delete.open(); selected_daily_log = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="daily_report_list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>


<app-confirmation-popup #daily_log_delete confirmation_message="Are you sure you want to Delete this Daily Log?" popup_title="Delete Daily Logs">
    <app-button label="Delete" class="relative" (click)="delete_confirm(selected_daily_log.id)" color="bg-[#3F41D1] text-white">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-state-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>