import { Component } from '@angular/core';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { RouterLink } from '@angular/router';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-sub-topic-list-page',
  standalone: true,
  imports: [ButtonComponent,HeaderComponent,RouterLink,IconCtaComponent,ConfirmationPopupComponent,SerchTextInputComponent,FilterPillButtonComponent,NgIf,NgFor],
  templateUrl: './sub-topic-list-page.component.html',
})
export class SubTopicListPageComponent {
  list: any[] = [
    { cource_name: "Mathematics", study:"1 link", topic: "Algebra" },
    { cource_name: "Science", study:"2 Photo", topic: "Physics" },
    { cource_name: "Literature", study:"3 File", topic: "Shakespearean Sonnets" },
  ];
  
}
