import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  standalone: true,
  imports: [BaseChartDirective, NgFor],
  templateUrl: './doughnut-chart.component.html',
})
export class DoughnutChartComponent implements OnInit {
  @Input() labels: string[] = [];
  @Input() data_array: number[] = [];
  
  doughnutChartData: ChartData[] = [];
  doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      
    }
  };

  ngOnInit() {
    this.doughnutChartData.push({
      labels: this.labels,
      datasets: [{
        label: 'Count',
        data: this.data_array,
        backgroundColor: ['#3b82f6', '#f87171', '#f59e0b', '#84cc16', '#25706F'],
        hoverOffset: 4,
      }]
    });
  }
}
