<div class="flex justify-between col-span-6">
    <h2 class="text-3xl font-medium col-span-6">Venue Details</h2>
    <div class="flex items-center gap-3">
        <!-- <app-icon-cta class="text-red-500 text-2xl rounded-full" icon="bx bx-x"></app-icon-cta>
        <app-icon-cta class="text-green-500 text-2xl rounded-full" icon="bx bx-check"></app-icon-cta> -->
        <button type="button" class="border hover:bg-gray-200 ml-0 rounded-full w-10 h-10 text-red-500 text-2xl m-auto"><i class='bx bx-x'></i></button>
        <button type="button" class="border hover:bg-gray-200 ml-0 rounded-full w-10 h-10 text-green-500 text-2xl m-auto"><i class='bx bx-check'></i></button>
    </div>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Venue Name</p>
    <p class=" text-gray-900 text-md w-full">Terna College</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Nature of Venue</p>
    <p class=" text-gray-900 text-md w-full">MCC</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Primary Contact Number</p>
    <p class=" text-gray-900 text-md w-full">8563254125</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Primary Email Id</p>
    <p class=" text-gray-900 text-md w-full">arun.gounder&#64;gmail.com</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Secondary Contact Number</p>
    <p class=" text-gray-900 text-md w-full">8563254125</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Secondary Email Id</p>
    <p class=" text-gray-900 text-md w-full">arun.gounder&#64;gmail.com</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">GST</p>
    <p class=" text-gray-900 text-md w-full">4566d1dDDND5626</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Aadhar</p>
    <p class=" text-gray-900 text-md w-full">2563 5485 6523 5688</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Pareking Facility</p>
    <p class=" text-gray-900 text-md w-full">Yes</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">CCTV Coverage</p>
    <p class=" text-gray-900 text-md w-full">Yes</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-sm font-semibold text-gray-900">Network Details</p>
    <p class=" text-gray-900 text-md w-full">Network.jpg</p>
</div>
<app-text-input class="col-span-3 mt-5" label="Remark" ></app-text-input>