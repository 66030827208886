import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-serch-text-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './serch-text-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SerchTextInputComponent
    }
  ]
})
export class SerchTextInputComponent implements ControlValueAccessor {
  @Input() placeholder: any = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  value: string = '';

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.valueChange.emit(this.value);
  }

  @Input() disabled: boolean = false;

  control: any;
  onChange: any;
  onTouched: any;

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
