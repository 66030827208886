<app-header header_title="Units">
    <div class="dropdown">
        <app-button tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Unit" (click)="open_attandence.open()"></app-button>
    </div>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3">
        <app-serch-text-input class="w-full md:w-auto" placeholder="Search"></app-serch-text-input>
        <!-- <app-filter-pill-button class="hidden md:block" label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">
                        <span class="flex items-center gap-2">
                            <span>Unit Name</span>
                            <i class='cursor-pointer text-xs bx bxs-sort-alt'></i>
                        </span>
                    </th>
                    <th class="px-4 h-12 text-left">Course</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of unit_list; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left">{{i+1}}</td>
                    <td class="px-4 h-12 text-left">{{item?.Unit_Name}}</td>
                    <td class="px-4 h-12 text-left">{{item?.Course}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta (click)="dis_edit.open()" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="dis_delete.open()" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="px-8 bg-surface-100 z-10 w-full bottom-0 py-4 lg:absolute flex flex-col-reverse sm:flex-row gap-2 items-center justify-between">
    <div>
        <p class="text-[14px] text-text-color-600 font-normal">
            Showing 1 to 5 of 50 Records
        </p>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-left'></i>
        </button>
        <span class="p-[10px] bg-primary-500 text-white rounded-lg">1</span>
        <span class="p-[10px] bg-[#E1E8F5] rounded-lg">2</span>
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-right'></i>
        </button>
    </div>
</div>

<app-modal #open_attandence title="Add Unit">
    <form class="w-full">
        <div class=" bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-text-input class="col-span-6 sm:col-span-3 lg:col-span-3" label="Unit Name"></app-text-input>
                <app-select-input label="Module" class="w-full md:col-span-3" [options]="[{value:'Module 1', title:'Module 1'},{value:'Module 2', title:'Module 2'}]"></app-select-input>
                <app-select-input label="Course" class="w-full md:col-span-3" [options]="[{value:'English', title:'English'},{value:'Marathi', title:'Marathi'}]"></app-select-input>
                <app-text-input class="col-span-6 sm:col-span-3 lg:col-span-3" label="Description"></app-text-input>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="open_attandence.close()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button (click)="open_attandence.close()" class="self-center" color="bg-[#3F41D1] text-white" label="Submit"></app-button>
    </div>
</app-modal>
<app-confirmation-popup #dis_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this District?" popup_title="Edit District"></app-confirmation-popup>
<app-confirmation-popup #dis_delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this District?" popup_title="Delete District"></app-confirmation-popup>