import { Component, ElementRef, ViewChild } from '@angular/core';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { StudyMaterialPageComponent } from '../study-material-page/study-material-page.component';
import { GlobalService } from '../../services/global.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SchoolService } from '../../services/school.service';
import { DailyLogService } from '../../services/daily-log.service';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';

@Component({
  selector: 'app-daily-reports-form-page',
  standalone: true,
  imports: [ButtonComponent, ZoroFileInputComponent, HeaderComponent, TextInputComponent, SelectInputComponent, DateInputComponent, TextAreaInputComponent, RouterLink, NgFor, NgIf, StudyMaterialPageComponent, FormsModule, ReactiveFormsModule, NgxUiLoaderModule, JsonPipe],
  templateUrl: './daily-reports-form-page.component.html',
})
export class DailyReportsFormPageComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('add_daily_log_button') add_daily_log_button: any
  @ViewChild('update_daily_log_button') update_daily_log_button: any
  current_date_show: any
  file_list: any[] = []
  path = window.location.pathname;
  birth_date: string = '';

  constructor(public ui_spinner: NgxUiLoaderService,public instr_ass: InstructorAssignmentService, public fb: FormBuilder, public ds: DailyLogService, public ar: ActivatedRoute, public router: Router, public toastr: ToastrService, public ss: SchoolService, public gs: GlobalService) {
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }
  dropdown_list: any = {}
  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.get_daily_log(params['id'])
        this.daily_log_form.patchValue(await this.ds.get(params['id']))
      }
    })
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    const todayDate = `${yyyy}-${mm}-${dd}`;

    const schoolId = this.gs.selected_school.school_name;
    console.log(this.gs.selected_school.school_name,'schoolid');
    
    this.daily_log_form.patchValue({
      date: todayDate,
      school_id:schoolId
    });
  }

  daily_log_form: any = this.fb.group({
    school_id: [{ value: '', disabled: true }, Validators.required],
    description: ['', Validators.required],
    date: ['', Validators.required],
    school_udise_code: '',
    image: '',
    video: '',
  })

  params: any = {};
  selected_daily_log: any = {};
  async add() {
    if (this.daily_log_form.valid) {
      this.ui_spinner.startLoader('add-daily_log-loader');
      this.add_daily_log_button.disabled = true
      try {
        const formValue = {
          description: this.daily_log_form.value.description,
          date: this.daily_log_form.value.date,
          image: this.daily_log_form.value.image,
          video: this.daily_log_form.value.video,
          school_id: this.gs.selected_school.school_id
        };
        console.log(formValue, " jjj");
        let response: any = await this.ds.add(formValue)
        console.log(response);
        this.daily_log_form.reset();
        this.ui_spinner.stopLoader('add-daily_log-loader');
        this.add_daily_log_button.disabled = false
        this.toastr.success(response.message || 'daily_log Added Successfully!')
        this.router.navigate(['/daily-logs'])
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-daily_log-loader');
        this.add_daily_log_button.disabled = false
        console.error(error?.message, '')
        this.toastr.error(error?.error?.message || "Something Went Wrong!")
      }
    } else {
      this.daily_log_form.markAllAsTouched()
    }
  }

  async get_dropdown_list() {
    try {
      const school_response = await this.instr_ass.get_inst_school_assignment_list({})
      let school_options = school_response.data.map((item: any) => {
        return {
          title: item.school_name || "none",
          value: item.school_id || "none",
        };
      });
      this.dropdown_list.school_list = school_options
      console.log(school_response, school_options);


    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  async get_daily_log(id: any) {
    try {
      let data = await this.ds.get(id)
      this.selected_daily_log = data.data
      this.daily_log_form.patchValue(data.data)
    } catch (error: any) {
      console.error("Error fetching State:", error);
      console.log(error);

    }
  }


  async edit(id: number) {
    if (this.daily_log_form.valid) {
      this.ui_spinner.startLoader('update-daily_log-loader');
      this.update_daily_log_button.disabled = true
      try {
        const formValue = {
          description: this.daily_log_form.value.description,
          date: this.daily_log_form.value.date,
          image: this.daily_log_form.value.image,
          video: this.daily_log_form.value.video,
          school_id: this.gs.selected_school.school_id
        };
        let response = await this.ds.update(id, formValue);
        this.daily_log_form.reset();
        console.log(id, this.daily_log_form.value);
        this.toastr.success('  Updated Successfully!')
        this.ui_spinner.stopLoader('update-daily_log-loader');
        this.update_daily_log_button.disabled = false
        this.router.navigate(['/daily-logs']);
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-daily_log-loader');
        this.update_daily_log_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message || 'Something Went Wrong!')
      }
    } else {
      this.daily_log_form.markAllAsTouched()
    }
  }


  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
    }
  }

}
