import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-sessions-form-page',
  standalone: true,
  imports: [HeaderComponent,ButtonComponent,SelectInputComponent,TextInputComponent,NgFor,RouterLink],
  templateUrl: './sessions-form-page.component.html',
})
export class SessionsFormPageComponent {
  session_list: any[] = []; 
  add_session() {
    this.session_list.push({});
  }
  delete_session(index: number) {
    this.session_list.splice(index, 1);
  }
  batch:any=[
    {title:'Batch 1'},
    {title:'Batch 2'},
    {title:'Batch 3'},
    {title:'Batch 4'},
    {title:'Batch 5'},
    {title:'Batch 6'},
  ]
  lesson:any=[
    {title:'Lesson 1'},
    {title:'Lesson 2'},
    {title:'Lesson 3'},
    {title:'Lesson 4'},
    {title:'Lesson 5'},
    {title:'Lesson 6'},
    {title:'Lesson 7'},
    {title:'Lesson 8'},
    {title:'Lesson 9'}
  ]
  status_list:any=[
    {title:"In Progress"},
    {title:"Completed"},
    {title:"Pending"},
  ]
}
