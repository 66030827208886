import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'is_Pdf',
  standalone: true
})
export class IsPdfPipe implements PipeTransform {

  transform(file_name: string): boolean {
    return file_name.toLowerCase().endsWith('.pdf');
  }

}
