import { Component } from '@angular/core';
import { ModalComponent } from '../../components/modal/modal.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { NgFor, NgIf } from '@angular/common';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';

@Component({
  selector: 'app-activities-list-page',
  standalone: true,
  imports: [ModalComponent,HeaderComponent,ButtonComponent,FilterPillButtonComponent,ConfirmationPopupComponent,SerchTextInputComponent,NgFor,NgIf,TextInputComponent,SelectInputComponent,IconCtaComponent],
  templateUrl: './activities-list-page.component.html',
})
export class ActivitiesListPageComponent {
  activities_list:any=[
    { name: "Assessment", description: "Evaluate, measure, analyze, judge, determine" },
    { name: "Sport", description: "Physical activity, competition, recreation, exercise, athleticism." },
]
}
