<app-header header_title="Add BRC Assignment">
  <app-button color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel"   placement="header"
      routerLink="/dashboard/brc-assignment"></app-button>
  <app-button color="bg-accent-300 text-accent-on" label="Submit" routerLink="/dashboard/brc-assignment"
       ></app-button>
</app-header>
<div class="px-8 mb-2">
  <form>
      <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
          <p class="font-normal text-base text-text-color-600">BRC Assignment Meta Data</p>
          <p class="text-text-color-500 font-medium text-sm mt-2">
              To create a new BRC Assignment, please ensure all mandatory fields are completed accurately. Each field serves a specific purpose in facilitating the BRC Assignment creation process.
          </p>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
              <app-select-input label="Block" [options]="block"></app-select-input>
              <app-select-input label="Assigned BRC" [options]="assigned_brc"></app-select-input>
              <app-date-input label="Academic Year" [minDateInput]="current_date_show"></app-date-input>
          </div>
      </div>
      <div class="bg-white flex flex-col mt-6 p-4 rounded-lg">
          <p class="font-normal text-base text-text-color-600">BRC Assignment Details</p>
          <div class="mt-4 px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
              <div class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
                  <table class="table-auto w-full border-collapse">
                      <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                          <tr>
                              <th scope="col" class="px-4 h-12 py-1 text-center">Sr No</th>
                              <th scope="col" class="px-4 h-12 py-1 text-center">Block</th>
                              <th scope="col" class="px-4 h-12 py-1 text-center">Academic Year</th>
                              <th scope="col" class="px-4 h-12 py-1 text-center">Assigned BRC</th>
                          </tr>
                      </thead>
                      <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px]">
                          <tr *ngFor="let item of instructor_list; let i = index" class="bg-white border-b hover:bg-gray-50">
                              <td class="px-4 h-12 py-1 text-center">{{ i + 1 }}</td>
                              <td class="px-4 h-12 py-1 text-center"><input [disabled]="isTableDisabled || i !== newRowIndex" class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40" type="text" value="{{item.block}}"></td>
                              <td class="px-4 h-12 py-1 text-center"><input [disabled]="isTableDisabled || i !== newRowIndex" class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40" type="text" value="{{item.academic_year}}"></td>
                              <td class="px-4 h-12 py-1 text-center"><input [disabled]="isTableDisabled || i !== newRowIndex" class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40" type="text" value="{{item.assigned_brc}}"></td>
                              <td class="px-4 h-12 py-1 text-center">
                                  <button *ngIf="!isTableDisabled && i === newRowIndex" class="text-red-600 hover:text-red-900" (click)="delete_brc(i)">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.32-9.47l2.83-2.83a1 1 0 011.414 1.414L10.414 10l2.828 2.828a1 1 0 01-1.414 1.414L10 11.414l-2.828 2.829a1 1 0 01-1.414-1.414L8.586 10 5.758 7.172a1 1 0 011.414-1.414L10 8.586l2.828-2.83a1 1 0 011.414 1.414L11.414 10z"
                                              clip-rule="evenodd" />
                                      </svg>
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <app-button class="mt-2 border-none" icon="bx bx-plus-circle" label="Add BRC Assignment" size="sm" (click)="add_brc()"></app-button>                    
          </div>
      </div>
  </form>
</div>