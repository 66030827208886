import { Component } from '@angular/core';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { NgFor, NgIf } from '@angular/common';
import { ButtonComponent } from '../../components/button/button.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-topic-list-page',
  standalone: true,
  imports: [SerchTextInputComponent,FilterPillButtonComponent,HeaderComponent,ConfirmationPopupComponent,NgFor,NgIf,ButtonComponent,IconCtaComponent,RouterLink],
  templateUrl: './topic-list-page.component.html',
})
export class TopicListPageComponent {
  list: any[] = [
    { cource_name: "Mathematics", study:"1 link", topic: "Algebra" },
    { cource_name: "Science", study:"2 Photo", topic: "Physics" },
    { cource_name: "Literature", study:"3 File", topic: "Shakespearean Sonnets" },
  ];
}
