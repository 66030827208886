import { Component } from '@angular/core';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';

@Component({
  selector: 'app-student-attendance',
  standalone: true,
  imports: [ModalComponent, SelectInputComponent, HeaderComponent, ButtonComponent, TextInputComponent, SerchTextInputComponent, FilterPillButtonComponent, IconCtaComponent, NgIf, NgFor, RouterLink, ConfirmationPopupComponent, CheckboxInputComponent],
  templateUrl: './student-attendance.component.html',
})
export class StudentAttendanceComponent {
  isTableDisabled: boolean = true
  newRowIndex: number = -1;

  list: any = [
    { batch: '2023-24', date: '07/06/2024', present_students: '30', name: "Rudra Belwalkar", grade: "6th", },
    { batch: '2023-24', date: '07/06/2024', present_students: '28', name: "Arun Gounder", grade: "6th", },
    { batch: '2023-24', date: '07/06/2024', present_students: '29', name: "Abhishek Jadhav", grade: "8th", },
    { batch: '2023-24', date: '07/06/2024', present_students: '30', name: "Mayur Talekar", grade: "9th", },
    { batch: '2023-24', date: '07/06/2024', present_students: '28', name: "Shreeram Kaudhare", grade: "8th", },
  ]

  school: any = [
    { title: "Terna College" },
    { title: "Bharati Vidyapeeth" },
    { title: "AC College" },
    { title: "RJIT" },
    { title: "OES" },
    { title: "Others" },
  ]

  students_fields: any[] = [
    { student: "Rahul Sharma", remark: "Present", },
    { student: "Shubham Kadam", remark: "Present", },
    { student: "Viraj Patil", remark: "Present", },
  ];

  add_student(): void {
    this.students_fields.push({ student_name: '', marks: '' });
    this.newRowIndex = this.students_fields.length - 1;
    this.isTableDisabled = false;
  }

  delete_student(index: number): void {
    this.students_fields.splice(index, 1);
    if (this.students_fields.length === 0) {
      this.isTableDisabled = true;
    }
    this.newRowIndex = -1;
  }
}
