import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-checkbox-input',
  standalone: true,
  imports: [RouterLink,NgIf],
  templateUrl: './checkbox-input.component.html'
})
export class CheckboxInputComponent {
  @Input() label = ''
  @Input() message = ''
  @Input() is_required: boolean = false
}
