import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { TimeInputComponent } from '../../components/inputs/time-input/time-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CheckinCheckoutComponent } from '../../components/checkin-checkout/checkin-checkout.component';
import { GlobalService } from '../../services/global.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { SubmitAttendanceReportService } from '../../services/submit-attendance-report.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { MonthNamePipe } from '../../pipes/month-name.pipe';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { InstructorUserService } from '../../services/instructor-user.service';
import { BrcUserService } from '../../services/brc-user.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-submit-attendance-report-page',
  standalone: true,
  imports: [HeaderComponent, ZoroFileInputComponent, MonthNamePipe, ReactiveFormsModule, ButtonComponent, RouterLink, PaginationComponent, FilterPillButtonComponent, SerchTextInputComponent, ConfirmationPopupComponent, IconCtaComponent, NgFor, CheckinCheckoutComponent, NgIf, ModalComponent, SelectInputComponent, TimeInputComponent, TextInputComponent,NgClass],
  templateUrl: './submit-attendance-report-page.component.html',

})
export class SubmitAttendanceReportPageComponent {
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  attendanceData: any[] = [];
  list: any = {}
  quick_filters = [
    { label: 'All', selected: true },
    { label: 'Pending', selected: false },
    { label: 'Submitted', selected: false }
  ];

  show_month_Label: boolean = true || null;
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  submit_monthly_form: any
  file_object: any = {}
  dropdown_list: any = {}
  path = window.location.pathname

  constructor(public gs: GlobalService, public fs: FileService, public is: InstructorUserService, public brc_service: BrcUserService, public router: Router, public ar: ActivatedRoute, public toastr: ToastrService, public sas: SubmitAttendanceReportService, public fb: FormBuilder) { }

  params: any = {};
  async ngOnInit() {
     this.get_attendance_list(this.params)
    this.get_dropdown_list()
    this.submit_monthly_form = this.fb.group({
      file: ['', Validators.required]
    })

    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
       this.get_attendance_list(this.params)
      const status = params['status'];
      this.updateSelectedFilter(status);
      if (!this.params.page_size) {
        this.params.page_size = 8
      }
      if (!this.params.page) {
        this.params.page = 1
      }
      
    })
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_attendance_list(this.params);
  }

  async get_attendance_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.sas.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching Country list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  view_pdf(pdf: any) {
    this.openInNewTab(this.fs.base_gcs_url + pdf.file)
  }

  openInNewTab(url: string) {
    var win = window.open(url, '_blank');
  }

  user_show_filter(event: any) {
    const selectedUser = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedUser) {
      this.currentPage = 1;
      queryParams.user_id = selectedUser;
    } else {
      this.currentPage = 1;
      queryParams.user_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  onFilterClick(label: string) {
    console.log(label);
    this.currentPage = 1;
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: { status: label },
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }

  async get_dropdown_list() {
    try {
      const instructor_user = await this.is.get_list({})
      const brc_user = await this.brc_service.get_list({})
      let instructor_user_options = instructor_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_options = brc_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.instructor_list = [...instructor_user_options, ...brc_user_options]
    } catch (error: any) {
      console.error("Error fetching Dropdown list:", error);
    }
  }

  updateSelectedFilter(status: string) {
    this.quick_filters.forEach(filter => {
      filter.selected = (filter.label === status);
    });
    console.log(this.quick_filters, "11111111111");

  }


  async generate_pdf(list_data: any) {
    const doc = new jsPDF();
    let pdf_list = await this.sas.get_monthly_list({ month: list_data.month, year: list_data.year,school_id:this.gs.selected_school.school_id })
    this.file_object = { ...list_data }
    console.log(list_data, "88888888");

    try {
      if (pdf_list.data.length > 0) {
        this.toastr.success(pdf_list.message)
      } else {
        this.toastr.success(pdf_list.message || 'No records found!')
        return
      }
    } catch (error) {
      console.log(error);
    }
    doc.text('Attendance Report', 10, 10);
    const head = [['Date', 'UDISE Code', 'School Name', 'Check-In Time', 'Check-Out Time', 'Check-In Latitude', 'Check-In Longitude', 'Check-Out Latitude', 'Check-Out Longitude', 'Remark']];
    const data = pdf_list.data.map((item: any) => [
      item.date,
      item.udise_code,
      item.school_name,
      item.check_in_time,
      item.check_out_time,
      item.check_in_lat,
      item.check_in_long,
      item.check_out_lat,
      item.check_out_long,
      item.remark
    ]);

    (doc as any).autoTable({
      head: head,
      body: data,
      startY: 30,
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [245, 245, 245] }
    });

    // Get the final Y position of the table to add the signatures
    const finalY = (doc as any).lastAutoTable.finalY || 30;

    // Add signature sections
    doc.text('Principal Signature:', 10, finalY + 20);
    doc.text('Signature:', 150, finalY + 20);

    // Draw lines for signatures
    doc.line(10, finalY + 25, 60, finalY + 25);  // Principal signature line
    doc.line(150, finalY + 25, 200, finalY + 25); // Signature line

    doc.save('attendance_report.pdf');

    // this.router.navigate([this.navigate], { queryParams: {school_id:this.gs.selected_school.school_id} })
    // this.router.navigate(['/attendance/upload-monthly-attendance'], { queryParams:{school_id:this.gs.selected_school.school_id} });
  }

  lable_month_show(value: any) {
    this.show_month_Label = value === '';
  }

  async upload_file_link() {
    if (this.submit_monthly_form.valid) {
      try {
        console.log(this.file_object, 'file_object');  // Check the structure of file_object
        let body = {
          month: this.file_object.month,
          year: this.file_object.year,
          school_id: this.gs.selected_school.school_id,
          monthly_attendance_id: this.file_object.id,
          file: this.submit_monthly_form.value.file,
        }
        console.log(body, 'body');
        let response = await this.sas.upload_attendance_to_db(body);
        console.log(response.data, "qqqqqqqqqq");
        this.toastr.success(response.message)
        this.open_bulk_modal.close()
      } catch (error) {
        console.log(error);
      }
    } else {
      this.submit_monthly_form.markAllAsTouched();
    }
  }


  download_template(): void {
    const url = '../../../assets/template-files/sample_attendance.xls';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sample_attendance.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
