import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  base_gcs_url = 'https://storage.cloud.google.com/ict-recurring-media/'

  list = []

  constructor(public hs: HttpService) { }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/file/' + id, {})
      return response;
    } catch (error) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      let response = await this.hs.get('/files', filters)
      this.list = response
      return response;
    } catch (error) {

    }
  }
  async update(value: any) {
    try {
      let response = await this.hs.post('/file/' + value.id, {}, value)
      return response;
    } catch (error) {
      throw error;
    }
  }

}
