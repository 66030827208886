import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from '../../components/modal/modal.component';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { GlobalService } from '../../services/global.service';

import { DailyLogService } from '../../services/daily-log.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { Subject, debounceTime } from 'rxjs';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { SchoolService } from '../../services/school.service';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { InstructorUserService } from '../../services/instructor-user.service';
import { BrcUserService } from '../../services/brc-user.service';

@Component({
  selector: 'app-daily-reports-list-page',
  standalone: true,
  imports: [ModalComponent, DatePipe, ButtonComponent, HeaderComponent, NgFor, NgIf, SerchTextInputComponent, TextInputComponent, IconCtaComponent, ConfirmationPopupComponent, FilterPillButtonComponent, SelectInputComponent, RouterLink, PaginationComponent, NgxUiLoaderModule],
  templateUrl: './daily-reports-list-page.component.html',

})
export class DailyReportsListPageComponent {
  @ViewChild('daily_log_delete') daily_log_delete: any;
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  daily_report_list: any = []
  selected_daily_log: any = {};
  searchSubject: Subject<string> = new Subject<string>();
  dropdown_list: any = {}
  path = window.location.pathname;
  constructor(public ar: ActivatedRoute, public router: Router, public gs: GlobalService, public ds: DailyLogService, public toastr: ToastrService, public ui_spinner: NgxUiLoaderService, public ss: SchoolService, public is: InstructorUserService,public brc_service: BrcUserService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_daily_report_list(this.params);
    });
  }

  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_daily_report_list(this.params);
    })
    await this.get_dropdown_list()
  }

  instructor_show_filter(event: any) {
    const selectedInstructor = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedInstructor) {
      this.currentPage = 1;
      queryParams.instructor_id = selectedInstructor;
    } else {
      this.currentPage = 1;
      queryParams.instructor_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  school_show_filter(event: any) {
    const selectedSchool = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedSchool) {
      this.currentPage = 1;
      queryParams.school_id = selectedSchool;
    } else {
      this.currentPage = 1;
      queryParams.school_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  async get_dropdown_list() {
    try {
      const school = await this.ss.get_list({})
      console.log(school,'dhdh');
      
      const instructor_user = await this.is.get_list({})
      const brc_user = await this.brc_service.get_list({})
      let school_id_options = school.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let instructor_user_options = instructor_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_options = brc_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_id_options
      this.dropdown_list.instructor_list = [...instructor_user_options, ...brc_user_options]
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_daily_report_list(this.params);
  }

  params: any = {};
  async get_daily_report_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;

      if (this.gs.user.role == 'Block Resource Coordinator' && this.path.includes('reports')) {
        this.daily_report_list = await this.ds.get_daily_report_list(filter)
      } else {
        this.daily_report_list = await this.ds.get_list(filter)
      }
      this.totalItems = this.daily_report_list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.daily_report_list, "qqqqqqqqqqq");
      // this.toastr.success(this.daily_report_list.message)
    } catch (error: any) {
      console.error("Error fetching daily_report list:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_daily_report_list(this.params);
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-Daily Log-loader');
    try {
      let response = await this.ds.delete(id);
      this.daily_report_list.data = this.daily_report_list?.data.filter((item: any) => item.id != id);
      if (this.daily_report_list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_daily_report_list(this.params);
      this.toastr.success(response.message || 'Daily Log Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-Daily Log-loader');
      this.daily_log_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-Daily Log-loader');
      this.daily_log_delete.close()
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

}
