import { NgFor, NgIf, NgClass } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { GlobalService } from '../../services/global.service';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { ModalComponent } from "../../components/modal/modal.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { InstructorUserService } from '../../services/instructor-user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import * as XLSX from 'xlsx';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-instructors-list-page',
  standalone: true,
  templateUrl: './instructors-list-page.component.html',
  imports: [VenueAuditViewComponent, NgxUiLoaderModule, HeaderComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, FormsModule, ReactiveFormsModule, PaginationComponent],
})
export class InstructorsListPageComponent {
  @ViewChild('open_instructor') open_instructor: any;
  @ViewChild('update_instructor') update_instructor: any;
  @ViewChild('delete_instructor') delete_instructor: any;
  @ViewChild('add_instructor_button') add_instructor_button: any;
  @ViewChild('update_instructor_button') update_instructor_button: any;
  is_delete_popup_visible: boolean = false;
  is_edit_popup_visible: boolean = false;

  re_Audit_opetions: any = []
  list: any = {}
  params: any = {};
  selected_Instructor: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;
  birth_date?: any;
  form: FormGroup;
  Instructor_file_upload: boolean = true
  mistake_modal: boolean = false
  errorMessage: any;
  parsed_data: any[] = []
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, public is: InstructorUserService, public toastr: ToastrService) {
    const now = new Date();
    this.birth_date = now.toISOString().split('T')[0];
    this.form = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email,Validators.pattern(this.emailPattern)]],
      contact_number: ["", [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
      degree: ["", Validators.required],
      qualification: ["", Validators.required],
      aadhar_number: ["", [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(12), Validators.maxLength(12)]],
      pan_number: ["", [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'), Validators.minLength(10), Validators.maxLength(10)]],
      dob: ["", Validators.required]
    });
  }


  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_instructor_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_instructor_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_instructor_list(this.params);
      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_instructor_list(this.params);
  }

  async add() {
    if (this.form.valid) {
      this.ui_spinner.startLoader('add-instructor-loader');
      this.add_instructor_button.disabled = true
      try {
        let response: any = await this.is.add(this.form.value)
        this.form.reset();
        this.get_instructor_list(this.params)
        this.ui_spinner.stopLoader('add-instructor-loader');
        this.add_instructor_button.disabled = false
        this.toastr.success(response.message || 'Instructor Added Successfully!')
        this.open_instructor.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-instructor-loader');
        this.add_instructor_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.form.markAllAsTouched()
    }
  }
  async get_instructor_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.is.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    } catch (error: any) {
      console.error("Error fetching instructor list:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async get_instructor(id: any) {
    try {
      let data = await this.is.get(id);
      this.selected_Instructor = data.data;
      this.form.patchValue({
        name: data.data.name || '',
        email: data.data.email || '',
        contact_number: data.data.contact_number || '',
        degree: data.data.degree || '',
        qualification: data.data.qualification || '',
        aadhar_number: data.data.aadhar_number || '',
        pan_number: data.data.pan_number || '',
        dob: data.data.dob || ''
      });
      this.toastr.success(data.message);
    } catch (error: any) {
      console.error("Error fetching instructor:", error);
      this.toastr.error(error?.error?.message);
    }
  }

  async edit(id: number) {
    if (this.form.valid) {
      this.ui_spinner.startLoader('update-instructor-loader');
      this.update_instructor_button.disabled = true;
      try {
        let response = await this.is.update(id, this.form.value);
        this.form.reset();
        this.get_instructor_list(this.params);
        this.ui_spinner.stopLoader('update-instructor-loader');
        this.update_instructor_button.disabled = false;
        this.toastr.success(' Updated Successfully!');
        this.update_instructor.close();
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-instructor-loader');
        this.update_instructor_button.disabled = false;
        console.error(error?.error?.message, '');
        this.toastr.error(error?.error?.message);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-instructor-loader');
    try {
      let response = await this.is.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_instructor_list(this.params);
      this.toastr.success(response.message || 'Instructor Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-instructor-loader');
      this.delete_instructor.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-instructor-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  close_delete_modal() {
    this.is_delete_popup_visible = false;
  }
  delete_clicked() {
    this.is_delete_popup_visible = true;
  }


  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.Instructor_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        console.log(key);

        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.Instructor_file_upload = true
        return;
      }
    }
    this.Instructor_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }


  async add_instructor_bulk() {
    try {
      let new_csv = this.parsed_data.map((item: any) => {

        return {
          name: item['Name'],
          email: item['Email'],
          contact_number: item['Contact Number'],
          qualification: item['Qualification'],
          degree: item['Degree'],
          aadhar_number: item['Aadhar Number'],
          pan_number: item['PAN Number'],
          dob: this.convert_date_format(item['DOB']),
        }
      });
      const response: any = await this.is.add(new_csv)
      this.get_instructor_list(this.params)
      this.Instructor_file_upload = true
      this.toastr.success(response.message || 'Country Added Successfully!')
      this.open_bulk_modal.close()

    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.Instructor_file_upload = true
      this.toastr.error(error?.error?.message)
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/instructor_add_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'instructor_add_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convert_date_format(date_str: string): string {
    if (/^\d{2}-\d{2}-\d{4}$/.test(date_str)) {
      const [day, month, year] = date_str.split('-');
      return `${year}-${month}-${day}`;
    }
    return date_str;
  }

}
