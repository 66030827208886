import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [NgIf, NgFor, NgClass],
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 10;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter<number>();

  handlePrevClick() {
    if (this.currentPage > 1) {
      this.onPageChange.emit(this.currentPage - 1);
    }
  }

  handleNextClick() {
    if (this.currentPage < this.totalPages) {
      this.onPageChange.emit(this.currentPage + 1);
    }
  }

  renderPageNumbers(): number[] {
    const pageNumbers: number[] = [];
    const displayFirstPages = 2;
    const displayLastPages = 2;

    for (let i = 1; i <= this.totalPages; i++) {
      if (
        i <= displayFirstPages ||
        i > this.totalPages - displayLastPages ||
        (i === this.currentPage && this.currentPage > displayFirstPages && this.currentPage <= this.totalPages - displayLastPages)
      ) {
        pageNumbers.push(i);
      } else if (
        (i === displayFirstPages + 1 && this.currentPage > displayFirstPages + 1) ||
        (i === this.totalPages - displayLastPages && this.currentPage < this.totalPages - displayLastPages)
      ) {
        pageNumbers.push(-1); // Use -1 to represent "..."
      }
    }

    return pageNumbers;
  }

  get showingFrom(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  get showingTo(): number {
    return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
  }
}
