import { NgFor, NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { BrcAssignmentService } from '../../services/brc-assignment.service';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlockService } from '../../services/block.service';
import { AcademicYearService } from '../../services/academic-year.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { SchoolService } from '../../services/school.service';
import { BrcUserService } from '../../services/brc-user.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-brc-assignment-page',
  standalone: true,
  imports: [ButtonComponent, NgxUiLoaderModule, TextInputComponent, NgIf, SelectInputComponent, ModalComponent, HeaderComponent, IconCtaComponent, ConfirmationPopupComponent, FilterPillButtonComponent, SerchTextInputComponent, NgFor, RouterLink, FormsModule, ReactiveFormsModule, PaginationComponent],
  templateUrl: './brc-assignment-page.component.html',
  providers: []
})
export class BrcAssignmentPageComponent {
  list: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  @ViewChild('open_brc_form') open_brc_form: any
  @ViewChild('brc_delete') brc_delete: any
  @ViewChild('update_brc_form') update_brc_form: any
  @ViewChild('add_brc_assignment_button') add_brc_assignment_button: any
  @ViewChild('update_brc_assignment_button') update_brc_assignment_button: any
  searchSubject: Subject<string> = new Subject<string>();

  params: any = {};
  selected_brc: any = {};
  dropdown_list: any = {}
  brc_form: any = this.fb.group({
    brc_user_id: ["", Validators.required],
    block_id: ["", Validators.required],
    academic_year_id: ["", Validators.required],
    is_active: [false, Validators.required],
  })
  constructor(public ui_spinner: NgxUiLoaderService, public ss: SchoolService, public ays: AcademicYearService, public brs: BrcUserService, public ar: ActivatedRoute, public bas: BrcAssignmentService, public ba: BrcUserService, public fb: FormBuilder, public bs: BlockService, public ay: AcademicYearService, public toastr: ToastrService, public router: Router) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_brc_list(this.params);
    });
  }
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_brc_list(this.params);
    })
    await this.get_dropdown_list()
  }
  block_show_filter(event: any) {
    const selectedBlock = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedBlock) {
      this.currentPage = 1;
      queryParams.block_id = selectedBlock;
    } else {
      this.currentPage = 1;
      queryParams.block_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_brc_list(this.params);
  }

  async get_brc_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.bas.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching brc list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_dropdown_list() {
    try {
      const block_response = await this.bs.get_list({})
      const academic_year_id_response = await this.ays.get_list({})
      const brc_user_id_response = await this.ba.get_list({})
      let block_options = block_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_id = brc_user_id_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let academic_year_id_options = academic_year_id_response.data.map((item: any) => {
        return {
          title: item.year || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.academic_year_id_response = academic_year_id_options
      this.dropdown_list.brc_user_id_response = brc_user_id
      this.dropdown_list.block_response = block_options

    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }



  async add() {
    if (this.brc_form.valid) {
      this.ui_spinner.startLoader('add-brc-assignment-loader');
      this.add_brc_assignment_button.disabled = true
      try {
        let response: any = await this.bas.add(this.brc_form.value)
        console.log(response, " d");
        this.brc_form.reset();
        this.get_brc_list(this.params)
        this.ui_spinner.stopLoader('add-brc-assignment-loader');
        this.add_brc_assignment_button.disabled = false
        this.toastr.success(response.message)
        this.open_brc_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-brc-assignment-loader');
        this.add_brc_assignment_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.brc_form.markAllAsTouched()
    }
  }
  // async delete_confirm(id: number) {
  //   let response = await this.ba.delete(id);
  //   this.list.data = this.list?.data.filter((item: any) => item.id != id);
  //   this.toastr.success(response.message)
  //   this.brc_delete.close()
  // }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-brc-assignment-loader');
    try {
      let response = await this.bas.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_brc_list(this.params);
      this.toastr.success(response.message || 'Brc Assignment Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-brc-assignment-loader');
      this.brc_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-brc-assignment-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }
  async edit(id: number) {
    console.log(this.brc_form.value, "666666");
    
    if (this.brc_form.valid) {
      this.ui_spinner.startLoader('update-brc-assignment-loader');
      this.update_brc_assignment_button.disabled = true
      try {
        let response = await this.bas.update(id, this.brc_form.value);
        this.brc_form.reset();
        this.get_brc_list(this.params)
        this.ui_spinner.stopLoader('update-brc-assignment-loader');
        this.update_brc_assignment_button.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_brc_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-brc-assignment-loader');
        this.update_brc_assignment_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.brc_form.markAllAsTouched()
    }
  }
  async get_brc(id: any) {
    console.log(id);
    try {
      let data = await this.bas.get(id)
      this.selected_brc = data.data
      this.brc_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching brc:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_brc_list(this.params);
    }
  }

}
