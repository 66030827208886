<div class="flex justify-between col-span-6">
    <h2 class="text-3xl font-medium col-span-6">Personal Details</h2>
    <div class="flex items-center gap-3">
        <!-- <app-icon-cta class="text-red-500 text-2xl rounded-full" icon="bx bx-x"></app-icon-cta> -->
        <!-- <app-icon-cta class="text-green-500 text-2xl rounded-full" icon="bx bx-check"></app-icon-cta> -->
        <button type="button" class="border hover:bg-gray-200 ml-0 rounded-full w-10 h-10 text-red-500 text-2xl m-auto"><i class='bx bx-x'></i></button>
        <button type="button" class="border hover:bg-gray-200 ml-0 rounded-full w-10 h-10 text-green-500 text-2xl m-auto"><i class='bx bx-check'></i></button>
    </div>
</div>
<h3 class="text-2xl font-medium col-span-6">Primary Contact</h3>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
    <p class=" text-gray-900 text-md w-full">Arun Gounder</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Gender</p>
    <p class=" text-gray-900 text-md w-full">Male</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Email Id</p>
    <p class=" text-gray-900 text-md w-full">gounderarun2812&#64;gmail.com</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Phone Number</p>
    <p class=" text-gray-900 text-md w-full">9632587412</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Alternate Phone Number</p>
    <p class=" text-gray-900 text-md w-full">9652385698</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">City</p>
    <p class=" text-gray-900 text-md w-full">Navi Mumbai</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">State</p>
    <p class=" text-gray-900 text-md w-full">Maharashtra</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Country</p>
    <p class=" text-gray-900 text-md w-full">India</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Pin Code</p>
    <p class=" text-gray-900 text-md w-full">400708</p>
</div>
<h3 class="text-2xl font-medium col-span-6">Secondary Contact</h3>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
    <p class=" text-gray-900 text-md w-full">Arun Gounder</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Gender</p>
    <p class=" text-gray-900 text-md w-full">Male</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Email Id</p>
    <p class=" text-gray-900 text-md w-full">gounderarun2812&#64;gmail.com</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Phone Number</p>
    <p class=" text-gray-900 text-md w-full">9632587412</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Alternate Phone Number</p>
    <p class=" text-gray-900 text-md w-full">9652385698</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">City</p>
    <p class=" text-gray-900 text-md w-full">Navi Mumbai</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">State</p>
    <p class=" text-gray-900 text-md w-full">Maharashtra</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Country</p>
    <p class=" text-gray-900 text-md w-full">India</p>
</div>
<div class="col-span-6 sm:col-span-3 lg:col-span-2">
    <p class="block mb-2 text-md font-semibold text-gray-900">Pin Code</p>
    <p class=" text-gray-900 text-md w-full">400708</p>
</div>
<app-text-input class="col-span-3 mt-5" label="Remark"></app-text-input>