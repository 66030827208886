<!-- <div class="relative">
    <input type="date" [disabled]="disabled || paramValue == 'true'" [(ngModel)]="value"
        (ngModelChange)="onChange($event)" [id]="label" [min]="minDateInput || null" [max]="maxDateInput || null"
        class="rounded-lg block px-4 h-12 w-full text-sm text-gray-900 bg-white border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
    <label [for]="label"
        class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{{label}}<span
            *ngIf="is_required" class="col-span-2 text-red-600">*</span></label>
  </div>
  
<div *ngIf="control && control.errors && control.touched" class="error-messages">
    <p class="text-red-500" *ngIf="control.errors.required">{{label}} is required.</p>
    <p class="text-red-500" *ngIf="control.errors.minlength">Minimum length required is {{
        control.errors.minlength.requiredLength }}.
    </p>
    <p class="text-red-500" *ngIf="control.errors.maxlength">Maximum length allowed is {{
        control.errors.maxlength.requiredLength }}.
    </p>
</div> -->

<div class="relative">
    <input type="date" [disabled]="disabled || paramValue == 'true'" [(ngModel)]="value"
        (ngModelChange)="onChange($event)" (change)="onDateChange($event)" [id]="label" [min]="minDateInput || null" [max]="maxDateInput || null"
        class="appearance-none rounded-lg block px-4 h-14 w-full text-sm text-gray-900 bg-white border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
    <label [for]="label"
        class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{{label}}<span
            *ngIf="is_required" class="col-span-2 text-red-600">*</span></label>
    <i
        class='absolute bx bx-calendar-alt right-4 text-xl text-black dark:text-black top-1/2 transform -translate-y-1/2 pointer-events-none'></i>
</div>

<div *ngIf="control && control.errors && control.touched" class="error-messages">
    <p class="text-red-500" *ngIf="control.errors.required">{{label}} is required.</p>
    <p class="text-red-500" *ngIf="control.errors.minlength">Minimum length required is {{
        control.errors.minlength.requiredLength }}.
    </p>
    <p class="text-red-500" *ngIf="control.errors.maxlength">Maximum length allowed is {{
        control.errors.maxlength.requiredLength }}.
    </p>
</div>