<div *ngIf="is_popup_visible"
    class="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-55 backdrop-blur-sm p-4">
    <div class="bg-white rounded-lg max-h-screen overflow-auto w-[80%] sm::w-[50%] lg:w-[50%]">
        <div class="sticky top-0 z-30 bg-white flex w-full justify-between items-center p-4 rounded-t pb-2">
            <h1 class="leading-7 md:text-xl print-section sm:leading-10 text-xl tracking-tight font-medium text-[#212121]">{{title}}</h1>
            <button (click)="close()" type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <i class='bx bx-x text-2xl'></i>
                <span class="sr-only">Close modal</span>
            </button>
        </div>
        <div class="overflow-y-auto px-4 py-4 flex flex-col justify-between rounded-t w-full">
            <ng-content></ng-content>
        </div>
    </div>
</div>