<app-header header_title="{{params.id ? (params.view ? 'View': 'Update'): ' Add'}} Assessment">
    <app-button *ngIf="path.includes('reports')" color="bg-white text-color-500 border-gray-#BDBDBD border"
        label="Cancel" placement="header" routerLink="/reports/assessment"></app-button>
    <app-button *ngIf="params.view != 'true' && !path.includes('reports')"
        color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel" placement="header"
        routerLink="/assessment"></app-button>
    <app-button *ngIf="!params.id && params.view != 'true' && !path.includes('reports')"
        color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" #add_assessment_button (click)="add()" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="add-Assessment-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
    <app-button *ngIf="params.id && params.view != 'true'" color="bg-[#E697AB] text-[#310000]" [label]="'Update'"
        #update_assessment_button (click)="edit(params.id)" [disabled]="isFormDisabled" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="update-Assessment-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader></app-button>
</app-header>
<div class="px-8">
    <form [formGroup]="assessment_form">
        <div class="bg-white flex flex-col p-4 rounded-lg">
            <!-- Assessment Meta Data Section -->
            <p class="font-normal text-base text-text-color-600">Assessment Meta Data</p>
            <p class="text-text-color-500 font-medium text-sm mt-2">
                To create a new assessment, please ensure all mandatory fields are completed accurately. Each field
                serves a specific purpose in facilitating the assessment creation process.
            </p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4 w-full">
                <app-text-input formControlName="assessment_name" label="Assessment Name"></app-text-input>
                <app-select-input formControlName="grade_id" class="w-full" label="Grade"
                    [options]="dropdown_list.grade_list" (change)="onGradeChange($event)"></app-select-input>
                <app-select-input formControlName="course_id" class="w-full" label="Course"
                    [options]="dropdown_list.cource_list"></app-select-input>
                <app-select-input formControlName="assessment_type_id" class="w-full" label="Assessment Type"
                    [options]="dropdown_list.assessment_type_list"></app-select-input>
                <app-select-input formControlName="academic_year_id" class="w-full" label="Academic Year"
                    [options]="dropdown_list.academic_year_list" (change)="onAcdemicChange($event)"></app-select-input>
                <app-date-input [disabled]="params.id" [minDateInput]="current_date_show"
                    formControlName="assessment_date" label="Assessment Date"></app-date-input>
                <app-text-input [disabled]="params.add || params.id" formControlName="total_mark"
                    label="Total Marks"></app-text-input>

            </div>
        </div>


        <div class="bg-white flex flex-col mt-6 p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Student Details</p>
            <div class="mt-4 px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
                <div class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
                    <table class="table-auto w-full border-collapse">
                        <thead
                            class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                            <tr>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Sr No</th>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Student Name</th>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Marks</th>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Remark</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="student_details"
                            *ngFor="let student of studentDetails.controls; let i = index">
                            <tr class="bg-white border-b hover:bg-gray-50" [formGroupName]="i">
                                <td class="px-4 h-12 py-1 text-center">{{ i + 1 }}</td>
                                <td class="px-4 h-12 py-1 text-center">
                                    <p>{{student.value.student_name}}</p>
                                </td>
                                <td class="px-4 h-12 py-1 text-center">
                                    <input formControlName="mark" [disabled]="params.view"
                                        [class.pointer-events-none]="params.view" [class.bg-gray-200]="params.view"
                                        [class.pointer-events-none]="params.add" [class.bg-gray-200]="params.add"
                                        class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40 text-center"
                                        type="number">
                                    <div *ngIf="(student.get('mark')?.dirty || student.get('mark')?.touched)">
                                        <div *ngIf="student.get('mark')?.errors?.['marksExceeded']"
                                            class="text-red-400 text-sm">Mark cannot exceed Total Marks.</div>
                                        <div *ngIf="student.get('mark')?.errors?.['pattern']"
                                            class="text-red-400 text-sm">Enter a Valid Number.</div>
                                    </div>
                                </td>
                                <td class="px-4 h-12 py-1 text-center">
                                    <input formControlName="remark" [disabled]="params.view"
                                        [class.pointer-events-none]="params.view" [class.bg-gray-200]="params.view"
                                        [class.pointer-events-none]="params.add" [class.bg-gray-200]="params.add"
                                        class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40 text-center"
                                        type="text">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </form>

</div>