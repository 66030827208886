import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UdiseService {


  constructor(public us: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.us.post('/udise/add-udise', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.us.delete('/udise/delete-udise?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.us.get('/udise/get-udise?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  async get_by_udise_code(udise_code: any) {
    try {
      let response = await this.us.get('/udise/get-udise?udise_code=' + udise_code, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.us.get('/udise/get-udise-list', filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async update(id: number, value: any) {
    try {
      console.log(id);
      let response = await this.us.post('/udise/update-udise?id=' + id, {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
}
