<div class="fixed inset-0 z-40 bg-black opacity-50" [class.hidden]="!is_filter_open" (click)="toggle_filter()"></div>
<div [class.translate-x-full]="!is_filter_open" class="fixed inset-y-0 right-0 bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out transform pt-[30px] flex justify-start flex-col gap-[30px] items-center top-0 px-[12px] pb-[24px] h-full w-[70%] md:w-[50%] lg:w-[30%]">
    <div class="flex items-center justify-between w-full">
        <p class="text-xl ">Filter</p>
        <p (click)="reset_form()" class="text-[14px] text-[#3F41D1] font-medium cursor-pointer uppercase">
            Clear All
        </p>
    </div>
    <ng-content></ng-content>
</div>
<!-- 
<div class="grid w-full h-full grid-cols-12">
    <div class="col-span-4 gap-2 pe-2 border-e-2">
        <div class="flex flex-col w-full gap-2">
            @for (item of fields; track item) {
            <div [ngClass]="{'bg-[#3F41D1]': item.title && item.title == selected_item, 'text-white': item.title && item.title == selected_item}" class="p-2 text-black rounded-md cursor-pointer" (click)="selected_section(item.title)">{{item.title}}</div>
            }
        </div>
    </div>
    <form class="col-span-8 p-2" [formGroup]="form">
        @for (item of fields; track item) {
        <app-text-input *ngIf="selected_item == item.title" [formControlName]="item.label" [label]="item.title"></app-text-input>
        }
    </form>
</div>
<div class="flex items-center justify-center w-full gap-3 rounded-b-lg sm:justify-end">
    <app-filter-pill-button label="Cancel" (click)="toggle_filter()"></app-filter-pill-button>
    <app-button color="bg-[#3F41D1] text-white" label="Filter" (click)="submit_filter()"></app-button>
</div> -->