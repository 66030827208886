import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  list: any[] = []

  constructor(public hs: HttpService, public gs: GlobalService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/asset/add-asset', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/asset/delete-asset?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/dashboard/get-dashboard?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      let url;
      if (this.gs.user.role == 'Instructor') {
        url = `/dashboard/get-dashboard?school_id=${this.gs.selected_school.school_id}`
      } else {
        url = '/dashboard/get-dashboard'
      }
      console.log(filters)
      let response = await this.hs.get(url, filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async update(id: number, value: any) {
    try {
      console.log(id);
      let response = await this.hs.post('/asset/update-asset?id=' + id, {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
}
