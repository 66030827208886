<app-modal #modal_open title="Venue Details">
    <div class="w-[1200px] h-[600px]">
        <div class="flex justify-around bg-[#F7F7F7] cursor-pointer sticky top-0 p-5 w-full z-10">
            <ol
                class="flex items-center w-full text-sm font-medium text-center text-gray-500 sm:text-base bg-white px-6 py-4 shadow-sm rounded-md">
                <ng-container *ngFor="let step of form_steps; let i = index">
                    <li *ngIf="step.title != 'Facilities'"
                        [ngClass]="{'after:border-[#3F41D1]':current_step > step.step, 'after:border-[#666666]':current_step <= step.step }"
                        class="flex w-full items-center text-[#3F41D1] sm:after:content-[''] after:w-full after:h-1 after:border-b-2 after:border-1 after:hidden sm:after:inline-block after:mx-4 lg:after:mx-6"
                        (click)="toggleStep(i)">
                        <span
                            class="flex flex-col gap-2 items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
                            <div *ngIf="current_step <= step.step"
                                [ngClass]="{'bg-[#3F41D1]':current_step == step.step, 'bg-[#666666]':current_step < step.step}"
                                class="h-6 w-6 flex justify-center items-center rounded-full text-white">
                                <span class="h-full w-full">{{i + 1}}</span>
                            </div>
                            <span *ngIf="current_step > step.step"
                                class="bg-[#3F41D1] text-white h-6 w-6 flex justify-center items-center rounded-full">
                                <i class='bx bx-check'></i>
                            </span>
                            <span
                                [ngClass]="{'text-[#3F41D1]':current_step == step.step, 'text-[#666666]':current_step < step.step}"
                                class="w-full flex uppercase text-[12px] font-medium tracking-wide">{{step.title}}</span>
                        </span>
                    </li>
                    <li *ngIf="step.title == 'Facilities'" class="flex items-center w-full" (click)="toggleStep(i)">
                        <span class="flex flex-col items-center gap-2">
                            <div *ngIf="current_step <= step.step"
                                [ngClass]="{'bg-[#3F41D1]':current_step == step.step, 'bg-[#666666]':current_step < step.step}"
                                class="h-6 w-6 flex justify-center items-center rounded-full text-white">
                                <span class="w-full h-full">{{i + 1}}</span>
                            </div>
                            <span
                                [ngClass]="{'text-[#3F41D1]':current_step == step.step, 'text-[#666666]':current_step < step.step}"
                                class="w-full flex uppercase text-[12px] font-medium tracking-wide">{{step.title}}</span>
                        </span>
                    </li>
                </ng-container>
            </ol>
        </div>

        <form class="w-full overflow-hidden p-5 bg-white rounded mx-auto">
            <div class="flex flex-col" *ngFor="let step of form_steps; let i = index" class="cursor-pointer">
                <div class="flex flex-col w-full gap-5"
                    [ngClass]="{'transition-all duration-500 ease-in-out overflow-hidden h-0 opacity-0 p-0': !is_expanded[i], 'transition-all duration-500 ease-in-out overflow-hidden h-max text-opacity-100 p-5': is_expanded[i]}">
                    <div class="" [class.hidden]="!is_expanded[i]">
                        <div *ngIf="step.title == 'Personal Details'" class="grid grid-cols-6 gap-5 md:grid-cols-6">
                            <div class="flex justify-between col-span-6">
                                <h2 class="text-3xl font-medium col-span-6">Personal Details</h2>
                            </div>
                            <h3 class="text-2xl font-medium col-span-6">Primary Contact</h3>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                <p class=" text-gray-900 text-md w-full">Arun Gounder</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Gender</p>
                                <p class=" text-gray-900 text-md w-full">Male</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Email Id</p>
                                <p class=" text-gray-900 text-md w-full">gounderarun2812&#64;gmail.com</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Phone Number</p>
                                <p class=" text-gray-900 text-md w-full">9632587412</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Alternate Phone Number</p>
                                <p class=" text-gray-900 text-md w-full">9652385698</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">City</p>
                                <p class=" text-gray-900 text-md w-full">Navi Mumbai</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">State</p>
                                <p class=" text-gray-900 text-md w-full">Maharashtra</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Country</p>
                                <p class=" text-gray-900 text-md w-full">India</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Pin Code</p>
                                <p class=" text-gray-900 text-md w-full">400708</p>
                            </div>
                            <h3 class="text-2xl font-medium col-span-6">Secondary Contact</h3>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                <p class=" text-gray-900 text-md w-full">Arun Gounder</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Gender</p>
                                <p class=" text-gray-900 text-md w-full">Male</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Email Id</p>
                                <p class=" text-gray-900 text-md w-full">gounderarun2812&#64;gmail.com</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Phone Number</p>
                                <p class=" text-gray-900 text-md w-full">9632587412</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Alternate Phone Number</p>
                                <p class=" text-gray-900 text-md w-full">9652385698</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">City</p>
                                <p class=" text-gray-900 text-md w-full">Navi Mumbai</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">State</p>
                                <p class=" text-gray-900 text-md w-full">Maharashtra</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Country</p>
                                <p class=" text-gray-900 text-md w-full">India</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-md font-semibold text-gray-900">Pin Code</p>
                                <p class=" text-gray-900 text-md w-full">400708</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                <p class=" text-gray-900 text-md w-full">Test Remark</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                            </div>
                        </div>

                        <div *ngIf="step.title == 'Venue Details'" class="grid grid-cols-6 gap-5 md:grid-cols-6">
                            <div class="flex justify-between col-span-6">
                                <h2 class="text-3xl font-medium col-span-6">Venue Details</h2>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Venue Name</p>
                                <p class=" text-gray-900 text-md w-full">Terna College</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Nature of Venue</p>
                                <p class=" text-gray-900 text-md w-full">MCC</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Primary Contact Number</p>
                                <p class=" text-gray-900 text-md w-full">8563254125</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Primary Email Id</p>
                                <p class=" text-gray-900 text-md w-full">arun.gounder&#64;gmail.com</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Secondary Contact Number</p>
                                <p class=" text-gray-900 text-md w-full">8563254125</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Secondary Email Id</p>
                                <p class=" text-gray-900 text-md w-full">arun.gounder&#64;gmail.com</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">GST</p>
                                <p class=" text-gray-900 text-md w-full">4566d1dDDND5626</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Aadhar</p>
                                <p class=" text-gray-900 text-md w-full">2563 5485 6523 5688</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Pareking Facility</p>
                                <p class=" text-gray-900 text-md w-full">Yes</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">CCTV Coverage</p>
                                <p class=" text-gray-900 text-md w-full">Yes</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Network Details</p>
                                <p class=" text-gray-900 text-md w-full">Network.jpg</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                <p class=" text-gray-900 text-md w-full">Test Remark</p>
                            </div>
                            <div class="col-span-2">
                                <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                            </div>
                        </div>

                        <div *ngIf="step.title == 'Resources'" class="grid grid-cols-6 md:grid-cols-6">
                            <h2 class="text-3xl font-medium col-span-4 mb-5">Resources</h2>
                            <app-serch-text-input class="col-span-2 mb-5" placeholder="Search"></app-serch-text-input>
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-t-lg pb-5">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                            </div>
                            <div class="col-span-6 overflow-x-auto bg-white border rounded-b-lg mb-5">
                                <table class="table-auto w-full border-collapse">
                                    <thead class="text-sm font-medium border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                                        <tr>
                                            <th class="border px-4 h-12 text-left">Self Rating</th>
                                            <th class="border px-4 h-12 text-left">Auditor Rating</th>
                                            <th class="border px-4 h-12 text-left">Auditor Remark</th>
                                            <th class="border px-4 h-12 text-center">Auditor Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm font-normal text-black/87 tracking-[0.32px]">
                                        <tr class="border-b border-[#CFD5F1]">
                                            <td class="border px-4 h-12 text-left">Very Good</td>
                                            <td class="border px-4 h-12 text-left">Good</td>
                                            <td class="border px-4 h-12 text-left">Test Remark</td>
                                            <th class="border px-4 h-12 text-center"><button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-red-500 text-2xl m-auto"><i class='bx bx-x'></i></button></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>   
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-t-lg pb-5">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                            </div>    
                            <div class="col-span-6 overflow-x-auto bg-white border rounded-b-lg mb-5">
                                <table class="table-auto w-full border-collapse">
                                    <thead class="text-sm font-medium border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                                        <tr>
                                            <th class="border px-4 h-12 text-left">Self Rating</th>
                                            <th class="border px-4 h-12 text-left">Auditor Rating</th>
                                            <th class="border px-4 h-12 text-left">Auditor Remark</th>
                                            <th class="border px-4 h-12 text-center">Auditor Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm font-normal text-black/87 tracking-[0.32px]">
                                        <tr class="border-b border-[#CFD5F1]">
                                            <td class="border px-4 h-12 text-left">Very Good</td>
                                            <td class="border px-4 h-12 text-left">Very Good</td>
                                            <td class="border px-4 h-12 text-left">Test Remark</td>
                                            <th class="border px-4 h-12 text-center"><button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto"><i class='bx bx-check'></i></button></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                        
                        </div>

                        <div *ngIf="step.title == 'Services'" class="grid grid-cols-6 gap-5 md:grid-cols-6">
                            <h2 class="text-3xl font-medium col-span-4">Services</h2>
                            <app-serch-text-input class="col-span-2" placeholder="Search"></app-serch-text-input>
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                    <p class=" text-gray-900 text-md w-full">Test Remark</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                    <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                                </div>
                            </div>
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                    <p class=" text-gray-900 text-md w-full">Test Remark</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                    <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="step.title == 'Facilities'" class="grid grid-cols-6 gap-5 md:grid-cols-6">
                            <h2 class="text-3xl font-medium col-span-4">Facilities</h2>
                            <app-serch-text-input class="col-span-2" placeholder="Search"></app-serch-text-input>
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Facility Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Facility Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                    <p class=" text-gray-900 text-md w-full">Test Remark</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                    <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                                </div>
                            </div>
                            <div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
                                <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop 1</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Facility Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
                                    <p class=" text-gray-900 text-md w-full">i7 13007K</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Facility Type</p>
                                    <p class=" text-gray-900 text-md w-full">Laptop</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
                                    <p class=" text-gray-900 text-md w-full">Dell</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
                                    <p class=" text-gray-900 text-md w-full">15</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Remark</p>
                                    <p class=" text-gray-900 text-md w-full">Test Remark</p>
                                </div>
                                <div class="col-span-2">
                                    <p class="block mb-2 text-sm font-semibold text-gray-900">Auditor Action</p>
                                    <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto text-center"><i class='bx bx-check'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-modal>