import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DistrictsService {
  constructor(public ds: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.ds.post('/district/add-district', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.ds.delete('/district/delete-district?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.ds.get('/district/get-district?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.ds.get('/district/get-district-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.ds.post('/district/update-district?id=' + parseInt(id), {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
