<app-header header_title="Daily Progress Report">
    <app-button color="bg-accent-300 text-accent-on" label="Add Daily Progress Report" routerLink="/dashboard/daily-progress-report/form"></app-button>
</app-header>
<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3">
        <div class="relative inline-block">
            <input type="month"  (change)="lable_month_show($event)"
                class="rounded-lg block px-4 h-12 w-full text-sm text-gray-900 bg-white border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
            <label [for]=""
                class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Month
            </label>
        </div>
        <app-serch-text-input (valueChange)="onSearch($event)" class="w-full md:w-auto" placeholder="Search"></app-serch-text-input>
        <!-- <app-filter-pill-button class="hidden md:block" label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="daily_progress_list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="daily_progress_list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">Date</th>
                    <th class="px-4 h-12 text-left">Description</th>
                    <!-- <th class="px-4 h-12 text-left">Proofs</th> -->
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of daily_progress_list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left">{{i+1}}</td>
                    <td class="px-4 h-12 text-left">{{item.date}}</td>
                    <td class="px-4 h-12 text-left max-w-36 truncate">{{item.description}}</td>
                    <!-- <td class="px-4 h-12 text-left">{{item.image}}</td> -->
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-edit-alt" routerLink="dashboard/daily-progress-report/form" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="country_delete.open()" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>
<app-confirmation-popup #country_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this Country" popup_title="Edit Country"></app-confirmation-popup>
<app-confirmation-popup #country_delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this Country" popup_title="Delete Country"></app-confirmation-popup>