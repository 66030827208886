<app-header header_title="Instructor Assignment"></app-header>
<div class="flex justify-end items-end px-4 mb-3">
    <div class="w-full flex justify-end items-end gap-3 md:w-96">
        <app-select-input class="w-full" label="Instructor" [options]="dropdown_list.instructor_list"
            (change)="instructor_show_filter($event)"></app-select-input>
        <app-select-input class="w-full" label="School" [options]="dropdown_list.school_list"
            (change)="school_show_filter($event)"></app-select-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="insctuctor_list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="insctuctor_list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">School </th>
                    <th class="px-4 h-12 text-left">Instructor</th>
                    <th class="px-4 h-12 text-left">Academic Year </th>
                    <th class="px-4 h-12 text-left">Date</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of insctuctor_list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left">{{item.instructor_user_name}}</td>
                    <td class="px-4 h-12 text-left">{{item.academic_year}}</td>
                    <td class="px-4 h-12 text-left">{{item.updatedAt | date: 'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="insctuctor_list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems"
        [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>