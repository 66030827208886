import { NgIf } from '@angular/common';
import { Component, Injector, Input, NgModule } from '@angular/core';
import { FormControl, FormsModule, NgControl } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  standalone: true,
  imports: [NgIf,FormsModule],
  templateUrl: './time-input.component.html',
})
export class TimeInputComponent {
  @Input() label = '';
  @Input() placeholder = 'HH:MM';
  @Input() disabled: boolean = false;
  @Input() is_required: boolean = false;

  control!: FormControl;
  value = '';
  onChange: any;
  onTouched: any;

  constructor(private injector: Injector) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl | null = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        // Component is missing form control binding
      }
    }, 100);
  }
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
