import { Component, ElementRef, ViewChild } from '@angular/core';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { HeaderComponent } from '../../components/header/header.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import * as XLSX from 'xlsx';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AssetsService } from '../../services/assets.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { SubjectService } from '../../services/subject.service';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-subject-list-page',
  standalone: true,
  imports: [VenueAuditViewComponent, NgxUiLoaderModule, HeaderComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, SelectInputComponent, ReactiveFormsModule, PaginationComponent],
  templateUrl: './subject-list-page.component.html',
})
export class SubjectListPageComponent {
  @ViewChild('open_popup') open_popup: any
  @ViewChild('add_subject_modal') add_subject_modal: any
  @ViewChild('update_assets_modal') update_assets_modal: any
  @ViewChild('subject_delete') subject_delete: any
  @ViewChild('add_subject_button') add_subject_button: any
  @ViewChild('update_subject_button') update_subject_button: any
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  debounce_timeout: any = undefined;
  list: any = {};
  params: any = {};
  asset_delete: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;
  errorMessage: any;
  open_button: boolean = false
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  subject_file_upload: boolean = true
  mistake_modal: boolean = false
  table_headers: any = [];
  table_data: any = [];
  selected_subject: any = {};
  parsed_data: any[] = [];

  subject_form = this.fb.group({
    name: ['', Validators.required],
  });

  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public fb: FormBuilder, public ss: SubjectService, public ar: ActivatedRoute, public toastr: ToastrService) {
  }

  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_subject_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_subject_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_subject_list(this.params);
      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_subject_list(this.params);
  }

  async add() {
    if (this.subject_form.valid) {
      this.ui_spinner.startLoader('add-subject-loader');
      this.add_subject_button.disabled = true
      try {
        let response: any = await this.ss.add(this.subject_form.value)
        this.subject_form.reset();
        this.add_subject_modal.close()
        this.get_subject_list(this.params)
        this.ui_spinner.stopLoader('add-subject-loader');
        this.add_subject_button.disabled = false
        this.toastr.success(response.message || 'Add Subject Successfully!')
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-subject-loader');
        this.add_subject_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.subject_form.markAllAsTouched()
    }
  }

  async get_subject_list(params: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ss.get_list(this.params)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching Country list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_subject(id: any) {
    console.log(id);
    try {
      let data = await this.ss.get(id)
      this.selected_subject = data.data
      console.log(this.selected_subject);
      this.subject_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching Country:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async edit(id: number) {
    if (this.subject_form.valid) {
      this.ui_spinner.startLoader('update-subject-loader');
      this.update_subject_button.disabled = true
      try {
        console.log(id);
        let response = await this.ss.update(id, this.subject_form.value);
        this.toastr.success(response.message)
        this.get_subject_list(this.params)
        this.subject_form.reset();
        this.get_subject_list(this.params)
        this.ui_spinner.stopLoader('update-subject-loader');
        this.update_subject_button.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_assets_modal.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-subject-loader');
        this.update_subject_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.subject_form.markAllAsTouched()
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-subject-loader');
    try {
      let response = await this.ss.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_subject_list(this.params);
      this.ui_spinner.stopLoader('delete-subject-loader');
      this.toastr.success(response.message || 'Subject Deleted Successfully!')
      this.subject_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-subject-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];

    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.subject_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.subject_file_upload = true
        return;
      }
    }
    this.subject_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }

  async add_subject_bulk() {
    try {

      let newData = this.parsed_data.map((item) => {
        let newItem = {
          name: item['Subject Name'],
        };
        return newItem;
      });

      const response: any = await this.ss.add(newData)
      this.get_subject_list(this.params)
      this.toastr.success(response.message || 'Subject Added Successfully!')
      this.open_bulk_modal.close()
      this.subject_file_upload = true;
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.subject_file_upload = true
      this.toastr.error(error?.error?.message)
    }
  }


  download_template(): void {
    const url = '../../../assets/template-files/subject_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'subject_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
