<label for="message" class="text-sm font-medium text-gray-900">{{label}} <span *ngIf="is_required" class="col-span-2 text-red-600">*</span></label>
<textarea id="message"  [disabled]="disabled || paramValue == 'true'"  [(ngModel)]="value" (ngModelChange)="onChange($event)"  rows="4" class="mt-2 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-blue-500" [placeholder]="label"></textarea>
<div *ngIf="control && control.errors && control.touched" class="error-messages">
    <p class="text-red-500" *ngIf="control.errors.required">This field is required.</p>
    <p class="text-red-500" *ngIf="control.errors.minlength">Minimum length required is {{
        control.errors.minlength.requiredLength }}.
    </p>
    <p class="text-red-500" *ngIf="control.errors.maxlength">Maximum length allowed is {{
        control.errors.maxlength.requiredLength }}.
    </p>
</div>