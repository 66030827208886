import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CircleProgressOptions } from 'ng-circle-progress';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideToastr({
    progressBar: true, // enable progress bar
    preventDuplicates: true, // prevent duplicate toasts
    closeButton: true, // show close button
  }), provideAnimationsAsync(), provideAnimations(), BrowserAnimationsModule, provideCharts(withDefaultRegisterables()), CircleProgressOptions, importProvidersFrom(HttpClientModule), provideHttpClient(withFetch())]
};
