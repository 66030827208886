<h2 class="text-3xl font-medium col-span-6">Resources</h2>
<div class="col-span-6 grid grid-cols-6 items-center gap-4">
    <app-serch-text-input class="col-span-2 mt-5" placeholder="Search"></app-serch-text-input>
    <app-select-input class="col-span-2 mt-5" label="Status" [options]="[{title: 'Audited', value: 'Audited'},{title: 'Pending', value: 'Pending'},{title: 'Rejected', value: 'Rejected'}]"></app-select-input>
    <app-select-input class="col-span-2 mt-5" label="Resources" [options]="[{title: 'Play Ground', value: 'Play Ground'},{title: 'Lab', value: 'Lab'}]"></app-select-input>
</div>
<div id="resource-view-tree" class="col-span-6 overflow-auto"></div>
<div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
    <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
        <p class=" text-gray-900 text-md w-full">Laptop 1</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
        <p class=" text-gray-900 text-md w-full">Laptop</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
        <p class=" text-gray-900 text-md w-full">Dell</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
        <p class=" text-gray-900 text-md w-full">15</p>
    </div>
    <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
        <p class=" text-gray-900 text-md w-full">i7 13007K</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
        <p class=" text-gray-900 text-md w-full">Laptop</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
        <p class=" text-gray-900 text-md w-full">Dell</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
        <p class=" text-gray-900 text-md w-full">15</p>
    </div>
    <app-select-input class="col-span-2" title="Rating" label="Give a Rating" [options]="[{value:'Excellent', title:'Excellent',},{value:'Very Good', title:'Very Good',},{value:'Good', title:'Good',},{value:'Better', title:'Better',},{value:'Poor', title:'Poor',}]"></app-select-input>
    <app-text-input class="col-span-5" label="Auditor Remark" placeholder="Auditor Remark"></app-text-input>
    <div class="flex col-span-1 items-end">
        <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-red-500 text-2xl m-auto"><i class='bx bx-x'></i></button>
        <button (click)="request_modal = !request_modal" type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto"><i class='bx bx-check'></i></button>
    </div>
</div>
<div class="border col-span-6 grid grid-cols-6 gap-5 md:grid-cols-8 p-3 rounded-lg">
    <h3 class="text-2xl font-medium col-span-8">Basic Information</h3>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Name</p>
        <p class=" text-gray-900 text-md w-full">Laptop 1</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
        <p class=" text-gray-900 text-md w-full">Laptop</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
        <p class=" text-gray-900 text-md w-full">Dell</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
        <p class=" text-gray-900 text-md w-full">15</p>
    </div>
    <h3 class="text-2xl font-medium col-span-8">Technical Information</h3>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Processor</p>
        <p class=" text-gray-900 text-md w-full">i7 13007K</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Resource Type</p>
        <p class=" text-gray-900 text-md w-full">Laptop</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Brand</p>
        <p class=" text-gray-900 text-md w-full">Dell</p>
    </div>
  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
        <p class="block mb-2 text-md font-semibold text-gray-900">Display Size</p>
        <p class=" text-gray-900 text-md w-full">15</p>
    </div>
    <app-select-input class="col-span-2" title="Rating" label="Give a Rating" [options]="[{value:'Excellent', title:'Excellent',},{value:'Very Good', title:'Very Good',},{value:'Good', title:'Good',},{value:'Better', title:'Better',},{value:'Poor', title:'Poor',}]"></app-select-input>
    <app-text-input class="col-span-5" label="Auditor Remark" placeholder="Auditor Remark"></app-text-input>
    <div class="flex col-span-1 items-end">
        <button type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-red-500 text-2xl m-auto"><i class='bx bx-x'></i></button>
        <button (click)="request_modal = !request_modal" type="button" class="border hover:bg-gray-200 rounded-full bg-white ml-0  w-10 h-10 text-green-500 text-2xl m-auto"><i class='bx bx-check'></i></button>
    </div>
</div>
<div *ngIf="request_modal" class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
    <div class="w-[250px] md:w-[350px] bg-white shadow-lg rounded-md p-6 relative">
        <div class="flex flex-col items-center justify-center gap-2">
            <span class="h-[76px] w-[76px] flex justify-center items-center bg-[#E1E8F5] rounded-full">
                <i class='bx bx-map text-5xl text-[#666666]'></i>
            </span>
            <h4 class="text-2xl text-[#333] font-semibold">Physical Vicinity Required</h4>
            <p class="text-sm text-gray-500 text-center">For approving the resource you need to be in the vicinity.</p>
        </div>
        <button (click)="access_success()" type="button" class="mt-4 px-6 h-10 min-w-[150px] w-full rounded-full text-white text-sm font-semibold border-none outline-none bg-[#3F41D1] hover:bg-[#3F41D1]">OK</button>
    </div>
</div>