<app-header header_title="Add Daily Progress Report">
    <app-button color="bg-white text-[#666666] border-gray-300 border" [label]="'Cancel'"
        routerLink="/dashboard/daily-progress-report"  ></app-button>
    <app-button color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" routerLink="/dashboard/daily-progress-report"
         ></app-button>
</app-header>
<form class="px-8 w-full">
    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">Basic Information</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new daily report progress
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 mt-4">
            <app-date-input label="Date" [minDateInput]="current_date_show"></app-date-input>
            <app-text-input label="Proofs"></app-text-input>
        </div>
        <div class="w-full mt-2">
            <app-text-area-input label="Description" class="w-full"></app-text-area-input>
        </div>
    </div>
</form>