import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';

@Component({
  selector: 'app-instructor-assignment-form',
  standalone: true,
  imports: [
    HeaderComponent,
    ButtonComponent,
    SelectInputComponent,
    TextInputComponent,
    NgFor,
    DateInputComponent,
    RouterLink,
    NgIf,
  ],
  templateUrl: './instructor-assignment-form.component.html',
})
export class InstructorAssignmentFormComponent {
  isTableDisabled: boolean = true;
  assessment_list: any[] = [];
  instructor_list: any = [
    {
      school: 'Jawahar Navodaya Vidyalaya',
      academic_year: '2023-24',
      instructor: 'Shubham Salunkhe',
      course: 'Integrated Law Course- BA + LL.B',
    },
  ];
  school: any = [
    { title: 'Ark International School' },
    { title: 'Darbhanga Public School' },
    { title: 'DAV Public School' },
    { title: 'Jawahar Navodaya Vidyalaya' },
  ];
  instructor: any = [
    { title: 'Shubham Salunke' },
    { title: 'Bhavesh Karkare' },
    { title: 'Arun Gounder' },
    { title: 'Shreeram Kaudare' },
    { title: 'Mayur Talekar' },
  ];
  course: any = [
    { title: 'BBA- Bachelor of Business Administration' },
    { title: 'BMS- Bachelor of Management Science' },
    { title: 'Integrated Law Course- BA + LL.B' },
    { title: 'BFA- Bachelor of Fine Arts' },
  ];
  newRowIndex: number = -1;
  current_date_show:any
  constructor(){
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }
  add_instructor(): void {
    this.instructor_list.push({ student_name: '', marks: '' });
    this.newRowIndex = this.instructor_list.length - 1;
    this.isTableDisabled = false;
  }

  delete_instructor(index: number): void {
    this.instructor_list.splice(index, 1);
    if (this.instructor_list.length === 0) {
      this.isTableDisabled = true;
    }
    this.newRowIndex = -1;
  }
}
