import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleByValue',
  standalone: true
})
export class TitleByValuePipe implements PipeTransform {

  transform(any_array: any[], value: any, key: string): string | undefined {
    if (!any_array) {
      return undefined;
    }
    const material = any_array.find(item => item.value == value);
    console.log(material ? (key ? typeof material[key] : material) : undefined, material ? (key ? material[key] : material) : undefined, "4444444444");
    return material ? (key ? material[key] : material) : undefined;
  }

}
