import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  
  list: any[] = []

  constructor(public hs: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/asset/add-asset', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/asset/delete-asset?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/asset/get-asset?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/asset/get-asset-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: number, value: any) {
    try {
      console.log(id);
      let response = await this.hs.post('/instructor-assignment/update-progress?id=' + id, {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
