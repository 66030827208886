import { Component } from '@angular/core';

@Component({
  selector: 'app-lesson-view',
  standalone: true,
  imports: [],
  templateUrl: './lesson-view.component.html'
})
export class LessonViewComponent {

}
