<app-header header_title="Dashboard" class="relative">
    <app-select-input *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'" class="w-44 sm:w-80" label="Filter By School"
        [options]="dropdown_list.school_list"  (change)="school_show_filter($event)"></app-select-input>
</app-header>
<div class="w-full flex flex-col-reverse md:flex-row px-4 md:px-8 pb-8 pt-2 gap-6">
    <div [ngClass]="(actionables_by_role[gs.user.role].length > 0 ) ? 'lg:w-[70%]' : 'w-full'"
        class="flex flex-col gap-6">
        <div *ngIf="card_data_by_role[gs.user.role].length >= 0 && actionables_by_role[gs.user.role].length >= 0" class="bg-white grid grid-cols-6 lg:grid-cols-12 gap-6 p-3
            sm:p-6 rounded-xl shadow">
            <div *ngIf="card_data_by_role[gs.user.role].length > 0" class="col-span-12 grid grid-cols-6 gap-2 sm:gap-4">
                <p class="col-span-6 font-medium text-base text-text-color-600">Overview</p>
                <div *ngFor="let item of card_data_by_role[gs.user.role]; let i = index;"
                    [ngClass]="{ 'lg:col-span-2' : card_data_by_role[gs.user.role].length === 6 }"
                    class="col-span-3 flex flex-col items-center justify-center px-1 py-8 rounded-2xl bg-indigo-50">
                    <p class="text-4xl sm:text-7xl font-medium leading-none tracking-tight"
                        [style.color]="color_scheme.domain[i]">{{item.count}}</p>
                    <p class="mt-1 text-[12px] sm:text-xl font-medium" [style.color]="color_scheme.domain[i]">
                        {{item.card_title}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="doughnut_chart_data_by_role[gs.user.role].length > 0" class="">
            <div class="gap-6 grid grid-cols-6 md:grid-cols-12">
                <ng-container *ngFor="let item of doughnut_chart_data_by_role[gs.user.role]; let i = index">
                    <div class="col-span-6 bg-white p-6 rounded-xl shadow flex flex-col gap-4 items-center">
                        <p class="text-base font-medium text-text-color-600 self-start">{{item.title}}</p>
                        <app-doughnut-chart [labels]="item.labels" [data_array]="item.data_array"></app-doughnut-chart>
                        <p class="font-medium ml-1.5 text-text-color-500 text-base">Total {{item.title}} :
                            {{item.total}}</p>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="line_chart_data_by_role[gs.user.role].length > 0" class="grid grid-cols-12 gap-6 overflow-hidden">
            <div class="col-span-12">
              <p class="text-2xl font-medium text-text-color-600">Data Summary</p>
            </div>
            <ng-container *ngFor="let item of line_chart_data_by_role[gs.user.role]; let i = index">
              <div class="col-span-12 md:col-span-6 bg-white flex flex-col gap-4 p-6 rounded-xl shadow">
                <div class="flex flex-col gap-4">
                  <p class="text-xl font-medium text-text-color-600 self-start">{{item.card_title}} vs Days</p>
                  <div class="flex items-start lg:items-center lg:flex-row flex-col justify-between gap-4">
                    <p>Date Range</p>
                    <div class="flex items-center gap-2 lg:flex-row flex-col ">
                      <app-date-input (dateSelected)="onDateSelected(item.card_title=='Activity'?'activities_start_date':'tests_start_date', $event)"></app-date-input>
                      <app-date-input (dateSelected)="onDateSelected(item.card_title=='Activity'?'activities_end_date':'tests_end_date', $event)"></app-date-input>
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <app-line-chart [labels]="item.labels" [data_array]="item.dataset"></app-line-chart>
                </div>
              </div>
            </ng-container>
          </div>          
        <div *ngIf="target_vs_actual_by_role[gs.user.role].length > 0" class="grid grid-cols-12 gap-6 overflow-hidden">
            <div class="col-span-12">
                <p class="text-2xl font-normal text-text-color-600">ReSchool Summary</p>
            </div>
            <ng-container *ngFor="let item of target_vs_actual_by_role[gs.user.role]; let i = index">
                <div class="col-span-12 md:col-span-6 bg-white flex flex-col gap-4 p-6 rounded-xl shadow">
                    <p class="text-base font-medium text-text-color-600">Target vs Actual Monthly ReSchool</p>
                    <p class="font-normal mb-2 text-base self-center text-text-color-500">{{item.target_name}}</p>
                    <div class="p-2">
                        <app-line-chart [labels]="item.labels" [data_array]="item.dataset"></app-line-chart>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="overall_statistic_doughnut_chart_data_by_role[gs.user.role].length > 0"
            class="grid grid-cols-6 md:grid-cols-12 gap-6 overflow-hidden">
            <div class="col-span-6 md:col-span-12">
                <p class="text-2xl font-normal text-text-color-600">Overall Statistics</p>
            </div>
            <ng-container
                *ngFor="let item of overall_statistic_doughnut_chart_data_by_role[gs.user.role]; let i = index">
                <div class="col-span-6 bg-white p-6 rounded-xl shadow flex flex-col gap-4 items-center">
                    <p class="text-base font-medium text-slate-500 self-start">{{item.title}}</p>
                    <app-doughnut-chart [labels]="item.labels" [data_array]="item.data_array"></app-doughnut-chart>
                    <p class="font-medium ml-1.5 text-base">Total {{item.title}} : {{item.total}}</p>
                </div>
            </ng-container>
        </div>

    </div>

    <div [ngClass]="(actionables_by_role[gs.user.role].length > 0) ? 'lg:w-[30%]' : 'hidden'"
        class="lg:flex flex-col gap-6 hidden">
        <div class="sticky h-[calc(100vh-100px-80px)] flex flex-col gap-5"
            [ngClass]="gs.user.role == 'Super Admin' || gs.user.role == 'Instructor' ? 'top-44' : 'top-[157px]'">
            <div *ngIf="actionables_by_role[gs.user.role].length > 0"
                class="bg-white p-6 rounded-xl shadow h-full overflow-y-auto">
                <div class="flex flex-col gap-4">
                    <div class="flex items-center justify-between">
                        <p class="font-medium text-xl text-text-color-600">Pending for your Actions</p>
                        <p *ngIf="actionables_by_role[gs.user.role].length > 5"
                            class="text-[14px] text-primary-500 font-medium cursor-pointer uppercase"
                            (click)="is_view_popup_visible = !is_view_popup_visible">View More</p>
                    </div>
                    <div class="flex flex-col">
                        <div *ngFor="let item of actionables_by_role[gs.user.role]; let i = index;"
                            class="cursor-pointer flex flex-row hover:bg-slate-100 items-center justify-between py-4 px-2 border-b"
                            [ngClass]="{'hidden': i >= 5}">
                            <div class="flex flex-col">
                                <p class="font-medium text-base text-text-color-500">{{item.actionable}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="is_view_popup_visible"
    class="bg-black bg-opacity-75 fixed flex h-full items-center justify-center left-0 top-0 w-full z-50">
    <div class="bg-white flex flex-col gap-4 w-10/12 md:w-9/12  p-4 relative rounded-lg shadow-xl">
        <div class="flex items-center justify-between">
            <p class="text-sm md:text-xl font-extrabold tracking-tight leading-none text-text-color-600">Pending for
                your Actions</p>
            <i class="hover:bg-slate-100 bx bx-x px-1 cursor-pointer rounded-full text-3xl z-20"
                (click)="is_view_popup_visible = !is_view_popup_visible"></i>
        </div>
        <div class="flex flex-col max-h-[400px] overflow-y-scroll">
            <div *ngFor="let item of actionables_by_role[gs.user.role]; let i = index;"
                class="cursor-pointer flex flex-row hover:bg-slate-100 items-center justify-between py-4 px-2 border-b">
                <div class="flex flex-col">
                    <p class="font-medium text-base">{{item.actionable}}</p>
                    <div class="flex items-center mt-1">
                        <i class='bx bxs-time text-text-color-500 text-xl'></i>
                        <p class="ml-1.5 text-sm text-text-color-500">10:30 AM</p>
                    </div>
                </div>
                <div>
                    <i class='bx bx-chevron-right text-4xl text-text-color-500'></i>
                </div>
            </div>
        </div>
    </div>
</div>