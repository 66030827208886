<app-header header_title="Block">
    <div class="dropdown">
        <app-button tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Block"></app-button>
        <ul tabindex="0" class="dropdown-content absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" (click)="add_block_modal.open()">Add Block</p>
            </li>
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" (click)="open_bulk_modal.open()">Add Bulk
                    Blocks</p>
            </li>
        </ul>
    </div>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3">
        <app-serch-text-input #searchInput class="w-full md:w-auto" placeholder="Search Name" (valueChange)="onSearch($event)"></app-serch-text-input>
        <!-- <app-filter-pill-button class="hidden md:block" label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <th class="px-4 h-12 text-left">
                        <span class="flex items-center gap-2">
                            <span>Block Name</span>
                            <div class="flex flex-col">
                                <i [routerLink]="'/master/block'" [queryParams]="{sort : 'ascending'}" class='cursor-pointer text-xs bx bxs-up-arrow'></i>
                                <i [routerLink]="'/master/block'" [queryParams]="{sort : 'descending'}" class='cursor-pointer text-xs bx bxs-down-arrow'></i>
                            </div>
                        </span>
                    </th>
                    <th class="px-4 h-12 text-left">country</th>
                    <th class="px-4 h-12 text-left">State</th>
                    <th class="px-4 h-12 text-left">District</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.country_name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.state_name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.district_name}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta (click)="update_block_modal.open(); get_block(item.id)" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="block_delete.open(); selected_block = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-modal #add_block_modal title="Add Block" (close_modal_clicked)="block_form.reset()">
    <div class="w-full">
        <div [formGroup]="block_form" class=" bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <app-select-input formControlName="country_id" label="Country" class="w-full md:col-span-2" [options]="dropdown_list.country_list" (change)="onCountryChange()"></app-select-input>
                <app-select-input formControlName="state_id" label="State" class="w-full md:col-span-2" [options]="dropdown_list.state_list" (change)="onStateChange()"></app-select-input>
                <app-select-input formControlName="district_id" label="District" class="w-full md:col-span-2" [options]="dropdown_list.district_list"></app-select-input>
                <app-text-input formControlName="name" class="w-full md:col-span-2" label="Block Name"></app-text-input>
            </div>
        </div>
        <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
            <app-button (click)="add_block_modal.close();block_form.reset()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
            <app-button #add_block_button (click)="add()" class="relative self-center" color="bg-[#3F41D1] text-white" label="Submit">
                <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="add-block-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
            </app-button>
        </div>
    </div>
</app-modal>

<app-modal #update_block_modal title="Update Block" (close_modal_clicked)="block_form.reset()">
    <div class="w-full">
        <div [formGroup]="block_form" class=" bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <app-select-input formControlName="country_id" label="Country" class="w-full md:col-span-2" [options]="dropdown_list.country_list"></app-select-input>
                <app-select-input formControlName="state_id" label="State" class="w-full md:col-span-2" [options]="dropdown_list.state_list"></app-select-input>
                <app-select-input formControlName="district_id" label="District" class="w-full md:col-span-2" [options]="dropdown_list.district_list"></app-select-input>
                <app-text-input formControlName="name" class="w-full md:col-span-2" label="Block Name"></app-text-input>
            </div>
        </div>
        <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
            <app-button (click)="update_block_modal.close();block_form.reset()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
            <app-button #update_block_button (click)="edit(selected_block.id)" class="relative self-center" color="bg-[#3F41D1] text-white" label="Update">
                <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="update-block-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
            </app-button>
        </div>
    </div>
</app-modal>

<app-modal #open_bulk_modal title="Add Bulk Blocks">
    <div class="w-full flex flex-col gap-4">
        <div *ngIf="!block_file_upload" class="flex justify-between items-center">
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <div class="flex items-center gap-5">
                <app-filter-pill-button icon="bx bx-upload" label="Reupload Blocks File" (click)="block_file_upload = !block_file_upload"></app-filter-pill-button>
                <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                    Download Template
                </button>
            </div>
        </div>
        <div *ngIf="block_file_upload" class='w-full flex justify-between items-center'>
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                Download Template
            </button>
        </div>
        <div *ngIf="block_file_upload" class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg class="w-8 h-8 mb-4 text-[666666]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-[666666]"><span class="font-semibold">Click to upload</span> or drag and
                        drop</p>
                    <p class="text-xs text-[666666]">CSV (MAX. 5MB)</p>
                </div>
                <input #fileInput id="dropzone-file" type="file" class="hidden" accept=".csv" (change)="on_file_change($event)" />
            </label>
        </div>
        <div *ngIf="errorMessage" class="mt-2 text-red-600">{{ errorMessage }}</div>
        <div *ngIf="!block_file_upload" class="end-full bg-white border border-primary rounded-lg">
            <table class="table-auto text-text-color-600 w-full border-collapse">
                <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                    <tr>
                        <th class="px-4 h-12 text-center">Block Name</th>
                        <th class="px-4 h-12 text-center">Country</th>
                        <th class="px-4 h-12 text-center">State</th>
                        <th class="px-4 h-12 text-center">District</th>
                    </tr>
                </thead>
                <tbody class="text-sm font-normal text-black/87 tracking-[0.32px]">
                    <tr *ngFor="let item of parsed_data" class="border-b border-[#CFD5F1]">
                        <td class="px-4 h-12 text-center">{{ item.Block || item.block }}</td>
                        <td class="px-4 h-12 text-center">{{ item.Country || item.country }}</td>
                        <td class="px-4 h-12 text-center">{{ item.State || item.state }}</td>
                        <td class="px-4 h-12 text-center">{{ item.District || item.district }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-button (click)="add_block_bulk()" class="self-end" *ngIf="!block_file_upload" color="bg-primary-500 text-white" icon="bx bx-plus" [label]="'Add ' + (parsed_data.length) + ' Blocks'"></app-button>
    </div>
</app-modal>
<!-- <app-confirmation-popup #block_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this Block?" popup_title="Edit Block"></app-confirmation-popup> -->
<app-confirmation-popup #block_delete confirmation_message="Are you sure you want to Delete this Block?" popup_title="Delete Block">
    <app-button class="relative" (click)="delete_confirm(selected_block.id)" label="Delete" color="bg-[#3F41D1] text-white">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-block-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>