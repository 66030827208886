import { Component } from '@angular/core';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-topic-form-page',
  standalone: true,
  imports: [TextInputComponent,SelectInputComponent,HeaderComponent,ButtonComponent,FormsModule,NgIf,NgFor],
  templateUrl: './topic-form-page.component.html',
})
export class TopicFormPageComponent {
  selected_material: any = '';
  material_Types:any = [
    { title: 'File' },
    {  title: 'Upload' },
    {  title: 'Link' },
  ];
  study_materail_field:any=[]

  add_study_material(){
    this.study_materail_field.push({})
  }
  delete_study_material(){
    this.study_materail_field.pop()
  }
}
