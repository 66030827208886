<div class="relative">
    <label *ngIf="label" [for]="label" class="absolute bg-white duration-300 floating-label origin-[0] px-2 pointer-events-none text-sm text-gray-500 top-[12px] transform transition-all z-10 {{( value || value == false) ? '-translate-y-5 scale-75 text-[rgba(0,0,0,0.6)]' : '' }} {{ value || value == false ? 'left-1' : 'left-[4px]' }}">{{
        label }}<span *ngIf="is_required" class="col-span-2 text-red-600">*</span></label>
    <select [disabled]="disabled || paramValue == 'true'" [(ngModel)]="value" (ngModelChange)="onChange($event);change_display_value($event)" class="focus:border-blue-600 appearance-none top-0 left-0 w-full bg-white border border-gray-300 outline-none px-4 h-12 text-[14px] rounded-lg font-normal">
        <option value="" class="text-blue-500 text-center font-semibold" [selected]="!value">{{!value ? '': clear}}</option>
        <option *ngFor="let item of options;" [value]="item.value || item.title">{{item.title}}</option>
    </select>
    <i class='absolute bx bx-chevron-down pointer-events-none right-2 text-2xl text-gray-400 top-1.5 z-10'></i>
</div>
<div *ngIf="control && control.errors && control.touched" class="error-messages">
    <p class="text-red-500" *ngIf="control.errors.required">{{label}} is required.</p>
    <p class="text-red-500" *ngIf="control.errors.minlength">Minimum length required is {{
        control.errors.minlength.requiredLength }}.
    </p>
    <p class="text-red-500" *ngIf="control.errors.maxlength">Maximum length allowed is {{
        control.errors.maxlength.requiredLength }}.
    </p>
</div>