<div class="sticky top-0 z-[11] bg-white border-b flex gap-4 items-center justify-between p-4 flex-col"
    [class.mb-4]="!no_margin_bottom">
    <div class="flex justify-between items-center w-full py-2">
        <div class="flex font-normal items-center gap-1">
            <div *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first"
                class="flex text-sm sm:text-base">
                <div [routerLink]="last ? null : breadcrumb.url" [class.text-[#3F41D1]]="first"
                    [class.text-[#666666]]="!first" class="cursor-pointer text-nowrap">
                    <a>{{ breadcrumb.label }}</a>
                </div>
                <div *ngIf="!last" class="cursor-pointer flex gap-1 items-center ml-1 whitespace-nowrap">
                    <i class='bx bxs-chevron-right'></i>
                </div>
            </div>
        </div>
        <div class="flex gap-3 items-center justify-center">
            <p class="font-medium leading-7 print-section sm:leading-10 text-base text-nowrap tracking-tight text-[#212121]"
                *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator'">
                {{ gs.selected_school.school_name || 'No Assigned School'}}</p>
            <div class="flex gap-2 items-center justify-center"
                *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator'">
                <div *ngIf=" (gs.attendnace?.check_in_time == null || gs.attendnace?.check_out_time ==null) && gs.selected_school.school_name">
                    <app-filter-pill-button (click)="view_attandance_modal2.open(); getCurrentTime(true)"
                        *ngIf="gs.attendnace?.check_in_time == null" label="Check In"
                        icon="bx bx-time"></app-filter-pill-button>
                    <app-filter-pill-button (click)="view_attandance_modal.open(); getCurrentTime(false)"
                        *ngIf="gs.attendnace?.check_in_time != null && gs.attendnace?.check_out_time == null"
                        label="Check Out" icon="bx bx-time"></app-filter-pill-button>
                </div>
            </div>
            <div (click)="toggle_notification()"
                class="relative flex h-10 items-center justify-center rounded-full text-2xl w-10 cursor-pointer">
                <i class='bx bx-bell'></i>
                <span
                    class="absolute bg-accent-300 cursor-pointer flex items-center h-5 w-5 justify-center right-[-4px] rounded-full text-white top-[2px] text-sm">2</span>
            </div>
            <!-- <label class="flex items-center cursor-pointer" title="Show Role">
                <input type="checkbox" [(ngModel)]="showRoleSelect" class="sr-only peer">
                <i class='bx bx-git-compare text-2xl'></i>
            </label> -->
            <div (click)="toggle_drawer()" class="cursor-pointer h-10 w-10 rounded-full overflow-hidden">
                <img class="object-contain h-full w-full" src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                    alt="user">
            </div>
            <!-- <select *ngIf="showRoleSelect && is_dashboard_route()" [(ngModel)]="selected_role" (change)="role_change()"
                id="role" class="bg-white border botext-blue-950 text-gray-900 text-sm rounded-lg block w-full p-2">
                <option [selected]="'Choose a Role'">Choose a Role</option>
                <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
            </select> -->
        </div>
    </div>
    <div class="flex w-full items-center justify-between">
        <h1
            class="font-medium leading-7 md:text-xl print-section sm:leading-10 text-xl sm:text-3xl text-nowrap tracking-tight text-[#212121]">
            {{ header_title }}</h1>
        <div class="flex w-full justify-end gap-4 items-center">
            <ng-content></ng-content>
        </div>
    </div>
</div>
<app-notification-panel *ngIf="is_notification_open"
    (notification_toggled)="toggle_notification()"></app-notification-panel>

<app-drawer [is_open]="is_drawer_open" [drawer_title]="'Profile'" (closed)="close_drawer()">
    <div class="flex flex-col md:flex-row justify-center gap-4 lg:gap-8 h-[87vh] w-72 ">
        <div class="flex flex-col items-center">
            <img src="http://pluspng.com/img-png/user-png-icon-big-image-png-2240.png" alt="Profile Picture"
                class="w-32 h-32 rounded-full mx-auto mb-4">
            <h2 class="text-xl font-bold text-center mb-2">{{gs.user.name}}</h2>
            <div class="border-t border-gray-200 pt-7">
                <p class="text-gray-600 text-center mb-4">Qualification: {{gs.user.qualification}}</p>
                <h3 class="text-lg font-normal mb-2">Email : {{gs.user.email}}</h3>
                <h3 class="text-lg font-normal mb-2">DOB : {{gs.user.dob}}</h3>
                <h3 class="text-lg font-normal mb-2">Contact Number : {{gs.user.contact_number}}</h3>
            </div>
        </div>
    </div>
</app-drawer>
<app-modal #view_attandance_modal title="Check Out" class="w-full m-8">
    <div [formGroup]="form" class="w-full max-w-full">
        <div class="flex flex-col gap-5 w-full">
            <div>
                <label for="message" class="text-sm font-medium text-gray-900">Enter Time</label>
                <input formControlName="check_out_time" type="time"
                    class="mt-2 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-blue-50">
                <div *ngIf="form.get('check_out_time')?.touched && form.get('check_out_time')?.invalid">
                    <span *ngIf="form.get('check_out_time')?.errors?.['required']" class="text-red-500">
                        Select Check Out Time is required.
                    </span>
                </div>
            </div>
            <div class="w-full p-2 mt-2 mb-2 h-40">
                <img src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                    class="object-cover w-full h-full" alt="">
            </div>
        </div>
        <div class="flex flex-col gap-2 w-full mt-2">
            <p class="text-md font-semibold mt-3">Check Out Remark</p>
            <p class="text-sm mt-2">Remark</p>
            <textarea formControlName="remark"
                class="block p-2 w-full text-sm text-nowrap text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-blue-500"></textarea>
            <div class="flex flex-col lg:flex-row lg:justify-between justify-start w-full items-center gap-4 py-3">
                <p class="text-text-color-400 font-medium text-start text-sm mt-2">You Can Also Add Todays Report Along
                    with Images And Videos</p>
                <div class="flex items-start justify-start gap-2">
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end w-full gap-3 rounded-b-lg mt-4">
            <app-button (click)="onCheckout()" class="self-center" color="bg-primary-500 text-white"
                label="Submit"></app-button>
        </div>
    </div>
</app-modal>
<app-modal #view_attandance_modal2 title="Check In" class="w-full">
    <div [formGroup]="form" class="w-full max-w-full">
        <div class="flex flex-col gap-5 w-full">
            <div>
                <label for="message" class="text-sm font-medium text-gray-900">Enter Time</label>
                <input formControlName="check_in_time" type="time"
                    class="mt-2 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-blue-50">
                <div *ngIf="form.get('check_in_time')?.touched && form.get('check_in_time')?.invalid">
                    <span *ngIf="form.get('check_in_time')?.errors?.['required']" class="text-red-500">
                        Select Check in Time is required.
                    </span>
                </div>
            </div>
            <div class="w-full p-2 h-40">
                <img src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                    class="object-cover w-full h-full" alt="">
            </div>
        </div>
        <div class="flex items-center justify-end mt-4 w-full gap-3 p-4 rounded-b-lg">
            <app-button class="self-center" label="Cancel" (click)="view_attandance_modal2.close()"></app-button>
            <app-button class="self-center" color="bg-primary-500 text-white" label="Submit"
                (click)="onCheckIn()"></app-button>
        </div>
    </div>
</app-modal>
<app-confirmation-popup #check_out_redirect color="bg-[#3F41D1] text-white" router_link="/dashboard/daily-report/form"
    button_title="Add Report" confirmation_message="You Can Also Add Todays Report Along with Images And Videos"
    popup_title="Confirmation">
    <div class="w-full flex justify-between">
        <app-button color="bg-primary-500 text-white text-nowrap" label="skip" size="sm"
            (click)="check_out_redirect.close()"></app-button>
        <app-button color="bg-primary-500 text-white text-nowrap" label="Add Report" size="sm"
            (click)="onAddReportClick()"></app-button>
    </div>
</app-confirmation-popup>


<div *ngIf="location_permission_modal"
    class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
    <div class="w-[250px] md:w-[350px] bg-white shadow-lg rounded-md p-6 relative">
        <div class="flex flex-col items-center justify-center gap-2">
            <span class="h-[76px] w-[76px] flex justify-center items-center bg-[#E1E8F5] rounded-full">
                <i class='bx bx-map-pin text-5xl text-[#666666]'></i>
            </span>
            <h1 class='text-[24px] font-medium text-center tracking-tight'>Kindly Give Access to Your Location</h1>
            <p class='text-center text-[16px] text-black/60'>Your location service needs to be turned on in order to
                access the Website.</p>
        </div>
        <button type="button"
            class="mt-4 px-6 h-10 min-w-[150px] w-full rounded-full text-white text-sm font-semibold border-none outline-none bg-[#3F41D1] hover:bg-[#3F41D1] uppercase"
            (click)="location_permission_modal = false">enable Location</button>
    </div>
</div>