import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { Validators } from '@angular/forms';
import { AcademicYearService } from '../../services/academic-year.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import * as XLSX from 'xlsx';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-academic-year-list-page',
  standalone: true,
  imports: [VenueAuditViewComponent, NgxUiLoaderModule, DatePipe, HeaderComponent, DateInputComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, SelectInputComponent, ReactiveFormsModule, PaginationComponent],
  templateUrl: './academic-year-list-page.component.html',
  providers: [TextInputComponent, DatePipe]
})
export class AcademicYearListPageComponent {
  @ViewChild('update_academic_form') update_academic_form: any;
  @ViewChild('open_academic_form') open_academic_form: any;
  @ViewChild('academic_year_delete') academic_year_delete: any;
  @ViewChild('add_academic_year_buuton') add_academic_year_buuton: any;
  @ViewChild('update_academic_year_buuton') update_academic_year_buuton: any;
  @ViewChild('open_bulk_modal') open_bulk_modal: any;
  open_button: boolean = false
  list: any = {}
  params: any = {};
  selected_academic_year: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  current_date_show: any;
  end_date_min: any;
  academic_term_file_upload: boolean = true
  mistake_modal: boolean = false
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  errorMessage: any;
  parsed_data: any[] = [];
  @ViewChild('searchInput') searchInput: any;
  myForm = this.fb.group({
    year: ['', [Validators.required, Validators.pattern('^\\d{4}-\\d{4}$')]],
    start_date: ['', Validators.required],
    end_date: ['', Validators.required],
  });
  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public gs: GlobalService, public fb: FormBuilder, public as: AcademicYearService, public ar: ActivatedRoute, public toastr: ToastrService) {
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_academic_year_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_academic_year_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_academic_year_list(this.params);
      }
    });
    this.myForm.get('start_date')?.valueChanges.subscribe((selectedStartDate: any) => {
      this.updateEndDateMin(selectedStartDate);
    });
  }
  updateEndDateMin(startDate: string) {
    if (startDate) {
      const selectedDate = new Date(startDate);
      selectedDate.setDate(selectedDate.getDate() + 1);
      this.end_date_min = selectedDate.toISOString().split('T')[0];

      // Validate end date if already selected
      const endDateControl = this.myForm.get('end_date');
      if (endDateControl && endDateControl.value !== null) { // Check if endDateControl is not null and value is not null
        const endDate = new Date(endDateControl.value as string); // Cast to string assuming it's a string
        if (endDate < selectedDate) {
          endDateControl.setErrors({ 'invalidEndDate': true });
        } else {
          endDateControl.setErrors(null);
        }
      }
    } else {
      this.end_date_min = this.current_date_show;
    }
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_academic_year_list(this.params);
  }

  async add() {
    if (this.myForm.valid) {
      this.ui_spinner.startLoader('add-academic-year-loader');
      this.add_academic_year_buuton.disabled = true
      try {
        let response: any = await this.as.add(this.myForm.value)
        this.myForm.reset();
        this.get_academic_year_list(this.params)
        this.ui_spinner.stopLoader('add-academic-year-loader');
        this.add_academic_year_buuton.disabled = false
        this.toastr.success(response.message || 'Academic Year Added Successfully!')
        this.open_academic_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-academic-year-loader');
        this.add_academic_year_buuton.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.myForm.markAllAsTouched()
    }
  }

  async get_academic_year_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.as.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching academic_year list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_academic_year(id: any) {
    console.log(id);
    try {
      let data = await this.as.get(id)
      this.selected_academic_year = data.data
      this.myForm.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching academic_year:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async edit(id: number) {
    if (this.myForm.valid) {
      this.ui_spinner.startLoader('update-academic-year-loader');
      this.update_academic_year_buuton.disabled = true
      console.log(id, "qqqqqqqqqqqqqqqq");
      try {
        let response = await this.as.update(id, this.myForm.value);
        this.toastr.success(response.message)
        this.get_academic_year_list(this.params)
        this.myForm.reset();
        this.get_academic_year_list(this.params)
        this.ui_spinner.stopLoader('update-academic-year-loader');
        this.update_academic_year_buuton.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_academic_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-academic-year-loader');
        this.update_academic_year_buuton.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.myForm.markAllAsTouched()
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-academic-year-loader');
    try {
      let response = await this.as.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_academic_year_list(this.params);
      this.toastr.success(response.message || 'Academic Year Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-academic-year-loader');
      this.academic_year_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-academic-year-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }


  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];

    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.academic_term_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.academic_term_file_upload = true
        return;
      }
    }
    this.academic_term_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }

  async add_academic_term_bulk() {
    try {

      let newData = this.parsed_data.map((item) => {
        return {
          year: item['Academic Year'],
          start_date: this.convert_date_format(item['Start Date']),
          end_date: this.convert_date_format(item['End Date']),
        };
      });

      const response: any = await this.as.add(newData)
      this.get_academic_year_list(this.params)
      this.toastr.success(response.message || 'Academic Term Added Successfully!')
      this.open_bulk_modal.close()
      this.academic_term_file_upload = true
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.academic_term_file_upload = true
      this.toastr.error(error?.error?.message)
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/academic_year_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'academic_year_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convert_date_format(date_str: string): string {
    if (/^\d{2}-\d{2}-\d{4}$/.test(date_str)) {
      const [day, month, year] = date_str.split('-');
      return `${year}-${month}-${day}`;
    }
    return date_str;
  }
}
