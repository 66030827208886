import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { CountsCardComponent } from '../../components/counts-card/counts-card.component';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { NgxChartsModule, PieChartModule } from '@swimlane/ngx-charts';
import { SidebarService } from '../../services/sidebar.service';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { HeaderComponent } from '../../components/header/header.component';
import { GlobalService } from '../../services/global.service';
import { ButtonComponent } from '../../components/button/button.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { DoughnutChartComponent } from '../../components/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from '../../components/line-chart/line-chart.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { TimeInputComponent } from '../../components/inputs/time-input/time-input.component';
import { SchoolService } from '../../services/school.service';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { AttendanceService } from '../../services/attendance.service';
import { DashboardService } from '../../services/dashboard.service';
import { filter } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [ButtonComponent, CountsCardComponent, NgClass, IconCtaComponent, DateInputComponent, NgFor, NgClass, NzMenuModule, NzTabsModule, NgxChartsModule, PieChartModule, SelectInputComponent, HeaderComponent, NgIf, TextAreaInputComponent, DoughnutChartComponent, LineChartComponent, ModalComponent, RouterLink, TextInputComponent, SelectInputComponent, TimeInputComponent, FormsModule],
  templateUrl: './dashboard-page.component.html',
})

export class DashboardPageComponent {
  is_sidebar_open: boolean = true
  link_clicked: boolean = false
  isLargeWindow: any;
  window: any;
  list: any = {}
  params: any = {}
  is_school_modal: boolean = false
  selectedSchool: string = '';
  dropdown_list: any = {}
  constructor(public ss: SidebarService, public datePipe: DatePipe, public gs: GlobalService, public router: Router, public ar: ActivatedRoute, public instr_ass: InstructorAssignmentService, public attendance_service: AttendanceService, public ds: DashboardService, public sss: SchoolService) { }


  toggle_sidebar() {
    this.is_sidebar_open = !this.is_sidebar_open
  }
  async get_dropdown_list() {
    try {
      const school_response = await this.sss.get_list({})
      let school_options = school_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  handle_sidebar_width(event: any) {
    if (event) {
      this.link_clicked = true
    } else {
      this.link_clicked = false
    }
  }

  @ViewChild('modal') modal: any = true
  request_modal = false
  rating_modal: boolean = true
  is_view_popup_visible: boolean = false
  animations: boolean = true;
  starsArray: number[] = [1, 2, 3, 4, 5];
  value: number = 0;
  color_scheme: any = {
    domain: ['#3b82f6', '#f87171', '#f59e0b', '#84cc16', '#25706F']
  };
  color_scheme_for_stacked_bar: any = {
    domain: ['#3b82f6', '#ef4444', '#84cc16']
  }
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_asset_list(this.params);
      await this.loadData()
    });
    this.loadData();
    this.get_dropdown_list();
  }

  school_show_filter(event: any) {
    const selectedSchool = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedSchool) {
      queryParams.school_id = selectedSchool;
    } else {
      queryParams.school_id = null;
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  
  async loadData() {
    try {
      const response = await this.ds.get_list(this.params);
      const activitiesData = response.data.activities_data;
      const testsData = response.data.tests_data;
      Object.keys(this.line_chart_data_by_role).forEach(role => {
        if (role === 'Super Admin' || role === 'Sub Admin' || role === 'Block Resource Coordinator' || role === 'Instructor') {
          const activityDataForRole = activitiesData.find((data: any) => data.name.toLowerCase() === 'activities');
          if (activityDataForRole) {
            this.line_chart_data_by_role[role][0].labels = activityDataForRole.series.map((series: any) => series.name);
            this.line_chart_data_by_role[role][0].dataset[0].data = activityDataForRole.series.map((series: any) => series.value);
          }
          const testsDataForRole = testsData.find((data: any) => data.name.toLowerCase() === 'tests');
          if (testsDataForRole) {
            this.line_chart_data_by_role[role][1].labels = testsDataForRole.series.map((series: any) => series.name);
            this.line_chart_data_by_role[role][1].dataset[0].data = testsDataForRole.series.map((series: any) => series.value);
          }
        }
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }


  async get_asset_list(params: any) {
    try {
      const response = await this.ds.get_list(this.params);
      console.log(response,'parms');
      
      const data = response.data.response;
      const role = this.gs.user.role;
      const cardData = this.card_data_by_role[role];

      cardData.forEach(card => {
        switch (card.card_title) {
          case 'Schools':
            card.count = data.schools.count;
            card.total_count = data.schools.total_count;
            break;
          case 'Courses':
            card.count = data.courses.count;
            card.total_count = data.courses.total_count;
            break;
          case 'Batches':
            break;
          case 'BRC':
            card.count = data.brc.count;
            card.total_count = data.brc.total_count;
            break;
          case 'Assessment Tests':
            card.count = data.assessment_tests.count;
            card.total_count = data.assessment_tests.total_count;
            break;
          case 'Total Students':
            card.count = data.students.count;
            card.total_count = data.students.total_count;
            break;
          case 'Instructors':
            card.count = data.instructors.count;
            card.total_count = data.instructors.total_count;
            break;
          case 'Sub Admins':
            card.count = data.sub_admins.count;
            card.total_count = data.sub_admins.total_count;
            break;
          default:
            break;
        }
      });
      this.card_data_by_role[role] = [...cardData];
    } catch (error: any) {
      console.error("Error fetching asset list:", error);
    }
  }

  card_data_by_role: { [key: string]: any[] } = {
    "Super Admin": [
      { card_title: 'Schools', card_sub_title: 'Schools', count: 0, total_count: 0 },
      { card_title: 'Courses', card_sub_title: 'Courses', count: 0, total_count: 0 },
      { card_title: 'Batches', card_sub_title: 'Batches', count: 0, total_count: 0 },
      { card_title: 'BRC', card_sub_title: 'BRC', count: 0, total_count: 0 },
      { card_title: 'Instructors', card_sub_title: 'Instructors', count: 0, total_count: 0 },
      { card_title: 'Sub Admins', card_sub_title: 'Sub Admins', count: 0, total_count: 0 },
    ],
    "Sub Admin": [
      { card_title: 'Schools', card_sub_title: 'Schools', count: 0, total_count: 0 },
      { card_title: 'Courses', card_sub_title: 'Courses', count: 0, total_count: 0 },
      { card_title: 'Batches', card_sub_title: 'Batches', count: 0, total_count: 0 },
      { card_title: 'BRC', card_sub_title: 'BRC', count: 0, total_count: 0 },
      { card_title: 'Instructors', card_sub_title: 'Instructors', count: 0, total_count: 0 },
      { card_title: 'Sub Admins', card_sub_title: 'Sub Admins', count: 0, total_count: 0 },
    ],
    'Block Resource Coordinator': [
      { card_title: 'Batches', card_sub_title: 'Batches', count: 0, total_count: 0 },
      { card_title: 'Courses', card_sub_title: 'Courses', count: 0, total_count: 0 },
      { card_title: 'Schools', card_sub_title: 'Schools', count: 0, total_count: 0 },
      { card_title: 'Instructors', card_sub_title: 'Instructors', count: 0, total_count: 0 },
    ],
    "Instructor": [
      { card_title: 'Courses', card_sub_title: 'Courses', count: 0, total_count: 0 },
      { card_title: 'Total Batches', card_sub_title: 'Total Batches', count: 0, total_count: 0 },
      { card_title: 'Total Students', card_sub_title: 'Total Students', count: 0, total_count: 0 },
      { card_title: 'Assessment Tests', card_sub_title: 'Assessment Tests', count: 0, total_count: 0 },
    ],
    "Student": [{}],
  };

  actionables_by_role: { [key: string]: any[] } = {
    "Super Admin": [
      // {
      //   actionable: '3 Inventory Requests Pending for Approval',
      //   action: 'View',
      //   icon: 'bx bx-show',
      //   link: ''
      // },
      // {
      //   actionable: '5 Inventory Requests Pending for Delivered',
      //   action: 'View',
      //   icon: 'bx bx-show',
      //   link: ''
      // },
    ],
    'Sub Admin': [
    ],
    'Block Resource Coordinator': [
      // {
      //   actionable: '2 Inventory Requests Approved',
      //   action: 'View',
      //   icon: 'bx bx-time',
      //   link: ''
      // },
      // {
      //   actionable: '2 Inventory Requests Rejected',
      //   action: 'View',
      //   icon: 'bx bx-show',
      //   link: ''
      // },
      // {
      //   actionable: '1 Inventory Requests Dispatched',
      //   action: 'View',
      //   icon: 'bx bx-time',
      //   link: ''
      // },
    ],
    "Instructor": [],
    "Student": [{}],
  };

  doughnut_chart_data_by_role: { [key: string]: any[] } = {
    "Super Admin": [
      {
        title: 'Batches',
        total: 138,
        labels: ["Pending", "In Progress", "completed"],
        data_array: [127, 9, 50],
      },
      {
        title: 'Schools',
        total: 157,
        labels: ["Pending", "Ongoing", "Completed"],
        data_array: [148, 7, 50],
      },
    ],
    'Sub Admin': [
      {
        title: 'Batches',
        total: 138,
        labels: ["Pending", "In Progress", "completed"],
        data_array: [127, 9, 50],
      },
      {
        title: 'Schools',
        total: 157,
        labels: ["Pending", "Ongoing", "Completed"],
        data_array: [148, 7, 50],
      },
    ],
    'Block Resource Coordinator': [
      {
        title: 'Batches',
        total: 138,
        labels: ["Pending", "In Progress", "completed"],
        data_array: [127, 9, 50],
      },
      {
        title: 'Schools',
        total: 157,
        labels: ["Pending", "Ongoing", "Completed"],
        data_array: [148, 7, 50],
      },
    ],
    "Instructor": [
      {
        title: 'Batches',
        total: 138,
        labels: ["Pending", "In Progress", "completed"],
        data_array: [127, 9, 50],
      },
      {
        title: 'Courses',
        total: 157,
        labels: ["Pending", "Ongoing", "Completed"],
        data_array: [148, 7, 50],
      },
    ]
  };


  line_chart_data_by_role: { [key: string]: any[] } = {
    "Super Admin": [
      {
        card_title: 'Activity',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Activity'
          }
        ],
      },
      {
        card_title: 'Tests',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Tests'
          }
        ],
      }
    ],
    "Sub Admin": [
      {
        card_title: 'Activity',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Activity'
          }
        ],
      },
      {
        card_title: 'Tests',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Tests'
          }
        ],
      }
    ],
    "Block Resource Coordinator": [
      {
        card_title: 'Activity',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Activity'
          }
        ],
      },
      {
        card_title: 'Tests',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Tests'
          }
        ],
      }
    ],
    "Instructor": [
      {
        card_title: 'Activity',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Activity'
          }
        ],
      },
      {
        card_title: 'Tests',
        labels: [],
        dataset: [
          {
            data: [],
            label: 'Tests'
          }
        ],
      }
    ]
  }

  target_vs_actual_by_role: { [key: string]: any[] } = {
    "Super Admin": [],
    "Sub Admin": [],
    "Block Resource Coordinator": [],
    "Instructor": [],
    "Student": [{}],
  }

  overall_statistic_doughnut_chart_data_by_role: { [key: string]: any[] } = {
    "Super Admin": [],
    "Sub Admin": [],
    "Block Resource Coordinator": [],
    "Instructor": [],
    "Student": [{}],
  };

  rate(stars: number): void {
    this.value = stars;
  }

  close() {
    this.rating_modal = false
  }

  onDateSelected(paramName: string, date: string) {
    const queryParams = { [paramName]: date ? date : null };
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge' // this ensures other existing query params are not removed
    });
  }
}