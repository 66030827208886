import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { ModalComponent } from '../../components/modal/modal.component';
import * as XLSX from 'xlsx';
import { GlobalService } from '../../services/global.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DrawerComponent } from '../../components/drawer/drawer.component';
import { FilterDrawerComponent } from '../../components/filter-drawer/filter-drawer.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from '../../services/course.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { GradeService } from '../../services/grade.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { ProgressService } from '../../services/progress.service';



@Component({
  selector: 'app-cources-list-page',
  standalone: true,
  imports: [NgxUiLoaderModule, RouterLink, HeaderComponent, NgClass, FilterDrawerComponent, ButtonComponent, NgCircleProgressModule, FilterPillButtonComponent, SerchTextInputComponent, TextInputComponent, SelectInputComponent, IconCtaComponent, NgFor, NgIf, RouterLink, ModalComponent, ConfirmationPopupComponent, VenueDetailsModalComponent, PaginationComponent],
  templateUrl: './cources-list-page.component.html',
})
export class CourcesListPageComponent {
  open_button: boolean = false
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  @ViewChild('open_progress_modal') open_progress_modal: any
  @ViewChild('delete_course') delete_course: any
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  course_file_upload: boolean = true
  mistake_modal: boolean = false
  table_headers: any = [];
  table_data: any = [];
  form: any;
  is_filter_open: boolean = false;
  selected_item = ''
  list: any = {}
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;


  filter_options = [
    { label: 'Language', type: 'text', title: 'Language', icon: "bx bx-calendar" },
    { label: 'Grade', type: 'text', title: 'Grade', icon: "bx bx-calendar" },
    { label: 'Course', type: 'text', title: 'Course', icon: "bx bx-edit-alt" },
  ]
  constructor(public ui_spinner: NgxUiLoaderService, public gs: GlobalService, public router: Router, public ar: ActivatedRoute, public toastr: ToastrService, public cs: CourseService, public gds: GradeService,public ps:ProgressService) {
    // this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
    //   this.params.search = searchText;
    //   this.get_course_list(this.params);
    // });
  }
  counter: number = 0;

  debounce_timeout: any = undefined;
  params: any = {};
  selected_course: any = {};
  dropdown_list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;

  // progress_count: any;
  // total: any;
  // api_data: any = {}
  // progressPercent: any = 0;
  // selected_course_for_progress: any = {}
  // intervalId: any;

  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_course_list(this.params);
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_course_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_course_list(this.params);
      }
    });
  }
  grade_show_filter(event: any) {
    const selectedGrade = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedGrade) {
      this.currentPage = 1;
      queryParams.grade_id = selectedGrade;
    } else {
      this.currentPage = 1;
      queryParams.grade_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_course_list(this.params);
  }
  async get_dropdown_list() {
    try {
      const grade_response = await this.gds.get_list({})
      let grade_options = grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.grade_list = grade_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  async get_course_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.cs.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching course list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_course(id: any) {
    console.log(id);
    try {
      let data = await this.cs.get(id)
      this.selected_course = data.data
      this.form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching course:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  edit(id: number) {
    this.router.navigate(['/course/form'], { queryParams: { id: id } });
  }
  // async delete_confirm(id: number) {
  //   let response = await this.cs.delete(id);
  //   this.list.data = this.list?.data.filter((item: any) => item.id != id);
  //   this.toastr.success(response.message)
  //   this.delete_course.close()
  // }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-cources-loader');
    try {
      let response = await this.cs.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_course_list(this.params);
      this.delete_course.close()
      this.toastr.success(response.message || 'Cources Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-cources-loader');
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-cources-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  // async onSearch(searchText: string) {
  //   if (searchText) {
  //     this.currentPage = 1;
  //     this.params.search = searchText;
  //     this.searchSubject.next(searchText);
  //   } else {
  //     this.currentPage = 1;
  //     delete this.params['search'];
  //     this.get_course_list(this.params);
  //   }
  // }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  increment(): void {
    this.counter++;
  }
  decrement(): void {
    this.counter--;
  }
  reset(): void {
    this.counter = 0;
  }
  parse_excel_file(file: File, inputElement: HTMLInputElement) {
    const reader = new FileReader();
    reader.onload = () => {
      const data = new Uint8Array(reader.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      console.log(jsonData, "ghjkl");

      this.table_headers = jsonData[0];
      this.table_data = jsonData.slice(1);
    }
    setTimeout(() => {
      inputElement.value = '';
      this.course_file_upload = false
    }, 0);
    reader.readAsArrayBuffer(file);
  };
  on_file_change(event: any) {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      this.parse_excel_file(file, this.fileInput.nativeElement);
    }
  }
  toggle_filter() {
    this.is_filter_open = !this.is_filter_open
  }
  selected_section(selected: string) {
    this.selected_item = selected
  }
  download_template(): void {
    const url = '../../../assets//template-files/OrgChart.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'resource-template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  detail(i: any) {
    let id = this.list[i].id
    let language = this.list[i].language
    this.router.navigate(['dashboard/course/form/'], { queryParams: { language: language, id: id, view: 'true' } });
    // console.log('dashboard/course/form/','language',i);

  }

  // async update_course_progress(id: any) {
  //   console.log(id);
  //   try {
  //     let data = await this.ps.update(id, { progress: this.counter })
  //     this.open_progress_modal.close()
  //     this.get_course_list(this.params);
  //     this.toastr.success(data.message)
  //   } catch (error: any) {
  //     console.error("Error fetching course:", error);
  //     this.toastr.error(error?.error?.message)
  //   }
  // }

  // startProgressCalculation(): void {
  //   this.intervalId = setInterval(() => {
  //     this.calculateProgress();
  //   }, 1000); // 10000 milliseconds = 10 seconds

  //   setTimeout(() => {
  //     clearInterval(this.intervalId);
  //     console.log('Interval stopped after 5 seconds');
  //   }, 10000);
  // }

  // calculateProgress() {
  //   this.list.data?.forEach((item: any) => {
  //     this.progress_count = item.progress;
  //     console.log(this.progress_count,'progress count');
      
  //     this.total = item.course_details.sub_course_count || 0;
  //     console.log(this.total,'total');
      
  //     console.log("Progress Value for ID", item.id, ":", item.progress);
  //   });

  //   if (this.total > 0) {
  //     this.progressPercent = Math.min((this.progress_count / this.total) * 100, 100);
  //   } else {
  //     this.progressPercent = 0;
  //   }
  // }

  // formatPercentTitle = (percent: number): string => {
  //   return `${percent.toFixed(0)}`;
  // };
}
