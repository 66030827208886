import { Component } from '@angular/core';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { NgFor, NgIf } from '@angular/common';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-daily-progress-report-form-page',
  standalone: true,
  imports: [TextInputComponent,SelectInputComponent,TextAreaInputComponent,NgFor,NgIf,HeaderComponent,ButtonComponent,DateInputComponent,RouterLink],
  templateUrl: './daily-progress-report-form-page.component.html',
})
export class DailyProgressReportFormPageComponent {
  current_date_show:any
  constructor(){
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }
}
