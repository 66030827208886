import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [NgIf, NgFor, ButtonComponent],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() is_popup_visible: boolean = false
  @Input() list: any = []
  @Input() title: string = ''
  @Output() close_modal_clicked = new EventEmitter();
  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    this.close()
  }
  open() {
    this.is_popup_visible = true
  }

  close() {
    this.is_popup_visible = false
    this.close_modal_clicked.emit()
  }
}
