import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';

@Component({
  selector: 'app-brc-assignment-form',
  standalone: true,
  imports: [ HeaderComponent,
    ButtonComponent,
    SelectInputComponent,
    TextInputComponent,
    NgFor,
    DateInputComponent,
    RouterLink,
    NgIf,],
  templateUrl: './brc-assignment-form.component.html',
})
export class BrcAssignmentFormComponent {
  isTableDisabled: boolean = true;
  assessment_list: any[] = [];
  instructor_list: any = [
    {
      block: 'Jawahar Navodaya Vidyalaya',
      academic_year: '2023-24',
      assigned_brc: 'Shubham Salunkhe',
    },
  ];
  block: any = [
    { title: 'Pune Block' },
    { title: 'Mumbai Block' },
    { title: 'Navi-Mumbai Block' },
    { title: 'Thane Block' },
  ];
  assigned_brc: any = [
    { title: 'Shubham Salunke' },
    { title: 'Bhavesh Karkare' },
    { title: 'Arun Gounder' },
    { title: 'Shreeram Kaudare' },
    { title: 'Mayur Talekar' },
  ];
  current_date_show:any
  newRowIndex: number = -1;
 constructor(){
  const future = new Date();
  this.current_date_show = future.toISOString().split('T')[0];
 }
  add_brc(): void {
    this.instructor_list.push({ student_name: '', marks: '' });
    this.newRowIndex = this.instructor_list.length - 1;
    this.isTableDisabled = false;
  }

  delete_brc(index: number): void {
    this.instructor_list.splice(index, 1);
    if (this.instructor_list.length === 0) {
      this.isTableDisabled = true;
    }
    this.newRowIndex = -1;
  }
}
