import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SchoolService } from '../../services/school.service';
import { GradeService } from '../../services/grade.service';
import { AcademicYearService } from '../../services/academic-year.service';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from '../../services/student.service';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';
import { GlobalService } from '../../services/global.service';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';

@Component({
  selector: 'app-student-form-page',
  standalone: true,
  imports: [FormsModule, NgIf, NgFor, TextInputComponent, SelectInputComponent, DateInputComponent, HeaderComponent, ButtonComponent, NgxUiLoaderModule, ReactiveFormsModule, RouterLink, ZoroFileInputComponent],
  templateUrl: './student-form-page.component.html',
})
export class StudentFormPageComponent {
  params: any = {}
  birth_date?: any;
  dropdown_list: any = {}
  whether_bpl_beneficiary: any = [{ title: "YES" }, { title: "NO" },]
  selectedBplBeneficiary: any
  Whether_CWSN: any = [{ title: "YES" }, { title: "NO" },]
  selectedcwsn: any
  current__previous_Years: any = [{ title: "YES" }, { title: "NO" },]
  current_previos: any
  student_form: FormGroup
  selected_student: any = {};
  constructor(public fb: FormBuilder, public global_service: GlobalService, public ui_spinner: NgxUiLoaderService, public toastr: ToastrService, public router: Router, public ss: SchoolService, public gs: GradeService, public ac: AcademicYearService, public sts: StudentService, public ar: ActivatedRoute, public instass: InstructorAssignmentService) {
    this.student_form = this.fb.group({
      name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      mother_name: ['', [Validators.required]],
      image: ['', [Validators.required]],
      school_id: [this.global_service.selected_school.school_id ? this.global_service.selected_school.school_id : '', Validators.required],
      grade_id: ['', [Validators.required]],
      academic_year_id: ['', [Validators.required]],
      father_name: ['', [Validators.required]],
      guardian_name: ['', [Validators.required]],
      adhar_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(12), Validators.maxLength(12)]],
      name_as_per_adhar: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$') ]],
      address: ['', [Validators.required]],
      pincode: ['', [Validators.required,Validators.pattern('^-?\\d*(\\.\\d+)?$')]],
      contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
      alternate_contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required]],
      mother_tongue_of_student: ['', [Validators.required]],
      blood_group: ['', [Validators.required]],
      social_category: ['', [Validators.required]],
      minority_group: ['', [Validators.required]],
      is_bpl_beneficiary: ['',[Validators.required]],
      is_antyodaya_anna_yojana: [''],
      is_belongs_to_ews_or_disadvantaged_group: ['',[Validators.required]],
      is_cwsn: ['',[Validators.required]],
      type_of_impairment: [''],
      disability_percentage: [''],
      is_indian: ['',[Validators.required]],
      is_this_student_is_out_of_school: ['',[Validators.required]],
      when_the_student_is_mainstreamed: [''],
    });

  }
  ngOnInit() {
    this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.get_student(params['id'])
        // this.patchFormData(params['id'])
        this.student_form.patchValue(await this.sts.get(params['id']))
      }
    })

    const schoolId = this.global_service.selected_school.school_id;
    if (this.global_service.user.role == 'Instructor' || this.global_service.user.role == 'Block Resource Coordinator' && schoolId) {
      this.student_form.patchValue({
        school_id: schoolId
      });
    }

    const now = new Date();
    this.birth_date = now.toISOString().split('T')[0];
  }
  async get_dropdown_list() {
    try {
      let school_response;
      let school_options;
      if (this.global_service.user.role == 'Instructor' || this.global_service.user.role == 'Block Resource Coordinator') {
        school_response = await this.instass.get_list({})
        school_options = school_response.data.map((item: any) => {
          return {
            title: item.school_name || "none",
            value: item.school_id || "none",
          };
        });
      } else {
        school_response = await this.ss.get_list({})
        school_options = school_response.data.map((item: any) => {
          return {
            title: item.name || "none",
            value: item.id || "none",
          };
        });
      }
      const grade_response = await this.gs.get_list({})
      const academic_year_response = await this.ac.get_list({})
      console.log(school_response.data, grade_response.data, academic_year_response.data, "RESPONSE");
      let grade_options = grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let academic_year_options = academic_year_response.data.map((item: any) => {
        return {
          title: item.year || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_options
      this.dropdown_list.grade_list = grade_options
      this.dropdown_list.academic_year_list = academic_year_options
      console.log(school_options, grade_options, academic_year_options, "qqqqqqqqqqqq");

    } catch (error: any) {
      console.error("Error fetching Dropdown list:", error);
    }
  }
  async add() {
    if (this.student_form.valid) {
      this.ui_spinner.startLoader('add-Student-loader');
      console.log(this.student_form);
      console.log(this.student_form.value.school_id);
      try {
        const formData = { ...this.student_form.value, };
        console.log(this.student_form.value, " jjj");
        let response: any = await this.sts.add(formData)
        console.log(response, "lolololo");
        this.student_form.reset();
        this.ui_spinner.stopLoader('add-Student-loader');
        this.toastr.success(response.message || 'Student Added Successfully!')
        console.log(response);
        this.router.navigate(['/master/student'])
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-Student-loader');
        this.toastr.error(error?.error?.message)
        console.error(error?.error?.message, '')
      }
    } else {
      this.student_form.markAllAsTouched();
    }
  }

  async edit(id: number) {
    if (this.student_form.valid) {
      this.ui_spinner.startLoader('update-Student-loader');
      try {
        let response: any = await this.sts.update(id, this.student_form.value);
        this.student_form.reset();
        this.toastr.success(' Updated Successfully!');
        this.ui_spinner.stopLoader('update-Student-loader');
        this.router.navigate(['/master/student']);
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-Student-loader');
        console.error("Error updating Student:", error);
      }
    } else {
      this.student_form.markAllAsTouched();
    }
  }
  // async patchFormData(id: any) {
  //   let data = await this.sts.get(id);

  //   if (data && data.data) {
  //     this.student_form.patchValue({
  //       name: data.data.name,
  //     gender: data.data.gender,
  //     dob: data.data.dob,
  //     mother_name: data.data.mother_name,
  //     image: data.data.image,
  //     school_id: data.data.school_id,
  //     grade_id: data.data.grade_id,
  //     academic_year_id: data.data.academic_year_id,
  //     father_name: data.data.father_name,
  //     guardian_name: data.data.guardian_name,
  //     adhar_number: data.data.adhar_number,
  //     name_as_per_adhar: data.data.name_as_per_adhar,
  //     address: data.data.address,
  //     pincode: data.data.pincode,
  //     contact_number: data.data.contact_number,
  //     alternate_contact_number: data.data.alternate_contact_number,
  //     email: data.data.email,
  //     mother_tongue_of_student: data.data.mother_tongue_of_student,
  //     blood_group: data.data.blood_group,
  //     social_category: data.data.social_category,
  //     minority_group: data.data.minority_group,
  //     is_bpl_beneficiary: data.data.is_bpl_beneficiary,
  //     is_antyodaya_anna_yojana: data.data.is_antyodaya_anna_yojana,
  //     is_belongs_to_ews_or_disadvantaged_group: data.data.is_belongs_to_ews_or_disadvantaged_group,
  //     is_cwsn: data.data.is_cwsn,
  //     type_of_impairment: data.data.type_of_impairment,
  //     disability_percentage: data.data.disability_percentage,
  //     is_indian: data.data.is_indian,
  //     is_this_student_is_out_of_school_child_in_current_or_previous_year: data.data.is_this_student_is_out_of_school_child_in_current_or_previous_year,
  //     when_the_student_is_mainstreamed: data.data.when_the_student_is_mainstreamed,
  //     });
  //   }
  // }

  async get_student(id: any) {
    try {
      let data = await this.sts.get(id)
      this.selected_student = data.data
      this.student_form.patchValue(data.data)
    } catch (error: any) {
      console.error("Error fetching State:", error);
      console.log(error);

    }
  }
}
