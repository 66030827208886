<app-header header_title="Topics">
    <app-button color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Topic" routerLink="/dashboard/topics/form"></app-button>
</app-header>
<div class="flex flex-col sm:flex-row justify-end mb-5 gap-3 px-8">
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-serch-text-input placeholder="Search"></app-serch-text-input>
        <!-- <app-filter-pill-button label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
        <table class=" table-auto w-full border-collapse ">
            <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-nowrap text-center">Sr No</th>
                    <th class="px-4 h-12 text-nowrap text-left">Course</th>
                    <th class="px-4 h-12 text-nowrap text-left">Topic</th>
                    <th class="px-4 h-12 text-nowrap text-left">Study Material</th>
                    <th class="px-4 h-12 text-nowrap text-center">Action</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px] ">
                <tr *ngFor="let item of list ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{i+1}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.cource_name}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.topic}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.study}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta routerLink="/dashboard/topics/form" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="udise_delete.open()" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="px-8 bg-[#F7F7F7] z-10 w-full bottom-0 h-12 absolute flex flex-col sm:flex-row gap-2 items-center justify-between">
    <div>
        <p class="text-[14px] text-[#212121] font-normal">
            Showing 1 to 5 of 50 Records
        </p>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button class="w-8 h-10 bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-left'></i>
        </button>
        <span class="w-8 h-10 flex justify-center items-center bg-[#3F41D1] text-white rounded-lg">1</span>
        <span class="w-8 h-10 flex justify-center items-center bg-[#E1E8F5] rounded-lg">2</span>
        <button class="w-8 h-10 bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-right'></i>
        </button>
    </div>
</div>
<!-- <app-confirmation-popup routerLink="/master/udise/form" #udise_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this UDISE?" popup_title="Edit UDISE"></app-confirmation-popup> -->
<app-confirmation-popup #udise_delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this UDISE?" popup_title="Delete UDISE"></app-confirmation-popup>