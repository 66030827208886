<div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
    <p class="font-normal text-base text-text-color-600">{{lable}}</p>
    <div class="bg-white flex flex-col md:mt-3 p-4 rounded-lg">
        <p class="font-normal text-base capitalize text-text-color-600">Upload Images</p>
        <div class="flex flex-col gap-4 mt-4">
            <div class="flex gap-4">
                <div *ngFor="let img of images" class="bg-gray-100 flex flex-col gap-2 rounded h-32 w-24 p-2">
                    <img class="bg-white rounded" [src]="img.image" alt="">
                    <div class="flex justify-around items-center">
                        <i class='bx bx-hide text-xl cursor-pointer hover:text-blue-600'></i>
                        <i class='bx bx-trash text-xl cursor-pointer hover:text-blue-600'></i>
                    </div>
                </div>
                <div class="bg-gray-100 flex flex-col gap-2 rounded h-32 cursor-pointer w-24 p-2">
                    <i class='bx bx-plus text-6xl m-auto text-center'></i>
                    <div class="flex justify-around items-center">
                        <span class="text-sm">Add Images</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
        <p class="font-normal text-base capitalize text-text-color-600">Upload Video</p>
        <div class="flex flex-col gap-4 mt-4">
            <div class="flex gap-4">
                <div *ngFor="let video of videos" class="bg-gray-100 flex flex-col gap-2 rounded h-32 w-24 p-2">
                    <img class="bg-white rounded" [src]="video.video" alt="">
                    <div class="flex justify-around items-center">
                        <i class='bx bx-hide text-xl cursor-pointer hover:text-blue-600'></i>
                        <i class='bx bx-trash text-xl cursor-pointer hover:text-blue-600'></i>
                    </div>
                </div>
                <div class="bg-gray-100 flex flex-col gap-2 rounded h-32 cursor-pointer w-24 p-2">
                    <i class='bx bx-plus text-6xl m-auto text-center'></i>
                    <div class="flex justify-around items-center">
                        <span class="text-sm">Add Video</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>