import { Component, EventEmitter, Output } from '@angular/core';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-notification-panel',
  standalone: true,
  imports: [DatePipe, NgFor, NgIf],
  templateUrl: './notification-panel.component.html'
})
export class NotificationPanelComponent {
  @Output() notification_toggled = new EventEmitter<void>();

  list: any[] = []

  constructor() { }

  // ngOnInit() {
  //   this.list = this.ns.list
  //   console.log(this.list, "NOti compo ts");
  // }

  notification_toggle() {
    this.notification_toggled.emit();
  }
}
