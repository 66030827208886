import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SidebarService } from '../../services/sidebar.service';
import { GlobalService } from '../../services/global.service';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [FormsModule,NgFor, NgIf],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {

  @Output() sidebar_toggled = new EventEmitter<void>();
    sidebar_data: any = {}
    roles = ['Super Admin', 'Venue Admin', 'Venue Auditor', 'Agent', 'Finance Controller', 'Manpower', 'Resources Manager', 'User'];
    selected_role: string = ''
   
    constructor(public ss: SidebarService, public gs: GlobalService) { }
   
    role_change() {
      this.gs.user.role = this.selected_role
      this.ss.selected_role = this.selected_role
    }
   
    // sidebar_toggle(): void {
    //   this.sidebar_toggled.emit();
    // }
}
