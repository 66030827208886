import { Component, ElementRef, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ButtonComponent } from '../../components/button/button.component';
import { NgFor, NgIf } from '@angular/common';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { debounceTime, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-assessment-type-list-page',
  standalone: true,
  imports: [HeaderComponent, NgxUiLoaderModule, ModalComponent, ReactiveFormsModule, ConfirmationPopupComponent, FilterPillButtonComponent, SerchTextInputComponent, IconCtaComponent, ButtonComponent, NgFor, NgIf, TextInputComponent, TextAreaInputComponent, PaginationComponent],
  templateUrl: './assessment-type-list-page.component.html',
  providers: []
})
export class AssessmentTypeListPageComponent {
  list: any = {}

  @ViewChild('open_bulk_modal') open_bulk_modal: any
  @ViewChild('open_assessment_type_modal') open_assessment_type_modal: any
  @ViewChild('add_assessment_type_modal') add_assessment_type_modal: any
  @ViewChild('update_assessment_modal') update_assessment_modal: any
  @ViewChild('assessment_delete') assessment_delete: any
  @ViewChild('add_assessment_type_button') add_assessment_type_button: any
  @ViewChild('update_assessment_type_button') update_assessment_type_button: any

  params: any = {}
  selected_assessment: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  errorMessage: any;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  assessment_file_upload: boolean = true
  mistake_modal: boolean = false
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  parsed_data: any[] = [];
  @ViewChild('searchInput') searchInput: any;

  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public fb: FormBuilder, public ar: ActivatedRoute, public as: AssessmentService, public toastr: ToastrService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_assessment_type_list(this.params);
    });
  }


  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_assessment_type_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_assessment_type_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_assessment_type_list(this.params);
      }
    });
  }


  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_assessment_type_list(this.params);
  }


  assessment_type_form: any = this.fb.group({
    name: ["", Validators.required],
    total_marks: ["", Validators.required],
    description: ["", Validators.required],
  })


  async add() {
    if (this.assessment_type_form.valid) {
      this.ui_spinner.startLoader('add-assessment-type-loader');
      this.add_assessment_type_button.disabled = true
      try {
        let response: any = await this.as.add(this.assessment_type_form.value)
        this.list = await this.as.get_list(this.params)
        this.get_assessment_type_list(this.params)
        this.ui_spinner.stopLoader('add-assessment-type-loader');
        this.add_assessment_type_button.disabled = false
        this.toastr.success(response.message || 'Assessment Type Added Successfully!')
        this.add_assessment_type_modal.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-assessment-type-loader');
        this.add_assessment_type_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.assessment_type_form.markAllAsTouched()
    }
  }

  async get_assessment_type_list(params: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.as.get_list(this.params)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching State list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_assessment_type(id: any) {
    console.log(id);
    try {
      let data = await this.as.get(id)
      this.selected_assessment = data.data
      console.log(this.selected_assessment);
      this.assessment_type_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching State:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async edit(id: number) {
    console.log(id, "qqqqqqqqqqqqqqqq");
    if (this.assessment_type_form.valid) {
      this.ui_spinner.startLoader('update-assessment-type-loader');
      this.update_assessment_type_button.disabled = true
      try {
        let response = await this.as.update(id, this.assessment_type_form.value);
        // this.toastr.success(response.message || 'Assessment Type Updated Successfully!')
        // this.get_assessment_type_list(this.params)
        this.assessment_type_form.reset();
        this.get_assessment_type_list(this.params)
        this.ui_spinner.stopLoader('update-assessment-type-loader');
        this.update_assessment_type_button.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_assessment_modal.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-assessment-type-loader');
        this.update_assessment_type_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.assessment_type_form.markAllAsTouched()
    }
  }

  async delete_confirm(id: number) {
    console.log(id, " jhhk");

    this.ui_spinner.startLoader('delete-assessment-type-loader');
    try {
      let response = await this.as.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_assessment_type_list(this.params);
      this.toastr.success(response.message || 'Assessment Type Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-assessment-type-loader');
      this.assessment_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-assessment-type-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];

    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.assessment_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.assessment_file_upload = true
        return;
      }
    }
    this.assessment_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }


  async add_assessment_bulk() {
    try {

      let newData = this.parsed_data.map((item) => {
        return {
          name: item['Assessment Type Name'],
          description: item.Description,
          total_marks: item['Total Marks'],
        };
      });

      const response: any = await this.as.add(newData)
      this.get_assessment_type_list(this.params)
      this.toastr.success(response.message || 'Assessment Type Added Successfully!')
      this.open_bulk_modal.close()
      this.assessment_file_upload = true
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.assessment_file_upload = true
      this.toastr.error(error?.error?.message)
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/assessment_type_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'assessment_type_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
