import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitAttendanceReportService {

  list: any = {}
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IkFydW4gR291bmRlciIsImVtYWlsIjoiZ291bmRlcmFydW4yODEyQGdtYWlsLmNvbSIsInBob25lIjoiOTk2NTIzNjUyMiIsInJvbGUiOiJTdXBlciBBZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsImlhdCI6MTcwNzI4Mjk1Mn0.sFqW995QzFMRhU40hha6sF39iqGmbIt24csLbBDvlyk'

  constructor(public hs: HttpService, public gs: GlobalService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/instructor-assignment/assign-instructor-assignment', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async delete(id: number) {
    console.log(id, "aaaaaaaaaa");

    try {
      let response = await this.hs.delete('/instructor-assignment/delete-instructor-assignment?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/instructor-assignment/get-instructor-assignment?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  async get_list(filters: any) {
    try {
      let url;
      if (this.gs.user?.role == 'Instructor' || this.gs.user?.role == 'Block Resource Coordinator') {
        url = `/attendance/get-monthly-attendance-list?school_id=${this.gs.selected_school.school_id || 0}`
      } else if (this.gs.user?.role == 'Super Admin') {
        url = '/attendance/get-uploaded-monthly-attendance-list'
      } else {
        url = `/attendance/get-monthly-attendance-list`
      }
      console.log(filters)
      let response = await this.hs.get(url, filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  async get_monthly_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/attendance/get-monthly-attendance', filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/instructor-assignment/update-instructor-assignment?id=' + id, {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  async upload_attendance_to_db(value: any) {
    try {
      let response = await this.hs.post('/attendance/upload-monthly-attendance', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
}
