<app-header header_title="Attendance"></app-header>
<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex flex-col-reverse sm:flex-row justify-end mb-5 gap-3 px-4">
        <div class="w-full flex justify-end items-center gap-3 md:w-[800px]">
            <div class="relative inline-block">
                <input type="month" (change)="lable_month_show($event)" class="rounded-lg block px-4 h-12 w-full text-sm text-gray-900 bg-white border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                <label [for]="" class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Month
                </label>
            </div>
            <app-select-input *ngIf="path.includes('reports') && gs.user.role == 'Super Admin'  || gs.user.role == 'Super Admin'" class="w-full" label="User" [options]="dropdown_list.user_list" (change)="user_show_filter($event)"></app-select-input>
            <app-select-input *ngIf="path.includes('reports') && gs.user.role == 'Block Resource Coordinator'" class="w-full" label="Instructor" [options]="dropdown_list.instructor_list" (change)="instructor_show_filter($event)"></app-select-input>
            <app-select-input *ngIf="path.includes('reports') && gs.user.role == 'Block Resource Coordinator' || gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'" class="w-full" label="School" [options]="dropdown_list.school_list" (change)="school_show_filter($event)"></app-select-input>
        </div>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="report_list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="report_list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <th *ngIf="gs.user.role === 'Super Admin' || gs.user.role ==='Sub Admin'" class="px-4 h-12 text-left">User</th>
                    <th *ngIf="gs.user.role==='Block Resource Coordinator'" class="px-4 h-12 text-left">Instructor</th>
                    <th *ngIf="gs.user.role !='Instructor'" class="px-4 h-12 text-left">School</th>
                    <th class="px-4 h-12 text-left">Block</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role === 'Super Admin' || gs.user.role ==='Sub Admin'">Role</th>
                    <th class="px-4 h-12 text-left">Date</th>
                    <th class="px-4 h-12 text-left">Check In</th>
                    <th class="px-4 h-12 text-left">Check Out</th>
                    <th class="px-4 h-12 text-left">Remark</th>
                    <th class="px-4 h-12 text-center">Action</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of report_list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td *ngIf="gs.user.role !='Instructor'" class="px-4 h-12 text-left">{{item.user_name?item.user_name:'-'}}</td>
                    <td *ngIf="gs.user.role !='Instructor'" class="px-4 h-12 text-left">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left" >{{item.block_name}}</td>
                    <td class="px-4 h-12 text-left" *ngIf="gs.user.role === 'Super Admin'">{{item.user_role?item.user_role:'-'}}</td>
                    <td class="px-4 h-12 text-left">{{item.date | date:'dd-MM-yyyy'}}</td>
                    <td class="px-4 h-12 text-left">{{item.check_in_time?item.check_in_time:'-' }}  </td>
                    <td class="px-4 h-12 text-left">{{item.check_out_time?item.check_out_time:'-' }} </td>
                    <td class="px-4 h-12 text-left">{{item.remark?item.remark:'-'}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-show" (click)="open_attandence_timeline.open(); selected_report(item)" tooltip="Timeline"></app-icon-cta>


                            <app-icon-cta icon="bx bxs-report" *ngIf="!path.includes('reports') && item.daily_report != null" tooltip="view Report" routerLink="/attendance/daily-log" [queryParams]="{id: item.daily_report.id, view:true}"></app-icon-cta>
                            <app-icon-cta icon="bx bxs-report" *ngIf="path.includes('reports') && item.daily_report != null" tooltip="view Report" routerLink="/reports/attendance/daily-log-report" [queryParams]="{id: item.id,view:true}"></app-icon-cta>


                            <!-- <app-icon-cta *ngIf="item.daily_report != null" icon="bx bxs-report" routerLink="/reports/daily-log-report" [queryParams]="{id: item.daily_report.id}" tooltip="View Report"></app-icon-cta> -->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="report_list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-modal #open_attandence_timeline title="Attendance Timeline">
    <div class="w-full grid md:grid-cols-2 lg:grid-cols-12 gap-[16px] rounded-b-lg">
        <div class="flex justify-between items-center lg:col-span-12 border bg-white px-[16px] py-[8px] rounded-lg">
            <div>
                <div class="flex items-center gap-[8px]">
                    <h1 class="text-[20px]">{{time_line_data.school_name}}</h1>
                    <p class="text-[16px] text-text-color-400">UDISE Code : {{time_line_data.school_udise_code}}</p>
                </div>
                <div class="text-text-color-400 text-[14px]"> {{time_line_data.school_address}}</div>
            </div>
            <div class="flex items-center gap-[8px]">
                <span class="text-[14px] font-medium text-text-color-600">Date:</span>
                <p class="flex items-center gap-[8px] text-[14px] text-text-color-400">
                    {{time_line_data?.date}}
                </p>
            </div>
        </div>
        <div class="lg:col-span-5 border rounded-lg bg-white px-[16px] py-[8px] text-[14px]">
            <p class="font-medium">{{time_line_data?.user_name}}</p>
            <p class="flex items-center gap-[8px] font-medium">
                <i class="bx bx-phone-call text-lg"></i>{{time_line_data.user_contact_number}}
            </p>
        </div>
        <div class="lg:col-span-7 border rounded-lg bg-[#fff] text-[14px] px-[16px] py-[8px] flex items-center justify-center gap-[24px] font-medium">
            <p class="flex items-center justify-center gap-2"><span>check-in :
                </span><span>{{time_line_data?.check_in_time}}</span></p>
            <p class="flex items-center justify-center gap-2"><span>check-out
                    :</span><span>{{time_line_data?.check_out_time}}</span></p>
        </div>
        <!-- <div class="lg:col-span-6 border rounded-lg bg-white px-[16px] py-[8px] text-[14px] font-medium">
            Check In Location
            <p class="flex justify-between items-center"><span class="text-[#6666]">google maps link</span>
                <a [href]="'https://www.google.com/maps?q={{time_line_data?.check_in_lat}},{{time_line_data?.check_in_lat}}'" target="_blank">
                    <i class="bx bx-link-external text-lg"  (click)="redirectToGoogleMaps(time_line_data?.check_in_lat, time_line_data?.check_in_lat)"></i>
                </a>
            </p>
        </div>
        <div class="lg:col-span-6 border rounded-lg bg-white px-[16px] py-[8px] text-[14px] font-medium">
            Check Out Location
            <p class="flex justify-between items-center"><span class="text-[#6666]">google maps link</span>
                <a [href]="'https://www.google.com/maps?q={{time_line_data?.check_in_lat}},{{time_line_data?.check_in_lat}}'" target="_blank">
                    <i class="bx bx-link-external text-lg"   (click)="redirectToGoogleMaps(time_line_data?.check_in_lat, time_line_data?.check_in_lat)"></i>
                </a>
            </p>
        </div> -->
        <div class="lg:col-span-6 border rounded-lg bg-white px-[16px] py-[8px] text-[14px] font-medium">
            Check In Location
            <p class="flex justify-between items-center">
                <span class="text-[#6666]">google maps link</span>
                <a [href]="'https://www.google.com/maps?q=' + time_line_data?.check_in_lat + ',' + time_line_data?.check_in_long" target="_blank">
                    <i class="bx bx-link-external text-lg"></i>
                </a>
            </p>
        </div>
        <div class="lg:col-span-6 border rounded-lg bg-white px-[16px] py-[8px] text-[14px] font-medium">
            Check Out Location
            <p class="flex justify-between items-center">
                <span class="text-[#6666]">google maps link</span>
                <a [href]="'https://www.google.com/maps?q=' + time_line_data?.check_out_lat + ',' + time_line_data?.check_out_long" target="_blank">
                    <i class="bx bx-link-external text-lg"></i>
                </a>
            </p>
        </div>
    </div>
</app-modal>