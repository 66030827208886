<ng-container *ngIf="currentLevel==0">
    <div  class="flex items-center justify-between px-5 py-3 bg-white border rounded-md cursor-pointer" (click)="selected.emit([])">
        <span > {{title_of_tree || 'Course Name'}} </span>
        <span class="flex items-center gap-2">
            <app-icon-cta *ngIf="buttons_visible && currentLevel==0" (click)="add_level_inside(true,0)"
                icon="bx bx-plus" tooltip="Add"></app-icon-cta>
        </span>
    </div>
</ng-container>


<div *ngFor="let node of treeData; let i = index" class="my-2 rounded-md" [class.pl-4]="currentLevel==0" (click)="handle_accordion_click($event,node.id); level_clicked(i)">
    <div class="flex items-center justify-between px-5 py-3 bg-white border rounded-md cursor-pointer">
        <span *ngIf="node.name" >{{ node.name}}</span>
        <span *ngIf="!node.name">
            Level
            <span *ngFor="let old of level_array"> {{old+1}}.</span>{{i+1}}
        </span>
        <span class="flex items-center gap-2">
            <app-icon-cta *ngIf="buttons_visible && this.currentLevel < (this.total_levels-1)" (click)="add_level_inside(false,i)"
                icon="bx bx-plus" tooltip="Add"></app-icon-cta>
            <app-icon-cta *ngIf="buttons_visible" (click)="remove_level_from_inside(i)"
                icon="bx bx-minus" tooltip="Remove"></app-icon-cta>
            <ng-container *ngIf=" node.levels && node.levels.length > 0 && level > 0">
                <svg (click)="toggle_node(node, i)" *ngIf="(node.expanded)" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
                <svg (click)="toggle_node(node, i)" *ngIf="!(node.expanded)" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </ng-container>
        </span>
    </div>
    <div *ngIf="node.levels && (expanded[i])" class="pl-4 ml-4">
        <app-course-tree [treeData]="node.levels" [level]="level" [currentLevel]="currentLevel + 1" [level_array]="[i]" (selected)="selected.emit($event)" [buttons_visible]="buttons_visible" (add_level)="add_level.emit($event)" (remove_level)="remove_level.emit($event)" ></app-course-tree>
    </div>
</div>