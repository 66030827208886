<div class="bg-white flex items-center justify-end md:px-6 px-4 py-4 shadow sticky top-0 w-full z-49">
    <div class="flex gap-2 items-center justify-center">
        <div class="h-8 w-8 rounded-full overflow-hidden">
            <img class="object-cover h-full w-full" src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                alt="user">
        </div>
        <select [(ngModel)]="selected_role" (change)="role_change()" id="role"
            class="bg-gray-50 border botext-blue-950 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option selected>Choose a Role</option>
            <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
        </select>
    </div>
</div>