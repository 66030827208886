import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

interface TreeNode {
  id: number; // Assuming each node has an id for identification
  name: string;
  selected: boolean;
  children?: TreeNode[];
}

@Component({
  selector: 'app-single-option-tree-selector',
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, CommonModule],
  templateUrl: './single-option-tree-selector.component.html',
})
export class SingleOptionTreeSelectorComponent {
  @Input() tree_data: TreeNode[] = [];
  selectedChild: TreeNode | null = null;

  constructor() { }

  selectChild(node: TreeNode, selectedChild: TreeNode): void {
    if (this.selectedChild) {
      this.selectedChild.selected = false; // Deselect the previously selected child
    }
    this.selectedChild = selectedChild; // Set the new selected child
    selectedChild.selected = true; // Mark the new selected child as selected
  }
}
