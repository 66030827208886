import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { IconCtaComponent } from '../icon-cta/icon-cta.component';


@Component({
  selector: 'app-course-tree',
  standalone: true,
  imports: [NgIf, NgFor,IconCtaComponent,JsonPipe],
  templateUrl: './course-tree.component.html',
})
export class CourseTreeComponent {
  @Input() treeData: any = {};
  @Input() level: any = 0;
  @Input() currentLevel: number = 0;
  @Input() total_levels: number = 0;
  @Output() selected = new EventEmitter<any>()
  @Output() add_level = new EventEmitter<any>()
  @Output() remove_level = new EventEmitter<any>()
  @Input() level_array:any[] = [];
  @Input() buttons_visible = false;
  @Input() title_of_tree=''
  expanded:any = {}


  ngOnInit(){

  }

  toggle_node(node: any,index:any): void {
    this.expanded[index] = !this.expanded[index];
  }

  handle_accordion_click(event: any, id: string): void {
    event.stopPropagation()
    
    // You can do more with the ID here
  }
  

  addNode(parentNode: any): void {
    const newNode = { id: uuidv4(), name: 'Level Name', children: [] };
    if (!parentNode.children) {
      parentNode.children = [];
    }
    parentNode.children.push(newNode);
    parentNode.expanded = true;
  }

  removeNodeById(nodeId: string, nodes: any[]): void {
    // const index = nodes.findIndex(node => node.id === nodeId);
    // if (index !== -1) {
    //   nodes.splice(index, 1);
    //   return;
    // }
    // for (const node of nodes) {
    //   if (node.children && node.children.length > 0) {
    //     this.removeNodeById(nodeId, node.children);
    //   }
    // }
  }

  can_add_node(): boolean {
    return this.currentLevel < this.level;
  }

  level_clicked(i:any){
    let position = [...this.level_array]
    position.push(i)
    this.selected.emit(position)
  }

  add_level_inside(base=true,position:any){
    if(base){
      this.add_level.emit([])
    }
     else{
      console.log('Hereeeeeee')
      this.add_level.emit([position])
    }
  }

  remove_level_from_inside(i:any){
    let position = [...this.level_array]
    position.push(i)
    this.remove_level.emit(position)
  }

}
