import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ViewportScroller } from '@angular/common';
import { SelectInputComponent } from './components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, NgxGalleryModule, SelectInputComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'ict-training';
  // constructor(private router: Router, private vs: ViewportScroller) { }
  // ngOnInit(): void {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       this.vs.scrollToPosition([0, 0]);
  //     }
  //   });
  // }
  constructor() { }
}
