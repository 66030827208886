<input style="display: none;" type="file" #fileInput [(ngModel)]="file_model" (change)="on_value_change($event)" [accept]="supported_extension">
<ng-container>
    <div class="flex gap-3 mt-3">
        <div style="height:150px;width:150px;" class="relative border-2 rounded flex flex-col justify-center items-center cursor-pointer"
            *ngIf="!params.view" (click)="fileInput.click()">
            <i class='bx bx-plus text-3xl'></i>
            <span class="cursor-pointer" color="bg-[#E697AB] text-[#310000]">Attach
                File{{multiple?'s':''}}
            </span>
                <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-5 right-5 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#000" loaderId="add-file-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
            <div *ngIf="control && control.errors && control.touched" class="error-messages">
                <p class="text-red-500" *ngIf="control.errors.required">{{label}} is required.</p>
            </div>
        </div>
        <div class="flex flex-col justify-center items-center p-1 bg-white"
            *ngFor="let attachment of (value | splitString : ','); let i=index">

            <!-- Image File -->
            <img *ngIf="(attachment | fileType: 'icon') === 'bxs-image'" class="rounded"
                style="height:150px;width:150px;" [src]="this.fs.base_gcs_url+attachment" alt="">

            <!-- Other File Types -->
            <div *ngIf="(attachment | fileType: 'icon') !== 'bxs-image'"
                class="rounded relative flex justify-center items-center" style="height:150px;width:150px;">
                <i
                    [class]="'bx ' + (attachment | fileType: 'icon') + ' text-[100px] ' + (attachment | fileType: 'color')"></i>
                <span class="right-0 absolute top-0 rounded-lg p-1 bg-[#3F41D1] text-white">{{attachment.split('.').pop()}}</span>
            </div>
            <span class="text-center mt-2 bg-[#3F41D1] text-white text-lg font-bold px-4 rounded-full">
                <i *ngIf="!params.view" (click)="remove_attachment(i)"
                    class='bx bx-trash text-2xl cursor-pointer mr-3'></i>
                <i (click)="open_attachment(attachment)" class='bx bx-show text-2xl cursor-pointer mr-3'></i>
                <i (click)="open_attachment_link(attachment)" class='bx bx-link text-2xl cursor-pointer mr-3'></i>
            </span>
        </div>
    </div>
</ng-container>

<app-modal #open_media title="File">
    <iframe class="w-full h-full rounded-2xl object-cover" [src]="file_url | safeUrl" frameborder="0" allowfullscreen></iframe>
</app-modal>