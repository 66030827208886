import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';

@Component({
  selector: 'app-unit-list-page',
  standalone: true,
  imports: [VenueAuditViewComponent, HeaderComponent,TextAreaInputComponent, SelectInputComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent],
  templateUrl: './unit-list-page.component.html',
})
export class UnitListPageComponent {
  unit_list = [
    { Unit_Name: 'ABC', Course: "English", Description: "ABC" },
    { Unit_Name: 'XYZ', Course: "Marathi", Description: "XYZ" },
  ]
}
