import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BrcAssignmentService {
  list: any = {}
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IkFydW4gR291bmRlciIsImVtYWlsIjoiZ291bmRlcmFydW4yODEyQGdtYWlsLmNvbSIsInBob25lIjoiOTk2NTIzNjUyMiIsInJvbGUiOiJTdXBlciBBZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsImlhdCI6MTcwNzI4Mjk1Mn0.sFqW995QzFMRhU40hha6sF39iqGmbIt24csLbBDvlyk'

  constructor(public hs: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/brc-assignment/assign-brc-assignment', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/brc-assignment/delete-brc-assignment?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/brc-assignment/get-brc-assignment?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }
  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/brc-assignment/get-brc-assignment-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
  async get_brc_school_assignment_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/brc-assignment/get-brc-assigned-school', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/brc-assignment/update-brc-assignment?id=' + id, {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
