<app-header [header_title]="path.includes('reports') ? 'Monthly Upload Report' : 'Upload Monthly Attendance'"></app-header>

<div [ngClass]="{'justify-end': gs.user.role == 'Super Admin', 'justify-between': gs.user.role != 'Super Admin'}" class="w-full flex flex-col-reverse sm:flex-row mb-5 gap-3 px-4">
    <div *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator'" class="w-full flex items-center gap-3 md:w-96">
        <ng-container *ngFor="let filter of quick_filters">
            <app-filter-pill-button [routerLink]="path" [queryParams]="{status: filter.label}" queryParamsHandling="merge" [label]="filter.label" [selected]="filter.selected"></app-filter-pill-button>
        </ng-container>
    </div>
    <div class="w-full flex justify-end items-center gap-3 md:w-[300px]">
        <div class="w-full flex justify-end items-center gap-3">
            <app-select-input *ngIf="path.includes('reports') && gs.user.role == 'Super Admin'" class="w-full" label="User" [options]="dropdown_list.instructor_list" (change)="user_show_filter($event)"></app-select-input>
        </div>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <!-- <th class="px-4 h-12 text-left" *ngIf="gs.user.role === 'Block Resource Coordinator'">Instructor
                    </th> -->
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">User</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">Role</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">School</th>
                    <th class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">Block</th>
                    <th class="px-4 h-12 text-left">Month</th>
                    <th class="px-4 h-12 text-left">Year</th>
                    <th class="px-4 h-12 text-left">Status</th>
                    <th class="px-4 h-12 text-center">Action</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{i+1}}</td>
                    <!-- <td class="px-4 h-12 text-left" *ngIf="gs.user.role === 'Block Resource Coordinator'">
                        {{item.instructor}}</td> -->
                    <td class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">{{item.user_name}}</td>
                    <td class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">{{item.user_role}}</td>
                    <td class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left" *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Sub Admin'">{{item.block_name}}</td>
                    <td class="px-4 h-12 text-left">{{item.month | monthName}}</td>
                    <td class="px-4 h-12 text-left">{{item.year}}</td>
                    <td class="px-4 h-12 text-left">{{item.upload_status}}</td>
                    <td class="px-4 h-12 text-left">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-show" [disabled]="item.upload_status==='Pending'" (click)="view_pdf(item)" tooltip="View uploaded report" class="tooltip"></app-icon-cta>
                            <span class="tooltip-text absolute bg-gray-700 text-white text-[10px] rounded py-1 px-2 bottom-full  transform -translate-x-1/2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                View uploaded report
                              </span>
                            <app-icon-cta *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator'" icon="bx bx-download" (click)="generate_pdf(item)"  tooltip="Download"></app-icon-cta>
                            <app-icon-cta *ngIf="item.upload_status == 'Submitted' && !path.includes('reports')" (click)="open_bulk_modal.open(); file_object = item" icon="bx bx-reset" tooltip="Reupload"></app-icon-cta>
                            <app-icon-cta *ngIf="item.upload_status == 'Pending' && !path.includes('reports')" (click)="open_bulk_modal.open(); file_object = item" icon="bx bx-upload" tooltip="Upload"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-modal #open_bulk_modal title="Upload File">
    <div [formGroup]="submit_monthly_form" class="bg-white flex flex-col rounded-lg">
        <app-zoro-file-input [multiple]="false" formControlName="file" class="w-full" label="Files" supported_extension=".pdf"></app-zoro-file-input>
    </div>
    <app-button (click)="upload_file_link()" class="self-end" color="bg-primary-500 text-white" [label]="'Submit'">
    </app-button>
</app-modal>