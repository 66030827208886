import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString',
  standalone: true
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string,split_by:string): any[] {
    return value?.split(split_by).filter(item=>item!='')
  }

}
