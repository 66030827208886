<!-- <label class="block mb-2 text-sm font-medium text-gray-900" for="file_input">Upload Image</label>
<input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white focus:outline-none p-2.5" id="file_input" type="file"> -->

<div class="mb-3">
    <label class="w-full relative">
        <div
            class="w-full bg-none flex items-center border border-gray-300 outline-none  text-[16px] text-[#212121] rounded-[8px] font-normal p-2 cursor-pointer">
            <div class="text-[#212121] text-[14px] font-normal flex justify-between items-center w-full px-2">
                <p class="text-gray-500">Image</p>
                <p target="_blank" class="flex gap-2 items-center">
                    <i class='bx bx-paperclip text-xl text-[#3F41D1]'></i>
                    <!-- <span class="text-[#3F41D1] ">Upload Attachment</span> -->
                </p>
            </div>

            <input type="file" accept=".pdf, .png, .jpg, .jpeg" class="w-full hidden" />
        </div>
    </label>
</div>