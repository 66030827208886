import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitieTypeServiceService {

  list: any[] = []

  constructor(public hs: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/activity-type/add-activity-type', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/activity-type/delete-activity-type?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/activity-type/get-activity-type?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/activity-type/get-activity-type-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/activity-type/update-activity-type?id=' + id, {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
