import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // base_url = 'http://localhost:3000'
  base_url = environment.url

  constructor(public http: HttpClient, public gs: GlobalService, public router: Router) { }

  customUpload(url: string, file: File): Observable<any> {
    console.log("ffffffffffffff", file);

    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const base64String = reader.result as string;
        // console.log(file)
        // You can now use the base64String in your POST request
        // const formData = {
        //   file: base64String,
        //   file_name: file.name,
        //   size: file.size,
        // };


        let formData = {
          content_type: file.type,
          file_extension: file.name.split('.').pop(),
          file_size: file.size,
        };

        this.post('/attachment/uplaod-attachment', formData, {}).then((data: any) => {
          const formData = new FormData();
          formData.append('file', file);
          const headers = new HttpHeaders({
            'Content-Type': file.type
          });
          this.http.put(url, formData, { headers }).subscribe(
            (response) => {
              observer.next(response);
              observer.complete();
            },
            (error) => {
              observer.error(error);
              observer.complete();
            }
          );
        })

      };

      reader.readAsDataURL(file);
    });
  }

  async get(url: string, query_params: any) {
    try {
      let params = { ...query_params }
      if (this.gs.user?.token)
        params.token = this.gs.user?.token;
      let response: any = await firstValueFrom(this.http.get(this.base_url + url, { params: params }))
      return response
    } catch (error: any) {
      throw error;
    }
  }

  async delete(url: string, query_params: any) {
    try {
      let params = { ...query_params }
      if (this.gs.user?.token)
        params.token = this.gs.user?.token;
      let response: any = await firstValueFrom(this.http.delete(this.base_url + url, { params: params }))
      return response
    } catch (error: any) {
      throw error;
    }
  }

  async post(url: string, query_params: any, body: any) {
    try {
      let params = { ...query_params }
      if (url.includes('set-password') || url.includes('forgot-password')) {
        console.log((url.includes('set-password')), "TOKEN", params);
        let response: any = await firstValueFrom(this.http.post(this.base_url + url, body, { params: params }))
        return response
      }
      if (this.gs.user?.token && !(url.includes('sign-in')))
        params.token = this.gs.user?.token;
      let response: any = await firstValueFrom(this.http.post(this.base_url + url, body, { params: params }))
      return response
    } catch (error: any) {
      throw error;
    }
  }
  async put(url: string, body: any) {
    try {
      let response: any = await firstValueFrom(this.http.put(url, body))
      return response
    } catch (error: any) {
      throw error;
    }
  }

  check_sign_out(error: any) {
    if (error.status == 401 || error.status == 403) {
      localStorage.removeItem('user')
      this.gs.user = {}
      // window.location.reload()
      this.router.navigate(['/login'])
    }
  }
}
