<app-header header_title="Add Session">
    <app-button color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel"   placement="header"
        routerLink="/dashboard/sessions"></app-button>
    <app-button color="bg-accent-300 text-accent-on" label="Submit" routerLink="/dashboard/sessions"
         ></app-button>
</app-header>
<div class="px-8 mb-2">
    <form>
        <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Assessment Meta Data</p>
            <p class="text-text-color-500 font-medium text-sm mt-2">
                To create a new session, please ensure all mandatory fields are completed accurately. Each field serves a specific purpose in facilitating the session creation process.
            </p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
                <app-select-input title="Batch" label="Batch" [options]="batch"></app-select-input>
                <app-select-input title="Lessons" label="Lessons" [options]="lesson"></app-select-input>
            </div>
        </div>
        <div class="bg-white flex flex-col mt-6 p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Lessons Details</p>
            <p class="text-text-color-500 font-medium text-sm mt-2">
                This description prompts users to fill in all mandatory fields accurately to create a new session record, emphasizing the importance of providing precise information for a smooth process.
            </p>
            <div class="flex justify-between mt-4">
                <p class="font-normal text-base ">Field</p>
            </div>
            <ng-container>
                <div *ngFor="let field of session_list; let i=index"
                    class="relative border border-slate-300 rounded-lg mt-4">
                    <i (click)="delete_session(i)"
                        class="absolute -top-4 -right-4 bg-transparent hover:bg-gray-100 flex items-center justify-center w-10 h-10 bx bx-x-circle rounded-full cursor-pointer hover:text-gray-700 text-2xl text-gray-500"></i>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3 px-4 py-6 rounded-sm">
                        <app-text-input icon="bx bx-dumbbell" label="Lessons" title="Lessons" ></app-text-input>
                        <app-text-input icon="bx bx-dumbbell" label="Description" title="Description"></app-text-input>
                        <app-select-input title="Status" icon="bx bx-list-plus" label="Status" [options]="status_list"></app-select-input>
                    </div>
                </div>
            </ng-container>
            <app-button class="mt-4 border-none" icon="bx bx-plus-circle" label="Add Student" size="sm"
                (click)="add_session()"></app-button>
        </div>
    </form>
</div>