import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { GlobalService } from '../../services/global.service';
import { SelectInputComponent } from "../../components/inputs/select-input/select-input.component";
import * as XLSX from 'xlsx';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { StateService } from '../../services/state.service';


import { CountryService } from '../../services/country.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-state-list-page',
  standalone: true,
  templateUrl: './state-list-page.component.html',
  imports: [VenueAuditViewComponent, NgxUiLoaderModule, HeaderComponent, ReactiveFormsModule, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, SelectInputComponent, PaginationComponent],

})
export class StateListPageComponent {
  open_button: boolean = false
  state_list: any = []
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  @ViewChild('add_state_modal') add_state_modal: any
  @ViewChild('update_state_modal') update_state_modal: any
  @ViewChild('state_delete') state_delete: any
  @ViewChild('add_state_button') add_state_button: any
  @ViewChild('update_state_button') update_state_button: any

  is_delete_popup_visible: boolean = false;
  is_edit_popup_visible: boolean = false;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  state_file_upload: boolean = true
  parsed_data: any[] = []
  mistake_modal: boolean = false
  debounce_timeout: any = undefined;
  list: any = {};
  params: any = {};
  selected_state: any = {};
  dropdown_list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  errorMessage: any;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;


  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public cs: CountryService, public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, public ss: StateService, public toastr: ToastrService) {
    // this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
    //   this.params.search = searchText;
    //   this.get_state_list(this.params);
    // });
  }

  state_from: any = this.fb.group({
    name: new FormControl("", Validators.required),
    country_id: new FormControl("", Validators.required),
  })

  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_state_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_state_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_state_list(this.params);
      }
    });
  }
  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_state_list(this.params);
  }
  async get_dropdown_list() {
    try {
      const country_response = await this.cs.get_list({})
      let country_options = country_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.country_list = country_options
      console.log(country_options, "qqqqqqqqqqqqqqqq");

    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  async add() {
    if (this.state_from.valid) {
      this.ui_spinner.startLoader('add-state-loader');
      this.add_state_button.disabled = true
      try {
        let response: any = await this.ss.add(this.state_from.value)
        this.state_from.reset();
        this.get_state_list(this.params)
        this.add_state_modal.close()
        this.ui_spinner.stopLoader('add-state-loader');
        this.add_state_button.disabled = false
        this.toastr.success(response.message || 'State Added Successfully!')
      } catch (error: any) {
        console.error(error?.error?.message, '')
        this.add_state_button.disabled = false
        this.ui_spinner.stopLoader('add-state-loader');
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.state_from.markAllAsTouched();
    }

  }
  async get_state_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ss.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching State list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_state(id: any) {
    console.log(id);
    try {
      let data = await this.ss.get(id)
      this.selected_state = data.data
      this.state_from.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching State:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async edit(id: number) {
    if (this.state_from.valid) {
      console.log(id, "qqqqqqqqqqqqqqqq");
      this.ui_spinner.startLoader('update-state-loader');
      this.update_state_button.disabled = true
      try {
        let response = await this.ss.update(id, this.state_from.value);
        this.state_from.reset();
        this.get_state_list(this.params)
        this.ui_spinner.stopLoader('update-state-loader');
        this.update_state_button.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_state_modal.close()
      } catch (error: any) {
        console.error(error?.error?.message, '')
        this.ui_spinner.stopLoader('update-state-loader');
        this.update_state_button.disabled = false
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.state_from.markAllAsTouched();
    }
  }
  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-state-loader');
    try {
      let response = await this.ss.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_state_list(this.params);
      this.toastr.success(response.message || 'State Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-state-loader');
      this.state_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-state-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }
  

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.state_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.state_file_upload = true
        return;
      }
    }
    this.state_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }



  async add_state_bulk() {
    try {
      let new_csv = this.parsed_data.map((item: any) => {
        const country = this.dropdown_list.country_list.find((c: any) => c.title === item.country || item.Country);
        if (!country) throw ({ message: `Country "${item.country || item.Country}" not found in Master` });
        return {
          name: item.State || item.state,
          country_id: country.value,
        };
      });
      console.log(new_csv, "hhhhhhhhhhhhh");
      const response: any = await this.ss.add(new_csv)
      this.get_state_list(this.params)
      this.open_bulk_modal.close()
      this.state_file_upload = true
      this.toastr.success(response.message || 'State Added Successfully!')
    } catch (error: any) {
      this.state_file_upload = true
      this.toastr.error(error?.error?.message || error.message || "Invalid File")
    }
  }
  download_template(): void {
    const url = '../../../assets/template-files/state_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'state_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
