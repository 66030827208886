import { Component, ElementRef, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { StudyMaterialPageComponent } from '../study-material-page/study-material-page.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { GlobalService } from '../../services/global.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GroundLevelReportService } from '../../services/ground-level-report.service';
import { ToastrService } from 'ngx-toastr';
import { GradeService } from '../../services/grade.service';
import { CourseService } from '../../services/course.service';
import { ActivitieTypeServiceService } from '../../services/activitie-type-service.service';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';

@Component({
  selector: 'app-ground-level-activities-form-page',
  standalone: true,
  imports: [HeaderComponent, ButtonComponent, ZoroFileInputComponent, FilterPillButtonComponent, SerchTextInputComponent, ConfirmationPopupComponent, IconCtaComponent, TextInputComponent, SelectInputComponent, DateInputComponent, RouterLink, NgFor, NgIf, StudyMaterialPageComponent, TextAreaInputComponent, NgxUiLoaderModule, FormsModule, ReactiveFormsModule],
  templateUrl: './ground-level-activities-form-page.component.html',
})
export class GroundLevelActivitiesFormPageComponent {
  isTableDisabled: boolean = true
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('add_ground_activitie_button') add_ground_activitie_button: any
  @ViewChild('update_ground_activitie_button') update_ground_activitie_button: any
  current_date_show: any
  path = window.location.pathname;
  constructor(public ui_spinner: NgxUiLoaderService, public fb: FormBuilder, public gas: GroundLevelReportService, public ar: ActivatedRoute, public router: Router, public toastr: ToastrService, public gs: GlobalService, public grade: GradeService, public cs: CourseService, public ats: ActivitieTypeServiceService) {
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }
  dropdown_list: any = {}
  async ngOnInit() {
    this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.get_ground_activity(params['id'])
        this.ground_activity_form.patchValue(await this.gas.get(params['id']))
      }
    })
  }
  ground_activity_form: any = this.fb.group({
    grade_id: ["", Validators.required],
    description: ["", Validators.required],
    date: ["", Validators.required],
    activity_type_id: ['', Validators.required],
    school_udise_code: '',
    video: '',
    image: '',
  })
  params: any = {};
  selected_ground_activity: any = {};
  async add() {
    console.log(this.ground_activity_form);
    if (this.ground_activity_form.valid) {
      this.ui_spinner.startLoader('add-ground_activity-loader');
      this.add_ground_activitie_button.disabled = true
      try {
        const formValue = {
          grade_id: this.ground_activity_form.value.grade_id,
          date: this.ground_activity_form.value.date,
          description: this.ground_activity_form.value.description,
          activity_type_id: this.ground_activity_form.value.activity_type_id,
          image: this.ground_activity_form.value.image,
          video: this.ground_activity_form.value.video,
          school_id: this.gs.selected_school.school_id
        };
        let response: any = await this.gas.add(formValue);
        console.log(response);
        this.ground_activity_form.reset();
        this.ui_spinner.stopLoader('add-ground_activity-loader');
        this.add_ground_activitie_button.disabled = false
        this.toastr.success(response.message || 'Ground Activity Added Successfully!');
        this.router.navigate(['/ground-activities']);
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-ground_activity-loader');
        this.add_ground_activitie_button.disabled = false
        console.error(error?.error?.message, '');
      }
    } else {
      this.ground_activity_form.markAllAsTouched()
    }
  }
  async get_dropdown_list() {
    try {
      const grade_response = await this.grade.get_list({})
      const activitie_type_response = await this.ats.get_list({})
      let grade_options = grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let activitie_type_options = activitie_type_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.grade_list = grade_options
      this.dropdown_list.activitie_type_list = activitie_type_options
      console.log(grade_response, grade_options);
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  async get_ground_activity(id: any) {
    try {
      let data = await this.gas.get(id);
      this.selected_ground_activity = data.data;
      const formatDate = (dateString: string) => {
        const [year, month, day] = new Date(dateString).toISOString().split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      };
      data.data.date = formatDate(data.data.date);
      this.ground_activity_form.patchValue(data.data);
    } catch (error: any) {
      console.error("Error fetching State:", error);
      console.log(error);
    }
  }

  async edit(id: number) {
    if (this.ground_activity_form.valid) {
      this.ui_spinner.startLoader('update-ground_activity-loader');
      this.update_ground_activitie_button.disabled = true
      try {
        const formValue = {
          grade_id: this.ground_activity_form.value.grade_id,
          date: this.ground_activity_form.value.date,
          description: this.ground_activity_form.value.description,
          activity_type_id: this.ground_activity_form.value.activity_type_id,
          image: this.ground_activity_form.value.image,
          video: this.ground_activity_form.value.video,
          school_id: this.gs.selected_school.school_id
        };
        let response = await this.gas.update(id, formValue);
        this.ground_activity_form.reset();
        console.log(id, formValue);
        this.toastr.success(' Updated Successfully!');
        this.ui_spinner.stopLoader('update-ground_activity-loader');
        this.update_ground_activitie_button.disabled = false
        this.router.navigate(['/ground-activities']);
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-ground_activity-loader');
        this.update_ground_activitie_button.disabled = false
        console.error(error?.error?.message, '');
      }
    } else {
      this.ground_activity_form.markAllAsTouched()
    }
  }
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
    }
  }

}