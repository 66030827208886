import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PersonalDetailsViewComponent } from '../personal-details-view/personal-details-view.component';
import { VenueDetailsViewComponent } from '../venue-details-view/venue-details-view.component';
import { ResourcesViewComponent } from '../resources-view/resources-view.component';
import { FacilitiesViewComponent } from '../facilities-view/facilities-view.component';
import { ServicesViewComponent } from '../services-view/services-view.component';
import { ModalComponent } from '../modal/modal.component';
import OrgChart from '../../../assets/balkanapp/orgchart';
import { SingleOptionTreeSelectorComponent } from '../single-option-tree-selector/single-option-tree-selector.component';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { SerchTextInputComponent } from '../serch-text-input/serch-text-input.component';
declare var document: any;

@Component({
  selector: 'app-venue-audit-view',
  standalone: true,
  imports: [ModalComponent, NgClass, NgFor, NgIf, PersonalDetailsViewComponent, TextInputComponent, VenueDetailsViewComponent, ResourcesViewComponent, FacilitiesViewComponent, ServicesViewComponent, SingleOptionTreeSelectorComponent, SerchTextInputComponent],
  templateUrl: './venue-audit-view.component.html',
})
export class VenueAuditViewComponent {
  @Input() venue_detail: any = {};
  @ViewChild('modal_open') modal_open:any;

  is_expanded: boolean[] = [true, false, false, false, false];
  current_step = 1
  form_steps: any = [
    { step: 1, title: 'Personal Details', description: 'Fill in your basic information to let us know your preferences' },
    { step: 2, title: 'Venue Details', description: 'Fill in your basic information to let us know your preferences' },
    { step: 3, title: 'Resources', description: 'Fill in your basic information to let us know your preferences' },
    { step: 4, title: 'Services', description: 'Fill in your basic information to let us know your preferences' },
    { step: 5, title: 'Facilities', description: 'Fill in your basic information to let us know your preferences' },
  ]

  chart_data = [
    { id: "1", name: "LABS", title: "LABS" },
    { id: "2", pid: "1", name: "Computer Lab", title: "Computer Lab" },
    { id: "3", pid: "1", name: "Chemistry Lab", title: "Chemistry Lab" },
    { id: "4", pid: "2", name: "LAB-001", title: "LAB-001" },
    { id: "5", pid: "2", name: "LAB-002", title: "LAB-002" },
    { id: "10", pid: "4", name: "LAB-001/SYS-01", title: "LAB-001/SYS-01", tags: ["computer"] },
    { id: "11", pid: "4", name: "LAB-001/SYS-02", title: "LAB-001/SYS-02", tags: ["computer"] },
    { id: "12", pid: "4", name: "LAB-001/SYS-03", title: "LAB-001/SYS-03", tags: ["computer"] },
    { id: "13", pid: "4", name: "LAB-001/SYS-04", title: "LAB-001/SYS-04", tags: ["computer"] },
    { id: "14", pid: "4", name: "LAB-001/SYS-05", title: "LAB-001/SYS-05", tags: ["computer"] },
    { id: "15", pid: "3", name: "DSK-001", title: "DSK-001", tags: ["chemistry desk"] },
    { id: "16", pid: "3", name: "DSK-002", title: "DSK-002", tags: ["chemistry desk"] },
    { id: "17", pid: "3", name: "DSK-003", title: "DSK-003", tags: ["chemistry desk"] },
    { id: "18", pid: "3", name: "DSK-004", title: "DSK-004", tags: ["chemistry desk"] },
    { id: "19", pid: "3", name: "DSK-005", title: "DSK-005", tags: ["chemistry desk"] },
    { id: "20", pid: "5", name: "LAB-002/SYS-01", title: "LAB-002/SYS-01", tags: ["computer"] },
    { id: "21", pid: "5", name: "LAB-002/SYS-02", title: "LAB-002/SYS-02", tags: ["computer"] },
    { id: "22", pid: "5", name: "LAB-002/SYS-03", title: "LAB-002/SYS-03", tags: ["computer"] },
  ]

  list= [
    {parameter: 'Basic Information', venue_admin_response: '', venue_aduditor_response: '',},
    {parameter: 'Name', venue_admin_response: 'Laptop 1', venue_aduditor_response: 'Laptop 1',},
    {parameter: 'Resource Type', venue_admin_response: 'Laptop', venue_aduditor_response: 'Laptop',},
    {parameter: 'Brand', venue_admin_response: 'Dell', venue_aduditor_response: 'Dell',},
    {parameter: 'Display Size', venue_admin_response: '15', venue_aduditor_response: '15',},
    {parameter: 'Technical Information', venue_admin_response: '', venue_aduditor_response: '',},
    {parameter: 'Processor', venue_admin_response: 'i7 13007K', venue_aduditor_response: 'i7 13007K',},
    {parameter: 'Storage', venue_admin_response: '1TB', venue_aduditor_response: '1TB',},
    {parameter: 'RAM', venue_admin_response: '12GB', venue_aduditor_response: '12GB',},
    {parameter: 'Refresh Rate', venue_admin_response: '144Hz', venue_aduditor_response: '144Hz',},
    {parameter: 'Rating', venue_admin_response: 'Very Good', venue_aduditor_response: 'Good',},
    {parameter: 'Auditor Remark', venue_admin_response: '-', venue_aduditor_response: 'Test Remark',},
    {parameter: 'Auditor Action', venue_admin_response: '-', venue_aduditor_response: 'Approved',},
  ]

  tree_data = [
    {
      id: 1,
      name: 'Resources',
      selected: false,
      children: [
        {
          id: 11,
          name: 'Resource 1',
          selected: true,
        },
        {
          id: 12,
          name: 'Resource 2',
          selected: false,
        },
        {
          id: 13,
          name: 'Resource 3',
          selected: false,
        },
      ]
    },
  ];
  
  close(){
    this.modal_open.close()
  }
  open(){
    this.modal_open.open()
  }

  toggleStep(step_index: number) {
    this.current_step = step_index + 1
    if (!this.is_expanded[step_index]) {
      this.is_expanded = this.is_expanded.map((_, index) => index === step_index);
    }
  }
}
