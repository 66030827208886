<app-header header_title="Student Attendances">
    <app-button (click)="open_attandence.open()" color="bg-accent-300 text-accent-on" icon="bx bx-plus" label="ADD ATTENDANCE" icon_alignment="right"></app-button>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-select-input class="w-full" label="Grade" [options]="[{title: '6'},{title: '7'},{title: '8'},{title: '9'},{title: '10'}]"></app-select-input>
    </div>
    <div class="w-full flex justify-end items-center gap-3">
        <app-serch-text-input class="w-full md:w-auto" placeholder="Search"></app-serch-text-input>
        <!-- <app-filter-pill-button class="hidden md:block" label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <th class="px-4 h-12 text-left">Name</th>
                    <!-- <th class="px-4 h-12 text-left">Date</th> -->
                    <th class="px-4 h-12 text-left">Grade</th>
                    <!-- <th class="px-4 h-12 text-center">Present Students</th> -->
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{i+1}}</td>
                    <td class="px-4 h-12 text-left">{{item.name}}</td>
                    <!-- <td class="px-4 h-12 text-left">{{item.date}}</td> -->
                    <td class="px-4 h-12 text-left">{{item.grade}}</td>
                    <!-- <td class="px-4 h-12 text-center">{{item.present_students}}</td> -->
                    <td class="px-4 h-12 text-center">
                        <app-icon-cta icon="bx bx-show" (click)="view_attendance.open()" tooltip="view"></app-icon-cta>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="px-8 bg-surface-100 z-10 w-full bottom-0 py-4 lg:absolute flex flex-col-reverse sm:flex-row gap-2 items-center justify-between">
    <div>
        <p class="text-[14px] text-text-color-600 font-normal">
            Showing 1 to 5 of 50 Records
        </p>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-left'></i>
        </button>
        <span class="p-[10px] bg-primary-500 text-white rounded-lg">1</span>
        <span class="p-[10px] bg-[#E1E8F5] rounded-lg">2</span>
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-right'></i>
        </button>
    </div>
</div>
<app-confirmation-popup #batch_delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this Resource?" popup_title="Delete Resource"></app-confirmation-popup>

<app-modal #open_attandence title="Add Attendance">
    <form class="w-full">
        <div class=" bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-select-input class="md:col-span-2" title="Academic Year" icon="bx bx-list-plus" label="Academic Year" [options]="[{title: '2020-21', value: '2020-21'},{title: '2021-22', value: '2021-22'},{title: '2022-23', value: '2022-23'},{title: '2023-24', value: '2023-24'}]"></app-select-input>
                <app-select-input class="md:col-span-2" title="Grade" icon="bx bx-list-plus" label="Grade" [options]="[{title: '7th', value: '7th'},{title: '8th', value: '8th'},{title: '9th', value: '9th'},{title: '10th', value: '10th'}]"></app-select-input>

            </div>
        </div>
        <div class="flex flex-col p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Attendance Details</p>
            <div class="mt-4 relative overflow-x-auto flex flex-col gap-5">
                <div class="overflow-x-auto border border-[#7B7FDE] rounded-lg">
                    <table class=" table-auto w-full border-collapse ">
                        <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                            <tr>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Sr No</th>
                                <th scope="col" class="px-4 h-12 py-1 text-center">Student</th>
                                <th scope="col" class="px-4 h-12 py-1 text-center">
                                    <div>
                                        <p class="text-center">Attendance</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px] ">
                            <tr *ngFor="let item of students_fields let i = index" class="bg-white border-b hover:bg-gray-50">
                                <td class="px-4 h-12 py-1 text-center">{{i+1}}</td>
                                <td class="px-4 h-12 py-1 text-center"><input [disabled]="isTableDisabled || i !== newRowIndex" class="bg-white border border-slate-300 cursor-pointer px-2 py-1 rounded-md w-40" type="text" value="{{item.student}}"></td>
                                <td class="px-4 h-12 py-1 text-center">
                                    <div class="flex gap-4 justify-center items-center">
                                        <app-checkbox-input></app-checkbox-input>
                                    </div>
                                </td>
                                <td class="px-4 h-12 py-1 text-center">
                                    <button *ngIf="!isTableDisabled && i === newRowIndex" class="text-red-600 hover:text-red-900" (click)="delete_student(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.32-9.47l2.83-2.83a1 1 0 011.414 1.414L10.414 10l2.828 2.828a1 1 0 01-1.414 1.414L10 11.414l-2.828 2.829a1 1 0 01-1.414-1.414L8.586 10 5.758 7.172a1 1 0 011.414-1.414L10 8.586l2.828-2.83a1 1 0 011.414 1.414L11.414 10z" clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <app-button class="border-none" icon="bx bx-plus-circle" label="Add Student" size="sm" (click)="add_student()"></app-button>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="open_attandence.close()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button (click)="open_attandence.close()" class="self-center" color="bg-[#3F41D1] text-white" label="Submit"></app-button>
    </div>
</app-modal>

<app-modal #view_attendance title="Attendance">
    <div class="flex p-4 gap-2">
        <div class="w-1/3 h-full p-2 rounded-lg flex flex-col gap-2 items-center">
            <div class="h-36 w-36 bg-gray-400 border rounded-full over flex justify-center items-center overflow-hidden">
                <img class="h-full w-full object-cover" src="https://conferenceoeh.com/wp-content/uploads/profile-pic-dummy.png" alt="user">
            </div>
            <p class="h5">Rudra Belwalkar</p>
            <div class="grid w-full grid-cols-1 gap-2">
                <div class="col-span-1">
                    <p class="subtitle-1">Grade</p>
                    <p class="subtitle-2">6th</p>
                </div>
                <div class="col-span-1">
                    <p class="subtitle-1">Email</p>
                    <p class="subtitle-2">rudra.belwalkar&#64;gmail.com</p>
                </div>
                <div class="col-span-1">
                    <p class="subtitle-1">Phone</p>
                    <p class="subtitle-2">9652365214</p>
                </div>
            </div>
        </div>
        <div class="w-2/3 h-full shadow-md p-2 rounded-lg bg-white rounded-t">
            <div class="px-4 flex items-center justify-between">
                <span tabindex="0" class="focus:outline-none  text-base font-bold text-gray-800">October
                    2020</span>
                <div class="flex items-center">
                    <button aria-label="calendar backward" class="focus:text-gray-400 hover:text-gray-400 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                    </button>
                    <button aria-label="calendar forward" class="focus:text-gray-400 hover:text-gray-400 ml-3 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler  icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    </button>

                </div>
            </div>
            <div class="flex items-center justify-between pt-12 overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Mo
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Tu
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">We
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Th
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Fr
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Sa
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Su
                                    </p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        1</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        2</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        3</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        4</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        5</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        6</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        7</p>
                                </div>
                            </td>
                            <td>
                                <div class="w-full h-full">
                                    <div class="flex items-center justify-center w-full rounded-full cursor-pointer">
                                        <a role="link" tabindex="0" class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">8</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        9</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        10</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        11</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        12</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        13</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        14</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        15</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        16</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        17</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        18</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        19</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        20</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        21</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        22</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        23</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        24</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        25</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        26</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        27</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        28</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        29</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        30</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-modal>