import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class InstructorUserService {


  list: any = {}
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IkFydW4gR291bmRlciIsImVtYWlsIjoiZ291bmRlcmFydW4yODEyQGdtYWlsLmNvbSIsInBob25lIjoiOTk2NTIzNjUyMiIsInJvbGUiOiJTdXBlciBBZG1pbiIsImNyZWF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDItMDZUMTI6MjE6MzguMzYzWiIsImlhdCI6MTcwNzI4Mjk1Mn0.sFqW995QzFMRhU40hha6sF39iqGmbIt24csLbBDvlyk'

  constructor(public hs: HttpService, public gs: GlobalService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/user/add-instructor', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/user/delete-instructor?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/user/get-instructor?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  async get_list(filters: any) {
    try {
      let url;
      if (this.gs.user?.role == 'Instructor') {
        url = `/user/get-instructor-list?school_id=${this.gs.selected_school.school_id || 0}`
      } else {
        url = `/user/get-instructor-list`
      }
      console.log(filters)
      let response = await this.hs.get(url, filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/user/update-instructor?id=' + id, {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
}
