import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { GlobalService } from '../../services/global.service';
import * as XLSX from 'xlsx';
import { FilterDrawerComponent } from '../../components/filter-drawer/filter-drawer.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { FileInputComponent } from '../../components/file-input/file-input.component';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { StudentService } from '../../services/student.service';


import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { SchoolService } from '../../services/school.service';
import { GradeService } from '../../services/grade.service';
import { AcademicYearService } from '../../services/academic-year.service';
import { Subject, debounceTime } from 'rxjs';
import { ZoroFileInputComponent } from '../../components/inputs/zoro-file-input/zoro-file-input.component';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-student-list-page',
  standalone: true,
  imports: [NgxUiLoaderModule, ZoroFileInputComponent, VenueAuditViewComponent, NgClass, FilterDrawerComponent, HeaderComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, SelectInputComponent, FileInputComponent, ReactiveFormsModule, PaginationComponent],
  templateUrl: './student-list-page.component.html',
})
export class StudentListPageComponent {
  @ViewChild('open_popup') open_popup: any
  @ViewChild('add_student_model') add_student_model: any
  @ViewChild('update_student_model') update_student_model: any
  @ViewChild('students_delete') students_delete: any
  @ViewChild('add_student_button') add_student_button: any
  @ViewChild('update_student_button') update_student_button: any
  debounce_timeout: any = undefined;
  list: any = {};
  params: any = {};
  student_delete: any = {};
  dropdown_list: any = {}
  errorMessage: any;
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  is_delete_popup_visible: boolean = false;
  is_edit_popup_visible: boolean = false;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  student_file_upload: boolean = true
  result: any = [];
  form: any;
  mistake_modal: boolean = false
  is_filter_open: boolean = false;
  selected_item = ''
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  selected_student: any = {}
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;
  open_button: boolean = false
  parsed_data: any[] = []

  student_form = this.fb.group({
    name: ['', [Validators.required]],
    image: ['', [Validators.required]],
    school_id: [null, [Validators.required]],
    grade_id: [null, [Validators.required]],
    academic_year_id: [null, [Validators.required]],
    father_name: ['', [Validators.required]],
    mother_name: ['', [Validators.required]],
    contact_number: ['', [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$'), Validators.minLength(10)]],
    email: ['', [Validators.required]],
  });


  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public gs: GlobalService, public shls: SchoolService, public grds: GradeService, public as: AcademicYearService, public fb: FormBuilder, public ss: StudentService, public ar: ActivatedRoute, public toastr: ToastrService) {
    // this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
    //   this.params.search = searchText;
    //   this.get_student_list(this.params);
    // });
  }


  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_student_list(this.params);
    })

    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_student_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_student_list(this.params);
      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_student_list(this.params);
  }
  async get_student_list(params: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ss.get_list(this.params)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, " nnk");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching Country list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_student(id: any) {
    console.log(id, "  jkn");
    try {
      let data = await this.ss.get(id)
      this.selected_student = data.data
      this.student_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching Country:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-Student-loader');
    try {
      let response = await this.ss.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_student_list(this.params);
      this.toastr.success(response.message || 'Student Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-Student-loader');
      this.students_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-Student-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }
  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');
      this.searchInput.nativeElement.value = '';
    }
  }

  async get_dropdown_list() {
    try {
      const school_response = await this.shls.get_list({})
      const grade_response = await this.grds.get_list({})
      const academic_year_response = await this.as.get_list({})
      console.log(school_response.data, grade_response.data, academic_year_response.data, "RESPONSE");
      let school_options = school_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let grade_options = grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let academic_year_options = academic_year_response.data.map((item: any) => {
        return {
          title: item.year || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_options
      this.dropdown_list.grade_list = grade_options
      this.dropdown_list.academic_year_list = academic_year_options
      console.log(school_options, grade_options, academic_year_options, "qqqqqqqqqqqq");
      console.log(this.dropdown_list.academic_year_list, "lolippppppp");


    } catch (error: any) {
      console.error("Error fetching Dropdown list:", error);
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.student_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        console.log(key);

        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.student_file_upload = true
        return;
      }
    }
    this.student_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }

  async add_level_bulk() {
    try {
      let new_csv = this.parsed_data.map((item: any) => {
        const academicYear = this.dropdown_list.academic_year_list.find((ay: any) => ay.title === item['Academic Year']);
        const school = this.dropdown_list.school_list.find((s: any) => s.title === item['School Name']);
        const grade = this.dropdown_list.grade_list.find((g: any) => g.title === item['Grade']);

        if (!academicYear) throw ({ message: `Academic Year "${item['Academic Year']}" not found in Master` });
        if (!school) throw ({ message: `School Name "${item['School Name']}" not found in Master` });
        if (!grade) throw ({ message: `Grade "${item['Grade']}" not found in Master` });

        return {
          name: item['Student Name'],
          school_id: school.value,
          grade_id: grade.value,
          academic_year_id: academicYear.value,
          father_name: item['Father Name'],
          mother_name: item['Mother Name'],
          contact_number: item['Contact Name'],
          email: item['Email'],
          gender: item['Gender'],
          dob: this.convert_date_format(item['DOB']),
          guardian_name: item['Guardian Name'],
          adhar_number: item['Adhar Number'],
          name_as_per_adhar: item['Name As Per Adhar'],
          address: item['Address'],
          pincode: item['Pincode'],
          alternate_contact_number: item['Alternate Contact Number'],
          mother_tongue_of_student: item['Mother Tongue of Student'],
          blood_group: item['Blood Group'],
          social_category: item['Social Category'],
          minority_group: item['Minority Group'],
          is_bpl_beneficiary: item['Is Bpl Beneficiary'],
          is_antyodaya_anna_yojana: item['Is Antyodaya Anna Yojana'],
          is_belongs_to_ews_or_disadvantaged_group: item['Is Belongs To Ews Or Disadvantaged Group'],
          is_cwsn: item['Is CWSN'],
          type_of_impairment: item['Type Of Impairment'],
          disability_percentage: item['Disability Percentage'],
          is_indian: item['Is Indian'],
          is_this_student_is_out_of_school: item['Is This Student Is Out Of School'],
          when_the_student_is_mainstreamed: item['When The Student Is Mainstreamed'],
        };
      });
      const response: any = await this.ss.add(new_csv);
      this.get_student_list(this.params)
      this.toastr.success(response.message || 'Student Added Successfully!')
      this.open_bulk_modal.close();
      this.student_file_upload = true;
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.student_file_upload = true;
      this.toastr.error(error?.error?.message || error.message)
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/student_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'student_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  convert_date_format(date_str: string): string {
    if (/^\d{2}-\d{2}-\d{4}$/.test(date_str)) {
      const [day, month, year] = date_str.split('-');
      return `${year}-${month}-${day}`;
    }
    return date_str;
  }
}

