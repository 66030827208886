import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileType',
  standalone: true
})
export class FileTypePipe implements PipeTransform {

  private fileTypeIcons: { [key: string]: { icon: string, color: string } } = {
    'jpg': { icon: 'bxs-image', color: 'text-blue-500' },
    'jpeg': { icon: 'bxs-image', color: 'text-blue-500' },
    'png': { icon: 'bxs-image', color: 'text-blue-500' },
    'svg': { icon: 'bxs-image', color: 'text-blue-500' },
    'gif': { icon: 'bxs-image', color: 'text-blue-500' },
    'pdf': { icon: 'bxs-file-pdf', color: 'text-red-500' },
    'doc': { icon: 'bxs-file-word', color: 'text-blue-600' },
    'docx': { icon: 'bxs-file-word', color: 'text-blue-600' },
    'xls': { icon: 'bxs-file', color: 'text-green-600' },
    'xlsx': { icon: 'bxs-file', color: 'text-green-600' },
    'ppt': { icon: 'bxs-file-powerpoint', color: 'text-orange-600' },
    'pptx': { icon: 'bxs-file-powerpoint', color: 'text-orange-600' },
    'mp4': { icon: 'bx-play-circle', color: 'text-black' },
    'mov': { icon: 'bx-play-circle', color: 'text-black' },
    'avi': { icon: 'bx-play-circle', color: 'text-black' },
    'wmv': { icon: 'bx-play-circle', color: 'text-black' },
    'mp3': { icon: 'bx-music', color: 'text-blue-500' },
    'wav': { icon: 'bx-music', color: 'text-blue-500' },
    'ogg': { icon: 'bx-music', color: 'text-blue-500' },
    'zip': { icon: 'bxs-file-archive', color: 'text-yellow-600' },
    'rar': { icon: 'bxs-file-archive', color: 'text-yellow-600' },
    'txt': { icon: 'bxs-file-text', color: 'text-gray-600' },
    'html': { icon: 'bxs-file-code', color: 'text-orange-500' },
    'css': { icon: 'bxs-file-code', color: 'text-blue-500' },
    'js': { icon: 'bxs-file-code', color: 'text-yellow-500' },
    'json': { icon: 'bxs-file-code', color: 'text-green-500' },
    'xml': { icon: 'bxs-file-code', color: 'text-green-500' }
  };

  transform(value: string, type: 'icon' | 'color'): string {
    const ext = value.split('.').pop()?.toLowerCase();
    if (!ext) return '';

    const fileType = this.fileTypeIcons[ext] || { icon: 'bxs-file', color: 'text-gray-500' };
    return type === 'icon' ? fileType.icon : fileType.color;
  }

}
