import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [],
  templateUrl: './drawer.component.html',
})
export class DrawerComponent {
  @Input() is_open: boolean = false;
  @Input() drawer_title: string = ''
  @Output() closed = new EventEmitter()

  toggle_drawer() {
    this.is_open = !this.is_open;
  }

  close_drawer() {
    this.is_open = false;
    this.closed.emit();
  }
}
