import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-study-material-page',
  standalone: true,
  imports: [NgFor],
  templateUrl: './study-material-page.component.html',
})
export class StudyMaterialPageComponent {
  @Input() lable:any = ''
  images:any=[
    {image:'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'},
    {image:'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'},
    {image:'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'},
    {image:'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'},
  ]
  
  videos:any=[
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
  ]
}
