import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { ModalComponent } from '../modal/modal.component';
import { TimeInputComponent } from '../inputs/time-input/time-input.component';
import { SelectInputComponent } from '../inputs/select-input/select-input.component';

@Component({
  selector: 'app-checkin-checkout',
  standalone: true,
  imports: [ButtonComponent,ModalComponent,TimeInputComponent,SelectInputComponent],
  templateUrl: './checkin-checkout.component.html',
})
export class CheckinCheckoutComponent {
  @ViewChild('open_attandence') open_attandence:any;
  close(){
    this.open_attandence.close()
  }
  open(){
    this.open_attandence.open()
  }
}
