import { Component, Input, OnChanges } from '@angular/core';
import { ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './line-chart.component.html',
})
export class LineChartComponent implements OnChanges {
  @Input() labels: string[] = [];
  @Input() data_array: any[] = [];

  public lineChartType: ChartType = 'line';
  public lineChartData: Array<any> = [{ data: [], label: '' }];
  public lineChartLabels: Array<any> = [];

  ngOnChanges() {
    this.lineChartData = this.data_array;
    this.lineChartLabels = this.labels;
  }

  public lineChartOptions: any = {
    responsive: true,
  };
  public lineChartLegend = true;
}
