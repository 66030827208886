<app-header header_title="Add Topic">
    <app-button color="bg-white text-[#666666] border-gray-300 border" [label]="'Cancel'"
        routerLink="/dashboard/topics"  ></app-button>
    <app-button color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" routerLink="/dashboard/topics"
         ></app-button>
</app-header>
<form class="px-8 w-full">
    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">Basic Information</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new Cource
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 mt-4">
            <app-select-input  label="Course"
                [options]="[{title: 'Science'},{title: 'Mathematics'},{title: 'Marathi'},{title: 'Hindi'}]"></app-select-input>
            <app-text-input label="Topic"></app-text-input>
        </div>
    </div>
    <div class="bg-white flex flex-col md:mt-6 p-4 rounded-lg">
        <p class="font-medium text-base text-[#212121]">Study Material</p>
        <p class="text-[#666666] text-sm mt-2">
            Please select your Cource from the dropdown provided under the 'Material Type' section. Upon
            selection,
            a form will open where you can fill in the related information.
        </p>
        <ng-container *ngFor="let field of study_materail_field">
            <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
                <i (click)="delete_study_material()" class="absolute -top-4 -right-4 bg-transparent hover:bg-gray-100 flex items-center justify-center w-10 h-10 bx bx-x-circle rounded-full cursor-pointer hover:text-gray-700 text-2xl text-gray-500"></i>
                <div class="gap-4 grid grid-cols-1 md:grid-cols-3">
                    <app-select-input label="Material Type" [(ngModel)]="selected_material" [ngModelOptions]="{ standalone: true }" [options]="material_Types"></app-select-input>
                </div>
                <div class="flex flex-col mt-2" *ngIf="selected_material === 'Upload'">
                    <p class="font-medium text-sm text-[#212121]">Basic</p>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
                        <app-text-input icon="bx bxs-rename" label="Material Name" title="Material Name"></app-text-input>
                        <app-text-input icon="bx bx-dumbbell" label="Material Data" title="Material Data"></app-text-input>
                        <app-text-input icon="bx bx-message-square-detail" label="Material Language" title="Material Language"></app-text-input>
                        <app-select-input  label="Grade"[options]="[{title: 'Yes'},{title: 'No'}]"></app-select-input>
                    </div>
                </div>
                <div class="flex flex-col mt-2" *ngIf="selected_material === 'File'">
                    <p class="font-medium text-sm text-[#212121]">Basic</p>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
                        <app-text-input icon="bx bxs-rename" label="Material Name" title="Material Name"></app-text-input>
                        <app-text-input icon="bx bx-dumbbell" label="Material Data" title="Material Data"></app-text-input>
                        <app-text-input icon="bx bx-message-square-detail" label="Material Language" title="Material Language"></app-text-input>
                        <app-select-input  label="Grade"[options]="[{title: 'Yes'},{title: 'No'}]"></app-select-input>
                    </div>
                </div>
                <div class="flex flex-col mt-2" *ngIf="selected_material === 'Link'">
                    <p class="font-medium text-sm text-[#212121]">Basic</p>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
                        <app-text-input icon="bx bxs-rename" label="Material Name" title="Material Name"></app-text-input>
                        <app-text-input icon="bx bx-dumbbell" label="Material Data" title="Material Data"></app-text-input>
                        <app-text-input icon="bx bx-message-square-detail" label="Material Language" title="Material Language"></app-text-input>
                        <app-select-input  label="Grade"[options]="[{title: 'Yes'},{title: 'No'}]"></app-select-input>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <app-button label="Add Material Type" class="flex justify-end mt-2" color="text-white bg-primary-500" (click)="add_study_material()"></app-button>

</form>