<app-header header_title="BRC Assignments">
    <app-button color="bg-accent-300 text-accent-on" icon="bx bx-plus" label="Add BRC Assignment" icon_alignment="right"
        (click)="open_brc_form.open()"></app-button>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-end mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-end gap-3 md:w-96">
        <app-select-input class="w-full" label="Block" [options]="dropdown_list.block_response"
            (change)="block_show_filter($event)"></app-select-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <th class="px-4 h-12 text-left">Block</th>
                    <th class="px-4 h-12 text-left">Academic Year</th>
                    <th class="px-4 h-12 text-left">Assigned BRC</th>
                    <th class="px-4 h-12 text-center">Status</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list?.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item?.block_name}}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.academic_year_name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.brc_user_name}}</td>
                    <!-- <td class="px-4 h-12 text-left truncate capitalize">{{item?.status}}</td> -->
                    <td class="px-4 h-12 text-center">
                        <span *ngIf="item.is_active"
                            class="bg-green-100 text-green-800 text-xs font-medium cursor-pointer border border-gray-400 px-4 py-2 rounded-full">Active</span>
                        <span *ngIf="!item.is_active"
                            class="bg-red-100 text-red-800 text-xs font-medium cursor-pointer border border-gray-400 px-4 py-2 rounded-full">Inactive</span>
                    </td>
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta (click)="update_brc_form.open(); get_brc(item.id)" icon="bx bx-edit-alt"
                                tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="brc_delete.open(); selected_brc = item" icon="bx bx-trash"
                                tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems"
        [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>
<app-confirmation-popup #brc_delete confirmation_message="Are you sure you want to Delete this BRC Assignment"
    popup_title="Delete BRC Assignment">
    <app-button label="Delete" (click)="delete_confirm(selected_brc.id)" color="bg-[#3F41D1] text-white"
        class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="delete-brc-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>


<app-modal #open_brc_form title="Add BRC Assignment" (close_modal_clicked)="brc_form.reset()">
    <form [formGroup]="brc_form" class="w-full">
        <div class="bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-select-input formControlName="academic_year_id" class="md:col-span-3" label="Academic Year"
                    [options]="dropdown_list.academic_year_id_response"></app-select-input>
                <app-select-input formControlName="block_id" class="md:col-span-3" label="Block"
                    [options]="dropdown_list.block_response"></app-select-input>
                    <app-select-input formControlName="is_active" class="md:col-span-3" label="Status"
                    [options]="[{title: 'Active', value: 'true' },{title: 'Inactive', value: 'false' }]"></app-select-input>
                <app-select-input formControlName="brc_user_id" class="md:col-span-3" label="Assign BRC"
                    [options]="dropdown_list.brc_user_id_response"></app-select-input>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="open_brc_form.close(); brc_form.reset()" class="self-center"
            color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button #add_brc_assignment_button (click)="add()" class="self-center relative"
            color="bg-[#3F41D1] text-white" label="Submit">
            <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
                class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
                fgsColor="#FFF" loaderId="add-brc-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle"
                [fgsSize]="25"></ngx-ui-loader>
        </app-button>
    </div>
</app-modal>
<app-modal #update_brc_form title="Update BRC Assignment " (close_modal_clicked)="brc_form.reset()">
    <form [formGroup]="brc_form" class="w-full">
        <div class="bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-select-input formControlName="academic_year_id" class="md:col-span-3" label="Academic Year"
                    [options]="dropdown_list.academic_year_id_response"></app-select-input>
                <app-select-input formControlName="block_id" class="md:col-span-3" label="Block"
                    [options]="dropdown_list.block_response"></app-select-input>
                <app-select-input formControlName="is_active" class="md:col-span-3" label="Status"
                    [options]="[{title: 'Active', value: 'true' },{title: 'Inactive', value: 'false' }]"></app-select-input>
                <app-select-input formControlName="brc_user_id" class="md:col-span-3" label="Assign BRC"
                    [options]="dropdown_list.brc_user_id_response"></app-select-input>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="update_brc_form.close();brc_form.reset()" class="self-center"
            color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button #update_brc_assignment_button (click)="edit(selected_brc.id)" class="self-center relative"
            color="bg-[#3F41D1] text-white" label="Update">
            <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
                class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
                fgsColor="#FFF" loaderId="update-brc-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle"
                [fgsSize]="25"></ngx-ui-loader>
        </app-button>
    </div>
</app-modal>