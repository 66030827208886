<app-modal #modal_open title="Venue Details">
    <div class="p-2 md:p-5 grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Venue Name</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.name}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Nature of Venue</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">MCC</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">GST</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">4566d1dDDND5626</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Aadhar</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">2563 5485 6523 5688</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Pareking Facility</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">CCTV Coverage</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Network Details</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Network.jpg</p>
        </div>
    </div>
    <div class="p-2 md:p-5 grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Venue Name</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.name}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Nature of Venue</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">MCC</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">GST</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">4566d1dDDND5626</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Aadhar</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">2563 5485 6523 5688</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Pareking Facility</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">CCTV Coverage</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Network Details</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Network.jpg</p>
        </div>
    </div>
    <div class="p-2 md:p-5 grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Venue Name</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.name}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Nature of Venue</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">MCC</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">GST</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">4566d1dDDND5626</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Aadhar</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">2563 5485 6523 5688</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Pareking Facility</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">CCTV Coverage</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Network Details</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Network.jpg</p>
        </div>
    </div>
    <div class="p-2 md:p-5 grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Venue Name</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.name}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Nature of Venue</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">MCC</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Primary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Contact Number</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">8563254125</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Secondary Email Id</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">{{venue_detail?.email_id}}</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">GST</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">4566d1dDDND5626</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Aadhar</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">2563 5485 6523 5688</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Pareking Facility</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">CCTV Coverage</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Yes</p>
        </div>
        <div class="col-span-1">
            <p class="block mb-2 text-xs md:text-sm font-semibold text-gray-900">Network Details</p>
            <p class=" text-gray-900 text-xs md:text-md w-full">Network.jpg</p>
        </div>
    </div>
</app-modal>