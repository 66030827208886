<div class="bg-white flex flex-col md:mt-5 p-4 rounded-lg">
    <div class="flex flex-col gap-4">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-8">
           <div *ngFor="let img of images,let i=index" class="p-3 gap-3 col-span-2 md:col-span-4 xl:col-span-2 w-full h-auto bg-gray-100 flex justify-start items-center rounded-md">
            <img (click)="previewImages=i" class="h-20 min-w-20 cursor-pointer rounded-md" [src]="img.image" alt="">
            <div class="flex justify-between w-full items-center">
                <div>
                    <p class=" text-sm md:text-md text-gray-700 cursor-pointer">{{img.title}}</p>
                    <div class="flex gap-1 items-center text-gray-400 hover:text-blue-600 cursor-pointer">
                        <p *ngIf="img.type == 'video'" (click)="open_image.open()" (click)="previewImages=i"  class="text-xs md:text-sm cursor-pointer flex items-center gap-1"><span>View Image</span><i class='bx bx-image mt-1'></i></p>
                        <p *ngIf="img.type == 'file'" class="text-xs md:text-sm cursor-pointer flex items-center gap-1"><span>Download File</span><i class='bx bx-download mt-1'></i></p>
                        <p *ngIf="img.type == 'image'" (click)="open_video.open()" (click)="previewImages=i"  class="text-xs md:text-sm cursor-pointer flex items-center gap-1"><span>View Video</span><i class='bx bx-play-circle mt-1'></i></p>
                    </div>
                </div>
                <div class="dropdown">
                <i *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Instructor'" tabindex="0" class='bx bx-dots-vertical-rounded text-xl px-2 py-1 transition-all duration-300 hover:bg-slate-200 rounded-full cursor-pointer'></i>     
                        <ul class="dropdown-content absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
                            <li>
                                <p (click)="edit.open()" class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" >Edit</p>
                            </li>
                            <li>
                                <p (click)="delete.open()" class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer">Delete</p>
                            </li>
                        </ul>
                    </div>
            </div>
           </div>
           <div *ngIf="gs.user.role == 'Super Admin' || gs.user.role == 'Instructor'" class="p-3 gap-3 col-span-2 md:col-span-4 xl:col-span-2 w-full h-auto bg-gray-100 flex justify-start items-center rounded-md">
           <i class="bx bx-book-add p-5 rounded-md bg-white text-gray-700 cursor-pointer text-3xl"></i>
            <div class="flex justify-between w-full items-center">
                <div>
                    <p class="text-sm md:text-md text-gray-700 cursor-pointer">Upload</p>
                    <div class="flex gap-1 items-center text-gray-400 hover:text-blue-600 cursor-pointer">
                        <p class="cursor-pointer flex items-center gap-1 text-xs md:text-sm"><span>File/Video/Image</span><i class='bx bx-plus-circle mt-1'></i></p>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>

<app-confirmation-popup #edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this {{option}}?" popup_title="Edit {{option}}"></app-confirmation-popup>
<app-confirmation-popup #delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this {{option}}?" popup_title="Delete {{option}}"></app-confirmation-popup>

<app-modal #open_image title="Image">
    <img class="rounded-2xl" src="https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg" alt="Study Image">
</app-modal>
<app-modal #open_video title="Video">
    <iframe class="rounded-2xl md:h-96 w-full" src="https://www.youtube.com/embed/UgwaO8JkXYU?si=BdLDjSRDoQWrHpud" frameborder="0" allowfullscreen></iframe>
</app-modal>