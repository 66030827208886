import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-date-input',
  standalone: true,
  imports: [CommonModule, NgIf, FormsModule],
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    }
  ]
})
export class DateInputComponent implements ControlValueAccessor{
  @Input() label = '';
  @Input() is_required: boolean = false;
  @Input() placeholder = '';
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() rounded: boolean = false;
  @Input() minDateInput: string | null = null;  // Minimum date input
  @Input() maxDateInput: string | null = null;  // Maximum date input
  @Output() dateSelected = new EventEmitter<string>();

  paramValue: any;
  control: any;
  value = '';
  onChange: any;
  onTouched: any;

  constructor(private injector: Injector, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paramValue = params['view'];
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl | null = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }
    }, 100);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;    
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  onDateChange(event: any) {
    const selectedDate = event.target.value;
    this.dateSelected.emit(selectedDate);
  }
}