import { Component } from '@angular/core';
import { IconCtaComponent } from '../icon-cta/icon-cta.component';
import { TextInputComponent } from '../inputs/text-input/text-input.component';

@Component({
  selector: 'app-venue-details-view',
  standalone: true,
  imports: [IconCtaComponent, TextInputComponent],
  templateUrl: './venue-details-view.component.html'
})
export class VenueDetailsViewComponent {

}
