<app-header
    [header_title]="gs.user.role == 'Super Admin' ? (params?.id? (params?.view ? 'View Course': 'Update Course') : 'Add Course') : (params?.view ? 'View Course': 'Course')">
    <app-button *ngIf="gs.user.role == 'Super Admin'" color="bg-white text-[#666666] border-gray-300 border"
        [label]="'Cancel'" routerLink="/master/course"></app-button>
    <app-button *ngIf="gs.user.role == 'Super Admin' && !params?.id" color="bg-[#E697AB] text-[#310000]"
        [label]="'Submit'" #add_course_button (click)="add()"></app-button>
    <app-button *ngIf="gs.user.role == 'Super Admin' && params?.id && !params?.view" color="bg-[#E697AB] text-[#310000]"
        [label]="'Update'" #update_course_button (click)="update(params?.id)"></app-button>
</app-header>
<form [formGroup]="courseForm" class="w-full px-8">
    <div class="flex flex-col w-full p-4 overflow-hidden bg-white rounded shadow ">
        <p class="font-normal text-base text-[#212121]">Basic Information</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new Course
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-4">
            <app-text-input formControlName="course_code" [disabled]="view == 'true'"
                label="Course Code"></app-text-input>
            <app-text-input formControlName="name" [disabled]="view == 'true'" label="Course Name"></app-text-input>
            <app-text-input formControlName="duration" [disabled]="view == 'true'"
                label="Course Duration"></app-text-input>
            <app-text-input formControlName="description" [disabled]="view == 'true'"
                label="Course Description"></app-text-input>
            <app-select-input formControlName="grade_id" [disabled]="view == 'tue'" label="Grade"
                [options]="dropdown_list.grade_list"></app-select-input>
            <app-select-input formControlName="subject" [disabled]="view == 'tue'" label="Subject"
                [options]="dropdown_list.subject_list"></app-select-input>
            <app-select-input formControlName="language" [disabled]="view == 'tue'" label="Medium of Instruction"
                [options]="[{title: 'English',value: 'English'},{title: 'Hindi',value: 'Hindi'}, {title: 'Marathi',value: 'Marathi'}]"></app-select-input>
        </div>
    </div>
    <div *ngIf="!params?.view" class="flex flex-col w-full p-4 mt-6 overflow-hidden bg-white rounded shadow ">
        <p class="font-normal text-base text-[#212121]">Course Level Configuration</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new Course
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-3" [formGroup]="courseForm">
            <app-select-input label="Number of Levels" formControlName="number_of_levels"
                [options]="[{title: '0',value: 0},{title: '1',value: 1},{title: '2',value: 2}]"></app-select-input>
            <app-text-input *ngIf="courseForm.value.number_of_levels >= 1" label="Level 1 Name"
                formControlName="level1_name"></app-text-input>
            <app-text-input *ngIf="courseForm.value.number_of_levels == 2" label="Level 2 Name"
                formControlName="level2_name"></app-text-input>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-2">
        <!-- Hello {{courseForm.value.name}} {{view}} -->
        <app-course-tree [title_of_tree]="courseForm.value.name" class="col-span-4 mt-6"
            *ngIf="courseForm.value.number_of_levels> 0 && !view" [treeData]="courseForm.value.levels"
            (selected)="level_selected($event)" [total_levels]="courseForm.get('number_of_levels').value"
            [buttons_visible]="!params?.view" (add_level)="add_level($event)" (remove_level)="remove_level_2($event)"
            [level]="courseForm.value.number_of_levels"></app-course-tree>
        <div *ngIf="!view"
            [ngClass]="{'col-span-12': courseForm.value.number_of_levels == 0, 'col-span-8' : courseForm.value.number_of_levels != 0}"
            class="p-4 bg-white rounded-lg md:my-6">
            <ng-container *ngIf="selected_level_array.length">
                <p class="font-medium text-base text-[#212121]">Level Details</p>

                <div class="grid grid-cols-1 gap-4 mt-4 mb-6 md:grid-cols-3" [formGroup]="courseForm">
                    <app-text-input label="Level Name"
                        [formControl]="courseForm | getLevelNameControl : selected_level_array"></app-text-input>
                </div>
            </ng-container>

            <p class="font-medium text-base text-[#212121]">Study Material for
                <span *ngIf="selected_level_array.length"> {{courseForm.get('level1_name').value}} <span
                        *ngFor="let old of selected_level_array;let l=last">{{old+1}}{{!l? '.' : ''}}</span> </span>
                <span *ngIf="!selected_level_array.length"> Course </span>
            </p>
            <p class="text-[#666666] text-sm mt-2">
                Please select your course from the dropdown provided under the 'Material Type' section. Upon
                selection,
                a form will open where you can fill in the related information.
            </p>
            <ng-container
                *ngFor="let field of (courseForm | getStudyMaterialControl : selected_level_array).controls;let i=index"
                [formGroup]="(courseForm | getStudyMaterialControl : selected_level_array)">
                <div class="relative p-4 mt-4 border rounded-lg border-slate-300" [formGroupName]="i"
                    *ngIf="!params?.view">
                    <i (click)="remove_study_material_2(selected_level_array,i)"
                        class="absolute flex items-center justify-center w-10 h-10 text-2xl text-gray-500 bg-transparent rounded-full cursor-pointer -top-4 -right-4 hover:bg-gray-100 bx bx-x-circle hover:text-gray-700"></i>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                    </div>
                    <div class="flex flex-col">
                        <div class="grid grid-cols-1 gap-4 md:grid-cols-5">
                            <app-select-input #selected_material_type label="Material Type"
                                formControlName="material_type_id"
                                [options]="dropdown_list.material_type_list"></app-select-input>
                            <app-text-input formControlName="name" icon="bx bxs-rename" label="Material Name"
                                title="Material Name"></app-text-input>
                            <app-text-input formControlName="value"
                                *ngIf="(dropdown_list.material_type_list | titleByValue:selected_material_type.value:'material_category')=='Link'"
                                icon="bx bx-dumbbell" label="URL" title="URL"></app-text-input>
                            <app-select-input formControlName="language" icon="bx bx-message-square-detail"
                                [options]="[{title: 'English'},{title: 'Hindi'},{title: 'Marathi'}]"
                                label="Material Language" title="Material Language"></app-select-input>
                            <app-select-input formControlName="visibility" label="Visiblity"
                                [options]="[{title: 'Instructor'},{title: 'Student'},{title: 'Both'}]"></app-select-input>
                        </div>
                        <!-- <p class="mt-4 text-base font-normal capitalize text-text-color-600"
                            *ngIf="selected_material_type.display_value === 'Text Book' || selected_material_type.display_value === 'Lesson Plan'|| selected_material_type.display_value === 'Video'">
                            {{selected_material_type.display_value}}</p> -->
                        <div class="flex flex-col gap-4 mt-2"
                            *ngIf="(dropdown_list.material_type_list | titleByValue:selected_material_type.value:'material_category')!='Link'">
                            <!-- <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg"> -->
                            <app-zoro-file-input
                                [multiple]="(dropdown_list.material_type_list | titleByValue:selected_material_type.value:'is_multiple_allow') + '' == 'true'"
                                formControlName="value" class="w-full" label="Files"
                                [supported_extension]="(dropdown_list.material_type_list | titleByValue:selected_material_type.value:'supportted_extensions') || ''"></app-zoro-file-input>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="params?.view" class="bg-white flex flex-col mt-6 p-4 rounded-lg">
                <div class="mt-4 px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
                    <div class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
                        <table class="table-auto w-full border-collapse">
                            <thead
                                class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                                <tr>
                                    <th scope="col" class="px-4 h-12 py-1 text-center">Sr No</th>
                                    <th scope="col" class="px-4 h-12 py-1 text-center">Name</th>
                                    <th scope="col" class="px-4 h-12 py-1 text-center">Language</th>
                                    <th scope="col" class="px-4 h-12 py-1 text-center">Visibility</th>
                                    <th scope="col" class="px-4 h-12 py-1 text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white border-b hover:bg-gray-50"
                                    *ngFor="let field of (courseForm | getStudyMaterialControl : selected_level_array).controls;let i=index">
                                    <td class="px-4 h-12 py-1 text-center">{{ i + 1 }}</td>
                                    <td class="px-4 h-12 py-1 text-center">{{field.value.name}} </td>
                                    <td class="px-4 h-12 py-1 text-center">{{field.value.language}}</td>
                                    <td class="px-4 h-12 py-1 text-center">{{field.value.visibility}}</td>
                                    <td class="px-4 h-12 py-1 text-center">
                                        <div class="flex gap-2 items-center justify-center">
                                            <ng-container
                                                *ngFor="let attachment_url of (field.value.value | splitString : ',')">
                                                <div
                                                    *ngIf="(dropdown_list.material_type_list | titleByValue:field.value.material_type_id:'material_category')=='File'">
                                                    <app-icon-cta (click)="open_attachment(attachment_url)"
                                                        [icon]="'bx ' + (attachment_url | fileType: 'icon')"
                                                        tooltip="File"></app-icon-cta>
                                                </div>
                                                <app-icon-cta icon="bx bx-show" tooltip="View"
                                                    (click)="goToLink(attachment_url)"></app-icon-cta>
                                                <app-icon-cta icon="bx bxs-download" tooltip="Download"
                                                    (click)="download_template(fs.base_gcs_url+attachment_url)"></app-icon-cta>
                                            </ng-container>
                                            <a *ngIf="(dropdown_list.material_type_list | titleByValue:field.value.material_type_id:'material_category')=='Link'"
                                                [href]="field.value.value" target="_blank"><app-icon-cta
                                                    icon="bx bx-link" tooltip="Link"></app-icon-cta></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-button *ngIf="!params?.view" label="Add Study Material" class="flex justify-end mt-2"
        color="text-white bg-primary-500" (click)="add_study_material_2(selected_level_array)"></app-button>
</form>

<div class="grid w-full grid-cols-12 gap-2 px-8 mb-10" *ngIf="view">
    <app-course-tree class="col-span-4 mt-10" *ngIf="courseForm.value.number_of_levels> 0"
        [treeData]="courseForm.value.levels" (selected)="level_selected($event)" [buttons_visible]="!params?.view"
        [level]="courseForm.value.number_of_levels"></app-course-tree>
</div>

<app-confirmation-popup #edit color="bg-[#3F41D1] text-white" button_title="Edit"
    confirmation_message="Are you sure you want to Edit this {{option}}?"
    popup_title="Edit {{option}}"></app-confirmation-popup>
<app-confirmation-popup #delete color="bg-[#3F41D1] text-white" button_title="Delete"
    confirmation_message="Are you sure you want to Delete this {{option}}?"
    popup_title="Delete {{option}}"></app-confirmation-popup>

<app-modal #open_media title="File" class="h-screen w-screen">
    <iframe class="w-full h-96 rounded-2xl object-contain" [src]="file_url | safeUrl" frameborder="0"
        allowfullscreen></iframe>
</app-modal>

<app-modal #open_image title="Image">
    <img class="rounded-2xl" [src]="file_url | safeUrl" alt="Study Image">
</app-modal>