import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  user: any = {}
  selected_school: any = {}
  attendnace: any = {}

  constructor(public router: Router) {
    let user_data = localStorage.getItem('user')
    let school_data = localStorage.getItem('selected_school')
    
    if (user_data) {
      this.user = JSON.parse(user_data)
    } else {
      const path = window.location.pathname;
      const token = window.location.search
      if (path.includes('/set-password')) {
        this.router.navigate([path, token])
        return
      }
      this.router.navigate(['/sign-in'])
    }
    if (school_data) {
      this.selected_school = JSON.parse(school_data)
    }
  }
}