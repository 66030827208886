import { Component } from '@angular/core';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { IconCtaComponent } from '../icon-cta/icon-cta.component';

@Component({
  selector: 'app-personal-details-view',
  standalone: true,
  imports: [TextInputComponent, IconCtaComponent],
  templateUrl: './personal-details-view.component.html'
})
export class PersonalDetailsViewComponent {

}
