import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DistrictsService } from '../../services/districts.service';
import { CountryService } from '../../services/country.service';
import { StateService } from '../../services/state.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-district-list-page',
  standalone: true,
  imports: [VenueAuditViewComponent, NgxUiLoaderModule, ReactiveFormsModule, HeaderComponent, SelectInputComponent, NgFor, ConfirmationPopupComponent, FilterPillButtonComponent, ButtonComponent, IconCtaComponent, SerchTextInputComponent, TextInputComponent, TextInputComponent, NgIf, RouterLink, VenueDetailsModalComponent, TextAreaInputComponent, DateInputComponent, CheckboxInputComponent, ModalComponent, PaginationComponent],
  templateUrl: './district-list-page.component.html',

})
export class DistrictListPageComponent {
  district_list: any = [
  ]
  open_button: boolean = false
  @ViewChild('open_bulk_modal') open_bulk_modal: any
  @ViewChild('add_district_modal') add_district_modal: any
  @ViewChild('update_district_modal') update_district_modal: any
  @ViewChild('district_delete') district_delete: any
  @ViewChild('add_district_button') add_district_button: any
  @ViewChild('update_district_button') update_district_button: any
  is_delete_popup_visible: boolean = false;
  is_edit_popup_visible: boolean = false;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  district_file_upload: boolean = true
  mistake_modal: boolean = false
  errorMessage: any;
  debounce_timeout: any = undefined;
  list: any = {};
  params: any = {};
  selected_district: any = {};
  dropdown_list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  data_bulk_upload: any = []
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  states: any = []
  filteredStates: any[] = [];
  parsed_data: any[] = []


  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public fb: FormBuilder, public ar: ActivatedRoute, public ss: StateService, public cs: CountryService, public ds: DistrictsService, public toastr: ToastrService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_district_list(this.params);
    });
  }

  district_form: any = this.fb.group({
    name: ['', [Validators.required]],
    country_id: ['', [Validators.required]],
    state_id: ['', [Validators.required]],
  })


  async ngOnInit() {

    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_district_list(this.params);
    })
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_district_list(this.params);
  }

  async add() {
    if (this.district_form.valid) {
      this.ui_spinner.startLoader('add-district-loader');
      this.add_district_button.disabled = true
      try {
        let response: any = await this.ds.add(this.district_form.value)
        this.district_form.reset();
        this.add_district_modal.close()
        this.get_district_list(this.params)
        this.ui_spinner.stopLoader('add-district-loader');
        this.add_district_button.disabled = false
        this.toastr.success(response.message || 'District Added Successfully!')
      } catch (error: any) {
        console.error(error?.error?.message, '')
        this.ui_spinner.stopLoader('add-district-loader');
        this.add_district_button.disabled = false
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.district_form.markAllAsTouched();
    }
  }
  async get_dropdown_list() {
    try {
      const country_response = await this.cs.get_list({})
      const state_response = await this.ss.get_list({})
      let country_options = country_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let state_options = state_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
          val: item.country_id || "none",
        };
      });
      this.dropdown_list.country_list = country_options
      this.dropdown_list.state_list = state_options
      this.states = state_response.data;

    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }




  async onCountryChange() {

    const state_response = await this.ss.get_list({})
    this.states = state_response.data;

    const selectedCountryId = this.district_form.get('country_id').value;
    console.log('All States:', this.states);

    this.filteredStates = this.states.filter((state: any) => state.country_id == selectedCountryId);

    this.district_form.patchValue({ state_id: '' });

    let state_options = this.filteredStates.map((item: any) => {
      return {
        title: item.name || "none",
        value: item.id || "none",
      };
    });

    this.dropdown_list.state_list = state_options

    // For debugging or checking purposes
    console.log('Selected Country ID:', selectedCountryId);
    console.log('Filtered States:', this.filteredStates);
  }



  async get_district_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ds.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching District list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_district(id: any) {
    console.log(id);
    try {
      let data = await this.ds.get(id)
      this.selected_district = data.data
      this.district_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching District:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async edit(id: number) {
    if (this.district_form.valid) {
      this.ui_spinner.startLoader('update-district-loader');
      this.update_district_button.disabled = true
      try {
        let response = await this.ds.update(id, this.district_form.value);
        this.district_form.reset();
        this.get_district_list(this.params)
        this.ui_spinner.stopLoader('update-district-loader');
        this.update_district_button.disabled = false
        this.toastr.success(' Updated Successfully!')
        this.update_district_modal.close()
      } catch (error: any) {
        console.error(error?.error?.message, '')
        this.update_district_button.disabled = false
        this.ui_spinner.stopLoader('update-district-loader');
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.district_form.markAllAsTouched();
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-district-loader');
    try {
      let response = await this.ds.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_district_list(this.params);
      this.toastr.success(response.message || 'District Deleted Successfully')
      this.ui_spinner.stopLoader('delete-district-loader');
      this.district_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-district-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_district_list(this.params);
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.district_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.district_file_upload = true
        return;
      }
    }
    this.district_file_upload = false
  }

  async add_district_bulk() {
    try {
      let new_csv = this.parsed_data.map((item: any) => {
        const country = this.dropdown_list.country_list.find((c: any) => c.title === item.country || item.Country);
        const state = this.dropdown_list.state_list.find((s: any) => s.title === item.State || item.state);
        if (!country) throw ({ message: `Country "${item.country || item.Country}" not found in Master` });
        if (!state) throw ({ message: `State "${item.State || item.state}" not found in Master` });
        return {
          name: item.district || item.District,
          country_id: country.value,
          state_id: state.value,
        };
      });
      const response: any = await this.ds.add(new_csv)
      this.get_district_list(this.params)
      this.toastr.success(response.message || 'District Added Successfully!')
      this.district_file_upload = true
      this.open_bulk_modal.close()
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.district_file_upload = true
      this.toastr.error(error?.error?.message || error.message)
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/district_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'district_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
