import { NgFor, NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '../../components/button/button.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ModalComponent } from '../../components/modal/modal.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { GlobalService } from '../../services/global.service';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';


import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { SchoolService } from '../../services/school.service';
import { AcademicYearService } from '../../services/academic-year.service';
import { GradeService } from '../../services/grade.service';
import { CourseService } from '../../services/course.service';
import { InstructorUserService } from '../../services/instructor-user.service';
import { Subject, debounceTime } from 'rxjs';
import { BlockService } from '../../services/block.service';
import { BrcUserService } from '../../services/brc-user.service';

@Component({
  selector: 'app-instructor-assignment',
  standalone: true,
  imports: [ButtonComponent, ModalComponent, NgxUiLoaderModule, SelectInputComponent, DateInputComponent, TextInputComponent, HeaderComponent, IconCtaComponent, NgCircleProgressModule, ConfirmationPopupComponent, FilterPillButtonComponent, SerchTextInputComponent, NgFor, RouterLink, NgIf, FormsModule, ReactiveFormsModule, PaginationComponent],
  templateUrl: './instructor-assignment.component.html',

})
export class InstructorAssignmentComponent {
  @ViewChild('open_instuctor_form') open_instuctor_form: any
  @ViewChild('update_instuctor_form') update_instuctor_form: any
  @ViewChild('instructor_delete') instructor_delete: any
  @ViewChild('add_instuctor_assignment_button') add_instuctor_assignment_button: any
  @ViewChild('update_instuctor_assignment_button') update_instuctor_assignment_button: any
  list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  dropdown_list: any = {}
  searchSubject: Subject<string> = new Subject<string>();


  constructor(public bs: BlockService, public brc_service: BrcUserService, public ui_spinner: NgxUiLoaderService, public cs: CourseService, public ays: AcademicYearService, public grs: GradeService, public ias: InstructorAssignmentService, public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder, public is: InstructorUserService, public toastr: ToastrService, public ss: SchoolService, public router: Router) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_instructor_list(this.params);
    });
  }
  params: any = {};
  selected_Instructor: any = {};

  instructor_form = this.fb.group({
    school_id: ["", Validators.required],
    academic_year_id: ["", Validators.required],
    is_active: ["", Validators.required],
    course_id: ["", Validators.required],
    instructor_user_id: ["", Validators.required],
    block_id: ["", Validators.required],
  })
  async ngOnInit() {

    await this.get_dropdown_list()

    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_instructor_list(this.params);
    })

    this.instructor_form.get('block_id')?.valueChanges.subscribe(async (value: any) => {
      console.log(value);
      if (value != '' && value != null) {
        const school = await this.ss.get_list({ block_id: value })
        let school_id_options = school.data.map((item: any) => {
          return {
            title: item.name || "none",
            value: item.id || "none",
          };
        });
        this.dropdown_list.school_list = school_id_options
      } else {
        const school = await this.ss.get_list({})
        let school_id_options = school.data.map((item: any) => {
          return {
            title: item.name || "none",
            value: item.id || "none",
          };
        });
        this.dropdown_list.school_list = school_id_options
        console.log(this.dropdown_list.school_list, 'kkkkk');

      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_instructor_list(this.params);
  }

  async add() {
    if (this.instructor_form.valid) {
      this.ui_spinner.startLoader('add-instructor-assignment-loader');
      this.add_instuctor_assignment_button.disabled = true
      try {
        let body = { ...this.instructor_form.value }
        delete body.block_id;
        console.log(body);
        let response: any = await this.ias.add(body)
        console.log(response, " jdjs");

        this.instructor_form.reset();
        this.get_instructor_list(this.params)
        this.ui_spinner.stopLoader('add-instructor-assignment-loader');
        this.add_instuctor_assignment_button.disabled = false
        this.toastr.success(response.message || 'Instructor Assignment Added Successfully!')
        this.open_instuctor_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-instructor-assignment-loader');
        this.add_instuctor_assignment_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.instructor_form.markAllAsTouched()
    }
  }

  async get_instructor_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ias.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching instructor list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async get_instructor(id: any) {
    console.log(id);
    try {
      let data = await this.ias.get(id)
      this.selected_Instructor = data.data
      this.instructor_form.patchValue(data.data)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching instructor:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async edit(id: number) {
    if (this.instructor_form.valid) {
      this.ui_spinner.startLoader('update-instructor-assignment-loader');
      this.update_instuctor_assignment_button.disabled = true
      try {
        let body = { ...this.instructor_form.value }
        delete body.block_id;
        console.log(body);
        let response = await this.ias.update(id, body);
        this.instructor_form.reset();
        this.get_instructor_list(this.params)
        this.ui_spinner.stopLoader('update-instructor-assignment-loader');
        this.update_instuctor_assignment_button.disabled = false
        this.toastr.success('  Updated Successfully!')
        this.update_instuctor_form.close()
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-instructor-assignment-loader');
        this.update_instuctor_assignment_button.disabled = false
        console.error(error?.error?.message, '')
        this.toastr.error(error?.error?.message)
      }
    } else {
      this.instructor_form.markAllAsTouched()
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-instructor-assignment-loader');
    try {
      let response = await this.ias.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_instructor_list(this.params);
      this.ui_spinner.stopLoader('delete-instructor-assignment-loader');
      this.toastr.success(response.message || 'Instructor Assignment Deleted Successfully!')
      this.instructor_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-instructor-assignment-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_instructor_list(this.params);
    }
  }

  async get_dropdown_list() {
    try {
      const school = await this.ss.get_list({})
      const academic_year = await this.ays.get_list({})
      // const grade = await this.grs.get_list({})
      const course = await this.cs.get_list({})
      const instructor_user = await this.is.get_list({})
      const brc_user = await this.brc_service.get_list({})
      const block = await this.bs.get_list({})


      let school_id_options = school.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });

      let academic_year_id_options = academic_year.data.map((item: any) => {
        return {
          title: item.year || "none",
          value: item.id || "none",
        };
      });
      // let grade_id_options = grade.data.map((item: any) => {
      //   return {
      //     title: item.name || "none",
      //     value: item.id || "none",
      //   };
      // });
      let course_options = course.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let instructor_user_options = instructor_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_options = brc_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let block_options = block.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_id_options
      this.dropdown_list.academic_year_list = academic_year_id_options
      // this.dropdown_list.grade_list = grade_id_options
      this.dropdown_list.course_list = course_options
      this.dropdown_list.instructor_list = [...instructor_user_options, ...brc_user_options]
      this.dropdown_list.block_list = block_options
      console.log(this.dropdown_list.instructor_list, "aaaaaaaaaaaaa");


    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  school_show_filter(event: any) {
    const selectedSchool = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedSchool) {
      this.currentPage = 1;
      queryParams.school_id = selectedSchool;
    } else {
      this.currentPage = 1;
      queryParams.school_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  course_show_filter(event: any) {
    const selectedCourse = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedCourse) {
      this.currentPage = 1;
      queryParams.course_id = selectedCourse;
    } else {
      this.currentPage = 1;
      queryParams.course_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  instructor_show_filter(event: any) {
    const selectedInstructor = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedInstructor) {
      this.currentPage = 1;
      queryParams.instructor_id = selectedInstructor;
    } else {
      this.currentPage = 1;
      queryParams.instructor_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }


}
