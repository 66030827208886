import { Component, ViewChild } from '@angular/core';
import { ButtonComponent } from '../../components/button/button.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { AssessmentReportService } from '../../services/assessment-report.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { CountryService } from '../../services/country.service';
import { Subject, debounceTime } from 'rxjs';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { CourseService } from '../../services/course.service';
import { InstructorUserService } from '../../services/instructor-user.service';
import { SchoolService } from '../../services/school.service';

@Component({
  selector: 'app-assessment-test-list-page',
  standalone: true,
  imports: [ButtonComponent, DatePipe, HeaderComponent, IconCtaComponent, ConfirmationPopupComponent, FilterPillButtonComponent, SerchTextInputComponent, NgFor, RouterLink, NgIf, PaginationComponent, NgxUiLoaderModule, SelectInputComponent],
  templateUrl: './assessment-test-list-page.component.html',
  providers: []
})
export class AssessmentTestListPageComponent {
  @ViewChild('assessment_delete') assessment_delete: any;
  list: any = {}
  show_month_Label: boolean = true || null;
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  isReportUrl: boolean = false
  params: any = {};
  form: any = {}
  selected_assessment: any = {};
  dropdown_list: any = {}
  selectedMonth: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  path = window.location.pathname;
  constructor(public gs: GlobalService, public ss: SchoolService, public cs: CountryService, public atr: AssessmentReportService, public ar: ActivatedRoute, public toastr: ToastrService, public router: Router, public ui_spinner: NgxUiLoaderService, public crs: CourseService, public is: InstructorUserService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_assessment_report_list(this.params);
    });
  }
  async ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkUrl(event.urlAfterRedirects);
      }
    });
    this.checkUrl(this.router.url);
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_assessment_report_list(this.params);
    });
    this.get_dropdown_list()
  }


  items = [
    { assessment_date: new Date() }, // Sample data
    // Add more items as needed
  ];
  isFutureDate(date: Date): boolean {
    const today = new Date();
    const assessmentDate = new Date(date);
    // Compare dates
    return assessmentDate > today;
  }

  async get_dropdown_list() {
    try {
      const course = await this.crs.get_list({})
      const instructor_user = await this.is.get_list({})
      const school_response = await this.ss.get_list({})
      let course_id_options = course.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let instructor_user_options = instructor_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let school_options = school_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.course_list = course_id_options
      this.dropdown_list.instructor_list = instructor_user_options
      this.dropdown_list.school_list = school_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  lable_month_show(event: any) {
    const selectedMonth = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedMonth) {
      this.currentPage = 1;
      queryParams.month = selectedMonth;
    } else {
      this.currentPage = 1;
      queryParams.month = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  school_show_filter(event: any) {
    const selectedUser = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedUser) {
      this.currentPage = 1;
      queryParams.school_id = selectedUser;
    } else {
      this.currentPage = 1;
      queryParams.school_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  course_show_filter(event: any) {
    const selectedCourse = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedCourse) {
      this.currentPage = 1;
      queryParams.course_id = selectedCourse;
    } else {
      this.currentPage = 1;
      queryParams.course_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  instructor_show_filter(event: any) {
    const selectedInstructor = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedInstructor) {
      this.currentPage = 1;
      queryParams.instructor_id = selectedInstructor;
    } else {
      this.currentPage = 1;
      queryParams.instructor_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_assessment_report_list(this.params);
  }

  async get_assessment_report_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.atr.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching Assessment Report list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  edit(id: number) {
    this.router.navigate(['/assessment/form'], { queryParams: { id: id } });
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-assessment-loader');
    try {
      let response = await this.atr.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_assessment_report_list(this.params);
      this.toastr.success(response.message || 'assessment Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-assessment-loader');
      this.assessment_delete.close()
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.assessment_delete.close()
      this.ui_spinner.stopLoader('delete-assessment-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_assessment_report_list(this.params);
    }
  }

  checkUrl(url: string): void {
    console.log(url);

    this.isReportUrl = url.includes('reports');
  }

}
