<app-header header_title="Student">
    <div class="dropdown">
        <app-button tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Student"></app-button>
        <ul tabindex="0" class="dropdown-content absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" routerLink="/master/student/form">Add Student</p>
            </li>
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" (click)="open_bulk_modal.open()">Add Bulk Student</p>
            </li>
        </ul>
    </div>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3">
        <app-serch-text-input #searchInput class="w-full md:w-auto" placeholder="Search Student Name" (valueChange)="onSearch($event)"></app-serch-text-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Sr No</th>
                    <th class="px-4 h-12 text-left">
                        <span class="flex items-center gap-2">
                            <span>Name</span>
                            <div class="flex flex-col">
                                <i [routerLink]="'/master/student'" [queryParams]="{sort : 'ascending'}" class='cursor-pointer text-xs bx bxs-up-arrow'></i>
                                <i [routerLink]="'/master/student'" [queryParams]="{sort : 'descending'}" class='cursor-pointer text-xs bx bxs-down-arrow'></i>
                            </div>
                        </span>
                    </th>
                    <th class="px-4 h-12 text-left">Phone</th>
                    <th class="px-4 h-12 text-left">Email</th>
                    <th class="px-4 h-12 text-left">School</th>
                    <th class="px-4 h-12 text-left">Grade</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list?.data; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-left">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.name}}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.contact_number}}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.email}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.grade_name}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta routerLink="/master/student/form" [queryParams]="{id: item.id,view:'true'}" icon="bx bx-show" tooltip="view"></app-icon-cta>
                            <app-icon-cta routerLink="/master/student/form" [queryParams]="{id: item.id}" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="students_delete.open();selected_student = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-modal #open_bulk_modal title="Add Bulk Students">
    <div class="w-full flex flex-col gap-4">
        <div *ngIf="!student_file_upload" class="flex justify-between items-center">
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <div class="flex items-center gap-5">
                <app-filter-pill-button icon="bx bx-upload" label="Reupload Student File" (click)="student_file_upload = !student_file_upload"></app-filter-pill-button>
                <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                    Download Template
                </button>
            </div>
        </div>
        <div *ngIf="student_file_upload" class='w-full flex justify-between items-center'>
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                Download Template
            </button>
        </div>
        <div *ngIf="student_file_upload" class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg class="w-8 h-8 mb-4 text-[666666]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-[666666]"><span class="font-semibold">Click to upload</span> or drag and
                        drop</p>
                    <p class="text-xs text-[666666]">CSV (MAX. 5MB)</p>
                </div>
                <input #fileInput id="dropzone-file" type="file" class="hidden" accept=".csv" (change)="on_file_change($event)" />
            </label>
        </div>
        <div *ngIf="errorMessage" class="mt-2 text-red-600">{{errorMessage }}</div>
        <div *ngIf="!student_file_upload" class="end-full bg-white border border-primary rounded-lg  overflow-x-auto">
            <table class="table-auto text-text-color-600 w-full border-collapse">
                <thead class="text-sm overflow-auto font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                    <tr>
                        <th class="px-4 h-12 text-center">Student Name</th>
                        <th class="px-4 h-12 text-center">School Name</th>
                        <th class="px-4 h-12 text-center">Grade</th>
                        <th class="px-4 h-12 text-center">Academic Year</th>
                        <th class="px-4 h-12 text-center">Father Name</th>
                        <th class="px-4 h-12 text-center">Mother Name</th>
                        <th class="px-4 h-12 text-center">Contact Name</th>
                        <th class="px-4 h-12 text-center">Email</th>
                        <th class="px-4 h-12 text-center">Gender</th>
                        <th class="px-4 h-12 text-center">DOB</th>
                        <th class="px-4 h-12 text-center">Guardian Name</th>
                        <th class="px-4 h-12 text-center">Adhar Number</th>
                        <th class="px-4 h-12 text-center">Name As Per Adhar</th>
                        <th class="px-4 h-12 text-center">Address</th>
                        <th class="px-4 h-12 text-center">Pincode</th>
                        <th class="px-4 h-12 text-center">Alternate Contact Number</th>
                        <th class="px-4 h-12 text-center">Mother Tongue of Student</th>
                        <th class="px-4 h-12 text-center">Blood Group</th>
                        <th class="px-4 h-12 text-center">Social Category</th>
                        <th class="px-4 h-12 text-center">Minority Group</th>
                        <th class="px-4 h-12 text-center">Is Bpl Beneficiary</th>
                        <th class="px-4 h-12 text-center">Is Antyodaya Anna Yojana</th>
                        <th class="px-4 h-12 text-center">Is Belongs To Ews Or Disadvantaged Group</th>
                        <th class="px-4 h-12 text-center">Is CWSN</th>
                        <th class="px-4 h-12 text-center">Type Of Impairment</th>
                        <th class="px-4 h-12 text-center">Disability Percentage</th>
                        <th class="px-4 h-12 text-center">Is Indian</th>
                        <th class="px-4 h-12 text-center">Is This Student Is Out Of School</th>
                        <th class="px-4 h-12 text-center">When The Student Is Mainstreamed</th>
                    </tr>
                </thead>
                <tbody class="text-sm overflow-auto font-normal text-black/87 tracking-[0.32px]">
                    <tr *ngFor="let item of parsed_data" class="border-b border-[#CFD5F1]">
                        <td class="px-4 h-12 text-center">{{item['Student Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['School Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Grade']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Academic Year']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Father Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Mother Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Contact Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Email']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Gender']}}</td>
                        <td class="px-4 h-12 text-center">{{item['DOB']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Guardian Name']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Adhar Number']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Name As Per Adhar']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Address']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Pincode']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Alternate Contact Number']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Mother Tongue of Student']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Blood Group']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Social Category']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Minority Group']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is Bpl Beneficiary']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is Antyodaya Anna Yojana']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is Belongs To Ews Or Disadvantaged Group']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is CWSN']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Type Of Impairment']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Disability Percentage']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is Indian']}}</td>
                        <td class="px-4 h-12 text-center">{{item['Is This Student Is Out Of School']}}</td>
                        <td class="px-4 h-12 text-center">{{item['When The Student Is Mainstreamed']}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-button class="self-end" (click)="add_level_bulk()" *ngIf="!student_file_upload" color="bg-primary-500 text-white" icon="bx bx-plus" [label]="'Add ' + (parsed_data.length) + ' Students'"></app-button>
    </div>
</app-modal>
<app-confirmation-popup #students_delete confirmation_message="Are you sure you want to Delete this Students" popup_title="Delete Students">
    <app-button (click)="delete_confirm(selected_student.id);" label="Delete" color="bg-[#3F41D1] text-white" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-Student-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>
<app-modal #view_attendance title="Attendance">
    <div class="flex p-4 gap-2">
        <div class="w-1/3 h-full p-2 rounded-lg flex flex-col gap-2 items-center">
            <div class="h-36 w-36 bg-gray-400 border rounded-full over flex justify-center items-center overflow-hidden">
                <img class="h-full w-full object-cover" [src]="'https://storage.cloud.google.com/ict-recurring-media/' + selected_student.image" alt="user">
            </div>
            <p class="h5">{{selected_student.name}}</p>
            <div class="grid w-full grid-cols-1 gap-2">
                <div class="col-span-1">
                    <p class="subtitle-1">Grade</p>
                    <p class="subtitle-2">{{selected_student.grade_name}}</p>
                </div>
                <div class="col-span-1">
                    <p class="subtitle-1">Email</p>
                    <p class="subtitle-2">{{selected_student.email}}</p>
                </div>
                <div class="col-span-1">
                    <p class="subtitle-1">Phone</p>
                    <p class="subtitle-2">{{selected_student.contact_number}}</p>
                </div>
            </div>
        </div>
        <div class="w-2/3 h-full shadow-md p-2 rounded-lg bg-white rounded-t">
            <div class="px-4 flex items-center justify-between">
                <span tabindex="0" class="focus:outline-none  text-base font-bold text-gray-800">October
                    2020</span>
                <div class="flex items-center">
                    <button aria-label="calendar backward" class="focus:text-gray-400 hover:text-gray-400 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                    </button>
                    <button aria-label="calendar forward" class="focus:text-gray-400 hover:text-gray-400 ml-3 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler  icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    </button>

                </div>
            </div>
            <div class="flex items-center justify-between pt-12 overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Mo
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Tu
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">We
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Th
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Fr
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Sa
                                    </p>
                                </div>
                            </th>
                            <th>
                                <div class="w-full flex justify-center">
                                    <p class="text-base font-medium text-center text-gray-800">Su
                                    </p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center"></div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        1</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        2</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        3</p>
                                </div>
                            </td>
                            <td class="pt-6">
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        4</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        5</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        6</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        7</p>
                                </div>
                            </td>
                            <td>
                                <div class="w-full h-full">
                                    <div class="flex items-center justify-center w-full rounded-full cursor-pointer">
                                        <a role="link" tabindex="0" class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">8</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        9</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        10</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        11</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        12</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        13</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        14</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        15</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        16</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        17</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        18</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        19</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        20</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        21</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        22</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        23</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        24</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-yellow-300 rounded-full">
                                        25</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        26</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        27</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        28</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-green-500 rounded-full">
                                        29</p>
                                </div>
                            </td>
                            <td>
                                <div class="px-2 py-2 cursor-pointer flex w-full justify-center">
                                    <p class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-red-500 rounded-full">
                                        30</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-modal>