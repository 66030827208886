import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { NgFor, NgIf } from '@angular/common';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { DailyProgressReportService } from '../../services/daily-progress-report.service';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { Subject, debounceTime } from 'rxjs';


@Component({
    selector: 'app-daily-progress-report-list-page',
    standalone: true,
    templateUrl: './daily-progress-report-list-page.component.html',
    imports: [HeaderComponent, ButtonComponent, FilterPillButtonComponent, SerchTextInputComponent, IconCtaComponent, NgFor, NgIf, ConfirmationPopupComponent, RouterLink, PaginationComponent]
})
export class DailyProgressReportListPageComponent {
  daily_progress_list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  selectedMonth: any;
  constructor(public toastr: ToastrService, public ar: ActivatedRoute, public dps: DailyProgressReportService, public router:Router) { 
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_daily_progress_list(this.params);
    });
  }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      if (!this.params.page_size) {
        this.params.page_size = 8
      }
      if (!this.params.page) {
        this.params.page = 1
      }
      this.get_daily_progress_list(this.params)
    })
  }
  lable_month_show(event: any) {
    const selectedMonth = event.target.value;
    this.router.navigate([], {
        relativeTo: this.ar,
        queryParams: { month: selectedMonth },
        queryParamsHandling: 'merge'
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_daily_progress_list(this.params);
  }
  params: any = {};
  async get_daily_progress_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.daily_progress_list = await this.dps.get_list(filter)
      this.totalItems = this.daily_progress_list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.daily_progress_list, "qqqqqqqqqqq");
      this.toastr.success(this.daily_progress_list.message)
    } catch (error: any) {
      console.error("Error fetching daily_report list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  
  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_daily_progress_list(this.params);
    }
  }
}
