import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from '../../services/global.service';
import { HttpService } from '../../services/http.service';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-cheng-password-page',
  standalone: true,
  imports: [RouterLink, SelectInputComponent, NgIf, ReactiveFormsModule, NgxUiLoaderModule],
  templateUrl: './cheng-password-page.component.html',
})
export class ChengPasswordPageComponent {
  passwordVisible = false;
  updatePasswordVisible = false;
  params: any = {}

  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(7),this.passwordStrengthValidator]],
    update_password: ['', [Validators.required, Validators.minLength(7),this.passwordStrengthValidator]],
  }, { validator: this.passwordMatchValidator });


  constructor(public ui_spinner: NgxUiLoaderService, public ar: ActivatedRoute, private fb: FormBuilder, private hs: HttpService, private router: Router, public gs: GlobalService, public toastr: ToastrService) { }

  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      this.params.token = params['token']
    })
  }

  passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasUpperCase = /[A-Z]/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValid = hasUpperCase && hasSpecialCharacter;
    return !isValid ? { passwordStrength: true } : null;
  }
  
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const update_password = formGroup.get('update_password')?.value;
    return password === update_password ? null : { passwordMismatch: true };
  }

  async update_password() {
    if (this.form.valid) {
      this.ui_spinner.startLoader('add-chenge-loader');
      try {
        let form_password = this.form.get("password")?.value
        let data = {
          password: form_password,
          token: this.params.token
        }
        let response = await this.hs.post('/auth/reset-password', {}, data)
        this.ui_spinner.stopBackgroundLoader('add-chenge-loader');
        this.toastr.success(response.message || 'Password set Successful!')
        this.router.navigate(['/sign-in']);
        console.log(data, "   ddfdf");
      } catch (error: any) {
        console.log(error);
        this.ui_spinner.stopBackgroundLoader('add-chenge-loader');
        this.toastr.error(error?.error?.message || 'Something Went Wrong!')
      }
    } else {
      this.form.markAllAsTouched()
    }
  }


  togglePasswordVisibility(field: string) {
    if (field === 'password') {
      this.passwordVisible = !this.passwordVisible;
    } else if (field === 'update_password') {
      this.updatePasswordVisible = !this.updatePasswordVisible;
    }
  }
}
