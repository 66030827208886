import { NgFor, NgIf } from '@angular/common';
import { Component , Input } from '@angular/core';
import { ModalComponent,  } from '../components/modal/modal.component';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-uploder',
  standalone: true,
  imports: [NgIf,NgFor,ModalComponent,ConfirmationPopupComponent,],
  templateUrl: './uploder.component.html',
  styleUrl: './uploder.component.css'
})
export class UploderComponent {


  @Input() data: any =[];

  option:any;
  previewImages:any;
  
  images:any=[]

  constructor(public gs:GlobalService){
   setTimeout(() => {
    //  console.log(this.data);
     this.images = this.data;
   }, 500);
  }
}