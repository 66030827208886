import { Component } from '@angular/core';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../components/header/header.component';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { UploderComponent } from '../../uploder/uploder.component';
import { GlobalService } from '../../services/global.service';


@Component({
  selector: 'app-lesson-form-page',
  standalone: true,
  imports: [SelectInputComponent, TextAreaInputComponent, TextInputComponent, ButtonComponent, FormsModule, ReactiveFormsModule, HeaderComponent, NgFor, NgIf, RouterLink, ConfirmationPopupComponent, ModalComponent, NzUploadModule, NzModalModule, NzImageModule, NgStyle, NzNoAnimationModule, UploderComponent],
  templateUrl: './lesson-form-page.component.html'
})
export class LessonFormPageComponent {

  option:any='Image';

  lessons_fields: any[] = [
    { lessons: "Mathematics", description: "Basic concepts of arithmetic and algebra", status: "In Progress" },
    { lessons: "Science", description: "Introduction to biology, chemistry, and physics", status: "Completed" },
    { lessons: "History", description: "World history from ancient civilizations to modern times", status: "Pending" }
  ];

  images:any=[
    {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'image', title:'Marathi'},
    {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'video', title:'Hindi'},
    {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'video', title:'English'},
     {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'file', title:'Science'},
     {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'image', title:'Mathematics'},
     {image:'https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg', type:'video', title:'Social Science'},
  ]
  
  videos:any=[
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
  ]

 imageUrl: string | ArrayBuffer | null = null;
 previewImages:any;
 selectedImages:any
 fileList: NzUploadFile[] = [
  {
    uid: '-1',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-2',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-3',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-4',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-xxx',
    percent: 50,
    name: 'image.png',
    status: 'uploading',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-5',
    name: 'image.png',
    status: 'error'
  }
];
previewImage: string | undefined = '';
previewVisible = false;
view = false
disable = false

  constructor(public gs:GlobalService){
    if(this.gs.user.role != 'Super Admin' || 'Instructor'){
      this.disable = true
    }
  }

  getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });



handlePreview = async (file: NzUploadFile): Promise<any> => {
  if (!file.url && !file['priview']) {
    file['preview'] = await this.getBase64(file.originFileObj!);
  }
  this.previewImage = file.url || file['preview'];
  this.previewVisible = true;
};


  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result; // Assign the result directly
        this.displayPreview(file)
      };
    }
  }

  onImageChange(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {
        this.selectedImages.push(file);
        this.displayPreview(file);
      }
    }
  }

  displayPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.images.push(reader.result as string);
    };
  }

}
