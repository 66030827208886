import { Routes } from '@angular/router';
import { SignInPageComponent } from './pages/sign-in-page/sign-in-page.component';
import { DashboardLayoutPageComponent } from './pages/dashboard-layout-page/dashboard-layout-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { InstructorsListPageComponent } from './pages/instructors-list-page/instructors-list-page.component';
import { UdiseListPageComponent } from './pages/udise-list-page/udise-list-page.component';
import { UdiseFormPageComponent } from './pages/udise-form-page/udise-form-page.component';
import { SchoolListPageComponent } from './pages/school-list-page/school-list-page.component';
import { CourcesListPageComponent } from './pages/cources-list-page/cources-list-page.component';
import { AssessmentTestListPageComponent } from './pages/assessment-test-list-page/assessment-test-list-page.component';
import { AssessmentTestFormPageComponent } from './pages/assessment-test-form-page/assessment-test-form-page.component';
import { CountryListPageComponent } from './pages/country-list-page/country-list-page.component';
import { StateListPageComponent } from './pages/state-list-page/state-list-page.component';
import { DistrictListPageComponent } from './pages/district-list-page/district-list-page.component';
import { BlocksListPageComponent } from './pages/blocks-list-page/blocks-list-page.component';
import { SessionsListPageComponent } from './pages/sessions-list-page/sessions-list-page.component';
import { SessionsFormPageComponent } from './pages/sessions-form-page/sessions-form-page.component';
import { SchoolFormPageComponent } from './pages/school-form-page/school-form-page.component';
import { AssetsListPageComponent } from './pages/assets-list-page/assets-list-page.component';
import { BlockManagerListPageComponent } from './pages/block-manager-list-page/block-manager-list-page.component';
import { SubAdminListPageComponent } from './pages/sub-admin-list-page/sub-admin-list-page.component';
import { ReportsListPageComponent } from './pages/reports-list-page/reports-list-page.component';
import { ModuleListPageComponent } from './pages/module-list-page/module-list-page.component';
import { UnitListPageComponent } from './pages/unit-list-page/unit-list-page.component';
import { LessonViewComponent } from './components/lesson-view/lesson-view.component';
import { LessonFormPageComponent } from './pages/lesson-form-page/lesson-form-page.component';
import { ActivitiesListPageComponent } from './pages/activities-list-page/activities-list-page.component';
import { InstructorAssignmentComponent } from './pages/instructor-assignment/instructor-assignment.component';
import { InstructorAssignmentFormComponent } from './pages/instructor-assignment-form/instructor-assignment-form.component';
import { BrcAssignmentPageComponent } from './pages/brc-assignment-page/brc-assignment-page.component';
import { BrcAssignmentFormComponent } from './pages/brc-assignment-form/brc-assignment-form.component';
import { AcademicYearListPageComponent } from './pages/academic-year-list-page/academic-year-list-page.component';
import { DailyReportsListPageComponent } from './pages/daily-reports-list-page/daily-reports-list-page.component';
import { DailyReportsFormPageComponent } from './pages/daily-reports-form-page/daily-reports-form-page.component';
import { GroundLevelActivitiesFormPageComponent } from './pages/ground-level-activities-form-page/ground-level-activities-form-page.component';
import { GroundLevelActivitiesListPageComponent } from './pages/ground-level-activities-list-page/ground-level-activities-list-page.component';
import { StudentAttendanceComponent } from './pages/student-attendance/student-attendance.component';
import { GradeMasterListPageComponent } from './pages/grade-master-list-page/grade-master-list-page.component';
import { CourseFormPageComponent } from './pages/course-form-page/course-form-page.component';
import { SubTopicListPageComponent } from './pages/sub-topic-list-page/sub-topic-list-page.component';
import { SubTopicFormPageComponent } from './pages/sub-topic-form-page/sub-topic-form-page.component';
import { TopicListPageComponent } from './pages/topic-list-page/topic-list-page.component';
import { TopicFormPageComponent } from './pages/topic-form-page/topic-form-page.component';
import { MaterialTypeListPageComponent } from './pages/material-type-list-page/material-type-list-page.component';
import { SubmitAttendanceReportPageComponent } from './pages/submit-attendance-report-page/submit-attendance-report-page.component';
import { CoursesDetailComponent } from './courses-detail/courses-detail.component';
import { DailyProgressReportListPageComponent } from './pages/daily-progress-report-list-page/daily-progress-report-list-page.component';
import { DailyProgressReportFormPageComponent } from './pages/daily-progress-report-form-page/daily-progress-report-form-page.component';
import { StudentListPageComponent } from './pages/student-list-page/student-list-page.component';
import { AssessmentTypeListPageComponent } from './pages/assessment-type-list-page/assessment-type-list-page.component';
import { LevelListPageComponent } from './pages/level-list-page/level-list-page.component';
import { InstructorAssignmentReportPageComponent } from './pages/instructor-assignment-report-page/instructor-assignment-report-page.component';
import { BrcAssignmentReportPageComponent } from './pages/brc-assignment-report-page/brc-assignment-report-page.component';
import { ActivitieTypeListPageComponent } from './pages/activitie-type-list-page/activitie-type-list-page.component';
import { AssignedCourseListPageComponent } from './pages/assigned-course-list-page/assigned-course-list-page.component';
import { ChengPasswordPageComponent } from './pages/cheng-password-page/cheng-password-page.component';
import { SubjectListPageComponent } from './pages/subject-list-page/subject-list-page.component';
import { StudentFormPageComponent } from './pages/student-form-page/student-form-page.component';

export const routes: Routes = [
    // { path: 'sign-in', component: SignInPageComponent },
    // // { path: 'home', redirectTo: '' },
    // { path: 'set-password', component: ChengPasswordPageComponent },
    // {
    //     path: '', component: DashboardLayoutPageComponent, children: [
    //         { path: 'dashboard', component: DashboardPageComponent },
    //         // { path: 'venue-audits', component: VenueAuditsListPageComponent },
    //         // { path: 'audit', component: VenueAuditsPgeComponent },
    //         { path: 'master/country', component: CountryListPageComponent },
    //         { path: 'master/state', component: StateListPageComponent },
    //         { path: 'master/district', component: DistrictListPageComponent },
    //         { path: 'master/block', component: BlocksListPageComponent },
    //         { path: 'master/udise', component: UdiseListPageComponent },
    //         { path: 'master/udise/form', component: UdiseFormPageComponent },
    //         { path: 'master/school', component: SchoolListPageComponent },
    //         { path: 'master/school/form', component: SchoolFormPageComponent },
    //         { path: 'master/course', component: CourcesListPageComponent },
    //         { path: 'master/course/form', component: CourseFormPageComponent },
    //         { path: 'master/student', component: StudentListPageComponent },
    //         { path: 'master/material-type', component: MaterialTypeListPageComponent },
    //         { path: 'master/assets', component: AssetsListPageComponent },
    //         { path: 'master/academic-term', component: AcademicYearListPageComponent },
    //         { path: 'master/grade', component: GradeMasterListPageComponent },
    //         { path: 'master/level', component: LevelListPageComponent },
    //         { path: 'master/assessment-type', component: AssessmentTypeListPageComponent },
    //         { path: 'master/activity-type', component: ActivitieTypeListPageComponent },
    //         // { path: 'master/material-type/form', component: MaterialTypeFormPageComponent },
    //         // { path: 'master/batches', component: IctBatchesPageComponent },
    //         // { path: 'master/batches/form', component: IctBatchesFormPageComponent },
    //         // { path: 'master/inventory-requests', component: InventoryListPageComponent },
    //         // { path: 'master/lessons', component: LessonListPageComponent },
    //         { path: 'users/instructor', component: InstructorsListPageComponent },
    //         { path: 'users/block-resource-coordinator', component: BlockManagerListPageComponent },
    //         { path: 'users/sub-admin', component: SubAdminListPageComponent },
    //         { path: 'user-assignments/intructor-assignment', component: InstructorAssignmentComponent },
    //         { path: 'user-assignments/brc-assignment', component: BrcAssignmentPageComponent },
    //         { path: 'reports/attendance', component: ReportsListPageComponent },
    //         { path: 'reports/attendance/daily-log-report', component: DailyReportsFormPageComponent },
    //         { path: 'reports/daily-log', component: DailyReportsListPageComponent },
    //         { path: 'reports/daily-log-report', component: DailyReportsFormPageComponent },
    //         { path: 'reports/ground-activity', component: GroundLevelActivitiesListPageComponent },
    //         { path: 'reports/ground-activity-report', component: GroundLevelActivitiesFormPageComponent },
    //         { path: 'reports/assessment', component: AssessmentTestListPageComponent },
    //         { path: 'reports/assessment-report', component: AssessmentTestFormPageComponent },
    //         { path: 'reports/instructor-assignment', component: InstructorAssignmentReportPageComponent },
    //         { path: 'reports/brc-assignment', component: BrcAssignmentReportPageComponent },


    //         { path: 'assessment', component: AssessmentTestListPageComponent },
    //         { path: 'assessment/form', component: AssessmentTestFormPageComponent },
    //         { path: 'attendance', component: AttendancePageComponent },
    //         { path: 'sessions', component: SessionsListPageComponent },
    //         { path: 'sessions/form', component: SessionsFormPageComponent },
    //         { path: 'my-attendance', component: ReportsListPageComponent },
    //         { path: 'module', component: ModuleListPageComponent },
    //         { path: 'unit', component: UnitListPageComponent },
    //         { path: 'lessons/form', component: LessonFormPageComponent },
    //         { path: 'lesson', component: LessonViewComponent },
    //         { path: 'activities', component: ActivitiesListPageComponent },
    //         { path: 'intructor-assignment/form', component: InstructorAssignmentFormComponent },
    //         { path: 'brc-assignment/form', component: BrcAssignmentFormComponent },
    //         { path: 'daily-logs', component: DailyReportsListPageComponent },
    //         // { path: 'reports/daily-log', component: DailyReportsFormPageComponent },
    //         { path: 'ground-activities', component: GroundLevelActivitiesListPageComponent },
    //         { path: 'ground-activities/form', component: GroundLevelActivitiesFormPageComponent },
    //         { path: 'student-attendance', component: StudentAttendanceComponent },
    //         { path: 'student-attendance-report', component: StudentAttendanceComponent },
    //         { path: 'assigned-courses', component: AssignedCourseListPageComponent },
    //         { path: 'sub-topic', component: SubTopicListPageComponent },
    //         { path: 'sub-topic/form', component: SubTopicFormPageComponent },
    //         { path: 'topics', component: TopicListPageComponent },
    //         { path: 'topics/form', component: TopicFormPageComponent },
    //         { path: 'submit-attendance-report', component: SubmitAttendanceReportPageComponent },
    //         { path: 'course/detail', component: CoursesDetailComponent },
    //         { path: 'daily-progress-report', component: DailyProgressReportListPageComponent },
    //         { path: 'daily-progress-report/form', component: DailyProgressReportFormPageComponent },
    //     ]
    // }

    { path: 'sign-in', component: SignInPageComponent },
    { path: 'set-password', component: ChengPasswordPageComponent },
    {
        path: '',
        component: SignInPageComponent,
        pathMatch: 'full'
    },
    {
        path: '',
        component: DashboardLayoutPageComponent,
        children: [
            // Dashboard
            { path: 'dashboard', component: DashboardPageComponent },
            // Master
            { path: 'master/country', component: CountryListPageComponent },
            { path: 'master/state', component: StateListPageComponent },
            { path: 'master/district', component: DistrictListPageComponent },
            { path: 'master/block', component: BlocksListPageComponent },
            { path: 'master/udise', component: UdiseListPageComponent },
            { path: 'master/udise/form', component: UdiseFormPageComponent },
            { path: 'master/school', component: SchoolListPageComponent },
            { path: 'master/school/form', component: SchoolFormPageComponent },
            { path: 'master/school-view', component: SchoolFormPageComponent },
            { path: 'master/course', component: CourcesListPageComponent },
            { path: 'master/course/form', component: CourseFormPageComponent },
            { path: 'master/subject', component: SubjectListPageComponent },
            { path: 'course/view', component: CourseFormPageComponent },
            { path: 'master/student', component: StudentListPageComponent },
            { path: 'master/student/form', component: StudentFormPageComponent },
            { path: 'student-detail', component: StudentListPageComponent },
            { path: 'master/material-type', component: MaterialTypeListPageComponent },
            { path: 'master/assets', component: AssetsListPageComponent },
            { path: 'master/academic-term', component: AcademicYearListPageComponent },
            { path: 'master/grade', component: GradeMasterListPageComponent },
            { path: 'master/level', component: LevelListPageComponent },
            { path: 'master/assessment-type', component: AssessmentTypeListPageComponent },
            { path: 'master/activity-type', component: ActivitieTypeListPageComponent },
            // User
            { path: 'users/instructor', component: InstructorsListPageComponent },
            { path: 'users/block-resource-coordinator', component: BlockManagerListPageComponent },
            { path: 'users/sub-admin', component: SubAdminListPageComponent },
            // User Assignment
            { path: 'user-assignments/intructor-assignment', component: InstructorAssignmentComponent },
            { path: 'user-assignments/brc-assignment', component: BrcAssignmentPageComponent },
            // Reports
            { path: 'reports/attendance', component: ReportsListPageComponent },
            { path: 'reports/attendance/daily-log-report', component: DailyReportsFormPageComponent },
            { path: 'reports/monthly-upload-report', component: SubmitAttendanceReportPageComponent },
            { path: 'reports/daily-log', component: DailyReportsListPageComponent },
            { path: 'reports/daily-log-report', component: DailyReportsFormPageComponent },
            { path: 'reports/ground-activity', component: GroundLevelActivitiesListPageComponent },
            { path: 'reports/ground-activity-report', component: GroundLevelActivitiesFormPageComponent },
            { path: 'reports/assessment', component: AssessmentTestListPageComponent },
            { path: 'reports/assessment-report', component: AssessmentTestFormPageComponent },
            { path: 'reports/instructor-assignment', component: InstructorAssignmentReportPageComponent },
            { path: 'reports/brc-assignment', component: BrcAssignmentReportPageComponent },
            // Instructor
            { path: 'attendance/my-attendance', component: ReportsListPageComponent },
            { path: 'attendance/daily-log', component: DailyReportsFormPageComponent },
            { path: 'attendance/upload-monthly-attendance', component: SubmitAttendanceReportPageComponent },
            { path: 'course', component: AssignedCourseListPageComponent },
            { path: 'assessment', component: AssessmentTestListPageComponent },
            { path: 'assessment/form', component: AssessmentTestFormPageComponent },
            { path: 'assessment-view', component: AssessmentTestFormPageComponent },
            { path: 'ground-activity', component: GroundLevelActivitiesListPageComponent },
            { path: 'ground-activity/form', component: GroundLevelActivitiesFormPageComponent },
            { path: 'ground-activity-view', component: GroundLevelActivitiesFormPageComponent },
            { path: 'daily-log', component: DailyReportsListPageComponent },
            { path: 'daily-log/form', component: DailyReportsFormPageComponent },
            { path: 'daily-log-view', component: DailyReportsFormPageComponent },
            { path: 'school-profile', component: SchoolFormPageComponent },
            { path: 'instructor', component: InstructorsListPageComponent },

            //Student
            { path: 'courses', component: CourcesListPageComponent },
            { path: 'course/view', component: CourseFormPageComponent },


            { path: 'sessions', component: SessionsListPageComponent },
            { path: 'sessions/form', component: SessionsFormPageComponent },
            { path: 'my-attendance', component: ReportsListPageComponent },
            { path: 'module', component: ModuleListPageComponent },
            { path: 'unit', component: UnitListPageComponent },
            { path: 'lessons/form', component: LessonFormPageComponent },
            { path: 'lesson', component: LessonViewComponent },
            { path: 'activities', component: ActivitiesListPageComponent },
            { path: 'intructor-assignment/form', component: InstructorAssignmentFormComponent },
            { path: 'brc-assignment/form', component: BrcAssignmentFormComponent },
            { path: 'daily-logs', component: DailyReportsListPageComponent },
            { path: 'ground-activities', component: GroundLevelActivitiesListPageComponent },
            { path: 'student-attendance', component: StudentAttendanceComponent },
            { path: 'student-attendance-report', component: StudentAttendanceComponent },
            { path: 'sub-topic', component: SubTopicListPageComponent },
            { path: 'sub-topic/form', component: SubTopicFormPageComponent },
            { path: 'topics', component: TopicListPageComponent },
            { path: 'topics/form', component: TopicFormPageComponent },
            { path: 'course/detail', component: CoursesDetailComponent },
            { path: 'daily-progress-report', component: DailyProgressReportListPageComponent },
            { path: 'daily-progress-report/form', component: DailyProgressReportFormPageComponent },
        ]
    }
];
