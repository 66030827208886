<div class="pagination flex flex-col sm:flex-row gap-2 items-center justify-between mt-4 bottom-0 sticky mb-2">
    <div>
        <p class="text-[14px] text-[#212121] font-normal"> Showing {{ showingFrom }} to {{ showingTo }} of {{ totalItems }} Records </p>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button (click)="handlePrevClick()" class="p-[10px] bg-[#E1E8F5] rounded-lg" [disabled]="currentPage === 1">
            <i class='bx bx-chevron-left'></i>
        </button>
        <ng-container *ngFor="let page of renderPageNumbers(); let i = index">
            <button *ngIf="page !== -1" (click)="onPageChange.emit(page)" class="p-[10px] rounded-lg text-[13px] font-[600]" [ngClass]="{ 'bg-[#3F41D1] text-[#E1E8F5]': currentPage === page, 'bg-[#E1E8F5] text-[#666]': currentPage !== page }">
                {{ page }}
            </button>
            <span *ngIf="page === -1" class="p-[10px] rounded-lg text-[13px] font-[600]">
                ...
            </span>
        </ng-container>
        <button (click)="handleNextClick()" class="p-[10px] bg-[#E1E8F5] rounded-lg" [disabled]="currentPage === totalPages">
            <i class='bx bx-chevron-right'></i>
        </button>
    </div>
</div>