<app-header header_title="Add Batch">
    <app-button color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel"   placement="header" routerLink="/dashboard/lessons"></app-button>
    <app-button color="bg-accent-300 text-accent-on" label="Submit" routerLink="/dashboard/lessons"  ></app-button>
</app-header>
<div class="px-8 mb-10">
    <form>
        <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Lesson Details</p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
                <app-text-input label="Name" icon="bx bx-list-plus"></app-text-input>
                <app-select-input label="Course" icon="bx bx-list-plus" [options]="[]"></app-select-input>
                <app-select-input label="Module" icon="bx bx-list-plus" [options]="[]"></app-select-input>
                <app-select-input label="Unit" icon="bx bx-list-plus" [options]="[]"></app-select-input>
            </div>
        </div>
        <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
            <p class="font-normal text-base text-text-color-600">Study Material</p>
            <app-uploder [data]="images"></app-uploder>
        </div>
    </form>
</div>

<app-confirmation-popup #edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this {{option}}?" popup_title="Edit {{option}}"></app-confirmation-popup>
<app-confirmation-popup #delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this {{option}}?" popup_title="Delete {{option}}"></app-confirmation-popup>

<app-modal #open_image title="Image">
    <img class="rounded-2xl" [src]="images[previewImages]" alt="Study Image">
</app-modal>
<app-modal #open_video title="Video">
    <iframe class="rounded-2xl md:h-96 w-full" src="https://www.youtube.com/embed/UgwaO8JkXYU?si=BdLDjSRDoQWrHpud" frameborder="0" allowfullscreen></iframe>
</app-modal>