import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'getLevelNameControl',
  standalone: true
})
export class GetLevelNameControlPipe implements PipeTransform {

  transform(form: any,controls_array:any[]): any {
    let temp_form_control = form;
    controls_array.forEach(index=>{
      temp_form_control = temp_form_control.controls.levels.controls[index]
    })
    return temp_form_control.controls.name
  }

}
