<app-header header_title="{{params.id?(params.view ? 'View' : ' Update'):'Add'}} Student">
    <app-button color="bg-white text-[#666666] border-gray-300 border" [label]="'Cancel'" routerLink="/master/student"></app-button>
    <app-button *ngIf="!params.id && !params.view" color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" (click)="add()" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="add-School-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
    <app-button *ngIf="params.id && !params.view" color="bg-[#E697AB] text-[#310000]" [label]="'Update'" (click)="edit(params.id)" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="update-School-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-header>
<form [formGroup]="student_form" class="px-8 w-full">
    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">STUDENT DETAILS</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new Student</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-1 mt-4">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
            <app-text-input formControlName="name" label="Student Name" class="uppercase"></app-text-input>
          <app-select-input formControlName="gender" label="Gender" [options]="[{title:'Male'},{title:'Female'},{title:'Transgender'}]"></app-select-input>
            <app-date-input formControlName="dob" label="Date Of Birth" [maxDateInput]="birth_date"></app-date-input>
            <app-select-input formControlName="school_id" label="School" [options]="dropdown_list.school_list"></app-select-input>
            <app-select-input formControlName="grade_id" label="Grade" [options]="dropdown_list.grade_list"></app-select-input>
            <app-select-input formControlName="academic_year_id" label="Academic Year" [options]="dropdown_list.academic_year_list"></app-select-input>
            <app-text-input formControlName="mother_name" label="Mother's Name"></app-text-input>
            <app-text-input formControlName="father_name" label="Father's Name"></app-text-input>
            <app-text-input formControlName="guardian_name" label="Guardian Name"></app-text-input>
            <app-text-input formControlName="adhar_number" label="Student Aadhaar Number"></app-text-input>
            <app-text-input formControlName="name_as_per_adhar" label="Student Name as in Aadhaar Card"></app-text-input>
            <app-text-input formControlName="address" label="Address"></app-text-input>
            <app-text-input formControlName="pincode" label="Pincode"></app-text-input>
            <app-text-input formControlName="contact_number" label="Mobile Number"></app-text-input>
            <app-text-input formControlName="alternate_contact_number" label="Alternate Mobile Number"></app-text-input>
            <app-text-input formControlName="email" label="Contact Email Id"></app-text-input>
            <app-text-input formControlName="mother_tongue_of_student" label="Mother Tongue"></app-text-input>
            <app-text-input formControlName="blood_group" label="Blood Group"></app-text-input>
            <app-select-input formControlName="social_category" label="Social Category" [options]="[{title:'General'},{title:'SC'},{title:'ST'},{title:'OBC'}]"></app-select-input>
            <app-select-input formControlName="minority_group" label="Minority Group" [options]="[{title:'Sikh'},{title:'Buddhist'},{title:'Parsi'},{title:'Jain'},{title:'Christian'},{title:'Muslim'},{title:'Not Applicable'}]"></app-select-input>
            <app-select-input [(ngModel)]="selectedBplBeneficiary"formControlName="is_bpl_beneficiary" label="Whether BPL beneficiary ?" [options]="whether_bpl_beneficiary" ></app-select-input>
            <app-select-input formControlName="is_antyodaya_anna_yojana" label="Whether Antyodaya Anna Yojna (AYY) beneficiary ?" *ngIf="selectedBplBeneficiary === 'YES'" [options]="[{title:'YES'},{title:'NO'}]"></app-select-input>
            <app-select-input formControlName="is_belongs_to_ews_or_disadvantaged_group" label="Whether Belongs To EWS/Disadvantaged Group ?" [options]="[{title:'YES'},{title:'NO'}]"></app-select-input>
            <app-select-input formControlName="is_cwsn" label="Whether CWSN ?" [options]="Whether_CWSN" [(ngModel)]="selectedcwsn"></app-select-input>
            <div class="flex gap-2 w-full" *ngIf="selectedcwsn === 'YES'"><app-text-input class="w-full" formControlName="type_of_impairment" label="Type Of Impairment(code)"></app-text-input><app-text-input class="w-full" formControlName="disability_percentage" label="Disability Percentage (in%)"></app-text-input></div>
            <app-select-input formControlName="is_indian" label="Whether The Student Is Indian National ?" [options]="[{title:'YES'},{title:'NO'}]"></app-select-input>
            <app-select-input formControlName="is_this_student_is_out_of_school" label="Is This Student identified as Out Of School child in current or previous Years ?" [options]="current__previous_Years" [(ngModel)]="current_previos"></app-select-input>
            </div>
            <app-select-input formControlName="when_the_student_is_mainstreamed" label="When The Student Is Mainstreamed ?" [options]="[{title:'In current Academic Year'},{title:'In Earlier Academic Year'}]" *ngIf="current_previos === 'YES'"></app-select-input>
        </div>
        <div class="flex flex-col rounded-lg my-2 col-span-3">
            <p class="font-normal text-base capitalize text-text-color-600">Image</p>
            <app-zoro-file-input [multiple]="true" formControlName="image" class="w-full" label="image"
                supported_extension=".png,.jpg,.jpeg,.svg"></app-zoro-file-input>
        </div>
    </div>
</form>