import { Component } from '@angular/core';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../components/header/header.component';
import { NgFor, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-sub-topic-form-page',
  standalone: true,
  imports: [TextInputComponent,SelectInputComponent,ButtonComponent,FormsModule,ReactiveFormsModule,HeaderComponent,NgIf,NgFor,RouterLink],
  templateUrl: './sub-topic-form-page.component.html',
})
export class SubTopicFormPageComponent {
  selected_material: any = '';
  material_Types:any = [
    { title: 'File' },
    {  title: 'Upload' },
    {  title: 'Link' },
  ];
  study_materail_field:any=[]

  add_study_material(){
    this.study_materail_field.push({})
  }
  delete_study_material(){
    this.study_materail_field.pop()
  }
}
