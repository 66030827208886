import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SidebarService } from '../../services/sidebar.service';
import { GlobalService } from '../../services/global.service';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component';
import { DrawerComponent } from '../drawer/drawer.component';
import { FilterPillButtonComponent } from '../filter-pill-button/filter-pill-button.component';
import { TimeInputComponent } from '../inputs/time-input/time-input.component';
import { ButtonComponent } from '../button/button.component';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { ModalComponent } from '../modal/modal.component';
import { SelectInputComponent } from '../inputs/select-input/select-input.component';
import { TextAreaInputComponent } from '../inputs/text-area-input/text-area-input.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { CheckInService } from '../../services/check-in.service';
import { CheckOutService } from '../../services/check-out.service';
import { ToastrService } from 'ngx-toastr';
import { AttendanceService } from '../../services/attendance.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgFor, NgIf, RouterLink, FormsModule, NotificationPanelComponent, DrawerComponent, FilterPillButtonComponent, TimeInputComponent, ButtonComponent, TextInputComponent, ModalComponent, SelectInputComponent, TextAreaInputComponent, ConfirmationPopupComponent, ReactiveFormsModule],
  templateUrl: './header.component.html',
  providers: [DatePipe]
})
export class HeaderComponent {
  @Input() header_title: string = '';
  @Input() no_margin_bottom: boolean = false;
  @ViewChild('view_attandance_modal') view_attandance_modal: any;
  @ViewChild('view_attandance_modal2') view_attandance_modal2: any;
  @ViewChild('check_out_redirect') check_out_redirect: any;
  showModal: boolean = false;
  latitude: any;
  longitude: any;
  countdownTimer: any;
  isCheckIn: boolean = true;
  checkInButtonText: string = 'Check In';
  disabled_btn: any;
  is_drawer_open: boolean = false;
  breadcrumbs: { label: string, url: string }[] = [];
  sidebar_data: any = {}
  roles = ['Super Admin', 'Sub Admin', 'Block Resource Coordinator', 'Instructor', 'Student'];
  selected_role: string = ''
  is_notification_open: any;
  location_permission_modal: boolean = false
  showRoleSelect: any;
  currentDate: Date;
  form: any = this.fb.group({
    check_in_time: [''],
    remark: [''],
    check_out_time: [''],
  })
  constructor(public datePipe: DatePipe, public ss: SidebarService, public atts: AttendanceService, public gs: GlobalService, public router: Router, public fb: FormBuilder, public cis: CheckInService, public cos: CheckOutService, public toastr: ToastrService) { this.currentDate = new Date(); }
  remark: string = '';
  onAddReportClick() {
    const url = '/daily-log/form?remark=' + encodeURIComponent(this.remark);
    this.router.navigateByUrl(url);
    this.check_out_redirect.close()
  }
  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.generate_breadcrumbs();
    };
    console.log('User Role:', this.gs.user.role);
    console.log('Check In Time:', this.gs.attendnace?.check_in_time);
    console.log('Check Out Time:', this.gs.attendnace?.check_out_time);
  }
  toggle_notification() {
    this.is_notification_open = !this.is_notification_open
  }
  role_change() {
    this.gs.user.role = this.selected_role;
  }
  generate_breadcrumbs() {
    const path = window.location.pathname;
    const parts = path.split("/").filter(part => part !== '');

    let breadcrumb_path = "/";
    for (let i = 0; i < parts.length; i++) {
      breadcrumb_path += parts[i] + "/";
      const label = this.format_breadcrumb_label(parts[i]);

      if (i === parts.length - 1 && parts[i].endsWith('form') && !window.location.search.includes('id=')) {
        this.breadcrumbs.push({ label: 'Add ' + this.format_breadcrumb_label(parts[i - 1]), url: breadcrumb_path });
      } else if (i === parts.length - 1 && parts[i].endsWith('form') && window.location.search.includes('id=')) {
        this.breadcrumbs.push({ label: 'Update ' + this.format_breadcrumb_label(parts[i - 1]), url: breadcrumb_path });
      } else {
        this.breadcrumbs.push({ label, url: breadcrumb_path });
      }
    }
  }
  format_breadcrumb_label(label: string): string {
    const words = label.split('-');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  }
  is_dashboard_route(): boolean {
    return this.router.url.includes('master')
  }
  toggle_drawer() {
    this.is_drawer_open = !this.is_drawer_open;
  }
  close_drawer() {
    this.is_drawer_open = false;
  }

  async onCheckout() {
    if (this.form.valid) {
      if (this.gs.attendnace?.check_in_time != null) {
        let options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        };
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            const now = new Date();
            const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
            const data: any = {
              date: formattedDate,
              check_out_time: this.form.value.check_out_time,
              check_out_lat: this.latitude.toString(),
              check_out_long: this.longitude.toString(),
              remark: this.form.value.remark || "",
              school_id: this.gs.selected_school.school_id ? this.gs.selected_school.school_id : 0
            };
            console.log(data, "kkkkjk");
            try {
              let response: any = await this.cos.add(data);
              let filter_object = {
                date: formattedDate
              }
              this.form.reset();
              this.atts.get_current_day_attendance(filter_object)
              this.toastr.success(response.message || 'Check Out Successfully!')
            } catch (error: any) {
              console.error(error?.error?.message, '');
              this.toastr.error(error?.error?.message);
            }
            this.view_attandance_modal.close();
            this.check_out_redirect.open()
            this.isCheckIn = !this.isCheckIn;
            this.location_permission_modal = false
          }, (error) => {
            console.error('Error getting location:', error);
            this.location_permission_modal = true
            alert('Error, Getting your location. Please enable if off!')
          },
            options);
        } else {
          console.error('Geolocation is not supported by this browser.');
          this.location_permission_modal = true
          alert("Location Permission Required")
        }
      } else {
        this.performCheckOut();
        this.view_attandance_modal.close();
        this.isCheckIn = !this.isCheckIn;
        this.check_out_redirect.open()
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  getCurrentTime(is_checkin: boolean): string {
    const now = new Date();
    const formattedTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    console.log(formattedTime, typeof formattedTime, "FFFFFFFFFFFF");
    if (is_checkin) {
      this.form?.get('check_in_time')?.setValue(formattedTime, { emitEvent: false });
    } else {
      this.form?.get('check_out_time')?.setValue(formattedTime, { emitEvent: false });
    }
    return formattedTime;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  async onCheckIn() {
    if (this.gs.attendnace?.check_in_time == null) {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const now = new Date();
          const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
          const add: any = {
            date: formattedDate,
            check_in_time: this.form.value.check_in_time,
            check_in_lat: this.latitude.toString(),
            check_in_long: this.longitude.toString(),
            school_id: this.gs.selected_school.school_id ? this.gs.selected_school.school_id : 0
          }
          this.location_permission_modal = false
          console.log(add, "kkkkjk");
          try {
            let response: any = await this.cis.add(add);
            let filter_object = {
              date: formattedDate
            }
            this.form.reset();
            this.atts.get_current_day_attendance(filter_object)
            this.toastr.success(response.message || 'Check In Successfully!')
          } catch (error: any) {
            console.error(error?.error?.message, '');
            this.toastr.error(error?.error?.message);
          }
        }, (error) => {
          console.error('Error getting location:', error);
          this.location_permission_modal = true
          alert('Error, Getting your location. Please enable if off!')
        }, options);

      } else {
        console.error('Geolocation is not supported by this browser.');
        this.location_permission_modal = true
        alert("Location Permission Required")
      }
    } else {
      this.performCheckOut();
    }
    this.view_attandance_modal2.close();
    this.isCheckIn = !this.isCheckIn;
  }


  performCheckOut() {
    clearInterval(this.countdownTimer);
  }
  status_list: any = [
    { title: "In Progress" },
    { title: "Completed" },
    { title: "Pending" },
  ]
}
