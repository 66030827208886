<app-header header_title="Sessions">
    <app-button color="bg-accent-300 text-accent-on" icon="bx bx-plus" label="ADD Sessions" icon_alignment="right" routerLink="/dashboard/sessions/form"></app-button>
</app-header>

<div class="w-full flex flex-col-reverse sm:flex-row justify-between mb-5 gap-3 px-4">
    <div class="w-full flex justify-end items-center gap-3">
        <app-serch-text-input class="w-full md:w-auto" placeholder="Search"></app-serch-text-input>
        <!-- <app-filter-pill-button class="hidden md:block" label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-center">Sr No</th>
                    <th class="px-4 h-12 text-center">Batch</th>
                    <th class="px-4 h-12 text-center">Check In Time</th>
                    <th class="px-4 h-12 text-center">Check Out Time</th>
                    <th class="px-4 h-12 text-left">Lessons</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of sessions_list; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{i+1}}</td>
                    <td class="px-4 h-12 text-center">{{item.batch}}</td>
                    <td class="px-4 h-12 text-center">{{item.check_in}}</td>
                    <td class="px-4 h-12 text-center">{{item.check_out}}</td>
                    <td class="px-4 h-12 text-left">{{item.lesson}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-show" tooltip="view"></app-icon-cta>
                            <app-icon-cta icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="batch_delete.open()" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="px-8 bg-surface-100 z-10 w-full bottom-0 py-4 lg:absolute flex flex-col-reverse sm:flex-row gap-2 items-center justify-between">
    <div>
        <p class="text-[14px] text-text-color-600 font-normal">
            Showing 1 to 5 of 50 Records
        </p>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-left'></i>
        </button>
        <span class="p-[10px] bg-primary-500 text-white rounded-lg">1</span>
        <span class="p-[10px] bg-[#E1E8F5] rounded-lg">2</span>
        <button class="p-[10px] bg-[#CFD5F1] rounded-lg">
            <i class='bx bx-chevron-right'></i>
        </button>
    </div>
</div>
<app-confirmation-popup #batch_delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this Resource?" popup_title="Delete Resource"></app-confirmation-popup>