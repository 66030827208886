import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { CheckinCheckoutComponent } from '../../components/checkin-checkout/checkin-checkout.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TimeInputComponent } from '../../components/inputs/time-input/time-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';


import { AttandanceReportService } from '../../services/attandance-report.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { Subject, debounceTime } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { InstructorUserService } from '../../services/instructor-user.service';
import { SchoolService } from '../../services/school.service';
import { BrcUserService } from '../../services/brc-user.service';

@Component({
  selector: 'app-reports-list-page',
  standalone: true,
  imports: [HeaderComponent, DatePipe, ButtonComponent, FilterPillButtonComponent, SerchTextInputComponent, ConfirmationPopupComponent, IconCtaComponent, NgFor, CheckinCheckoutComponent, NgIf, ModalComponent, SelectInputComponent, TimeInputComponent, TextInputComponent, RouterLink, PaginationComponent, FormsModule],
  templateUrl: './reports-list-page.component.html',

})
export class ReportsListPageComponent {
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  time_line_data: any = {}
  report_list: any = {}
  dropdown_list: any = {}
  searchSubject: Subject<string> = new Subject<string>();
  show_month_Label: boolean = true || null;
  path = window.location.pathname;
  constructor(public gs: GlobalService, public ss: SchoolService, public ar: ActivatedRoute, public as: AttandanceReportService, public toastr: ToastrService, public router: Router, public us: InstructorUserService, public brc_service: BrcUserService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_attendance_list(this.params);
    });
  }
  selectedMonth: string = '';
  params: any = {};
  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_attendance_list(this.params);
    })
  }
  redirectToGoogleMaps(latitude: number, longitude: number): void {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, '_blank');
  }
  async get_dropdown_list() {
    try {
      const user_response = await this.us.get_list({})
      const instructor_response = await this.us.get_list({})
      const school_response = await this.ss.get_list({})
      const brc_user = await this.brc_service.get_list({})
      let user_options = user_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let instructor_options = instructor_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let school_options = school_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_options = brc_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.user_list = [...user_options, ...brc_user_options]
      this.dropdown_list.instructor_list = instructor_options 
      this.dropdown_list.school_list = school_options
    } catch (error: any) {
      console.error("Error fetching Dropdown list:", error);
    }
  }
  user_show_filter(event: any) {
    const selectedUser = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedUser) {
      this.currentPage = 1;
      queryParams.user_id = selectedUser;
    } else {
      this.currentPage = 1;
      queryParams.user_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  instructor_show_filter(event: any) {
    const selectedUser = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedUser) {
      this.currentPage = 1;
      queryParams.user_id = selectedUser;
    } else {
      this.currentPage = 1;
      queryParams.user_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  school_show_filter(event: any) {
    const selectedUser = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedUser) {
      this.currentPage = 1;
      queryParams.school_id = selectedUser;
    } else {
      this.currentPage = 1;
      queryParams.school_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  // lable_month_show(event: any) {
  //   const selectedMonth = event.target.value;
  //   let queryParams: any = { ...this.ar.snapshot.queryParams };
  //   if (selectedMonth) {
  //     this.currentPage = 1;
  //     queryParams.month = selectedMonth;
  //   } else {
  //     this.currentPage = 1;
  //     queryParams = { ...queryParams };
  //     delete queryParams.month;
  //   }
  //   this.router.navigate([], {
  //     relativeTo: this.ar,
  //     queryParams:  queryParams,
  //     queryParamsHandling: 'merge'
  //   });
  // }

  lable_month_show(event: any) {
    const selectedMonth = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedMonth) {
      this.currentPage = 1;
      queryParams.month = selectedMonth;
    } else {
      this.currentPage = 1;
      queryParams.month = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_attendance_list(this.params);
  }

  async get_attendance_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      if (this.gs.user.role === 'Block Resource Coordinator' && this.path.includes('reports')) {
        this.report_list = await this.as.get_instructor_attendance_report(filter)
      } else {
        this.report_list = await this.as.get_list(filter)
      }
      this.totalItems = this.report_list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.report_list, "qqqqqqqqqqq");
      // this.toastr.success(this.report_list.message)
    } catch (error: any) {
      console.error("Error fetching attendance list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_attendance_list(this.params);
    }
  }

  selected_report(report_data: any) {
    this.time_line_data = {}
    this.time_line_data = report_data
  }
}
