import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-venue-details-modal',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './venue-details-modal.component.html'
})
export class VenueDetailsModalComponent {
  @Input() venue_detail: any = {};
  @ViewChild('modal_open') modal_open:any;

  close(){
    this.modal_open.close()
  }
  open(){
    this.modal_open.open()
  }

}
