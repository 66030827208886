import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialTypeService {

  list: any[] = []

  constructor(public hs: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/material-type/add-material-type', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/material-type/delete-material-type?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/material-type/get-material-type?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/material-type/get-material-type-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/material-type/update-material-type?id=' + id, {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
