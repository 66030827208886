import { Component } from '@angular/core';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { HeaderComponent } from '../../components/header/header.component';
import { NgFor } from '@angular/common';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { ButtonComponent } from '../../components/button/button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-sessions-list-page',
  standalone: true,
  imports: [FilterPillButtonComponent,SerchTextInputComponent,HeaderComponent,NgFor,IconCtaComponent,ConfirmationPopupComponent,ButtonComponent,RouterLink],
  templateUrl: './sessions-list-page.component.html',
})
export class SessionsListPageComponent {
  sessions_list:any=[
    {batch: "2022-2023",check_in: "08:00 AM",check_out: "10:00 AM",lesson: "Algebra Basics"},
    {batch: "2021-2024",check_in: "09:30 AM",check_out: "11:30 AM",lesson: "Introduction to Programming"},
    {batch: "2023-2024",check_in: "10:00 AM",check_out: "12:00 PM",lesson: "Literature Analysis"},
    {batch: "2022-2025",check_in: "01:00 PM",check_out: "03:00 PM",lesson: "Chemistry Lab"},
    {batch: "2021-2023",check_in: "02:30 PM",check_out: "04:30 PM",lesson: "Art Appreciation"}
  ]
}
