<app-header header_title="{{params.id?(params.view ? 'View' : ' Update'):'Add'}} School">
    <app-button *ngIf="gs.user.role == 'Super Admin'" color="bg-white text-[#666666] border-gray-300 border"
        [label]="'Cancel'" routerLink="/master/school"></app-button>
    <app-button *ngIf="!params.id && !params.view && gs.user.role == 'Super Admin'" color="bg-[#E697AB] text-[#310000]" [label]="'Submit'"
        #add_school_bitton (click)="add()" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="add-School-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
    <app-button *ngIf="params.id && !params.view" color="bg-[#E697AB] text-[#310000]" [label]="'Update'"
        #update_school_button (click)="edit(params.id)" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="update-School-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-header>
<form [formGroup]="school_form" class="px-8 w-full">
    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">SCHOOL PROFILE AND OTHER DETAILS</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new School</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
            <app-text-input formControlName="udise_code" [fetch]="true"
                (fetch_activated)="get_udise(school_form.get('udise_code').value)"
                class="col-span-6 sm:col-span-3 lg:col-span-2" label="UDISE Code"></app-text-input>
            <app-text-input formControlName="name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="School Name"></app-text-input>
            <app-select-input formControlName="country_id" label="Country" class="md:col-span-2"
                [options]="dropdown_list.country_list"></app-select-input>
            <app-select-input formControlName="block_id" label="Block" class="md:col-span-2"
                [options]="dropdown_list.block_list"></app-select-input>
            <app-select-input formControlName="state_id" label="State" class="md:col-span-2"
                [options]="dropdown_list.state_list"></app-select-input>
            <app-text-input formControlName="city" class="md:col-span-2" [label]="'City'"></app-text-input>
            <app-select-input formControlName="district_id" label="District" class="md:col-span-2"
                [options]="dropdown_list.district_list"></app-select-input>
            <!-- <app-text-input formControlName="udise_block" class="col-span-6 sm:col-span-3 lg:col-span-2" label="Name of the UDISE Block"></app-text-input> -->
            <app-text-input formControlName="pincode" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Pincode"></app-text-input>
            <app-select-input formControlName="location_type" class="md:col-span-2" label="School Location Type "
                [options]="[{title: 'Rural'},{title: 'Urban'}]"></app-select-input>
            <app-text-input formControlName="village_name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Village Name "></app-text-input>
            <app-text-input formControlName="gram_panchayat_name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Name of Gram Panchayat "></app-text-input>
            <app-text-input formControlName="urban_local_bodies" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Urban Local bodies"></app-text-input>
            <app-text-input formControlName="ward_name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Ward Name"></app-text-input>
            <app-text-input formControlName="address" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="School Address"></app-text-input>
            <app-text-input formControlName="cluster_resourse_centre" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Name of the Cluster Resource Centre(CRC)"></app-text-input>
            <app-text-input formControlName="assembly_constituency" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Name of the Assembly Constituency"></app-text-input>
            <app-text-input formControlName="parliamentary_constituency" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Name of the Parliamentary Constituency"></app-text-input>
            <app-text-input formControlName="lat" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Latitude"></app-text-input>
            <app-text-input formControlName="long" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Longitude"></app-text-input>
            <app-text-input formControlName="school_contact_details" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="School Contact Details "></app-text-input>
            <app-text-input formControlName="std_code" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="STD Code"></app-text-input>
            <app-text-input formControlName="landline_number" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Landline Number"></app-text-input>
            <app-text-input formControlName="contact_number" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Mobile Number"></app-text-input>
            <app-text-input formControlName="school_website" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Website of the School"></app-text-input>
            <app-text-input formControlName="email" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Email"></app-text-input>
            <app-text-input formControlName="head_of_school" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Head of the School (HoS)"></app-text-input>
            <app-text-input formControlName="hos_in_charge_name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="HoS /In-Charge Name"></app-text-input>
            <app-text-input formControlName="hos_contact_number" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Mobile Number"></app-text-input>
            <app-text-input formControlName="hos_email" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="HoS Email"></app-text-input>
            <app-text-input formControlName="respondent_type" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Respondent Type"></app-text-input>
            <app-text-input formControlName="respondent_name" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Respondent Name"></app-text-input>
            <app-text-input formControlName="respondent_email" class="md:col-span-2"
                [label]="'Respondent Email:'"></app-text-input>
            <app-text-input formControlName="respondent_contact_number" class="col-span-6 sm:col-span-3 lg:col-span-2"
                label="Mobile Number"></app-text-input>
            <app-select-input formControlName="special_school_for_cwsn" class="md:col-span-2"
                label="Special School for CWSN" [options]="[{title: 'Yes'},{title: 'No'}]"></app-select-input>
        </div>
    </div>
    <!-- --------new------- -->
    <div class="bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full mt-6">
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <p class="text-[#666666] font-medium text-sm mt-2">School Category:</p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-text-input formControlName="primary_only_with_grades_1_to_5" class="md:col-span-2"
                    [label]="'Primary only with grades 1 to 5'"></app-text-input>
                <app-text-input formControlName="upper_primary_with_grades_1_to_8" class="md:col-span-2"
                    [label]="'Upper Primary with grades 1 to 8'"></app-text-input>
                <app-text-input formControlName="higher_secondary_with_grades_1_to_12" class="md:col-span-2"
                    [label]="'Higher Secondary with grades 1 to 12'"></app-text-input>
                <app-text-input formControlName="upper_primary_only_with_grades_6_to_8" class="md:col-span-2"
                    [label]="'Upper Primary only with grades 6 to 8'"></app-text-input>
                <app-text-input formControlName="higher_secondary_with_grades_6_to_12" class="md:col-span-2"
                    [label]="'Higher Secondary with grades 6 to 12'"></app-text-input>
                <app-text-input formControlName="secondary_with_grades_6_to_10" class="md:col-span-2"
                    [label]="'Secondary with grades 6 to 10'"></app-text-input>
                <app-text-input formControlName="secondary_only_with_grades_9_to_10" class="md:col-span-2"
                    [label]="'Secondary only with grades 9 & 10'"></app-text-input>
                <app-text-input formControlName="higher_secondary_with_grades_9_to_12" class="md:col-span-2"
                    [label]="'Higher Secondary with grades 9 to 12'"></app-text-input>
                <app-text-input formControlName="hr_sec_jr_college_only_with_grades_11_12" class="md:col-span-2"
                    [label]="'Hr. Sec. /Jr. College only with grades 11 & 12 '"></app-text-input>
            </div>
        </div>
        <!-- <p class="text-[#666666] font-medium text-sm mt-4">School Category:</p> -->
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-text-input formControlName="lowest_and_highest_class_in_school" class="md:col-span-2"
                    [label]="'Lowest and Highest Class in School (e.g. 1 - 5) :'"></app-text-input>
                <app-text-input formControlName="type_of_the_school" class="md:col-span-2"
                    [label]="'Type of the School:'"></app-text-input>
                <app-text-input formControlName="affiliation_board_of_the_school_for_secondary_sections"
                    class="md:col-span-2"
                    [label]="'Affiliation Board of the school for SECONDARY Sections:'"></app-text-input>
                <app-text-input formControlName="affiliation_board_of_the_school_for_higher_secondary_sections"
                    class="md:col-span-2"
                    [label]="'Affiliation Board of the school for HIGHER SECONDARY Sections:'"></app-text-input>
                <app-text-input formControlName="academic_session_start_date" class="md:col-span-2"
                    [label]="'When does the academic session Start?'"></app-text-input>
                <app-text-input formControlName="academic_session_end_date" class="md:col-span-2"
                    [label]="'When does the academic session End Date?'"></app-text-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <p class="text-[#666666] font-medium text-sm mt-4">Medium of instruction(s) in the School :</p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-text-input formControlName="medium_of_instruction_1" class="md:col-span-2"
                    [label]="'Medium-1:'"></app-text-input>
                <app-text-input formControlName="medium_of_instruction_2" class="md:col-span-2"
                    [label]="'Medium-2:'"></app-text-input>
                <app-text-input formControlName="medium_of_instruction_3" class="md:col-span-2"
                    [label]="'Medium-3:'"></app-text-input>
                <app-text-input formControlName="medium_of_instruction_4" class="md:col-span-2"
                    [label]="'Medium-4:'"></app-text-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <!-- <p class="text-[#666666] font-medium text-sm mt-4">Medium of instruction(s) in the School :</p> -->
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-select-input class="md:col-span-2" formControlName="is_special_school_for_cwsn"
                    label="School for CWSN?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="type_of_special_school"
                    label="TYPE of Special School:"
                    [options]="[{title: '1-Visual impairments'},{title: '2-Hearing impairments',value:'Hearing impairments'},{title: '3-Motor impairments',value:'Motor impairments'},{title: '4-Cognitive impairments',value:'Cognitive impairments'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_majority_of_pupils_are_of_primary_level"
                    label="Are majority of the pupils taught through their mother tongue at the Primary level? "
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_teacher_attendance_captured"
                    label="Teachers’ attendance captured?"
                    [options]="[{title: '1- Attendance Register',value:' Attendance Register'},{title: '2- Electronically',value:'Electronically'},{title: '3- Biometric',value:'Biometric'}]"></app-select-input>
                <app-text-input class="md:col-span-2" formControlName="is_inventory_register_on_ict_items"
                    [label]="'Inventory Register on ICT Items'"></app-text-input>
                <app-text-input class="md:col-span-2"
                    formControlName="number_of_laboratory_rooms_including_computer_labs"
                    [label]="'Number of Laboratory Rooms including Computer Labs'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="audio_visual_public_address_system"
                    [label]="'Audio/Visual/Public Address System'"></app-text-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <p class="text-[#666666] font-medium text-sm mt-4">Computer or Digital Equipment Status :</p>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-text-input class="md:col-span-2" formControlName="desktop_pcs"
                    [label]="'Desktop/PCs'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="laptop_notebook"
                    [label]="'Laptop/Notebook'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="tablets" [label]="'Tablets'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="pcs_with_integrated_teaching_learning_devices"
                    [label]="'PCs with Integrated Teaching Learning Devices'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="digital_boards_with_content_management_systems"
                    [label]="'Digital Boards with Content Management Systems and solutions (CMS)/ Learning Management System'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="projector"
                    [label]="'Projector'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="printer" [label]="'Printer'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="scanner" [label]="'Scanner'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="server" [label]="'Server'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="web_camera"
                    [label]="'Web Camera'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="smart_tv" [label]="'Smart TV'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="smart_class_rooms_used_for_teaching_with"
                    [label]="'Smart Class rooms used for teaching with Digital boards/ Smart boards/ Virtual classrooms/ Smart TV'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="mobile_phone_used_for_teaching"
                    [label]="'Mobile phone used for teaching'"></app-text-input>
                <app-text-input class="md:col-span-2" formControlName="generator_invertors_power_backup_big_ups"
                    [label]="'Generator/Invertors/Power Backup/Big UPS (above 1KVA)'"></app-text-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <!-- <p class="text-[#666666] font-medium text-sm mt-4">Computer or Digital Equipment Status :</p> -->
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-select-input class="md:col-span-2" formControlName="is_school_have_internet_facility"
                    label="Does the School have Internet Facility?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="type_of_internet_facility_available_in_the_school"
                    *ngIf="school_form.get('is_school_have_internet_facility')?.value == 'Yes'"
                    label="Type of internet facility available in the school:"
                    [options]="[{title: '1-Broadband/Leased Line',value:'Broadband/Leased Line'},{title: '2-USB Modem/dongle/Portable Hotspot',value:'Modem/dongle/Portable Hotspot'},{title: '3-Telephone line with modem',value:'Telephone line with modem'},{title: '4-Mobile phone Internet',value:'Mobile phone Internet'},{title: '5-Any Other type of connection',value:'Any Other type of connection'},{title: '6-VSAT',value:'VSAT'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="is_access_to_internet_facility_used_for_pedagogical_purpose"
                    *ngIf="school_form.get('is_school_have_internet_facility')?.value == 'Yes'"
                    label="Is access to internet facility used for pedagogical purpose?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <!-- <app-select-input class="md:col-span-2" label="Is Computer/ICT Lab available in school?" [options]="[{title: 'Yes'},{title: 'No'}]"></app-select-input> -->
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <!-- <p class="text-[#666666] font-medium text-sm mt-4">Computer or Digital Equipment Status :</p> -->
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-select-input class="md:col-span-2" formControlName="is_computer_ict_lab_available_in_school"
                    label="Is Computer/ICT Lab available in school?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="number_of_ict_labs_available"
                    *ngIf="school_form.get('is_computer_ict_lab_available_in_school')?.value == 'Yes'"
                    label="Number of ICT Labs Available:"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>

                <app-select-input class="md:col-span-2" formControlName="is_only_for_govt_and_govt_aided_schools"
                    label="IS ONLY FOR GOVT. AND GOVT. AIDED SCHOOLS? "
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="is_the_ict_lab_available_in_the_school_under_samagra_siksha"
                    label="ICT Lab available in the school under Samagra Siksha"
                    *ngIf="school_form.get('is_only_for_govt_and_govt_aided_schools')?.value == 'Yes'"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="year_of_implementation"
                    label="Year of implementation:"
                    *ngIf="school_form.get('is_the_ict_lab_available_in_the_school_under_samagra_siksha')?.value == 'Yes'"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="whether_the_ict_lab_is_functional_or_not"
                    label="the ICT Lab is functional or not?"
                    *ngIf="school_form.get('is_the_ict_lab_available_in_the_school_under_samagra_siksha')?.value == 'Yes'"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="which_model_is_implemented_in_the_school"
                    label="Which model is implemented in the school :"
                    *ngIf="school_form.get('is_the_ict_lab_available_in_the_school_under_samagra_siksha')?.value == 'Yes'"
                    [options]="[{title: '1-BOOT Model',value:'BOOT Model'},{title: '2-BOO Model',value:'BOO Model'},{title: '3-Others',value:'Others'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="type_of_the_ict_instructor_in_the_school"
                    label="Type of the ICT Instructor in the school :"
                    *ngIf="school_form.get('is_the_ict_lab_available_in_the_school_under_samagra_siksha')?.value == 'Yes'"
                    [options]="[{title: '1-Full Time',value:'Full Time'},{title: '2-Part Time',value:'Part Time'},{title: '3-Not Available',value:'Not Available'}]"></app-select-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <!-- <p class="text-[#666666] font-medium text-sm mt-4">Computer or Digital Equipment Status :</p> -->
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-select-input class="md:col-span-2"
                    formControlName="whether_ict_based_tools_are_being_used_for_teaching"
                    label="Whether ICT based tools are being used for teaching?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="access_to_e_contents_digital_contents_resources_at_school"
                    label="Access to e-Contents/Digital Contents/resources at school"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="assistive_tech_based_solutions_for_cwsn"
                    label="Assistive tech-based solutions for CWSN"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="access_to_dth_tv_channels"
                    label="Access to DTH/TV channels"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="does_the_school_have_digital_library"
                    label="School have Digital Library?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="number_of_e_books_e_contents_available"
                    label="Number of e-Books/e-Contents available:"
                    *ngIf="school_form.get('does_the_school_have_digital_library')?.value == 'Yes'"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
            </div>
        </div>
        <div class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <!-- <p class="text-[#666666] font-medium text-sm mt-4">Computer or Digital Equipment Status :</p> -->
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-select-input class="md:col-span-2" formControlName="is_trained_for_teaching_cwsn"
                    label="Trained for teaching CWSN?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_trained_in_computer_for_use_in_teaching"
                    label="Trained in Computer for use in teaching?"
                    [options]="[{title: 'Training received',value:'Training received'},{title: 'Training Needs',value:'Training Needs'}]"></app-select-input>
                <!-- <app-text-input class="md:col-span-2" formControlName="" [label]="'Training received'"></app-text-input>
            <app-text-input class="md:col-span-2" formControlName="" [label]="'Training Needs'"></app-text-input> -->
                <!-- <app-text-input class="md:col-span-2" formControlName="is_languages_in_which_the_teacher_is_having_working_knowledge" [label]="'Languages in which the teacher is having working knowledge?'"></app-text-input> -->

                <app-select-input class="md:col-span-2"
                    formControlName="is_languages_in_which_the_teacher_is_having_working_knowledge"
                    label="Languages in which the teacher is having working knowledge?"
                    [options]="[{title: 'Marathi',value:'Marathi'},{title: 'Hindi',value:'Hindi'},{title: 'English',value:'English'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_whether_trained_in_safety_and_security"
                    label="Whether trained in safety and security audit of school for ensuring child safety?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_whether_received_training_on_cyber_safety"
                    label="Whether received training on cyber safety and psycho-social aspects?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="is_whether_received_training_in_early_identification_support"
                    label="Whether received training in early identification support and classroom support of CWSN? "
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2"
                    formControlName="is_whether_having_ict_training_knowledge_to_conduct_classes"
                    label="Whether having ICT training/ knowledge to conduct remote learning classes?"
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
                <app-select-input class="md:col-span-2" formControlName="is_whether_capable_of_handling_digital_devices"
                    label="Whether capable of handling digital devices and VC applications? "
                    [options]="[{title: 'Yes',value:'Yes'},{title: 'No',value:'No'}]"></app-select-input>
            </div>
        </div>
    </div>
    <!-- --------new------- -->
    <div formArrayName="physical_facilities"
        class="bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full mt-6">
        <p class="text-[#666666] font-medium text-sm mt-2">Physical Facilities, Equipment, Computer and Digital
            initiatives</p>
        <div *ngFor="let facility of physical_facilities.controls; let i=index" [formGroupName]="i"
            class="relative border border-slate-300 rounded-lg mt-4 p-4">
            <i *ngIf="!params.view" (click)="removeFacility(i)"
                class="absolute -top-4 -right-4 bg-transparent hover:bg-gray-100 flex items-center justify-center w-10 h-10 bx bx-x-circle rounded-full cursor-pointer hover:text-gray-700 text-2xl text-gray-500"></i>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
                <app-text-input formControlName="laboratory_room" class="md:col-span-2"
                    [label]="'Laboratory Rooms'"></app-text-input>
                <app-text-input formControlName="computer_lab" class="md:col-span-2"
                    [label]="'Computer Labs'"></app-text-input>
                <app-text-input formControlName="type_of_internet_facility" class="md:col-span-2"
                    [label]="'Type of internet facility available'"></app-text-input>
                <app-text-input formControlName="number_of_ict_lab" class="md:col-span-2"
                    [label]="'Number of ICT Labs Available'"></app-text-input>
                <app-select-input formControlName="ict_instructor_type" class="md:col-span-2"
                    label="Type of the ICT Instructor"
                    [options]="[{title: 'Full Time'}, {title: 'Part Time'}, {title: 'Not Available'}]"></app-select-input>
                <app-text-input formControlName="description" class="md:col-span-2"
                    [label]="'Description'"></app-text-input>
            </div>
        </div>
    </div>
    <app-button *ngIf="!params.view" label="ADD Facilities" class="flex justify-end mt-2 mb-2"
        color="text-white bg-primary-500" (click)="add_study_material()"></app-button>

</form>