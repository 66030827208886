import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { NgFor, NgIf } from '@angular/common';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { ModalComponent } from '../../components/modal/modal.component';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { SchoolService } from '../../services/school.service';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { BlockService } from '../../services/block.service';
import { CountryService } from '../../services/country.service';
import { StateService } from '../../services/state.service';
import { DistrictsService } from '../../services/districts.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Papa, ParseResult } from 'ngx-papaparse';

@Component({
  selector: 'app-school-list-page',
  standalone: true,
  templateUrl: './school-list-page.component.html',
  imports: [RouterLink, HeaderComponent, ReactiveFormsModule, NgxUiLoaderModule, ButtonComponent, FilterPillButtonComponent, SerchTextInputComponent, TextInputComponent, SelectInputComponent, IconCtaComponent, NgFor, NgIf, RouterLink, ConfirmationPopupComponent, VenueDetailsModalComponent, ModalComponent, PaginationComponent],
})
export class SchoolListPageComponent {
  open_button: boolean = false
  // @ViewChild('open_attandence') open_attandence: any;
  @ViewChild('add_school_button') add_school_button: any;
  @ViewChild('add_school_modal') add_school_modal: any;
  @ViewChild('open_bulk_modal') open_bulk_modal: any;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  school_file_upload: boolean = true
  mistake_modal: boolean = false
  parsed_data: any[] = []
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  selected_school: any = {}
  list: any = {}
  params: any = {};
  dropdown_list: any = {}
  errorMessage: any;
  show_month_Label: boolean = true || null;
  isReportUrl: boolean = false;
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;


  // close() {
  //   this.open_attandence.close()
  // }
  // open() {
  //   this.open_attandence.open()
  // }

  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public fb: FormBuilder, public ar: ActivatedRoute, public toastr: ToastrService, public ss: SchoolService, public router: Router, public gs: GlobalService, public cs: CountryService, public sts: StateService, public bs: BlockService, public ds: DistrictsService) {
    // this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
    //   this.params.search = searchText;
    //   this.school_list(this.params);
    // });
  }

  school_form: any = this.fb.group({
    udise_code: ['', [Validators.required]],
    name: ['', [Validators.required]],
    city: ['', [Validators.required]],
    state_id: ['', [Validators.required]],
    district_id: ['', [Validators.required]],
    country_id: ['', [Validators.required]],
    block_id: ['', [Validators.required]],
    // udise_block: ['', [Validators.required]],
    address: ['', Validators.required],
    pincode: ['', Validators.required]
  })

  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.school_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.school_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.school_list(this.params);
      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.school_list(this.params);
  }

  block_show_filter(event: any) {
    const selectedBlock = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedBlock) {
      this.currentPage = 1;
      queryParams.block_id = selectedBlock;
    } else {
      this.currentPage = 1;
      queryParams.block_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }


  async school_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.ss.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching ground_level list:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async add() {
    if (this.school_form.valid) {
      this.ui_spinner.startLoader('add-School-loader');
      this.add_school_button.disabled = true
      try {
        let response: any = await this.ss.add(this.school_form.value)
        this.school_form.reset();
        this.add_school_modal.close()
        this.ui_spinner.stopLoader('add-School-loader');
        this.add_school_button.disabled = false
        this.toastr.success(response.message || 'School Added Successfully!')
        console.log(response);
        this.router.navigate(['/master/school'])
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-School-loader');
        this.add_school_modal.close()
        this.add_school_button.disabled = false
        console.error(error?.error?.message, '')
      }
    } else {
      this.school_form.markAllAsTouched();
    }
  }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-school-loader');
    try {
      let response = await this.ss.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.school_list(this.params);
      this.toastr.success(response.message || 'School Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-school-loader');
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-school-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  edit(id: number) {
    this.router.navigate(['/school/form'], { queryParams: { id: id } });
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }
  async get_dropdown_list() {
    try {
      const country_response = await this.cs.get_list({})
      const state_response = await this.sts.get_list({})
      const block_response = await this.bs.get_list({})
      const district_response = await this.ds.get_list({})
      let country_options = country_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let state_options = state_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let block_options = block_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let district_options = district_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.country_list = country_options
      this.dropdown_list.state_list = state_options
      this.dropdown_list.block_list = block_options
      this.dropdown_list.district_list = district_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');;
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.school_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        console.log(key);

        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.school_file_upload = true
        return;
      }
    }
    this.school_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }

  async add_school_bulk() {
    try {

      let new_csv = this.parsed_data.map((item: any) => {
        const country = this.dropdown_list.country_list.find((c: any) => c.title === item.country || item.Country);
        const state = this.dropdown_list.state_list.find((s: any) => s.title === item.state || item.State);
        const district = this.dropdown_list.district_list.find((d: any) => d.title === item.district || item.District);
        const block = this.dropdown_list.block_list.find((b: any) => b.title === item.block || item.Block);

        if (!country) throw ({ message: `Country "${item.country || item.Country}" not found in Master` });
        if (!state) throw ({ message: `State "${item.state || item.State}" not found in Master` });
        if (!district) throw ({ message: `District "${item.district || item.District}" not found in Master` });
        if (!block) throw ({ message: `Block "${item.block || item.Block}" not found in Master` });
        return {
          udise_code: item['UDISE Code'],
          name: item['School Name'],
          country_id: country.value,
          state_id: state.value,
          city: item['City'],
          district_id: district.value,
          block_id: block.value,
          pincode: item['Pincode'],
          location_type: item['School Location Type'],
          village_name: item['Village Name'],
          gram_panchayat_name: item['Gram Panchayat'],
          urban_local_bodies: item['Urban Local Bodies'],
          ward_name: item['Ward'],
          address: item['School Address'],
          cluster_resourse_centre: item['Cluster Resourse Centre'],
          assembly_constituency: item['Assembly Constituency'],
          parliamentary_constituency: item['Parliamentary Constituency'],
          lat: item['Latitude'],
          long: item['Longitude'],
          school_contact_details: item['School Contact'],
          std_code: item['STD Code'],
          landline_number: item['Landline Number'],
          contact_number: item['Mobile Number'],
          school_website: item['School Website'],
          email: item['School Email'],
          head_of_school: item['Head of School'],
          hos_in_charge_name: item['HOS in charge Name'],
          hos_contact_number: item['HOS Contact Number'],
          hos_email: item['HOS Email'],
          respondent_type: item['Respondent Type'],
          respondent_name: item['Respondent Name'],
          respondent_contact_number: item['Respondent Contact Number'],
          special_school_for_cwsn: item['Special School for CWSN'],
        };
      });
      console.log(new_csv);
      const response: any = await this.ss.add(new_csv);
      this.school_list(this.params)
      this.toastr.success(response.message || 'School Added Successfully!')
      this.open_bulk_modal.close();
      this.school_file_upload = true
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.school_file_upload = true
      this.toastr.error(error?.error?.message || error.message || 'Invalid File')
    }
  }

  download_template(): void {
    const url = '../../../assets/template-files/school_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'school_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
