import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { ModalComponent } from '../../components/modal/modal.component';
import * as XLSX from 'xlsx';

import { UdiseService } from '../../services/udise.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime } from 'rxjs';
import { StateService } from '../../services/state.service';
import { DistrictsService } from '../../services/districts.service';
import { Papa, ParseResult } from 'ngx-papaparse';
import { BlockService } from '../../services/block.service';


@Component({
  selector: 'app-udise-list-page',
  standalone: true,
  imports: [RouterLink, HeaderComponent, NgxUiLoaderModule, ButtonComponent, ModalComponent, FilterPillButtonComponent, SerchTextInputComponent, TextInputComponent, SelectInputComponent, IconCtaComponent, NgFor, NgIf, RouterLink, ConfirmationPopupComponent, VenueDetailsModalComponent, RouterLink, PaginationComponent],
  templateUrl: './udise-list-page.component.html',

})
export class UdiseListPageComponent {
  open_button: boolean = false
  // list: any = []

  @ViewChild('fileInput', { static: false })
  // @ViewChild('add_state_modal') add_state_modal: any
  fileInput!: ElementRef;
  udise_file_upload: boolean = true
  mistake_modal: boolean = false
  parsed_data: any[] = []
  selected_udise: any = {};
  list: any = {};
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  dropdown_list: any = {}
  searchSubject: Subject<string> = new Subject<string>();
  @ViewChild('searchInput') searchInput: any;
  @ViewChild('open_bulk_modal') open_bulk_modal: any;
  errorMessage: any;
  constructor(public ui_spinner: NgxUiLoaderService, public papa: Papa, public ar: ActivatedRoute, public us: UdiseService, public bs: BlockService, public router: Router, public toastr: ToastrService, public ss: StateService, public ds: DistrictsService) {
    // this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
    //   this.params.search = searchText;
    //   this.get_state_list(this.params);
    // });
  }
  params: any = {}
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_state_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_state_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_state_list(this.params);
      }
    });
    this.get_dropdown_list()
  }

  async get_dropdown_list() {
    try {
      const state_response = await this.ss.get_list({})
      const district_response = await this.ds.get_list({})
      const block_response = await this.bs.get_list({})
      let district_options = district_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let state_options = state_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let block_options = block_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.district_list = district_options
      this.dropdown_list.state_list = state_options
      this.dropdown_list.block_list = block_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_state_list(this.params);
  }

  async get_state_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.us.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching State list:", error);
      // this.toastr.error(error?.error?.message)
    }
  }

  // async delete_confirm(id: number) {
  //   let response = await this.us.delete(id);
  //   this.list.data = this.list?.data.filter((item: any) => item.id != id);
  //   this.toastr.success(response.message)
  //   // this.state_delete.close()
  // }

  async delete_confirm(id: number) {
    this.ui_spinner.startLoader('delete-udise-loader');
    try {
      let response = await this.us.delete(id);
      this.list.data = this.list?.data.filter((item: any) => item.id != id);
      if (this.list.data.length === 0 && this.currentPage > 1) {
        this.currentPage -= 1;
      }
      await this.get_state_list(this.params);
      this.toastr.success(response.message || 'UDISE Deleted Successfully!')
      this.ui_spinner.stopLoader('delete-udise-loader');
    } catch (error: any) {
      console.error(error?.error?.message, '')
      this.ui_spinner.stopLoader('delete-udise-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  // async onSearch(searchText: string) {
  //   if (searchText) {
  //     this.currentPage = 1;
  //     this.params.search = searchText;
  //     this.searchSubject.next(searchText);
  //   } else {
  //     this.currentPage = 1;
  //     delete this.params['search'];
  //     this.get_state_list(this.params);
  //   }
  // }
  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.searchSubject.next('');  // Trigger the searchSubject with an empty string
      this.searchInput.nativeElement.value = '';  // Clear the input field
    }
  }

  on_file_change(event: any) {
    this.parsed_data = [];
    const file = event.target.files[0];
    if (file.name.split('.').pop() != 'csv') {
      this.toastr.error('Please, upload a valid CSV file!');
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      this.toastr.error('File size exceeds 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvData = e.target.result;

      // Parse CSV
      this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          this.validate_csv_data(result.data);
        }
      });
    };
    reader.readAsText(file);
  }

  validate_csv_data(data: any[]) {
    if (!data || data.length === 0) {
      this.toastr.error('CSV file is empty');
      this.udise_file_upload = true
      return;
    }

    const headers = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowData: any = {};

      let isValid = true;
      for (const key of headers) {
        if (!row[key]) {
          this.toastr.error(`Empty cell found at row ${i + 1}, column ${key}`);
          isValid = false;
        } else {
          rowData[key] = row[key];
        }
      }

      if (isValid) {
        console.log("valid");
        this.parsed_data.push(rowData);
      } else {
        console.log("Invalid");
        this.udise_file_upload = true
        return;
      }
    }
    this.udise_file_upload = false
    console.log('Parsed Data:', this.parsed_data);
  }

  async add_udise_bulk() {
    try {

      let new_csv = this.parsed_data.map((item: any) => {
        const state = this.dropdown_list.state_list.find((s: any) => s.title === item.state || item.State);
        const district = this.dropdown_list.district_list.find((d: any) => d.title === item.district || item.District);
        const block = this.dropdown_list.block_list.find((b: any) => b.title === item.block || item.Block);

        if (!state) throw ({ message: `State "${item.state || item.State}" not found in Master` });
        if (!district) throw ({ message: `District "${item.district || item.District}" not found in Master` });
        if (!block) throw ({ message: `Block "${item.block || item.Block}" not found in Master` });
        return {
          udise_code: item['UDISE Code'],
          school_code: item['School Code'] + '',
          school_name: item['School Name'],
          primary_contact_number: item['Primary Contact Number'] + '',
          secondary_contact_number: item['Secondary Contact Number'] + '',
          city: item.city || item.City,
          district_id: district.value,
          state_id: state.value,
          block_id: block.value,
          pincode: item.pincode || item.Pincode,
          lat: item.latitude|| item.Latitude,
          long: item.longitude || item.Longitude,
          description: item.description || item.Description,
        };
      });

      console.log(new_csv);
      const response: any = await this.us.add(new_csv);
      this.get_state_list(this.params)
      this.toastr.success(response.message || 'UDISE Added Successfully!')
      this.open_bulk_modal.close();
      this.udise_file_upload = true
    } catch (error: any) {
      console.error("Error while adding data:", error);
      this.udise_file_upload = true
      this.toastr.error(error?.error?.message || error.messages)
    }
  }


  download_template(): void {
    const url = '../../../assets/template-files/udise_template.csv';
    const link = document.createElement('a');
    link.href = url;
    link.download = 'udise_template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  edit(id: number) {
    this.router.navigate(['/udise/form'], { queryParams: { id: id } });
  }

}
