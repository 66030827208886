<app-header header_title="Course">
    <app-button *ngIf="gs.user.role == 'Super Admin'" tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add Course" routerLink="/master/course/form"></app-button>
</app-header>
<div class="flex flex-col sm:flex-row justify-between mb-5 gap-3 px-8">
    <div class="w-full flex justify-end items-center gap-3 md:w-96">

        <app-select-input *ngIf="gs.user.role != 'Student'" class="w-full" label="Grade" [options]="dropdown_list.grade_list" (change)="grade_show_filter($event)"></app-select-input>
    </div>
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-serch-text-input #searchInput class="w-full md:w-auto" placeholder="Search Course Name" (valueChange)="onSearch($event)"></app-serch-text-input>
        <!-- <app-filter-pill-button label="Filter" icon="bx bx-filter-alt" (click)="toggle_filter()"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list?.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list?.data?.length > 0" class="overflow-x-auto bg-white border border-[#7B7FDE] rounded-lg">
        <table class=" table-auto w-full border-collapse ">
            <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-nowrap text-center">Sr No</th>
                    <th class="px-4 h-12 text-nowrap text-left">Course Name</th>
                    <th class="px-4 h-12 text-nowrap text-left">Language</th>
                    <th class="px-4 h-12 text-nowrap text-left" *ngIf="gs.user.role != 'Instructor'">Course Duration
                    </th>
                    <th class="px-4 h-12 text-nowrap text-left">Grade</th>
                    <th *ngIf="gs.user.role == 'Instructor'" class="px-4 h-12 text-nowrap text-left">Progress</th>
                    <th class="px-4 h-12 text-nowrap text-center">Action</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px] ">
                <tr *ngFor="let item of list?.data ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left capitalize">{{item.name}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left capitalize">{{item.language}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left capitalize" *ngIf="gs.user.role != 'Instructor'">
                        {{item.duration}}</td>
                    <td class="px-4 h-12 truncate cursor-pointer text-left">{{item.grade_name}}</td>
                    <td *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator'" class="px-4 h-12 text-left">
                        <div class="w-full bg-gray-200 rounded-full">
                            <div class="bg-primary-100 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style="width: 45%"> {{item.progress}}%</div>
                        </div>
                    </td>
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta icon="bx bx-show" tooltip="view" routerLink="/course/view" [queryParams]="{id: item.id, view: 'true'}"></app-icon-cta>
                            <app-icon-cta *ngIf="gs.user.role == 'Super Admin'" routerLink="/master/course/form" [queryParams]="{id: item.id}" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta *ngIf="gs.user.role == 'Super Admin'" (click)="delete_course.open(); selected_course = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                            <app-icon-cta *ngIf="gs.user.role == 'Instructor'" (click)="open_progress_modal.open()" icon="bx bx-calendar-edit" tooltip="Update Progress"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="list?.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>
<app-confirmation-popup #delete_course confirmation_message="Are you sure you want to Delete this BRC Assignment" popup_title="Delete BRC Assignment">
    <app-button label="Delete" (click)="delete_confirm(selected_course.id)" color="bg-[#3F41D1] text-white" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-cources-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>