<!-- <button type="button" class="flex justify-center items-center " [ngClass]="{'bg-blue-700 hover:bg-blue-600 px-2': color === 'primary','bg-orange-500 hover:bg-orange-600': color === 'orange','bg-gray-900 hover:bg-gray-500': color === 'secondary','bg-green-900 hover:bg-green-600': color === 'success', 'bg-red-900 hover:bg-red-600': color === 'error','bg-yellow-900 hover:bg-yellow-600': color === 'warning','py-3 px-4 text-xs': size === 'sm','py-3 px-5 text-base': size === 'md', 'py-1.5 px-4 flex justify-center items-center rounded-full text-xl': size === 'lg','flex font-semibold focus:outline-none items-center justify-center rounded-3xl text-white': true,'flex-row-reverse': icon_alignment === 'right'}">
    <i class="py-1" [class]="icon" class="text-xl -mt-[1px]" [ngClass]="{'ml-2': icon_alignment === 'right'}"></i>
    <span *ngIf="placement == 'header'" class="m-1 text-base hidden md:block text-nowrapblock">{{ label }}</span>
    <span *ngIf="placement != 'header'" class="m-1 text-base text-nowrap block">{{ label }}</span>
</button> -->

<button type="button" [classList]="color" [disabled]="disabled" [ngClass]="{'pl-4 pr-6 gap-2': icon}" class="flex justify-center items-center uppercase hover:shadow-md focus:shadow-lg font-medium rounded-full text-sm px-12 h-10 text-center">
    <i *ngIf="icon" [class]="icon" class="text-xl"></i>
    {{label}}
    <ng-content></ng-content>
</button>