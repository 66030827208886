import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HeaderComponent } from '../components/header/header.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FilterDrawerComponent } from '../components/filter-drawer/filter-drawer.component';
import { ButtonComponent } from '../components/button/button.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FilterPillButtonComponent } from '../components/filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../components/serch-text-input/serch-text-input.component';
import { TextInputComponent } from '../components/inputs/text-input/text-input.component';
import { SelectInputComponent } from '../components/inputs/select-input/select-input.component';
import { IconCtaComponent } from '../components/icon-cta/icon-cta.component';
import { ModalComponent } from '../components/modal/modal.component';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { VenueDetailsModalComponent } from '../components/venue-details-modal/venue-details-modal.component';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-courses-detail',
  standalone: true,
  imports: [RouterLink, HeaderComponent, NgClass, FilterDrawerComponent, ButtonComponent, NgCircleProgressModule, FilterPillButtonComponent, SerchTextInputComponent, TextInputComponent, SelectInputComponent, IconCtaComponent, NgFor, NgIf, RouterLink, ModalComponent, ConfirmationPopupComponent, VenueDetailsModalComponent],
  templateUrl: './courses-detail.component.html',
  styleUrl: './courses-detail.component.css'
})
export class CoursesDetailComponent {

  constructor(public gs: GlobalService) { }


  
  option:any='Image';

  list = [
    { id:1, language:'Marathi', cource_name: 'ICT Training for grade 6', cource_duration: "12 weeks", progress: 20, grade: '6', course_code:'1111' },
    { id:2, language:'English', cource_name: 'ICT Training for grade 8',  cource_duration: "8 weeks", progress: 49, grade: '8', course_code:'1112' },
    { id:3, language:'Hindi',   cource_name: 'ICT Training for grade 9',  cource_duration: "6 weeks", progress: 38, grade: '9', course_code:'1113' },
    { id:4, language:'English', cource_name: 'ICT Training for grade 10', cource_duration: "6 weeks", progress: 55, grade: '10', course_code:'1114' },
  ]

  lessons_fields: any[] = [
    { lessons: "Mathematics", description: "Basic concepts of arithmetic and algebra", status: "In Progress" },
    { lessons: "Science", description: "Introduction to biology, chemistry, and physics", status: "Completed" },
    { lessons: "History", description: "World history from ancient civilizations to modern times", status: "Pending" }
  ];

  images:any=[
    // {image:'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'},
  ]
  
  videos:any=[
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
    {video:'https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg'},
  ]

 imageUrl: string | ArrayBuffer | null = null;
 previewImages:any;
 selectedImages:any

  getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

previewImage: string | undefined = '';
previewVisible = false;
view = false
courseForm:any
current_data:any

ngOnInit() {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result; // Assign the result directly
        this.displayPreview(file)
      };
    }
  }

  onImageChange(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {
        this.selectedImages.push(file);
        this.displayPreview(file);
      }
    }
  }

  displayPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.images.push(reader.result as string);
    };
  }


}
