import { Component } from '@angular/core';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { SelectInputComponent } from '../inputs/select-input/select-input.component';
import { SerchTextInputComponent } from '../serch-text-input/serch-text-input.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-services-view',
  standalone: true,
  imports: [TextInputComponent,SelectInputComponent, SerchTextInputComponent, NgIf],
  templateUrl: './services-view.component.html'
})
export class ServicesViewComponent {
  request_modal: boolean = false;

  access_success() {
    this.request_modal = !this.request_modal
  }
}
