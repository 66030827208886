<app-header header_title="{{params.view ? 'View ' : params.id ? 'Update' : 'Add'}} Ground Activity">
    <app-button *ngIf="!params.view" color="bg-white text-[#666666] border-gray-300 border" [label]="'Cancel'" routerLink="/ground-activity"></app-button>
    <app-button *ngIf="path.includes('reports')" color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel" placement="header" routerLink="/reports/ground-activity"></app-button>
    <app-button *ngIf="!params.id" color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" #add_ground_activitie_button
        (click)="add()" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="add-udise-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader>
    </app-button>
    <app-button *ngIf="params.id && !(params.id && params.view)" color="bg-[#E697AB] text-[#310000]" [label]="'Update'"
        #update_ground_activitie_button (click)="edit(params.id)" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
            class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent"
            fgsColor="#FFF" loaderId="update-udise-loader" [hasProgressBar]="false" fgsType="fading-circle"
            [fgsSize]="25"></ngx-ui-loader></app-button>
</app-header>
<form [formGroup]="ground_activity_form" class="px-8 w-full">
    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">Basic Information</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new report
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4 w-full">
            <app-date-input [disabled]="params.view" formControlName="date" class="w-full" label="Date"
                [minDateInput]="current_date_show"></app-date-input>
            <app-select-input [disabled]="params.view" formControlName="grade_id" class="w-full" label="Grade"
                [options]="dropdown_list.grade_list"></app-select-input>
            <app-select-input [disabled]="params.view" formControlName="activity_type_id" class="w-full"
                label="Activity Type" [options]="dropdown_list.activitie_type_list"></app-select-input>
                <app-text-input *ngIf="params.view" [disabled]="params.view" formControlName="school_udise_code" label="UDISE Code"></app-text-input>
        </div>
        <div class="w-full mt-4">
            <app-text-area-input [disabled]="params.view" formControlName="description" label="Description"
                class="w-full"></app-text-area-input>
        </div>
    </div>
    <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
        <p class="font-normal text-base text-text-color-600">Proofs</p>
        <div
            class="bg-white flex flex-col md:mt-3 p-4 rounded-lg">
            <p *ngIf="params.view && ground_activity_form.get('image').value != '' || !params.view && ground_activity_form.get('image').value == ''" class="font-normal text-base capitalize text-text-color-600">Image</p>
            <app-zoro-file-input formControlName="image" class="w-full" label="Files"
                placeholder="Enter Job Id" supported_extension=".png,.jpg,.jpeg,.svg,.pdf,.csv,.xlsx"></app-zoro-file-input>
        </div>
        <div
            class="bg-white flex flex-col md:mt-7 p-4 rounded-lg">
            <p *ngIf="params.view && ground_activity_form.get('video').value != '' || !params.view && ground_activity_form.get('video').value == ''" class="font-normal text-base capitalize text-text-color-600">Video</p>
            <app-zoro-file-input formControlName="video" class="w-full" label="Files"
                placeholder="Enter Job Id" supported_extension=".mp4,.mov,.avi,.wmv"></app-zoro-file-input>
        </div>
    </div>
</form>