<app-header header_title="UDISE">
    <div class="dropdown">
        <app-button tabindex="0" role="button" color="bg-[#E697AB] text-[#310000]" placement="header" icon="bx bx-plus" label="Add UDISE"></app-button>
        <ul tabindex="0" class="dropdown-content absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" routerLink="/master/udise/form">Add UDISE</p>
            </li>
            <li>
                <p class="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer" (click)="open_bulk_modal.open()">Add Bulk UDISE</p>
            </li>
        </ul>
    </div>
</app-header>
<div class="flex flex-col sm:flex-row justify-between mb-5 gap-3 px-8">
    <div class="w-full flex items-center gap-3 md:w-96">
    </div>
    <div class="w-full flex justify-end items-center gap-3 md:w-96">
        <app-serch-text-input #searchInput class="w-full md:w-auto" placeholder="Search UDISE Code,School Name" (valueChange)="onSearch($event)"></app-serch-text-input>
        <!-- <app-filter-pill-button label="Filter" icon="bx bx-filter-alt"></app-filter-pill-button> -->
    </div>
</div>
<div class="print-section px-8 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-x-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto w-full border-collapse ">
            <thead class="text-sm font-medium text-[#3F41D1] border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-nowrap text-center">Sr No</th>
                    <th class="px-4 h-12 text-nowrap text-left">UDISE Code</th>
                    <th class="px-4 h-12 text-nowrap text-left">
                        <span class="flex items-center gap-2">
                            <span>School Name</span>
                            <div class="flex flex-col">
                                <i [routerLink]="'/master/udise'" [queryParams]="{school_name_sort : 'ascending'}" class='cursor-pointer text-xs bx bxs-up-arrow'></i>
                                <i [routerLink]="'/master/udise'" [queryParams]="{school_name_sort : 'descending'}" class='cursor-pointer text-xs bx bxs-down-arrow'></i>
                            </div>
                        </span>
                    </th>
                    <th class="px-4 h-12 text-nowrap text-left">
                        <span class="flex items-center gap-2">
                            <span>City</span>
                            <div class="flex flex-col">
                                <i [routerLink]="'/master/udise'" [queryParams]="{city_sort : 'ascending'}" class='cursor-pointer text-xs bx bxs-up-arrow'></i>
                                <i [routerLink]="'/master/udise'" [queryParams]="{city_sort : 'descending'}" class='cursor-pointer text-xs bx bxs-down-arrow'></i>
                            </div>
                        </span>
                    </th>
                    <th class="px-4 h-12 text-nowrap text-left">State</th>
                    <th class="px-4 h-12 text-nowrap text-left">Pincode</th>
                    <th class="px-4 h-12 text-nowrap text-left">Lat / Long</th>
                    <th class="px-4 h-12 text-nowrap text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-[#212121] tracking-[0.32px] ">
                <tr *ngFor="let item of list.data ; let i = index" class="border-b border-[#CFD5F1]">
                    <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td [title]="item.udise_code" class="px-4 h-12 truncate cursor-pointer text-left">
                        {{item.udise_code}}</td>
                    <td [title]="item.school_name" class="capitalize px-4 h-12 truncate cursor-pointer text-left">
                        {{item.school_name}}</td>
                    <td [title]="item.city" class="px-4 h-12 truncate cursor-pointer text-left capitalize">{{item.city}}</td>
                    <td [title]="item.state" class="px-4 h-12 truncate cursor-pointer text-left capitalize">{{item.state_name}}
                    </td>
                    <td [title]="item.pincode" class="px-4 h-12 truncate cursor-pointer text-left">{{item.pincode}}</td>
                    <td [title]="item.lat_long" class="px-4 h-12 truncate cursor-pointer text-left">{{item.lat}} /
                        {{item.long}}</td>
                    <td class="px-4 h-12 border-b flex items-center justify-center ">
                        <div class="flex gap-2 items-center justify-center">
                            <app-icon-cta routerLink="/master/udise/form" [queryParams]="{id: item.id}" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta (click)="udise_delete.open(); selected_udise = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>

<app-modal #open_bulk_modal title="Add Bulk UDISE">
    <div class="w-full flex flex-col gap-4">
        <div *ngIf="!udise_file_upload" class="flex justify-between items-center">
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <div class="flex items-center gap-5">
                <app-filter-pill-button icon="bx bx-upload" label="Reupload Udise File" (click)="udise_file_upload = !udise_file_upload"></app-filter-pill-button>
                <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                    Download Template
                </button>
            </div>
        </div>
        <div *ngIf="udise_file_upload" class='w-full flex justify-between items-center'>
            <p class="text-[16px] flex items-center gap-1">Upload File</p>
            <button class="border border-[#3F41D1] text-[#3F41D1] font-medium cursor-pointer rounded-full text-sm px-6 h-10 text-center uppercase hover:shadow-md focus:shadow-lg" (click)="download_template()">
                Download Template
            </button>
        </div>
        <div *ngIf="udise_file_upload" class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg class="w-8 h-8 mb-4 text-[666666]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-[666666]"><span class="font-semibold">Click to upload</span> or drag and
                        drop</p>
                        <p class="text-xs text-[666666]">CSV (MAX. 5MB)</p>
                </div>
                <input #fileInput id="dropzone-file" type="file" class="hidden" accept=".csv" (change)="on_file_change($event)" />
            </label>
        </div>
        <div *ngIf="errorMessage" class="mt-2 text-red-600">{{ errorMessage }}</div>
        <div *ngIf="!udise_file_upload" class="end-full bg-white border border-primary rounded-lg overflow-x-auto">
            <table class="table-auto text-text-color-600 w-full border-collapse">
                <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px] overflow-auto">
                    <tr>
                        <th class="px-4 h-12 text-center">UDISE Code</th>
                        <th class="px-4 h-12 text-center">School Code</th>
                        <th class="px-4 h-12 text-center">School Name</th>
                        <th class="px-4 h-12 text-center">Primary Contact Number</th>
                        <th class="px-4 h-12 text-center">Secondary Contact Number</th>
                        <th class="px-4 h-12 text-center">City</th>
                        <th class="px-4 h-12 text-center">State</th>
                        <th class="px-4 h-12 text-center">District</th>
                        <th class="px-4 h-12 text-center">Pincode</th>
                        <th class="px-4 h-12 text-center">Latitude</th>
                        <th class="px-4 h-12 text-center">Longitude</th>
                        <th class="px-4 h-12 text-center">Description</th>
                        <th class="px-4 h-12 text-center">Block</th>
                    </tr>
                </thead>
                <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] overflow-auto">
                    <tr *ngFor="let item of parsed_data" class="border-b border-[#CFD5F1]">
                        <td class="px-4 h-12 text-center">{{ item['UDISE Code'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['School Code'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['School Name'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Primary Contact Number'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Secondary Contact Number'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['City'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['State'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['District'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Pincode'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Latitude'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Longitude'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Description'] }}</td>
                        <td class="px-4 h-12 text-center">{{ item['Block'] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-button (click)="add_udise_bulk()" class="self-end" *ngIf="!udise_file_upload" color="bg-primary-500 text-white" icon="bx bx-plus" [label]="'Add ' + (parsed_data.length) + '  UDISE'"></app-button>
    </div>
</app-modal>
<!-- <app-confirmation-popup routerLink="/master/udise/form" #udise_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this UDISE?" popup_title="Edit UDISE"></app-confirmation-popup> -->
<app-confirmation-popup #udise_edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this UDISE?" popup_title="Edit UDISE"></app-confirmation-popup>

<app-confirmation-popup #udise_delete confirmation_message="Are you sure you want to Delete this UDISE?" popup_title="Delete State">
    <app-button label="Delete" class="relative" (click)="delete_confirm(selected_udise.id);udise_delete.close()" color="bg-[#3F41D1] text-white">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-udise-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>