import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CountryService } from '../../services/country.service';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VenueAuditViewComponent } from '../../components/venue-audit-view/venue-audit-view.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FilterDrawerComponent } from '../../components/filter-drawer/filter-drawer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ConfirmationPopupComponent } from '../../components/confirmation-popup/confirmation-popup.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { ButtonComponent } from '../../components/button/button.component';
import { IconCtaComponent } from '../../components/icon-cta/icon-cta.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { VenueDetailsModalComponent } from '../../components/venue-details-modal/venue-details-modal.component';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { CheckboxInputComponent } from '../../components/inputs/checkbox-input/checkbox-input.component';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { CourseService } from '../../services/course.service';
import { GradeService } from '../../services/grade.service';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressService } from '../../services/progress.service';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-assigned-course-list-page',
  standalone: true,
  imports: [NgxUiLoaderModule, RouterLink, HeaderComponent, NgClass, FilterDrawerComponent, ButtonComponent, NgCircleProgressModule, FilterPillButtonComponent, SerchTextInputComponent, TextInputComponent, SelectInputComponent, IconCtaComponent, NgFor, NgIf, RouterLink, ModalComponent, ConfirmationPopupComponent, VenueDetailsModalComponent, PaginationComponent],
  templateUrl: './assigned-course-list-page.component.html'
})
export class AssignedCourseListPageComponent {
  open_button: boolean = false
  @ViewChild('open_progress_modal') open_progress_modal: any
  @ViewChild('delete_course') delete_course: any
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef;
  course_file_upload: boolean = true
  mistake_modal: boolean = false
  table_headers: any = [];
  table_data: any = [];
  form: any;
  is_filter_open: boolean = false;
  selected_item = ''
  list: any = {}
  selected_course_for_progress: any = {}
  intervalId: any;

  filter_options = [
    { label: 'Language', type: 'text', title: 'Language', icon: "bx bx-calendar" },
    { label: 'Grade', type: 'text', title: 'Grade', icon: "bx bx-calendar" },
    { label: 'Course', type: 'text', title: 'Course', icon: "bx bx-edit-alt" },
  ]
  constructor(public ui_spinner: NgxUiLoaderService, public gs: GlobalService, public router: Router, public ar: ActivatedRoute, public toastr: ToastrService, public is: InstructorAssignmentService, public gds: GradeService, public ps: ProgressService) { }
  counter: number = 2;

  debounce_timeout: any = undefined;
  params: any = {};
  selected_course: any = {};
  dropdown_list: any = {}
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;

  progress_count: any;
  total: any;
  api_data: any = {}
  progressPercent: any = 0;
  searchSubject: Subject<string> = new Subject<string>();

  async ngOnInit() {
    this.progress()
    // await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_assigned_course_list(this.params);
    })
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      if (searchText) {
        this.params.search = searchText;
        this.get_assigned_course_list(this.params);
      } else {
        this.currentPage = 1;
        delete this.params['search'];
        this.get_assigned_course_list(this.params);
      }
    });
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_assigned_course_list(this.params);
  }

  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_assigned_course_list(this.params);
    }
  }

  open_progress(course_data: any) {
    this.selected_course_for_progress = course_data
    this.counter = course_data?.progress
    this.open_progress_modal.open();
  }

  school_show_filter(event: any) {
    const selectedSchool = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedSchool) {
      this.currentPage = 1;
      queryParams.language = selectedSchool;
    } else {
      this.currentPage = 1;
      queryParams.language = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  async get_assigned_course_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.is.get_list(filter)
      this.api_data = this.list
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching course list:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  async update_course_progress(id: any) {
    console.log(id);
    try {
      let data = await this.ps.update(id, { progress: this.counter })
      this.open_progress_modal.close()
      this.get_assigned_course_list(this.params)
      this.toastr.success(data.message)
    } catch (error: any) {
      console.error("Error fetching course:", error);
      this.toastr.error(error?.error?.message)
    }
  }

  increment(): void {
    this.counter++;
  }
  decrement(): void {
    this.counter--;
  }
  reset(): void {
    this.counter = 0;
  }


  toggle_filter() {
    this.is_filter_open = !this.is_filter_open
  }

  selected_section(selected: string) {
    this.selected_item = selected
  }

  detail(i: any) {
    let id = this.list[i].id
    let language = this.list[i].language
    this.router.navigate(['dashboard/course/form/'], { queryParams: { language: language, id: id, view: 'true' } });
    // console.log('dashboard/course/form/','language',i);

  }

  progress(): number {
  //   this.progressPercent = (this.selected_course_for_progress?.progress / this.selected_course_for_progress?.course_details?.sub_course_count) * 100;
  // return(this.selected_course_for_progress?.progress / this.selected_course_for_progress?.course_details?.sub_course_count) * 100;
  if (this.selected_course_for_progress?.course_details?.sub_course_count) {
    return (this.selected_course_for_progress.progress / this.selected_course_for_progress.course_details.sub_course_count) * 100;
  }
  return 0;
  }

formatPercentTitle = (percent: number): string => {
  return `${percent.toFixed(0)}`;
};


calculateProgress(item: any): number {
  if (item?.course_details?.sub_course_count) {
    return (item.progress / item.course_details.sub_course_count) * 100;
  }
  return 0;
}


}
