<div class="fixed inset-0 z-40 bg-black opacity-50" [class.hidden]="!is_open" (click)="close_drawer()"></div>

<div [class.translate-x-full]="!is_open" class="fixed inset-y-0 right-0 bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out">
    <div class="border-b flex gap-10 items-center justify-between px-8 py-3">
        <h2 class="text-lg font-semibold">{{drawer_title}}</h2>
        <i (click)="close_drawer()" class='absolute cursor-pointer flex items-center justify-center w-8 h-8 text-2xl rounded-full bx bx-x right-8 top-3 hover:bg-black/10'></i>
    </div>
    <div class="px-8 py-6 h-full w-full overflow-y-auto">
        <ng-content></ng-content>
    </div>
</div>
