<div class="relative">
    <input [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="onChange($event)"
        type="text" [id]="label"
        class="block px-4 h-12 w-full text-sm text-gray-900 bg-white border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
        [placeholder]="placeholder" />
    <label [for]="label"
        class="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[rgba(0,0,0,0.6)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{{label}}<span
            *ngIf="is_required" class="col-span-2 text-red-600">*</span></label>
</div>
<div *ngIf="control && control.errors && control.touched" class="error-messages">
    <p class="text-red-500" *ngIf="control.errors['required']">This field is required.</p>
    <p class="text-red-500" *ngIf="control.errors['minlength']">Minimum length required is {{
        control.errors['minlength'].requiredLength }}.
    </p>
    <p class="text-red-500" *ngIf="control.errors['maxlength']">Maximum length allowed is {{
        control.errors['maxlength'].requiredLength }}.
    </p>
</div>