<div class="flex md:bg-gray-100 md:items-center md:justify-center lg:bg-white h-full">
    <div
        class="justify-center lg:justify-end items-center flex h-full lg:lg:w-6/12 md:items-center md:justify-center w-full xl:justify-end">
        <div
            class="bg-gray-100 md:bg-white md:px-12 md:py-12 md:rounded-2xl md:shadow-md h-full md:w-[416px] lg:shadow-none lg:lg:w-8/11 px-4 py-8 w-full xl:mt-4 xl:pl-4 lg:pt-20 xl:pt-28">
            <img class="h-12" src="https://angular-material.fusetheme.com/assets/images/logo/logo.svg" alt="">
            <div class="flex flex-col">
                <p class="font-extrabold font-inter font-sans pt-8 text-blue-gray-800 text-3xl xl:pt-6.5">Sign In</p>
                <p class="font-medium font-sans text-sm pt-0.5">Welcome To ICT Training</p>
            </div>
            <div [formGroup]="form" class="flex flex-col gap-4 pt-7 xl:pt-6">
                <div class="flex flex-col gap-1.5">
                    <p class="font-sans font-normal text-base text-blue-gray-800 xl:pt-1">Email Address <span
                            class="text-red-500">*</span></p>
                    <input formControlName="email"
                        class="border border-gray-300 border-solid px-4 py-3.5 rounded text-sm dark:bg-white"
                        type="text" value="shubham@gmail.com" placeholder="">
                    <div class="flex">
                        <span *ngIf="form.controls.email.invalid && form.controls.email.dirty"
                            class="ml-2 text-xs text-red-500">Please Enter A Valid Email Address</span>
                    </div>
                </div>
                <div class="flex flex-col gap-1.5" *ngIf="!forget_password">
                    <p class="font-sans font-normal text-base text-blue-gray-800">Password <span
                            class="text-red-500">*</span></p>
                    <div
                        class="flex border border-gray-300 border-solid bg-white items-center justify-between px-4 py-2.5 rounded text-sm">
                        <input formControlName="password" class="outline-none pr-2 w-full dark:bg-white"
                            type="{{ passwordVisible ? 'text' : 'password' }}" value="admin123" placeholder="">
                        <i (click)="togglePasswordVisibility()" class='bx bxs-show cursor-pointer items-center text-xl'
                            style='color:#cdcaca'></i>
                    </div>
                    <span *ngIf="form.controls.password.errors?.['required'] && form.controls.password.dirty"
                        class="ml-2 text-xs text-red-500">Password is required</span>
                    <span *ngIf="form.controls.password.errors?.['minlength'] && form.controls.password.dirty"
                        class="ml-2 text-xs text-red-500">Password must be at least 7 characters long</span>
                </div>
                <div class="flex flex-col gap-1.5" *ngIf="forget_password">
                    <p class="font-sans font-normal text-base text-blue-gray-800">OTP <span
                            class="text-red-500">*</span></p>
                    <div
                        class="flex border border-gray-300 border-solid bg-white items-center justify-between px-4 py-2.5 rounded text-sm">
                        <input formControlName="otp" class="outline-none pr-2 w-full dark:bg-white appearance-none"
                            type="number" placeholder="">
                        </div>
                        <span *ngIf="form.controls['otp'].errors?.['required'] && form.controls['otp'].touched" class="ml-2 text-xs text-red-500">Otp is required</span>
                    <span *ngIf="form.controls.password.errors?.['required'] && form.controls.password.dirty"
                        class="ml-2 text-xs text-red-500">Password is required</span>
                    <span *ngIf="form.controls.password.errors?.['minlength'] && form.controls.password.dirty"
                        class="ml-2 text-xs text-red-500">Password must be at least 7 characters long</span>
                </div>
                <div class="flex items-center justify-between md:pt-4.5 pt-4">
                    <div class="flex gap-4 items-center">
                        <input class="form-checkbox cursor-pointer h-4 w-4 dark:bg-white" type="checkbox">
                        <p class="font-normal font-sans text-sm">Remember Me</p>
                    </div>
                    <p (click)="forgot_password()"
                        class="cursor-pointer font-sans font-medium hover:underline text-sm text-blue-500">Forgot
                        Password?
                    </p>
                </div>
            </div>
            <div class="md:pt-10 pt-6 xl:pt-[43px]">
                <button *ngIf="!forget_password" (click)="sign_in()"
                    class="relative bg-indigo-600 font-medium md:text-sm md:py-3.5 py-3 text-white rounded-full w-full xl:text-sm">Sign
                    In
                    <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
                        class="absolute top-1/2 right-20 transform -translate-x-1/2 -translate-y-1/2"
                        overlayColor="transparent" fgsColor="#FFF" loaderId="signin-loader" [hasProgressBar]="false"
                        fgsType="fading-circle" [fgsSize]="30"></ngx-ui-loader>
                </button>
                <button *ngIf="forget_password" (click)="verigy_otp()"
                    class="relative bg-indigo-600 font-medium md:text-sm md:py-3.5 py-3 text-white rounded-full w-full xl:text-sm">Verify OTP
                    <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)"
                        class="absolute top-1/2 right-20 transform -translate-x-1/2 -translate-y-1/2"
                        overlayColor="transparent" fgsColor="#FFF" loaderId="signin-loader" [hasProgressBar]="false"
                        fgsType="fading-circle" [fgsSize]="30"></ngx-ui-loader>
                </button>
            </div>
        </div>
    </div>
    <div
        class="bg-slate-800 flex-col hidden h-full justify-center md:hidden p-28 w-6/12 lg:block lg:p-16 lg:pt-52 xl:p-20 xl:pt-64">
        <p class="font-bold text-white text-5xl">Welcome To <br>Our ICT Training</p>
        <p class="font-normal pt-6 text-gray-400">Experience a smarter way to manage your school with our innovative web
            application. Designed to handle all aspects of school operations, our app helps administrators, teachers,
            and students stay organized and connected. Manage schedules, track progress, and foster a collaborative
            educational environment effortlessly.</p>
        <div class="flex items-center pt-8">
            <img class="h-10 ring-4 rounded-full ring-gray-800 w-10"
                src="https://angular-material.fusetheme.com/images/avatars/male-16.jpg">
            <img class="h-10 ring-4 rounded-full ring-gray-800 w-10"
                src="https://angular-material.fusetheme.com/images/avatars/male-09.jpg">
            <img class="h-10 ring-4 rounded-full ring-gray-800 w-10"
                src="https://angular-material.fusetheme.com/images/avatars/female-11.jpg">
            <img class="h-10 ring-4 rounded-full ring-gray-800 w-10"
                src="https://angular-material.fusetheme.com/images/avatars/female-18.jpg">
            <p class="font-normal pl-2 text-gray-400">More than 17k people joined us, it's your turn</p>
        </div>
    </div>
</div>