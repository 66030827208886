<div class="fixed max-h-96 overflow-y-scroll no-scrollbar right-5 rounded-xl shadow-lg top-5 w-96 z-30">
    <div class="bg-[#3F41D1] flex items-center justify-between p-4 rounded-t-xl sticky top-0 text-white text-md">
        <div class="flex items-center gap-1">
            <div class="cursor-pointer hover:shadow-md p-2 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
                </svg>
            </div>
            <h1 class="font-medium text-white">Messages</h1>
        </div>
        <!-- <div (click)="notification_toggle()" class="border flex items-center hover:bg-slate-100/10 h-10 justify-center rounded-full text-2xl w-10 ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </div> -->
        <button (click)="notification_toggle()"  type="button"
        class="text-gray-100 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
        <i class='bx bx-x text-2xl'></i>
        <span class="sr-only">Close modal</span>
    </button>
    </div>
    <div class="bg-white w-full flex flex-col rounded-b-xl">
        <div class="flex hover:bg-gray-200 justify-between p-5">
            <div class="flex flex-col gap-0.5 text-sm">
                <h4 class="font-semibold text-sm">Arun Gounder</h4>
                <p class="w-full">was assigned for Venue Audit by You!</p>
                <p class="mt-2 text-xs text-gray-500">20 mins ago</p>
            </div>
            <div class="flex flex-col gap-2 items-center">
                <p class="bg-blue-600 h-2 rounded-full w-2"><span></span></p>
                <i class='bx bx-x cursor-pointer group/edit group-hover/item:visible hover:bg-gray-200 invisible px-1 rounded-full text-xl text-gray-500'></i>
            </div>
        </div>
        <div class="flex hover:bg-gray-200 justify-between p-5">
            <div class="flex flex-col gap-0.5 text-sm">
                <h4 class="font-semibold text-sm">Terna College, Navi Mumbai</h4>
                <p class="w-full">has Submitted Venue Details for Audit,Please Check!</p>
                <p class="mt-2 text-xs text-gray-500">50 mins ago</p>
            </div>
            <div class="flex flex-col gap-2 items-center">
                <p class="bg-blue-600 h-2 rounded-full w-2"><span></span></p>
                <i class='bx bx-x cursor-pointer group/edit group-hover/item:visible hover:bg-gray-200 invisible px-1 rounded-full text-xl text-gray-500'></i>
            </div>
        </div>
    </div>
    <ng-template #noRecordFound>
        <div class="bg-white divide-y p-4 rounded-b-xl text-center ">No records for today</div>
    </ng-template>
</div>