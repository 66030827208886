<app-modal #open_attandence title="Check In">
    <div class="flex flex-col gap-5 py-10 w-full">
        <app-select-input label="Batch" title="Batch"></app-select-input>
        <app-time-input label="Enter Time"></app-time-input>
        <p class="text-md font-semibold mb-2">Location</p>
        <div class="flex justify-around">
            <p>Latitude :</p>
            <p>Longitude :</p>
        </div>
    </div>
    
    <div class="flex items-center justify-between mt-4 w-full gap-3 p-4 rounded-b-lg">
        <app-button class="self-center" color="bg-gray-200 text-gray-400" label="Cancel"  ></app-button>
        <app-button class="self-center" color="bg-[#3F41D1] text-white" label="OK"  ></app-button>
    </div>
</app-modal>