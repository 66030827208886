<app-header header_title="Add Instructor">
  <app-button color="bg-white text-color-500 border-gray-#BDBDBD border" label="Cancel"   placement="header"
      routerLink="/dashboard/intructor-assignment"></app-button>
  <app-button color="bg-accent-300 text-accent-on" label="Submit" routerLink="/dashboard/intructor-assignment"
       ></app-button>
</app-header>
<div class="px-8 mb-2">
  <form>
      <div class="bg-white flex flex-col md:mt-10 p-4 rounded-lg">
          <p class="font-normal text-base text-text-color-600">Instructor Meta Data</p>
          <p class="text-text-color-500 font-medium text-sm mt-2">
              To create a new instructor, please ensure all mandatory fields are completed accurately. Each field serves a specific purpose in facilitating the instructor creation process.
          </p>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
              <app-select-input title="School" label="School" [options]="school"></app-select-input>
              <app-select-input title="Instructor" label="Instructor" [options]="instructor"></app-select-input>
              <app-date-input label="Academic Year" [minDateInput]="current_date_show"></app-date-input>
              <app-select-input label="Course" title="Course" [options]="course"></app-select-input>
          </div>
      </div>
  </form>
</div>