import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/button/button.component';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { NgFor, NgIf } from '@angular/common';
import { DateInputComponent } from '../../components/inputs/date-input/date-input.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TextAreaInputComponent } from '../../components/inputs/text-area-input/text-area-input.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgModel, ReactiveFormsModule, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AssessmentReportService } from '../../services/assessment-report.service';
import { AssessmentService } from '../../services/assessment.service';
import { GradeService } from '../../services/grade.service';
import { CourseService } from '../../services/course.service';
import { AcademicYearService } from '../../services/academic-year.service';
import { StudentService } from '../../services/student.service';
import { log } from 'console';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';

@Component({
  selector: 'app-assessment-test-form-page',
  standalone: true,
  imports: [HeaderComponent, ButtonComponent, SelectInputComponent, ReactiveFormsModule, TextInputComponent, NgFor, DateInputComponent, RouterLink, NgIf, TextAreaInputComponent, ToastrModule, NgxUiLoaderModule],
  templateUrl: './assessment-test-form-page.component.html',
})
export class AssessmentTestFormPageComponent {
  @ViewChild('add_assessment_button') add_assessment_button: any
  @ViewChild('update_assessment_button') update_assessment_button: any
  isTableDisabled: boolean = true
  assessment_list: any[] = [];
  params: any = {};
  selected_assessment: any = {};
  dropdown_list: any = {}
  selectedGradeId: any;
  selectedcourseId: any;
  selectedacademic: any;
  student_list_response: any = {}
  newRowIndex: number = -1;
  current_date_show: any
  path = window.location.pathname
  // isDateDisabled: boolean = false;

  // isDisabled: boolean = true;
  isFormDisabled: boolean = false;

  assessment_form = this.fb.group({
    // school_id: ["", Validators.required],
    grade_id: ["", Validators.required],
    course_id: ["", Validators.required],
    assessment_type_id: ["", Validators.required],
    assessment_date: ["", Validators.required],
    academic_year_id: ["", Validators.required],
    assessment_name: ["", Validators.required],
    total_mark: ["", Validators.required],
    school_udise_code: "",
    student_details: this.fb.array([])
  },);

  constructor(public fb: FormBuilder, public gs: GlobalService, public acedemic_ser: AcademicYearService, public toastr: ToastrService, public ui_spinner: NgxUiLoaderService, public gls: GradeService, public cs: CourseService, public ats: AssessmentService, public as: AssessmentReportService, public router: Router, public ar: ActivatedRoute, public student: StudentService, public is: InstructorAssignmentService) {
    const future = new Date();
    this.current_date_show = future.toISOString().split('T')[0];
  }

  async ngOnInit() {
    await this.get_dropdown_list()
    this.assessment_form.get('assessment_type_id')?.valueChanges.subscribe((selectedTitle: any) => {
      const selectedItem = this.dropdown_list.assessment_type_list.find((item: any) => item.value === parseInt(selectedTitle));
      if (selectedItem) {
        this.assessment_form.patchValue({
          total_mark: selectedItem.marks
        });
      }
    });
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.get_assessment(params['id'])
        this.assessment_form.patchValue(await this.ats.get(params['id']))
        console.log(this.assessment_form.value);
      }
    })
    this.assessment_form.get('assessment_date')?.valueChanges.subscribe((date) => {
      this.checkDateAndToggleFields(date);
    });

  }

  checkDateAndToggleFields(date: any) {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format
    if (date === currentDate) {
      this.assessment_form.disable();
    } else {
      this.assessment_form.enable();
    }
  }



  async get_dropdown_list() {
    try {
      const cource_response = await this.is.get_list({})
      const grade_response = await this.gls.get_list({})
      const assessment_type_response = await this.ats.get_list({})
      const academic_year_response = await this.acedemic_ser.get_list({})
      let cource_options = cource_response.data.map((item: any) => {
        return {
          title: item.course_name || "none",
          value: item.course_id || "none",
        };
      });
      let grade_options = grade_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let academic_year_options = academic_year_response.data.map((item: any) => {
        return {
          title: item.year || "none",
          value: item.id || "none",
        };
      });
      let assessment_type_options = assessment_type_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
          marks: item.total_marks || "none"
        };
      });
      this.dropdown_list.cource_list = cource_options
      this.dropdown_list.grade_list = grade_options
      this.dropdown_list.assessment_type_list = assessment_type_options
      this.dropdown_list.academic_year_list = academic_year_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  async onAcdemicChange(event: any) {
    this.selectedacademic = event.target.value;
    // this.selectedGradeId = event.target.value;
    this.student_list_response = await this.student.get_student_list({
      grade_id: this.selectedGradeId,
      academic_year_id: this.selectedacademic,
      school_id: this.gs.selected_school.school_id
    });
    console.log(this.student_list_response, 'main');
    const studentDetailsArray = this.assessment_form.get('student_details') as FormArray;
    while (studentDetailsArray.length !== 0) {
      studentDetailsArray.removeAt(0);
    }
    this.student_list_response?.data.forEach((student: any) => {
      studentDetailsArray.push(this.fb.group({
        student_id: student.id,
        student_name: student.name,
        mark: [0],
        remark: '-',
      }));
    });
    console.log(studentDetailsArray, 'userdata');
    console.log('Selected Acd ID (from change event):', this.student_list_response);
  }

  async onGradeChange(event: any) {
    
    this.selectedGradeId = event.target.value;
    console.log('Selected Grade ID (from change event):', this.selectedGradeId);
    // this.onAcdemicChange(event)
    this.student_list_response = await this.student.get_student_list({
      grade_id: this.selectedGradeId,
      academic_year_id: this.selectedacademic,
      school_id: this.gs.selected_school.school_id
    });
    console.log(this.student_list_response, 'main');
    const studentDetailsArray = this.assessment_form.get('student_details') as FormArray;
    while (studentDetailsArray.length !== 0) {
      studentDetailsArray.removeAt(0);
    }
    this.student_list_response?.data.forEach((student: any) => {
      studentDetailsArray.push(this.fb.group({
        student_id: student.id,
        student_name: student.name,
        mark: [0],
        remark: '-',
      }));
    });
    console.log(studentDetailsArray, 'userdata');
    console.log('Selected Acd ID (from change event):', this.student_list_response);
  }

  async add() {
    if (this.assessment_form.valid) {
      this.ui_spinner.startLoader('add-Assessment-loader');
      this.add_assessment_button.disabled = true
      try {
        const formValue = {
          grade_id: this.assessment_form.value.grade_id,
          course_id: this.assessment_form.value.course_id,
          assessment_type_id: this.assessment_form.value.assessment_type_id,
          assessment_date: this.assessment_form.value.assessment_date,
          academic_year_id: this.assessment_form.value.academic_year_id,
          assessment_name: this.assessment_form.value.assessment_name,
          total_mark: this.assessment_form.value.total_mark,
          student_details: this.assessment_form.value.student_details,
          school_id: this.gs.selected_school.school_id
        }
        let response: any = await this.as.add(formValue);
        this.assessment_form.reset();
        this.ui_spinner.stopLoader('add-Assessment-loader');
        this.add_assessment_button.disabled = false
        this.toastr.success(response.message || 'Assessment Added Successfully!');
        this.router.navigate(['/assessment']);
        console.log(formValue, 'value');

      } catch (error: any) {
        this.ui_spinner.stopLoader('add-Assessment-loader');
        this.add_assessment_button.disabled = false
        console.error(error?.error?.message, '');
      }
    } else {
      this.assessment_form.markAllAsTouched()
    }
  }
  get studentDetails(): FormArray {
    return this.assessment_form.get('student_details') as FormArray;
  }

  async get_assessment(id: any) {
    console.log(id);
    try {
      let data = await this.as.get(id);
      this.selected_assessment = data.data;
      this.assessment_form.patchValue(data.data);

      this.student_list_response = data?.data?.student_details;
      const studentDetailsArray = this.studentDetails;
      studentDetailsArray.clear(); // Clear the existing FormArray before populating it with new data

      // Populate the student_details FormArray
      this.student_list_response.forEach((student: any) => {
        studentDetailsArray.push(this.fb.group({
          student_id: [student.student_id],
          student_name: [student.student_name],
          mark: [student.mark || 0, this.marksValidator.bind(this)],
          remark: [student.remark || '-'],
        }));
      });

      console.log(this.student_list_response, 'alalalala');
      this.toastr.success(data.message);
    } catch (error: any) {
      console.error("Error fetching assessment:", error);
      this.toastr.error(error?.error?.message);
    }
  }

  async edit(id: number) {
    if (this.assessment_form.valid) {
      this.ui_spinner.startLoader('update-assessment-loader');
      this.update_assessment_button.disabled = true;
      try {
        const formValue = {
          grade_id: this.assessment_form.value.grade_id,
          course_id: this.assessment_form.value.course_id,
          assessment_type_id: this.assessment_form.value.assessment_type_id,
          assessment_date: this.assessment_form.value.assessment_date,
          academic_year_id: this.assessment_form.value.academic_year_id,
          assessment_name: this.assessment_form.value.assessment_name,
          total_mark: this.assessment_form.value.total_mark,
          student_details: this.assessment_form.value.student_details,
          school_id: this.gs.selected_school.school_id
        }
        // const formValue = { ...this.assessment_form.value, school_id: this.gs.selected_school.school_id };
        let response = await this.as.update(id, formValue);
        this.assessment_form.reset();
        this.toastr.success(' Updated Successfully!');
        this.ui_spinner.stopLoader('update-assessment-loader');
        this.update_assessment_button.disabled = false;
        this.router.navigate(['/assessment']);
      } catch (error: any) {
        console.error(error?.error?.message, '');
        this.ui_spinner.stopLoader('update-assessment-loader');
        this.update_assessment_button.disabled = false;
        this.toastr.error(error?.error?.message);
      }
    } else {
      this.assessment_form.markAllAsTouched();
    }
  }


  marksValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const totalMark = this.assessment_form?.get('total_mark')?.value ?? null;
    const number_regex = /^\d+$/;
    const mark = control.value ?? null;

    if (totalMark !== null && mark !== null && mark > totalMark) {
      return { 'marksExceeded': true };
    }
    if (!number_regex.test(mark)) {
      return { 'pattern': true };
    }
    return null;
  }





  toggleFormControls(disable: any) {
    this.studentDetails.controls.forEach(control => {
      if (disable) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }


}
// ------x-------
