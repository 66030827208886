import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStudyMaterialControl',
  standalone: true
})
export class GetStudyMaterialControlPipe implements PipeTransform {

  transform(form: any,controls_array:any[]): any {
    let temp_form_control = form;
    // console.log(controls_array)
    controls_array.forEach(index=>{
      // console.log(temp_form_control.value)
      temp_form_control = temp_form_control.controls.levels.controls[index]
    })
    return temp_form_control.controls.materials
  }

}
