import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  list: any[] = []

  constructor(public hs: HttpService) { }

  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/subject/add-subject', {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/subject/delete-subject?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/subject/get-subject?id=' + id, {})
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/subject/get-subject-list', filters)
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  async update(id: number, value: any) {
    try {
      console.log(id);
      let response = await this.hs.post('/subject/update-subject?id=' + id, {}, value)
      return response;
    } catch (error: any) {
      throw error;
    }
  }
}
