import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { HttpService } from '../../services/http.service';
import { GlobalService } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-sign-in-page',
  standalone: true,
  imports: [RouterLink, SelectInputComponent, NgIf, ReactiveFormsModule, NgxUiLoaderModule],

  templateUrl: './sign-in-page.component.html',
})
export class SignInPageComponent {
  passwordVisible = false;
  forget_password = false
  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(7)]],
    otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });

  constructor(public ui_spinner: NgxUiLoaderService, private fb: FormBuilder, private hs: HttpService, private router: Router, public gs: GlobalService, public toastr: ToastrService) { }

  async sign_in() {
    this.ui_spinner.startLoader('signin-loader');
    try {
      let body = {
        email: this.form.value.email,
        password: this.form.value.password
      }
      let response = await this.hs.post('/auth/sign-in', {}, body);
      this.gs.user = {}
      if (response) {
        localStorage.setItem('user', JSON.stringify(response.data));
        this.gs.user = response.data;
        this.toastr.success(response.message || 'Sign in Successful!')
        if (this.gs.user.role == 'Student') {
          this.router.navigate(['/courses']);
        } else {
          this.router.navigate(['/dashboard']);
        }
        this.ui_spinner.stopLoader('signin-loader');
      }
    } catch (error: any) {
      console.log(error);
      this.ui_spinner.stopLoader('signin-loader');
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async forgot_password() {
    try {
      if (this.form.value.email == '') {
        this.toastr.error('Please Enter your Email Address!')
        return
      }
      let response = await this.hs.post('/auth/forget-password', {}, { email: this.form.value.email });
      if (response) {
        this.forget_password = true
        this.toastr.success(response.message || 'OTP Sent Successfully!')
      }
    } catch (error: any) {
      console.log(error);
      this.toastr.error(error?.error?.message || 'Something Went Wrong!')
    }
  }

  async verigy_otp() {
    console.log(this.form.errors, this.form.valid);

    if (this.form.touched) {
      try {
        if (this.form.value.email == '' || this.form.value.otp == '') {
          this.toastr.error('Please Enter mandatory fileds!')
          return
        }
        let response = await this.hs.post('/auth/verify-otp', {}, { email: this.form.value.email, otp: this.form.value.otp + '' });
        if (response) {
          this.toastr.success(response.message || 'OTP Verified Successfully!')
          this.router.navigate(['/set-password'], { queryParams: { token: response.token } });
        }
      } catch (error: any) {
        console.log(error);

        this.toastr.error(error?.error?.message || 'Something Went Wrong!')
      }
    } else {
      this.form.markAllAsTouched()
    }
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

}
