import { Component } from '@angular/core';
import OrgChart from "../../../assets/balkanapp/orgchart";
import * as XLSX from 'xlsx';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { SelectInputComponent } from '../inputs/select-input/select-input.component';
import { NgIf } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ButtonComponent } from '../button/button.component';
import { FilterPillButtonComponent } from '../filter-pill-button/filter-pill-button.component';
import { SerchTextInputComponent } from '../serch-text-input/serch-text-input.component';
declare var document: any;

@Component({
  selector: 'app-resources-view',
  standalone: true,
  imports: [TextInputComponent, SelectInputComponent, SerchTextInputComponent, NgIf],
  templateUrl: './resources-view.component.html'
})
export class ResourcesViewComponent {
  request_modal: boolean = false;
  chart_data = [
    { id: "1", name: "LABS", title: "LABS" },
    { id: "2", pid: "1", name: "Computer Lab", title: "Computer Lab" },
    { id: "3", pid: "1", name: "Chemistry Lab", title: "Chemistry Lab" },
    { id: "4", pid: "2", name: "LAB-001", title: "LAB-001" },
    { id: "5", pid: "2", name: "LAB-002", title: "LAB-002" },
    { id: "10", pid: "4", name: "LAB-001/SYS-01", title: "LAB-001/SYS-01", tags: ["computer"] },
    { id: "11", pid: "4", name: "LAB-001/SYS-02", title: "LAB-001/SYS-02", tags: ["computer"] },
    { id: "12", pid: "4", name: "LAB-001/SYS-03", title: "LAB-001/SYS-03", tags: ["computer"] },
    { id: "13", pid: "4", name: "LAB-001/SYS-04", title: "LAB-001/SYS-04", tags: ["computer"] },
    { id: "14", pid: "4", name: "LAB-001/SYS-05", title: "LAB-001/SYS-05", tags: ["computer"] },
    { id: "15", pid: "3", name: "DSK-001", title: "DSK-001", tags: ["chemistry desk"] },
    { id: "16", pid: "3", name: "DSK-002", title: "DSK-002", tags: ["chemistry desk"] },
    { id: "17", pid: "3", name: "DSK-003", title: "DSK-003", tags: ["chemistry desk"] },
    { id: "18", pid: "3", name: "DSK-004", title: "DSK-004", tags: ["chemistry desk"] },
    { id: "19", pid: "3", name: "DSK-005", title: "DSK-005", tags: ["chemistry desk"] },
    { id: "20", pid: "5", name: "LAB-002/SYS-01", title: "LAB-002/SYS-01", tags: ["computer"] },
    { id: "21", pid: "5", name: "LAB-002/SYS-02", title: "LAB-002/SYS-02", tags: ["computer"] },
    { id: "22", pid: "5", name: "LAB-002/SYS-03", title: "LAB-002/SYS-03", tags: ["computer"] },
  ]

  ngOnInit() {
    this.show_graph()
  }

  show_graph() {
    const tree = document.getElementById('resource-view-tree');
    OrgChart.templates['ana'].link = '<path stroke-linejoin="round" stroke="black" stroke-width="10px" fill="none" d="{curve}" />'
    if (tree) {
      var selected_id: any = null;
      OrgChart.templates['ana'].defs += '<filter id="f1" > \
                    <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
                    </filter>';
      OrgChart.templates['ana'].link = '<path stroke-linejoin="round" stroke="black" stroke-width="2px" fill="none" d="{curve}" />'
      var chart = new OrgChart(tree, {
        mouseScrool: OrgChart.action.scroll,
        zoom: { speed: 130, smooth: 10 },
        nodeBinding: {
          field_0: "name",
        },
        toolbar: {
          layout: true,
          zoom: true,
          fit: true,
          expandAll: true
        },
        scaleInitial: 0.5,
      });


      chart.on('redraw', function (sender) {
        blur();
      });

      chart.editUI.on('show', (sender, id) => {
        selected_id = id;
        blur();
        return false;
      });
      chart.editUI.on('hide', function (sender) {
        var elements = document.querySelectorAll('[filter="url(#f1)"]');
        for (var i = 0; i < elements.length; i++) {
          elements[i].removeAttribute('filter');
        }
        selected_id = null;
      });
      chart.load(this.chart_data);

      function blur() {
        if (selected_id == null) {
          return;
        }
        var node = chart.getNode(selected_id);
        var skipBlur = [node.id];
        var skipBlurLink = [];
        while (node.parent) {
          skipBlur.push(node.parent.id);
          skipBlurLink.push('[' + node.parent.id + '][' + node.id + ']')
          node = node.parent;
        }

        var nodeElements = document.querySelectorAll('[data-n-id]');
        for (var i = 0; i < nodeElements.length; i++) {
          var id = nodeElements[i].getAttribute('data-n-id');

          if (skipBlur.indexOf(id) == -1) {
            // nodeElements[i].setAttribute('filter', 'url(#f1)');
            nodeElements[i].children[1].dataset.selected = 'no'
          } else {
            nodeElements[i].children[1].dataset.selected = 'yes'
          }
        }

        var expcollElements = document.querySelectorAll('[data-ctrl-ec-id]');
        for (var i = 0; i < expcollElements.length; i++) {
          var id = expcollElements[i].getAttribute('data-ctrl-ec-id');

          if (skipBlur.indexOf(id) == -1) {
            // expcollElements[i].setAttribute('filter', 'url(#f1)');
          }
        }

        var linksElements = document.querySelectorAll('[data-l-id]');
        for (var i = 0; i < linksElements.length; i++) {
          var id = linksElements[i].getAttribute('data-l-id');
          if (skipBlurLink.indexOf(id) == -1) {
            // linksElements[i].setAttribute('filter', 'url(#f1)');
            linksElements[i].children[0].dataset.selectedLink = 'no'
          } else {
            // linksElements[i].children.setAttribute('stroke', '#1d4ed8')
            linksElements[i].children[0].dataset.selectedLink = 'yes'
          }
        }
      }
    }
  }

  access_success() {
    this.request_modal = !this.request_modal
  }
}
