import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {
  constructor(public hs: HttpService) { }
  getCheckInStatus(userId: any){
    return this.get(userId)
  }

  // setCheckInStatus(userId: string, status: boolean): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/set-check-in-status`, { userId, status });
  // }
  async add(value: any) {
    try {
      delete value.id;
      let response = await this.hs.post('/attendance/check-in', {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async delete(id: number) {
    try {
      let response = await this.hs.delete('/block/delete-block?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get(id: any) {
    try {
      let response = await this.hs.get('/block/get-block?id=' + id, {})
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async get_list(filters: any) {
    try {
      console.log(filters)
      let response = await this.hs.get('/block/get-block-list', filters)
      return response;
    } catch (error:any) {
      throw error;
    }
  }

  async update(id: any, value: any) {
    try {
      let response = await this.hs.post('/block/update-block?id=' + parseInt(id), {}, value)
      return response;
    } catch (error:any) {
      throw error;
    }
  }
}
