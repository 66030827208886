<div class="single-option-selector overflow-y-auto max-h-[500px]">
    <ul>
        <ng-container *ngFor="let node of tree_data">
            <li class="flex items-center gap-2 py-1 px-2 hover:bg-slate-200" [class.bg-slate-200]="node.selected">
                <span class="text-base">{{ node.name }}</span>
            </li>
            <ul *ngIf="node.children && node.children.length > 0">
                <ng-container *ngFor="let child of node.children">
                    <li class="flex items-center gap-2 pl-6 py-1 hover:bg-slate-200" [class.bg-slate-200]="child.selected">
                        <input class="cursor-pointer" type="radio" name="{{node.id}}" [value]="child.id" (change)="selectChild(node, child)">
                        <span class="text-base">{{ child.name }}</span>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </ul>
</div>