import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { BrcAssignmentReportService } from '../../services/brc-assignment-report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { Subject, debounceTime } from 'rxjs';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { BlockService } from '../../services/block.service';

@Component({
  selector: 'app-brc-assignment-report-page',
  standalone: true,
  templateUrl: './brc-assignment-report-page.component.html',
  imports: [HeaderComponent, SerchTextInputComponent,DatePipe, FilterPillButtonComponent, NgFor, NgIf, PaginationComponent, PaginationComponent,SelectInputComponent]
})
export class BrcAssignmentReportPageComponent {
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  searchSubject: Subject<string> = new Subject<string>();
  dropdown_list:any={}
  list: any = {}
  params: any = {};
  constructor(public bar: BrcAssignmentReportService, public ar: ActivatedRoute, public toastr: ToastrService,public router:Router,public bs:BlockService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_brc_report_list(this.params);
    });
  }
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_brc_report_list(this.params);
    })
    await this.get_dropdown_list()
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_brc_report_list(this.params);
  }

  async get_brc_report_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.list = await this.bar.get_list(filter)
      this.totalItems = this.list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.list, "qqqqqqqqqqq");
      // this.toastr.success(this.list.message)
    } catch (error: any) {
      console.error("Error fetching brc_report list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_brc_report_list(this.params);
    }
  }
  async get_dropdown_list() {
    try {
      const block = await this.bs.get_list({})
      let block_id_options = block.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.block_list = block_id_options
    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  block_show_filter(event: any) {
    const selectedBlock = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedBlock) {
      this.currentPage = 1
      queryParams.block_id = selectedBlock;
    } else {
      this.currentPage = 1
      queryParams.block_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });  
  }
}
