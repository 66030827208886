import { Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { TextInputComponent } from '../../components/inputs/text-input/text-input.component';
import { ButtonComponent } from '../../components/button/button.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UdiseService } from '../../services/udise.service';
import { NgIf } from '@angular/common';
import { StateService } from '../../services/state.service';
import { DistrictsService } from '../../services/districts.service';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { BlockService } from '../../services/block.service';

@Component({
  selector: 'app-udise-form-page',
  standalone: true,
  imports: [HeaderComponent, NgxUiLoaderModule, ReactiveFormsModule, TextInputComponent, ButtonComponent, RouterLink, NgIf, SelectInputComponent],
  templateUrl: './udise-form-page.component.html',
})
export class UdiseFormPageComponent {
  @ViewChild('add_udise_button') add_udise_button: any
  @ViewChild('update_udise_button') update_udise_button: any


  dropdown_list: any = {}

  constructor(public ui_spinner: NgxUiLoaderService, public fb: FormBuilder, public us: UdiseService, public bs: BlockService, public ar: ActivatedRoute, public router: Router, public ss: StateService, public ds: DistrictsService, public toastr: ToastrService) { }

  async ngOnInit() {
    await this.get_dropdown_list()
    this.ar.queryParams.subscribe(async params => {
      this.params = params
      if (params['id']) {
        this.get_udise(params['id'])
        this.udise_form.patchValue(await this.us.get(params['id']))
      }
    })
  }

  udise_form: any = this.fb.group({
    school_name: new FormControl("", [Validators.required]),
    udise_code: new FormControl("", [Validators.required]),
    primary_contact_number: new FormControl("", [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^-?\\d*(\\.\\d+)?$')]),
    secondary_contact_number: new FormControl("", [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^-?\\d*(\\.\\d+)?$')]),
    school_code: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    state_id: new FormControl("", [Validators.required]),
    block_id: new FormControl("", [Validators.required]),
    district_id: new FormControl("", [Validators.required]),
    // udise_id: new FormControl("", [Validators.required]),
    pincode: new FormControl("", [Validators.required, Validators.maxLength(6), Validators.pattern('^-?\\d*(\\.\\d+)?$')]),
    lat: new FormControl("", [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$')]),
    long: new FormControl("", [Validators.required, Validators.pattern('^-?\\d*(\\.\\d+)?$')]),
    description: new FormControl("", [Validators.required]),
  })

  params: any = {};
  selected_udise: any = {};
  async add() {
    console.log(this.udise_form.valid, this.udise_form.value);
    
    if (this.udise_form.valid) {
      this.ui_spinner.startLoader('add-udise-loader');
      this.add_udise_button.disabled = true
      try {
        let response: any = await this.us.add(this.udise_form.value)
        console.log(response);
        this.udise_form.reset();
        this.ui_spinner.stopLoader('add-udise-loader');
        this.add_udise_button.disabled = false
        this.toastr.success(response.message || 'UDISE Added Successfully!')
        this.router.navigate(['/master/udise'])
      } catch (error: any) {
        this.ui_spinner.stopLoader('add-udise-loader');
        this.add_udise_button.disabled = false
        console.error(error?.error?.message, '')
      }
    } else {
      this.udise_form.markAllAsTouched();
    }
  }

  async get_dropdown_list() {
    try {
      const state_response = await this.ss.get_list({})
      const district_response = await this.ds.get_list({})
      const block_response = await this.bs.get_list({})
      let district_options = district_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let state_options = state_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let block_options = block_response.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.district_list = district_options
      this.dropdown_list.state_list = state_options
      this.dropdown_list.block_list = block_options
      console.log(district_options, state_options);


    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }

  async get_udise(id: any) {
    try {
      let data = await this.us.get(id)
      this.selected_udise = data.data
      this.udise_form.patchValue(data.data)
    } catch (error: any) {
      console.error("Error fetching State:", error);
      console.log(error);

    }
  }


  async edit(id: number) {
    if (this.udise_form.valid) {
      this.ui_spinner.startLoader('update-udise-loader');
      this.update_udise_button.disabled = true
      try {
        console.log(this.params.id);
        let response = await this.us.update(this.params.id, this.udise_form.value);
        this.udise_form.reset();
        console.log(id, this.udise_form.value);
        this.toastr.success(' Updated Successfully!')
        this.ui_spinner.stopLoader('update-udise-loader');
        this.update_udise_button.disabled = false
        this.router.navigate(['/master/udise']);
      } catch (error: any) {
        this.ui_spinner.stopLoader('update-udise-loader');
        this.update_udise_button.disabled = false
        console.error(error?.error?.message, '')
      }
    } else {
      this.udise_form.markAllAsTouched();
    }
  }


}
