import { NgClass, NgIf } from '@angular/common';
import { Component, Input, Injector } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
  selector: 'app-text-area-input',
  standalone: true,
  imports: [NgIf, FormsModule,NgClass],
  templateUrl: './text-area-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextAreaInputComponent
    }
  ]
})
export class TextAreaInputComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() is_required: boolean = false;
  @Input() disabled: boolean = false;

  
  control: any;
  onTouched: any;
  onChange: any;
  value:any;
  paramValue: any;

  constructor(public  injector: Injector, public route: ActivatedRoute){

  }
  writeValue(value: any): void {
    this.value = value

  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl | null = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        // Component is missing form control binding
      }
    }, 100);
  }
}
