import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  selected_role = 'Super Admin';

  constructor(public gs: GlobalService) {
    console.log(this.gs.selected_school.school_id, this.gs.selected_school.id, "********");
  }

  sidebar_data: any = {
    'Super Admin': [
      {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/dashboard',
        is_open: false,
      },
      {
        id: 2,
        label: 'Master',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          { label: 'Country', router_link: '/master/country' },
          { label: 'State', router_link: '/master/state' },
          { label: 'District', router_link: '/master/district' },
          { label: 'Block', router_link: '/master/block' },
          { label: 'UDISE', router_link: '/master/udise' },
          { label: 'School', router_link: '/master/school' },
          { label: 'Course', router_link: '/master/course' },
          { label: 'Subject', router_link: '/master/subject' },
          { label: 'Student', router_link: '/master/student' },
          { label: 'Material Type', router_link: '/master/material-type' },
          { label: 'Asset', router_link: '/master/assets' },
          { label: 'Academic Term', router_link: '/master/academic-term' },
          { label: 'Grade', router_link: '/master/grade' },
          { label: 'level', router_link: '/master/level' },
          { label: 'Assessment Type', router_link: '/master/assessment-type' },
          { label: 'Activity Type', router_link: '/master/activity-type' },
        ],
        is_open: false,
      },
      {
        id: 3,
        label: 'Users',
        icon: 'bx bx-user',
        selected: 'bx bxs-user',
        sublinks: [
          { label: 'Instructor', router_link: '/users/instructor' },
          { label: 'BRC', router_link: '/users/block-resource-coordinator' },
          { label: 'Sub Admin', router_link: '/users/sub-admin' },
        ],
        is_open: false,
      },
      {
        id: 4,
        label: 'User Assignments',
        icon: 'bx bx-user',
        selected: 'bx bxs-user',
        sublinks: [
          { label: 'BRC Assignment', router_link: '/user-assignments/brc-assignment' },
          {
            label: 'Instructor Assignment', router_link: '/user-assignments/intructor-assignment',
          },
        ],
        is_open: false,
      },
      // {
      //   id: 5,
      //   label: 'Inventory Requests',
      //   icon: 'bx bx-box',
      //   selected: 'bx bxs-box',
      //   router_link: '/dashboard/inventory-requests',
      //   is_open: false,
      // },
      {
        id: 6,
        label: 'Reports',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          { label: 'Attendance Report', router_link: '/reports/attendance' },
          { label: 'Monthly Upload Report', router_link: '/reports/monthly-upload-report' },
          { label: 'Daily Log Report', router_link: '/reports/daily-log' },
          {
            label: 'Ground Activity Report',
            router_link: '/reports/ground-activity',
          },
          { label: 'Assessment Report', router_link: '/reports/assessment' },
          { label: 'Instructor Assignment Report', router_link: '/reports/instructor-assignment' },
          { label: 'BRC Assignment Report', router_link: '/reports/brc-assignment' },
        ],
        is_open: false,
      },
    ],
    'Sub Admin': [
      {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/dashboard',
        is_open: false,
      },
      {
        id: 2,
        label: 'Reports',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          { label: 'Attendance Report', router_link: '/reports/attendance' },
          { label: 'Monthly Upload Report', router_link: '/reports/monthly-upload-report' },
          { label: 'Daily Log Report', router_link: '/reports/daily-log' },
          {
            label: 'Ground Report',
            router_link: '/reports/ground-activity',
          },
          { label: 'Assessment Report', router_link: '/reports/assessment' },
          { label: 'Instructor Assignment Report', router_link: '/reports/instructor-assignment' },
          { label: 'BRC Assignment Report', router_link: '/reports/brc-assignment' },
        ],
        is_open: false,
      },
    ],
    'Block Resource Coordinator': [
      {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/dashboard',
        is_open: false,
      },
      {
        id: 2,
        label: 'Students Detail',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/student-detail',
        is_open: false,
      },
      {
        id: 3,
        label: 'Attendance',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          {
            label: 'My Attendance',
            router_link: '/attendance/my-attendance'
          },
          {
            label: 'Upload Monthly Attendance',
            router_link: '/attendance/upload-monthly-attendance',
          },
        ],
        is_open: false,
      },
      {
        id: 4,
        label: 'Instructors',
        icon: 'bx bx-user',
        selected: 'bx bxs-user',
        router_link: '/instructor',
        is_open: false,
      },
      // {
      //   id: 5,
      //   label: 'Inventory Requests',
      //   icon: 'bx bx-box',
      //   selected: 'bx bxs-box',
      //   router_link: '/dashboard/inventory-requests',
      //   is_open: false,
      // },

      {
        id: 6,
        label: 'School Profile',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/school-profile',
        query_params: true,
        is_open: false,
      },
      {
        id: 7,
        label: 'Course',
        icon: 'bx bx-calendar-check',
        selected: 'bx bx-calendar-check',
        router_link: '/course',
        is_open: false,
      },
      {
        id: 8,
        label: 'Daily Log',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/daily-log',
        is_open: false,
      },
      {
        id: 9,
        label: 'Reports',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          { label: 'Attendance Report', router_link: '/reports/attendance' },
          { label: 'Daily Log Report', router_link: '/reports/daily-log' ,query_params: { is_report: 'true' } },
          {
            label: 'Ground Report',
            router_link: '/reports/ground-activity',query_params: { is_report: 'true' }},
          { label: 'Assessment Report', router_link: '/reports/assessment',query_params: { is_report: 'true' }},
        ],
        is_open: false,
      },
    ],
    'Instructor': [
      {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/dashboard',
        is_open: false,
      },
      {
        id: 2,
        label: 'Student Detail',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/student-detail',
        is_open: false,
      },
      // {
      //   id: 2,
      //   label: 'Master',
      //   icon: 'bx bx-buildings',
      //   selected: 'bx bxs-buildings',
      //   sublinks: [
      //     { label: 'Students', router_link: '/master/student' }
      //   ],
      //   is_open: false,
      // },
      {
        id: 3,
        label: 'Attendance',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          { label: 'My Attendance', router_link: '/attendance/my-attendance' },
          // {
          //   label: 'Student Attendance',
          //   router_link: '/dashboard/student-attendance',
          // },
          {
            label: 'Upload Monthly Attendance',
            router_link: '/attendance/upload-monthly-attendance',
          },
        ],
        is_open: false,
      },
      {
        id: 4,
        label: 'Course',
        icon: 'bx bx-calendar-check',
        selected: 'bx bx-calendar-check',
        router_link: '/course',
        is_open: false,
      },
      {
        id: 5,
        label: 'Assessments',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/assessment',
        is_open: false,
      },
      {
        id: 6,
        label: 'Ground Activity',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/ground-activity',
        is_open: false,
      },
      {
        id: 7,
        label: 'Daily Log',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/daily-log',
        is_open: false,
      },
      {
        id: 8,
        label: 'School Profile',
        icon: 'bx bx-book-open',
        selected: 'bx bx-book-open',
        router_link: '/school-profile',
        query_params: true,
        is_open: false,
      },
      {
        id: 9,
        label: 'Reports',
        icon: 'bx bx-buildings',
        selected: 'bx bxs-buildings',
        sublinks: [
          // {
          //   label: 'Student Attendance Report',
          //   router_link: '/dashboard/student-attendance-report',
          // },
          { label: 'Daily Log Report', router_link: '/reports/daily-log' },
          {
            label: 'Ground Report',
            router_link: '/reports/ground-activity',
          },
          { label: 'Assessment Report', router_link: '/reports/assessment' },
        ],
        is_open: false,
      },
    ],
    'Student': [
      {
        id: 1,
        label: 'Courses',
        icon: 'bx bx-bar-chart-alt-2',
        selected: 'bx bxs-bar-chart-alt-2',
        router_link: '/courses',
        is_open: true,
      },
    ],
  };
}
