import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { SerchTextInputComponent } from '../../components/serch-text-input/serch-text-input.component';
import { FilterPillButtonComponent } from '../../components/filter-pill-button/filter-pill-button.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';


import { ActivatedRoute, Router } from '@angular/router';
import { InstructorAssignmentService } from '../../services/instructor-assignment.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { Subject, debounceTime } from 'rxjs';
import { SelectInputComponent } from '../../components/inputs/select-input/select-input.component';
import { SchoolService } from '../../services/school.service';
import { InstructorUserService } from '../../services/instructor-user.service';
import { BrcUserService } from '../../services/brc-user.service';

@Component({
  selector: 'app-instructor-assignment-report-page',
  standalone: true,
  imports: [HeaderComponent, SerchTextInputComponent, FilterPillButtonComponent,DatePipe, NgFor, NgIf, PaginationComponent,SelectInputComponent],
  templateUrl: './instructor-assignment-report-page.component.html',

})
export class InstructorAssignmentReportPageComponent {
  insctuctor_list: any = []
  currentPage = 1;
  itemsPerPage = 8;
  totalItems = 0;
  totalPages = 1;
  dropdown_list:any = {}
  searchSubject: Subject<string> = new Subject<string>();

  constructor(public ar: ActivatedRoute, public iar: InstructorAssignmentService, public toastr: ToastrService,public router:Router, public ss:SchoolService, public is:InstructorUserService,public brc_service: BrcUserService) {
    this.searchSubject.pipe(debounceTime(1000)).subscribe(searchText => {
      this.params.search = searchText;
      this.get_insctuctor_report_list(this.params);
    });
  }
  params: any = {};
  async ngOnInit() {
    this.ar.queryParams.subscribe(async params => {
      this.params = { ...params }
      await this.get_insctuctor_report_list(this.params);
    });
    await this.get_dropdown_list()
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.get_insctuctor_report_list(this.params);
  }
  async get_dropdown_list() {
    try {
      const school = await this.ss.get_list({})
      const instructor_user = await this.is.get_list({})
      const brc_user = await this.brc_service.get_list({})
      let school_id_options = school.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let instructor_user_options = instructor_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      let brc_user_options = brc_user.data.map((item: any) => {
        return {
          title: item.name || "none",
          value: item.id || "none",
        };
      });
      this.dropdown_list.school_list = school_id_options
      this.dropdown_list.instructor_list = [...instructor_user_options, ...brc_user_options]
      console.log(school_id_options, "aaaaaaaaaaaaa");


    } catch (error: any) {
      console.error("Error fetching District list:", error);
    }
  }
  async get_insctuctor_report_list(filter: any) {
    try {
      this.params.page = this.currentPage;
      this.params.page_size = this.itemsPerPage;
      this.insctuctor_list = await this.iar.get_list(filter)
      this.totalItems = this.insctuctor_list.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      console.log(this.insctuctor_list, "qqqqqqqqqqq");
      // this.toastr.success(this.insctuctor_list.message)
    } catch (error: any) {
      console.error("Error fetching insctuctor_report list:", error);
      this.toastr.error(error?.error?.message)
    }
  }
  school_show_filter(event: any) {
    const selectedSchool = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedSchool) {
      this.currentPage =1
      queryParams.school_id = selectedSchool;
    } else {
      this.currentPage = 1
      queryParams.school_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });      
  }
  course_show_filter(event: any) {
    const selectedCourse = event.target.value;
  let queryParams: any = { ...this.ar.snapshot.queryParams };
  if (selectedCourse) {
    queryParams.course_id = selectedCourse;
    
  } else {
     queryParams.course_id = null
  }
  this.router.navigate([], {
    relativeTo: this.ar,
    queryParams: queryParams,
    queryParamsHandling: 'merge'
  });    
  }
  instructor_show_filter(event: any) {
    const selectedInstructor = event.target.value;
    let queryParams: any = { ...this.ar.snapshot.queryParams };
    if (selectedInstructor) {
      this.currentPage =1
      queryParams.instructor_id = selectedInstructor;
    } else {
      this.currentPage =1
       queryParams.instructor_id = null
    }
    this.router.navigate([], {
      relativeTo: this.ar,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });      
  }


  async onSearch(searchText: string) {
    if (searchText) {
      this.currentPage = 1;
      this.params.search = searchText;
      this.searchSubject.next(searchText);
    } else {
      this.currentPage = 1;
      delete this.params['search'];
      this.get_insctuctor_report_list(this.params);
    }
  }

}
