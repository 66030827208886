import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-counts-card',
  standalone: true,
  imports: [FormsModule, NgFor],
  templateUrl: './counts-card.component.html',
})
export class CountsCardComponent {
  @Input() card_title = '';
  @Input() card_sub_title = '';
  @Input() count = '';
  @Input() total_count = '';
  @Input() color: string = '';

  constructor() {}
}

