<app-header header_title="Instructor Assignments">
    <app-button color="bg-accent-300 text-accent-on" icon="bx bx-plus" label="Add  Instructor Assignment" icon_alignment="right" (click)="open_instuctor_form.open()"></app-button>
</app-header>
<div class="flex justify-end items-end px-4 mb-3">
    <div class="flex justify-end items-end gap-3 w-[800px]">
        <app-select-input class="w-full" label="Instructor" [options]="dropdown_list.instructor_list" (change)="instructor_show_filter($event)"></app-select-input>
        <app-select-input class="w-full" label="School" [options]="dropdown_list.school_list" (change)="school_show_filter($event)"></app-select-input>
        <app-select-input class="w-full" label="Course" [options]="dropdown_list.course_list" (change)="course_show_filter($event)"></app-select-input>
    </div>
</div>
<div class="print-section px-4 relative md:px-6 overflow-x-auto flex flex-col gap-5">
    <div *ngIf="list.data?.length == 0" class=" overflow-auto bg-white border border-primary rounded-lg">
        <div class="w-full h-96 p-10 flex justify-center items-center">
            <img src="../../../assets/no_record.png" class="w-[200px] h-[200px] object-contain" alt="No Records" />
        </div>
    </div>
    <div *ngIf="list.data?.length > 0" class="overflow-auto bg-white border border-primary rounded-lg">
        <table class=" table-auto text-text-color-600 w-full border-collapse ">
            <thead class="text-sm font-medium text-primary-500 border-b border-[#CFD5F1] capitalize tracking-[0.8px]">
                <tr>
                    <th class="px-4 h-12 text-left">Instructor</th>
                    <th class="px-4 h-12 text-left">Block</th>
                    <th class="px-4 h-12 text-left">School</th>
                    <th class="px-4 h-12 text-left">Academic Year</th>
                    <th class="px-4 h-12 text-left">Course</th>
                    <!-- <th class="px-4 h-12 text-left">Grade</th> -->
                    <th class="px-4 h-12 text-left">Reporting Manager</th>
                    <th class="px-4 h-12 text-center">Status</th>
                    <th class="px-4 h-12 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-black/87 tracking-[0.32px] ">
                <tr *ngFor="let item of list.data; let i = index" class="border-b border-[#CFD5F1]">
                    <!-- <td class="px-4 h-12 text-center">{{(currentPage - 1) * itemsPerPage + i + 1 }}</td> -->
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.instructor_user_name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.block_name}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.school_name}}</td>
                    <td class="px-4 h-12 text-left truncate">{{item.academic_year}}</td>
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.course_name}}</td>
                    <!-- <td class="px-4 h-12 text-left truncate">{{item.grade_name}}</td> -->
                    <td class="px-4 h-12 text-left truncate capitalize">{{item.brc_name}}</td>
                    <td class="px-4 h-12 text-center">
                        <span *ngIf="item.is_active" class="bg-green-100 text-green-800 text-xs font-medium cursor-pointer border border-gray-400 px-4 py-2 rounded-full">Active</span>
                        <span *ngIf="!item.is_active" class="bg-red-100 text-red-800 text-xs font-medium cursor-pointer border border-gray-400 px-4 py-2 rounded-full">Inactive</span>
                    </td>
                    <!-- <td class="px-4 h-12 text-left">
                        <circle-progress class="h-10 w-10" [percent]="item.progress" [radius]="40" [outerStrokeWidth]="6" [innerStrokeWidth]="3" [responsive]="false" [outerStrokeColor]="'#3F41D1'" [innerStrokeColor]="'#e6e6e6'" [animation]="true" [showSubtitle]="false" [animationDuration]="300" [showTitle]="true" [showUnits]="true">
                        </circle-progress>
                    </td> -->
                    <td class="px-4 h-12 border-b flex items-center justify-center">
                        <div class="flex gap-2 items-center justify-center">
                            <!-- <app-icon-cta icon="bx bx-show" tooltip="view"></app-icon-cta> -->
                            <app-icon-cta *ngIf="gs.user.role != 'Instructor'" (click)="update_instuctor_form.open(); get_instructor(item.id)" icon="bx bx-edit-alt" tooltip="edit"></app-icon-cta>
                            <app-icon-cta *ngIf="gs.user.role != 'Instructor'" (click)="instructor_delete.open(); selected_Instructor = item" icon="bx bx-trash" tooltip="delete"></app-icon-cta>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="list.data?.length > 0" class="sticky w-full bottom-0 px-6">
    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (onPageChange)="handlePageChange($event)">
    </app-pagination>
</div>
<app-modal #open_instuctor_form title="Add  Instructor Assignment" (close_modal_clicked)="instructor_form.reset()">
    <form [formGroup]="instructor_form" class="w-full">
        <div class="bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-select-input formControlName="block_id" class="md:col-span-3" title="Block" label="Block" [options]="dropdown_list.block_list"></app-select-input>
                <!-- <app-text-input formControlName="udise_code" class="col-span-6 sm:col-span-3 lg:col-span-3"
                    label="UDISE Code"></app-text-input> -->
                <app-select-input formControlName="school_id" class="md:col-span-3" label="School" [options]="dropdown_list.school_list"></app-select-input>
                <app-select-input formControlName="academic_year_id" class="md:col-span-3" label="Academic Year" [options]="dropdown_list.academic_year_list"></app-select-input>
                <app-select-input formControlName="is_active" class="md:col-span-3" label="Status" [options]="[{title: 'Active', value: 'true' },{title: 'Inactive', value: 'false' }]"></app-select-input>
                <app-select-input formControlName="course_id" class="md:col-span-3" label="Course" title="Course" [options]="dropdown_list.course_list"></app-select-input>
                <app-select-input formControlName="instructor_user_id" class="md:col-span-3" title="Instructor" label="Instructor" [options]="dropdown_list.instructor_list"></app-select-input>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="open_instuctor_form.close();instructor_form.reset()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button #add_instuctor_assignment_button (click)="add()" class="self-center relative" color="bg-[#3F41D1] text-white" label="Submit">
            <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="add-instructor-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
        </app-button>
    </div>
</app-modal>
<app-modal #update_instuctor_form title="Update Instructor Assignment" (close_modal_clicked)="instructor_form.reset()">
    <form [formGroup]="instructor_form" class="w-full">
        <div class="bg-white flex flex-col overflow-hidden p-4 rounded w-full">
            <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
                <app-select-input formControlName="block_id" class="md:col-span-3" title="Block" label="Block" [options]="dropdown_list?.block_list"></app-select-input>
                <!-- <app-text-input formControlName="udise_code" class="col-span-6 sm:col-span-3 lg:col-span-3"
                    label="UDISE Code"></app-text-input> -->
                <app-select-input formControlName="school_id" class="md:col-span-3" label="School" [options]="dropdown_list.school_list"></app-select-input>
                <app-select-input formControlName="academic_year_id" class="md:col-span-3" label="Academic Year" [options]="dropdown_list.academic_year_list"></app-select-input>
                <app-select-input formControlName="is_active" class="md:col-span-3" label="Status" [options]="[{title: 'Active', value: 'true' },{title: 'Inactive', value: 'false' }]"></app-select-input>
                <app-select-input formControlName="course_id" class="md:col-span-3" label="Course" title="Course" [options]="dropdown_list.course_list"></app-select-input>
                <app-select-input formControlName="instructor_user_id" class="md:col-span-3" title="Instructor" label="Instructor" [options]="dropdown_list.instructor_list"></app-select-input>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end gap-3 p-4 rounded-b-lg">
        <app-button (click)="update_instuctor_form.close();instructor_form.reset()" class="self-center" color="bg-[#e1e8f5] text-primary-500" label="Cancel"></app-button>
        <app-button #update_instuctor_assignment_button (click)="edit(selected_Instructor.id)" class="self-center relative" color="bg-[#3F41D1] text-white" label="Update">
            <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="update-instructor-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
        </app-button>
    </div>
</app-modal>

<app-confirmation-popup #instructor_delete confirmation_message="Are you sure you want to Delete this Instructor" popup_title="Delete Instructor">
    <app-button label="Delete" (click)="delete_confirm(selected_Instructor.id)" color="bg-[#3F41D1] text-white" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-8 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="delete-instructor-assignment-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
</app-confirmation-popup>