<app-header header_title="{{params.id?'Update':'Add'}} UDISE">
    <app-button color="bg-white text-[#666666] border-gray-300 border" [label]="'Cancel'" routerLink="/master/udise"></app-button>
    <app-button *ngIf="!params.id" color="bg-[#E697AB] text-[#310000]" [label]="'Submit'" #add_udise_button (click)="add()" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="add-udise-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader>
    </app-button>
    <app-button *ngIf="params.id" color="bg-[#E697AB] text-[#310000]" [label]="'Update'" #update_udise_button (click)="edit(params.id.value)" class="relative">
        <ngx-ui-loader overlayColor="rgba(0,0,0,0.5)" class="absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2" overlayColor="transparent" fgsColor="#FFF" loaderId="update-udise-loader" [hasProgressBar]="false" fgsType="fading-circle" [fgsSize]="25"></ngx-ui-loader></app-button>
</app-header>
<div class="px-8 w-full">
    <div [formGroup]="udise_form" class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">Basic Information</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new UDISE
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-6 mt-4">
            <app-text-input formControlName="udise_code" class="md:col-span-2" [label]="'UDISE Code'"></app-text-input>
            <app-text-input formControlName="school_code" class="md:col-span-2" [label]="'School Code'"></app-text-input>
            <app-text-input formControlName="school_name" class="md:col-span-2" [label]="'School Name'"></app-text-input>
            <app-text-input formControlName="primary_contact_number" class="md:col-span-2" [label]="'Primary Contact Number'"></app-text-input>
            <app-text-input formControlName="secondary_contact_number" class="md:col-span-2" [label]="'Secondary Contact Number'"></app-text-input>
            <app-text-input formControlName="city" class="md:col-span-2" [label]="'City'"></app-text-input>
            <app-select-input formControlName="state_id" label="State" class="md:col-span-2" [options]="dropdown_list.state_list"></app-select-input>
            <app-select-input formControlName="district_id" label="District" class="md:col-span-2" [options]="dropdown_list.district_list"></app-select-input>
            <app-select-input formControlName="block_id" label="Block" class="md:col-span-2" [options]="dropdown_list.block_list"></app-select-input>
            <app-text-input formControlName="pincode" class="md:col-span-2" [label]="'Pincode'"></app-text-input>
            <app-text-input formControlName="lat" class="md:col-span-2" [label]="'Lat'"></app-text-input>
            <app-text-input formControlName="long" class="md:col-span-2" [label]="'Long'"></app-text-input>
            <app-text-input formControlName="description" class="md:col-span-2" [label]="'Description'"></app-text-input>
        </div>
    </div>
</div>