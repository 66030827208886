import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterPillButtonComponent } from '../filter-pill-button/filter-pill-button.component';
import { ButtonComponent } from '../button/button.component';
import { TextInputComponent } from '../inputs/text-input/text-input.component';

@Component({
  selector: 'app-filter-drawer',
  standalone: true,
  imports: [NgClass, NgIf, ReactiveFormsModule, TextInputComponent, FilterPillButtonComponent, ButtonComponent],
  templateUrl: './filter-drawer.component.html'
})
export class FilterDrawerComponent {
  @Input() is_filter_open: boolean = false;
  @Input() fields: any[] = [];
  @Input() form: any;
  @Input() navigate: string = '';
  @Input() selected_item: string = '';
  @Output() closed = new EventEmitter<void>();

  constructor(public fb: FormBuilder, public router: Router,) { }

  selected_section(selected: string) {
    this.selected_item = selected
  }

  submit_filter() {
    const queryParams: any = {};
    for (const control_name in this.form.controls) {
      if (this.form.controls.hasOwnProperty(control_name)) {
        const control_value = this.form.get(control_name).value;
        if (control_value !== null && control_value !== '') {
          queryParams[control_name] = control_value;
        }
      }
    }
    this.closed.emit()
    this.router.navigate([this.navigate], { queryParams: queryParams })
    this.form.reset();
  }

  reset_form() {
    this.form.reset();
  }

  toggle_filter() {
    this.closed.emit()
  }

}
