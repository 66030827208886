import { JsonPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Injector, Input, ViewChild, viewChild } from '@angular/core';;
import { NzUploadFile, NzUploadModule, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { IsPdfPipe } from '../../../pipes/is-pdf.pipe';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../../services/http.service';
import { FileService } from '../../../services/file.service';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { SplitStringPipe } from '../../../pipes/split-string.pipe';
import { ButtonComponent } from '../../button/button.component';
import { ActivatedRoute } from '@angular/router';
import { FileTypePipe } from '../../../pipes/file-type.pipe';
import { ModalComponent } from '../../modal/modal.component';
import { SafeUrlPipe } from '../../../pipes/safe-url.pipe';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-zoro-file-input',
  standalone: true,
  imports: [NzUploadModule, SafeUrlPipe, NgxUiLoaderModule, ModalComponent, NzModalModule, FileTypePipe, NgStyle, FormsModule, NzIconModule, SplitStringPipe, JsonPipe, NgFor, NzImageModule, NgIf, NzPopconfirmModule, NgClass, IsPdfPipe, ButtonComponent],
  templateUrl: './zoro-file-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZoroFileInputComponent
    }
  ]
})

export class ZoroFileInputComponent implements ControlValueAccessor {
  @ViewChild('open_media') open_media: any;
  @Input() label = ''
  @Input() multiple: boolean = false;
  @Input() placeholder = ''
  @Input() supported_extension: string = ''


  value: string = '';
  file_model: string = '';
  file_url: string = ''
  control: any;
  onChange: any;
  onTouched: any;
  disabled: boolean = false;
  params: any = {}
  hide: boolean = false;

  constructor(private injector: Injector, public ui_spinner: NgxUiLoaderService, public hs: HttpService, public http: HttpClient, public fs: FileService, public route: ActivatedRoute) { }
  ngOnInit() {
    console.log(this.multiple);
    this.route.queryParams.subscribe(params => {
      this.params = params
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl | null = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        // Component is missing form control binding
      }
    }, 100);
  }

  writeValue(value: any): void {
    this.value = value

  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  async on_value_change(event: any) {
    console.log('aaaaaaaaaa', event)
    try {
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i] as File;
        if (!this.check_file_exension(event.target.files[i])) {
          throw { message: 'File not Supported' }
        }

        let query = {
          content_type: file.type,
          file_extension: file.name.split('.').pop(),
          file_size: file.size
        }
        let attachment_response = await this.hs.get('/attachment/uplaod-attachment', query)
        console.log(attachment_response);
        const headers = new HttpHeaders({
          'Content-Type': file.type // Set the content type based on the file type
        });
        this.ui_spinner.startLoader('add-file-loader');
        let gcloud_response = await this.hs.put(attachment_response.url, file)
        if (this.multiple) {
          this.value = this.value ? this.value + ',' + attachment_response.file_name : attachment_response.file_name
        } else {
          this.value = attachment_response.file_name
          this.hide = true
        }
        console.log(this.value)
        this.file_model = ''
        this.onChange(this.value)
        this.ui_spinner.stopLoader('add-file-loader');
      }
    } catch (error) {
      this.ui_spinner.stopLoader('add-file-loader');
      console.log(error)
    }
  }

  check_file_exension(file: any) {
    if (file) {
      console.log('Selected file:', file.name);
      const allowed_extensions = this.supported_extension;
      console.log(allowed_extensions, "000000000000");
      const file_extension = file.name.split('.').pop()?.toLowerCase();
      if (file_extension && allowed_extensions.includes(file_extension)) {
        console.log(`File type ${file_extension} is supported`);
      } else {
        alert(`File type ${file_extension} is not supported`);
        return false
      }
    }
    return true
  }

  remove_attachment(index: number) {
    let temp = this.value.split(',').filter(item => item != '')
    temp.splice(index, 1)
    this.value = temp.join(',')
    this.onChange(this.value)
  }
  open_attachment(attachment: string) {
    if (!attachment.includes('.xlsx')) {
      this.open_media.open()
      this.file_url = this.fs.base_gcs_url + attachment
    }
  }

  open_attachment_link(attachment: string) {
    this.openInNewTab(this.fs.base_gcs_url + attachment)
  }

  openInNewTab(url: string) {
    var win = window.open(url, '_blank');
  }
}
