import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-cta',
  standalone: true,
  imports: [NgIf],
  templateUrl: './icon-cta.component.html'
})
export class IconCtaComponent {
  @Input() icon = ''
  @Input() tooltip = ''
  @Input() disabled: boolean = false
}
