<!-- Web Slim Sidebar -->
<div
    class="fixed p-3 w-full lg:h-full lg:w-28 flex lg:flex-col items-center justify-between lg:py-8 lg:px-2 box-shadow lg:gap-5 bg-white overflow-auto scrollbar-thin z-10 lg:z-[100]">
    <div class="w-full flex flex-col gap-5">
        <div class="flex items-center gap-5 justify-start lg:justify-center cursor-pointer">
            <i class='cursor-pointer lg:hidden text-2xl bx bx-menu' (click)="drawer_open = true"></i>
            <img routerLink="/dashboard" class="w-15 h-15" src="../../../assets/ict-logo.jpg" alt="logo">
        </div>
        <ng-container class="hidden lg:block" *ngFor="let link of ss.sidebar_data[gs.user.role]">
            <button class="hidden lg:block w-full text-center group" (click)="activateLink(link)"
                (mouseover)="mouseOver(link)" (mouseleave)="mouseLeave()">
                <i [classList]="link.icon"
                    class="text-2xl w-full rounded-full group-hover:bg-[#E1E8F5] group-hover:text-[#3F41D1]"
                    [ngClass]="{'text-[#FFF] bg-[#3F41D1]': link.label == main_link, 'text-[#666666] ': link.label != main_link, 'bg-[#E1E8F5] text-[#3F41D1]': link.label != main_link && link.is_open}"></i>
                <span [ngClass]="{'text-[#3F41D1]': link.label == main_link || link.is_open}"
                    class="group-hover:text-[#3F41D1] text-[#666666] cursor-pointer  whitespace-nowrap overflow-hidden text-ellipsis">{{ link.label}}</span>
            </button>
        </ng-container>
    </div>
    <button (click)="sign_out()" class="hidden lg:block w-full text-center">
        <i class="text-2xl bx bx-log-out w-full rounded-full group-hover:bg-[#E1E8F5] group-hover:text-[#3F41D1]"></i>
        <span class="block text-[#212121] cursor-pointer">Logout</span>
    </button>
</div>

<!-- Sub-menu Drawer -->
<div [ngClass]="{'-translate-x-full': !drawer_open, 'translate-x-0 lg:translate-x-24': drawer_open}"
    class="fixed p-5 top-0 h-full shadow-lg z-30 transform transition-all ease-in-out duration-300 overflow-x-auto md:scrollbar-thin lg:py-8 overflow-y-auto bg-white border-l border-r w-80 lg:w-60 rounded-e-xl">
    <h2 class="hidden lg:block px-5 text-lg font-medium text-[#212121] mb-2 p-3 rounded-full">{{current_link?.label}}
    </h2>
    <!-- asset-list.component.html -->

    <div *ngIf="gs.user.role == 'Instructor' || gs.user.role == 'Block Resource Coordinator' && current_link?.label == 'Attendance'"
        class="flex gap-2">
        <div *ngIf="(gs.attendnace?.check_in_time == null || gs.attendnace?.check_out_time == null) && gs.selected_school.name" class="flex gap-3">
            <button (click)="view_attandance_modal2.open(); getCurrentTime(true)"
                [disabled]="gs.attendnace?.check_in_time != null"
                class="bg-green-300 uppercase px-2 py-2 flex justify-center items-center text-sm font-medium focus:outline-none rounded-full border hover:shadow-sm">
                Check In
            </button>
            <button (click)="view_attandance_modal.open(); getCurrentTime(false)"
                [disabled]="gs.attendnace?.check_out_time == null && gs.attendnace?.check_in_time == null"
                class="bg-green-300 uppercase px-2 py-2 flex justify-center items-center text-sm font-medium focus:outline-none rounded-full border hover:shadow-sm">
                Check Out
            </button>
        </div>
    </div>
    <div class="cursor-pointer lg:hidden flex hover:bg-slate-100 h-10 items-center justify-center rounded-full text-2xl w-10"
        (click)="drawer_open = false">
        <i class='bx bx-x'></i>
    </div>

    <!-- Mobile Sublinks -->
    <div class="relative bg-white w-full overflow-hidden h-[80%] flex lg:hidden">
        <div [ngClass]="{'-translate-x-full': mobile_sub_sidebar}"
            class="transition-all ease-in-out duration-300 transform absolute w-full h-max bg-white">
            <ng-container *ngFor="let link of ss.sidebar_data[gs.user.role]">
                <div class="lg:hidden cursor-pointer flex items-center justify-between w-full p-2.5 rounded-full hover:bg-[#E1E8F5] gap-3"
                    (click)="toggle_sub_sidebar(link)">
                    <div class="flex items-center gap-3">
                        <i [classList]="link.icon" class="text-3xl w-full rounded-full"></i>
                        <h1 class="text-sm font-medium text-[#212121] capitalize">{{link.label}}</h1>
                    </div>
                    <i *ngIf="link.sublinks" class="cursor-pointer bx bx-right-arrow-alt text-2xl rounded-full"></i>
                </div>
            </ng-container>
        </div>
        <div [ngClass]="{ 'translate-x-full': !mobile_sub_sidebar}"
            class="transition-all ease-in-out duration-300 transform absolute w-full h-max bg-white">
            <div class="cursor-pointer flex items-center gap-3 p-2 hover:bg-slate-200 w-full rounded-full"
                (click)="toggle_sub_sidebar({})">
                <i class="cursor-pointer bx bx-left-arrow-alt text-2xl rounded-full"></i>
                <p>Main Menu</p>
            </div>
            <ng-container *ngIf="current_link">
                <div *ngFor="let link of current_link.sublinks"
                    class="ps-[40px] lg:hidden cursor-pointer flex items-center w-full py-3 rounded-full hover:bg-[#E1E8F5] gap-3"
                    (click)="mobile_sublink_clicked(link.router_link)" routerLinkActive="bg-[#E1E8F5]">
                    <h1 class="text-sm font-medium text-[#212121] capitalize">{{link.label}}</h1>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Web Sublinks -->
    <ng-container *ngIf="current_link">
        <div *ngFor="let link of current_link.sublinks"
            class="hidden cursor-pointer lg:flex items-center w-full px-3 h-10 my-2 rounded-full hover:bg-[#EBEBEB] gap-x-2"
            [routerLink]="link.router_link" [queryParams]="link.query_params" routerLinkActive="bg-[#E1E8F5]">
            <h1 (mouseleave)="web_sublink_clicked()" class="text-sm font-medium text-[#212121] capitalize">
                {{link.label}}</h1>
        </div>
    </ng-container>
</div>

<app-modal #view_attandance_modal title="Check Out" class="w-full m-8">
    <div [formGroup]="form" class="w-full max-w-full">
        <div class="flex flex-col gap-5 w-full">
            <div>
                <label for="message" class="text-sm font-medium text-gray-900">Enter Time</label>
                <input formControlName="check_out_time" type="time"
                    class="mt-2 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-blue-50">
            </div>
            <div class="w-full p-2 mt-2 mb-2 h-40">
                <img src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                    class="object-cover w-full h-full" alt="">
            </div>
        </div>
        <div class="flex flex-col gap-2 w-full mt-2">
            <p class="text-md font-semibold mt-3">Check Out Remark</p>
            <p class="text-sm mt-2">Remark</p>
            <textarea formControlName="remark"
                class="block p-2 w-full text-sm text-nowrap text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-blue-500"></textarea>
            <div class="flex flex-col lg:flex-row lg:justify-between justify-start w-full items-center gap-4 py-3">
                <p class="text-text-color-400 font-medium text-start text-sm mt-2">You Can Also Add Todays Report Along
                    with Images And Videos</p>
                <div class="flex items-start justify-start gap-2">
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end w-full gap-3 rounded-b-lg mt-4">
            <app-button (click)="onCheckout()" class="self-center" color="bg-primary-500 text-white"
                label="Submit"></app-button>
        </div>
    </div>
</app-modal>
<app-modal #view_attandance_modal2 title="Check In" class="w-full">
    <div [formGroup]="form" class="w-full max-w-full">
        <div class="flex flex-col gap-5 w-full">
            <div>
                <label for="message" class="text-sm font-medium text-gray-900">Enter Time</label>
                <input formControlName="check_in_time" type="time"
                    class="mt-2 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-blue-50">
            </div>

            <div class="w-full p-2 h-40">
                <img src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                    class="object-cover w-full h-full" alt="">
            </div>
        </div>
        <div class="flex items-center justify-end mt-4 w-full gap-3 p-4 rounded-b-lg">
            <app-button class="self-center" label="Cancel" (click)="view_attandance_modal2.close()"></app-button>
            <app-button class="self-center" color="bg-primary-500 text-white" label="Submit"
                (click)="onCheckIn()"></app-button>
        </div>
    </div>
</app-modal>
<app-confirmation-popup #check_out_redirect color="bg-[#3F41D1] text-white" router_link="/dashboard/daily-report/form"
    button_title="Add Report" confirmation_message="You Can Also Add Todays Report Along with Images And Videos"
    popup_title="Confirmation">
    <div class="w-full flex justify-between">
        <app-button color="bg-primary-500 text-white text-nowrap" label="skip" size="sm"
            (click)="check_out_redirect.close()"></app-button>
        <app-button color="bg-primary-500 text-white text-nowrap" label="Add Report" size="sm"
            (click)="onAddReportClick()"></app-button>
    </div>
</app-confirmation-popup>
<div *ngIf="location_permission_modal"
    class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
    <div class="w-[250px] md:w-[350px] bg-white shadow-lg rounded-md p-6 relative">
        <div class="flex flex-col items-center justify-center gap-2">
            <span class="h-[76px] w-[76px] flex justify-center items-center bg-[#E1E8F5] rounded-full">
                <i class='bx bx-map-pin text-5xl text-[#666666]'></i>
            </span>
            <h1 class='text-[24px] font-medium text-center tracking-tight'>Kindly Give Access to Your Location</h1>
            <p class='text-center text-[16px] text-black/60'>Your location service needs to be turned on in order to
                access the Website.</p>
        </div>
        <button type="button"
            class="mt-4 px-6 h-10 min-w-[150px] w-full rounded-full text-white text-sm font-semibold border-none outline-none bg-[#3F41D1] hover:bg-[#3F41D1] uppercase"
            (click)="location_permission_modal = false">enable Location</button>
    </div>
</div>