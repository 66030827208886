<div class="lg:flex h-screen w-full">
    <app-sidebar class="print:hidden z-20" [ngClass]="{'w-28':!link_clicked, 'w-[428px]': link_clicked}" (link_clicked)="handle_sidebar_width($event)"></app-sidebar>
    <div class="scrollbar-thin relative pt-[64px] lg:pt-0 bg-surface-100 w-full h-full overflow-y-auto">
        <router-outlet></router-outlet>
    </div>
</div>
<div *ngIf="is_school_modal" class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
    <div class="w-[250px] sm:w-max bg-white shadow-lg rounded-md p-6 relative">
        <div class="flex flex-col items-center justify-center gap-2">
            <span class="h-[76px] w-[76px] flex justify-center items-center rounded-full">
                <img class="w-full h-full" src="../../../assets/hubbble-hox-logo.png" alt="logo">
            </span>
            <h4 class="text-2xl text-[#333] font-medium text-center">Welcome to ICT Training, {{gs.user.name}}</h4>
            <ng-container *ngIf="list.data.length == 0">
                <p class="text-sm text-gray-500">No school assigned yet. Please contact to administration.</p>
                <div class="flex justify-end items-center w-full">
                    <button (click)="sign_out()" type="button" class="mt-4 px-6 h-10 rounded-full text-white text-sm font-medium border-none outline-none bg-[#3F41D1] hover:bg-[#3F41D1]">Logout</button>
                </div>
            </ng-container>
            <ng-container *ngIf="list.data.length > 0">
                <p class="text-sm text-gray-500">Please select your school from the list below to proceed.</p>
                <div class="flex items-center gap-4 justify-center">
                    <div *ngFor="let item of list.data" (click)="onSchoolChange(item)" [ngClass]="{'border-primary': item.school_name == selectedSchool, 'border-gray': item.school_name != selectedSchool}" class="bg-white h-[250px] w-[250px] rounded-lg hover:shadow border cursor-pointer">
                        <div class="p-4 md:p-5">
                            <i class='bx bxs-school text-5xl text-gray-400'></i>
                            <h3 class="text-xl font-medium text-text-color-600">{{item.name || item.school_name}}</h3>
                            <p class="text-gray-500 mb-2">{{item.school_address || item.address}}</p>
                            <span class="text-sm font-medium text-text-color-600">UDISE Code : {{item.udise_code ||
                                item.school_udise_code}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="flex justify-end items-center w-full">
            <button *ngIf="selectedSchool != ''" (click)="proceed()" type="button" class="mt-4 px-6 h-10 rounded-full text-white text-sm font-medium border-none outline-none bg-[#3F41D1] hover:bg-[#3F41D1]">Proceed</button>
        </div>
    </div>
</div>