import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-pill-button',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './filter-pill-button.component.html'
})
export class FilterPillButtonComponent {
  @Input() color: string = 'error';
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() selected: boolean = false;

}
