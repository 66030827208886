import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, Injector, Input, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-input',
  standalone: true,
  imports: [NgIf, NgFor, FormsModule],
  templateUrl: './select-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectInputComponent
    }
  ]
})
export class SelectInputComponent implements ControlValueAccessor {
  @Input() icon = '';
  @Input() label = '';
  @Input() options: any = [];
  @Input() placeholder = '';
  @Input() disabled: boolean = false;
  @Input() is_required: boolean = false;

  control: any;
  onChange: any;
  onTouched: any;
  value:any = ''
  display_value = ''
  paramValue: any;
  clear:any='Clear'
  constructor(private route: ActivatedRoute, private injector: Injector) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paramValue = params['view'];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      console.log('data changed:', changes['data'].currentValue);
      this.change_display_value(this.value)
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl | null = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        // Component is missing form control binding
      }
    }, 100);
  }

  writeValue(value: any): void {
    this.value = value
    this.change_display_value(this.value)
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
  change_display_value(value: string) {
    this.display_value = this.options?.find((item: any) => item.value == value)?.title
  }
}
