  <app-header header_title="Course Detail"></app-header>

<div class="print-section px-8 md:px-6 overflow-x-auto flex flex-col gap-5">
    <div class="flex justify-around bg-[#b6bbd9] rounded-lg">
        <div class="bg-[#b6bbd9] flex flex-col justify-center items-start">
            <p class="text-4xl font-semibold">Course Name</p>
            <p class="text-sm text-gray-600 font-semibold">Course Duration</p>
        </div>
        <figure class=" max-w-full transition-all duration-300 cursor-pointer">
              <img class="rounded-lg h-60 py-5" src="https://cdn.elearningindustry.com/wp-content/uploads/2022/05/shutterstock_1775158427.jpg" alt="image description">
          </figure>
    </div>
</div>

<div class="bg-white flex flex-col mx-6 md:mt-6 p-4 rounded-lg">

    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <p class="font-normal text-base text-[#212121]">Study Material</p>
        <p class="text-[#666666] font-medium text-sm mt-2">This description prompts users to fill in all mandatory
            fields accurately to create a new Cource
            record, emphasizing the importance of providing precise information for a smooth process.</p>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
            <app-select-input label="Grade"
                [options]="[{title: 'Grade 6'},{title: 'Grade 7'},{title: 'Grade 8'},{title: 'Grade 9'},{title: 'Grade 10'}]"></app-select-input>
            <app-text-input label="Course Name"></app-text-input>
            <app-text-input label="Course Duration"></app-text-input>
            <app-text-input label="Course Code"></app-text-input>
            <app-select-input label="Academic Year"
                [options]="[{title: '2020-21'},{title: '2021-22'},{title: '2022-23'},{title: '2023-24'},{title: '2024-25'}]"></app-select-input>
        </div>
        <div class="bg-white flex flex-col md:mt-5 p-4 rounded-lg">
            <p class="font-normal text-base capitalize text-text-color-600">Upload Document</p>
            <!-- <p class="text-text-color-500 font-medium text-sm mt-2">
                To create a new batch, please ensure all mandatory fields are completed accurately. Each field serves a
                specific purpose in facilitating the batch creation process.
            </p> -->
            <div class="flex flex-col overflow-y-auto gap-4 mt-4">
               
                <div class="flex gap-4">
                    <div *ngFor="let img of images,let i=index" class="bg-gray-100 flex flex-col gap-2 rounded-md h-32 w-24 p-2">
                        <!-- <img class="bg-white rounded" [src]="img.image" alt=""> -->
                        <!-- <i (click)="open_image.open()" class='bx bx-image-alt  bg-white m-auto rounded-md text-4xl p-4 px-5 cursor-pointer hover:text-blue-600 transition-all duration-300' ></i> -->
                         <!-- <img (click)="open_image.open()" class="h-20 min-w-20 cursor-pointer rounded-md" src="https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg" alt=""> -->
                         <div *ngIf="imageUrl">
                            <img (click)="open_image.open()" (click)="previewImages=i" class="cursor-pointer h-20 w-full rounded-md" [src]="img" alt="Uploaded Image">
                          </div>
                         <div class="flex justify-around items-center">
                            <i (click)="edit.open()" (click)="option='Image'" class='bx bxs-edit text-xl cursor-pointer hover:text-blue-600'></i>
                            <i (click)="delete.open()" (click)="option='Image'" class='bx bxs-trash text-xl cursor-pointer hover:text-blue-600'></i>
                        </div>
                    </div>
                    <label for="fileInput" class="bg-gray-100 flex flex-col gap-2 rounded h-32 cursor-pointer w-24 p-2 transition-all duration-300">
                        <i class='bx bx-plus bg-white rounded-full text-4xl p-2 m-auto text-center transition-all duration-300'></i>
                        <div class="flex justify-around items-center">
                            <span class="text-sm text-nowrap"><input type="file" class="bg-transparent w-full" (change)="onFileSelected($event)"></span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <div class="bg-white flex flex-col md:mt-5 p-4 rounded-lg">
            <p class="font-normal text-base capitalize text-text-color-600">Upload Images</p>
            <!-- <p class="text-text-color-500 font-medium text-sm mt-2">
                To create a new batch, please ensure all mandatory fields are completed accurately. Each field serves a
                specific purpose in facilitating the batch creation process.
            </p> -->
            <div class="flex flex-col overflow-y-auto gap-4 mt-4">
               
                <div class="flex gap-4">
                    <div *ngFor="let img of images,let i=index" class="bg-gray-100 flex flex-col gap-2 rounded-md h-32 w-24 p-2">
                        <!-- <img class="bg-white rounded" [src]="img.image" alt=""> -->
                        <!-- <i (click)="open_image.open()" class='bx bx-image-alt  bg-white m-auto rounded-md text-4xl p-4 px-5 cursor-pointer hover:text-blue-600 transition-all duration-300' ></i> -->
                         <!-- <img (click)="open_image.open()" class="h-20 min-w-20 cursor-pointer rounded-md" src="https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg" alt=""> -->
                         <div *ngIf="imageUrl">
                            <img (click)="open_image.open()" (click)="previewImages=i" class="cursor-pointer h-20 w-full rounded-md" [src]="img" alt="Uploaded Image">
                          </div>
                         <div class="flex justify-around items-center">
                            <i (click)="edit.open()" (click)="option='Image'" class='bx bxs-edit text-xl cursor-pointer hover:text-blue-600'></i>
                            <i (click)="delete.open()" (click)="option='Image'" class='bx bxs-trash text-xl cursor-pointer hover:text-blue-600'></i>
                        </div>
                    </div>
                    <label for="fileInput" class="bg-gray-100 flex flex-col gap-2 rounded h-32 cursor-pointer w-24 p-2 transition-all duration-300">
                        <i class='bx bx-plus bg-white rounded-full text-4xl p-2 m-auto text-center transition-all duration-300'></i>
                        <div class="flex justify-around items-center">
                            <span class="text-sm text-nowrap"><input type="file" class="bg-transparent w-full" (change)="onFileSelected($event)"></span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class=" bg-white flex flex-col overflow-hidden p-4 rounded shadow w-full">
        <div class="bg-white flex flex-col md:mt-5 p-4 rounded-lg">
            <p class="font-normal text-base capitalize text-text-color-600">Upload Video</p>
            <!-- <p class="text-text-color-500 font-medium text-sm mt-2">
                To create a new batch, please ensure all mandatory fields are completed accurately. Each field serves a
                specific purpose in facilitating the batch creation process.
            </p> -->
            <div class="flex flex-col overflow-y-auto gap-4 mt-4">
               
                <div class="flex gap-4">
                    <div *ngFor="let img of images,let i=index" class="bg-gray-100 flex flex-col gap-2 rounded-md h-32 w-24 p-2">
                        <!-- <img class="bg-white rounded" [src]="img.image" alt=""> -->
                        <!-- <i (click)="open_image.open()" class='bx bx-image-alt  bg-white m-auto rounded-md text-4xl p-4 px-5 cursor-pointer hover:text-blue-600 transition-all duration-300' ></i> -->
                         <!-- <img (click)="open_image.open()" class="h-20 min-w-20 cursor-pointer rounded-md" src="https://pm1.aminoapps.com/6810/3bebcad4ea32fd50b0bee6be2fcb2c6362d52b5ev2_hq.jpg" alt=""> -->
                         <div *ngIf="imageUrl">
                            <img (click)="open_image.open()" (click)="previewImages=i" class="cursor-pointer h-20 w-full rounded-md" [src]="img" alt="Uploaded Image">
                          </div>
                         <div class="flex justify-around items-center">
                            <i (click)="edit.open()" (click)="option='Image'" class='bx bxs-edit text-xl cursor-pointer hover:text-blue-600'></i>
                            <i (click)="delete.open()" (click)="option='Image'" class='bx bxs-trash text-xl cursor-pointer hover:text-blue-600'></i>
                        </div>
                    </div>
                    <label for="fileInput" class="bg-gray-100 flex flex-col gap-2 rounded h-32 cursor-pointer w-24 p-2 transition-all duration-300">
                        <i class='bx bx-plus bg-white rounded-full text-4xl p-2 m-auto text-center transition-all duration-300'></i>
                        <div class="flex justify-around items-center">
                            <span class="text-sm text-nowrap"><input type="file" class="bg-transparent w-full" (change)="onFileSelected($event)"></span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>

</div>

<app-confirmation-popup #edit color="bg-[#3F41D1] text-white" button_title="Edit" confirmation_message="Are you sure you want to Edit this {{option}}?" popup_title="Edit {{option}}"></app-confirmation-popup>
<app-confirmation-popup #delete color="bg-[#3F41D1] text-white" button_title="Delete" confirmation_message="Are you sure you want to Delete this {{option}}?" popup_title="Delete {{option}}"></app-confirmation-popup>

<app-modal #open_image title="Image">
    <img class="rounded-2xl" [src]="images[previewImages]" alt="Study Image">
</app-modal>